//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import Modal from "../common_modal/Modal";
import TextFieldGroup from "../common_input/TextFieldGroup";
import ChartTemplate from "./ChartTemplate";

//Redux function
import {
  set_item_on_layer,
  view_layer,
  clear_tanggal,
  set_data_array,
  set_value_layer,
  filter_isi_layer,
  reset_fields,
} from "../../App/actions/dataActions";

//Picture Asset

//General Function
import generate_array_time from "../../App/validation/generate_array_time";
import dict from "../../Data/dict.json";

const language = localStorage?.language ? localStorage?.language : "ina";

class CountingChart extends Component {
  state = {
    data_array: [],
    startdate: "",
    enddate: "",
    modal_chart_fullscreen: false,
  };

  toggleChartFulscreen = () => {
    this.setState({
      modal_chart_fullscreen: !this.state.modal_chart_fullscreen,
    });
  };

  // componentDidMount() {
  //   this.on_set_mode_chart("10");
  // }

  on_set_mode_chart = (mode) => {
    const { layer_id, geo_layer_list } = this.props.layer;
    const layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);

    const field_date = this.props.field_date;
    const field_key = field_date.key;

    let data_array = [];

    if (mode === "all") {
      if (layer.geo_layer.geojson.features?.length > 0) {
        const f = layer.geo_layer.geojson.features[0];

        const date_value = f.properties[field_key];

        let date_test = `${date_value}T00:00:00`;
        date_test = new Date(date_test).getTime();

        //generate chart data
        data_array = generate_array_time(mode, date_test);

        this.props.set_value_layer("mode_chart", mode);
        this.props.set_data_array({ data_array, mode, field_key });
      }
    } else {
      //generate chart data
      data_array = generate_array_time(mode);

      this.props.set_value_layer("mode_chart", mode);
      this.props.set_data_array({ data_array, mode, field_key });
    }

    //filter feature
    if (mode !== "10") {
      const start_date = data_array[data_array.length - 1].x;
      const end_date = data_array[0].x;

      const body = {
        geo_layer_id: layer_id,
        start_date,
        end_date,
        field_key,
        time_mode: "unix",
      };
      this.props.filter_isi_layer(body);
    } else {
      this.props.clear_tanggal();
      const body = { geo_layer_id: layer_id };
      this.props.reset_fields(body);
    }
  };

  on_click_chart = (e, d) => {
    if (d?.[0]?.["element"]?.["$context"]?.raw) {
      const data = d?.[0]?.["element"]?.["$context"]?.raw;
      const { layer_id } = this.props.layer;

      const field_date = this.props.field_date;
      const field_key = field_date.key;
      // const layer = geo_layer_list.find(
      //   (l) => l.geo_layer._id === layer_id
      // ).geo_layer;
      // let geojson = layer.geojson;

      // const body = {
      //   key: "geojson_filtered",
      //   value: geojson,
      //   layer_id,
      // };
      // this.props.set_item_on_layer(body);

      const end_date = parseInt(data.x);
      const start_date = end_date - 3_600 * 1_000 * 24;

      const body = {
        geo_layer_id: layer_id,
        start_date,
        end_date,
        field_key,
        time_mode: "unix",
      };
      this.props.filter_isi_layer(body);
    }
  };

  on_reset = () => {
    this.props.clear_tanggal();
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { layer_id } = this.props.layer;

    const { startdate, enddate } = this.state;
    const field_date = this.props.field_date;
    const field_key = field_date.key;

    const body = {
      geo_layer_id: layer_id,
      start_date: startdate,
      end_date: enddate,
      field_key,
      time_mode: "string",
    };
    this.props.filter_isi_layer(body);
  };

  modeToTitle = (mode) => {
    switch (mode) {
      case "month":
        return dict["A Month"][language];
      case "6_months":
        return dict["6 Months"][language];
      case "year":
        return dict["A Year"][language];
      case "all":
        return dict["All"][language];
      default:
        break;
    }
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { mode_chart } = this.props.map;
    const { startdate, enddate } = this.state;

    const { layer_id, geo_layer_list } = this.props.layer;
    const layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);
    const data_array = layer?.geo_layer?.data_array || [];

    const optionsBar = {
      onClick: this.on_click_chart,
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      maintainAspectRatio: true,
      legend: {
        display: false,
      },
      responsive: true,
      scales: {
        x: {
          type: "time",
          title: {
            display: true,
            text: "Waktu/tanggal",
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Jumlah laporan",
          },
          display: true,
        },
      },
    };

    const data = {
      datasets: [
        {
          data: data_array,
          label: "Jumlah laporan",
          backgroundColor: "#3d3f92",
          hoverBackgroundColor: "#8fcc1bff",
          borderColor: "#ffcd2c",
          lineTension: 0.2,
        },
      ],
    };

    const modalChartFullscreen = this.state.modal_chart_fullscreen && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modal_chart_fullscreen}
        onClose={(params) => this.toggleChartFulscreen(params)}
      >
        <div className="box-body">
          <main className="max_height">
            <h1 className="text_header margin_bottom">
              Rangkuman banjir berdasarkan hari pelaporan
            </h1>
            <div>
              <ChartTemplate
                type="line"
                data={data}
                options={{
                  onClick: this.on_click_chart,
                  animation: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: true,
                  legend: {
                    display: false,
                  },
                  responsive: true,
                  scales: {
                    x: {
                      type: "time",
                      title: {
                        display: true,
                        text: "Waktu/tanggal",
                      },
                    },
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: "Jumlah laporan",
                      },
                      display: true,
                    },
                  },
                }}
                width={100}
                height={50}
              />
            </div>
          </main>
        </div>
      </Modal>
    );

    const field_date = this.props.field_date;

    return (
      <>
        {modalChartFullscreen}
        <main className="container_standard margin_bottom" id="grey_light">
          <h1 className="text_bold margin_bottom">
            {dict["Grouping by"][language]} {field_date?.name}
          </h1>
          <section>
            <button
              className="button_pill_tight margin_1"
              id={mode_chart === "10" ? "outline_blue" : "outline_white"}
              onClick={this.on_set_mode_chart.bind(this, "10")}
            >
              10 Terbaru
            </button>
            {/* <button
            className="button_pill_tight margin_1"
            id={mode_chart === "day" ? "outline_blue" : "outline_white"}
            onClick={this.on_set_mode_chart.bind(this, "day")}
          >
            1 Hari
          </button>
          <button
            className="button_pill_tight margin_1"
            id={mode_chart === "week" ? "outline_blue" : "outline_white"}
            onClick={this.on_set_mode_chart.bind(this, "week")}
          >
            1 Minggu
          </button> */}
            <button
              className="button_pill_tight margin_1"
              id={mode_chart === "month" ? "outline_blue" : "outline_white"}
              onClick={this.on_set_mode_chart.bind(this, "month")}
            >
              1 Bulan
            </button>
            <button
              className="button_pill_tight margin_1"
              id={mode_chart === "6_months" ? "outline_blue" : "outline_white"}
              onClick={this.on_set_mode_chart.bind(this, "6_months")}
            >
              6 Bulan
            </button>
            <button
              className="button_pill_tight margin_1"
              id={mode_chart === "year" ? "outline_blue" : "outline_white"}
              onClick={this.on_set_mode_chart.bind(this, "year")}
            >
              1 Tahun
            </button>
            <button
              className="button_pill_tight margin_1"
              id={mode_chart === "all" ? "outline_blue" : "outline_white"}
              onClick={this.on_set_mode_chart.bind(this, "all")}
            >
              Semua
            </button>
          </section>

          <section>
            <div style={{ width: "100%", textAlign: "end" }}>
              <button
                onClick={() => {
                  this.toggleChartFulscreen();
                }}
                className="button_small"
              >
                Fullscreen
              </button>
            </div>
            {mode_chart === "6_months" ||
            mode_chart === "year" ||
            mode_chart === "all" ? (
              <main>
                <section style={{ overflowX: "scroll" }}>
                  <div
                    style={{
                      width: "5000px",
                    }}
                  >
                    <ChartTemplate
                      type="bar"
                      data={data}
                      options={optionsBar}
                      width={100}
                      height={5}
                    />
                  </div>
                </section>
                <section className="text_center margin_top">
                  <button className="button_small">
                    {`< Bisa discroll kesamping >`}
                  </button>
                </section>
              </main>
            ) : (
              <section>
                <ChartTemplate
                  type="bar"
                  data={data}
                  options={optionsBar}
                  width={100}
                  height={75}
                />
              </section>
            )}
            <table
              className="table_lite"
              style={{ tableLayout: "fixed", width: "100%" }}
            >
              <tbody>
                <tr>
                  <td>
                    <TextFieldGroup
                      placeholder={dict["Select Start Date"][language]}
                      type="date"
                      name="startdate"
                      id="startdate"
                      value={startdate}
                      onChange={this.handleChange}
                    />
                  </td>
                  <td>
                    <TextFieldGroup
                      placeholder={dict["Select End Date"][language]}
                      type="date"
                      name="enddate"
                      id="enddate"
                      value={enddate}
                      onChange={this.handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <p className="button" onClick={this.handleSubmit}>
                      Filter tanggal
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <button className="button" id="red" onClick={this.on_reset}>
                      Reset lihat 10 terbaru saja
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  map: state.map,
});

export default connect(mapStateToProps, {
  set_item_on_layer,
  view_layer,
  clear_tanggal,
  set_data_array,
  set_value_layer,
  filter_isi_layer,
  reset_fields,
})(CountingChart);
