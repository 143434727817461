/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";
import bbox from "@turf/bbox";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class LayerInsight extends Component {
  state = {
    render_content: null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.sini.state_update !== prevProps.sini.state_update) {
      this.on_render_content();
    }

    if (this.props.sini.fly_update !== prevProps.sini.fly_update) {
      this.on_fly_content();
    }
  }

  on_render_content = () => {
    //local storage

    //local state

    //global props
    const { features_poi, features_demografi } = this.props.sini;

    //content
    if (features_demografi.length > 0) {
      const geojson_demografi = {
        type: "FeatureCollection",
        features: features_demografi,
      };
      const geojson_poi = {
        type: "FeatureCollection",
        features: features_poi,
      };

      const render_content = (
        <>
          <Source
            key="DEMOGRAFI_INSIGHT"
            id="DEMOGRAFI_INSIGHT"
            type="geojson"
            data={geojson_demografi}
          />
          <Layer
            id="DEMOGRAFI_INSIGHT"
            before="POI_INSIGHT"
            type="line"
            source="DEMOGRAFI_INSIGHT"
            paint={{
              "line-color": "#db1f2e",
              "line-width": 1,
            }}
            layout={{
              visibility: "visible",
            }}
            onClick={this.props.layerOnClick}
          />
          <Source
            key="POI_INSIGHT"
            id="POI_INSIGHT"
            type="geojson"
            data={geojson_poi}
          />
          <Layer
            id="POI_INSIGHT"
            before=""
            type="circle"
            source="POI_INSIGHT"
            paint={{
              "circle-color": "#000000",
              "circle-radius": 3,
              "circle-stroke-width": 2,
              "circle-stroke-color": "#fff",
            }}
            layout={{
              visibility: "visible",
            }}
            onClick={this.props.layerOnClick}
          />
        </>
      );
      this.setState({ render_content });
    }
  };

  on_fly_content = () => {
    //local storage

    //local state

    //global props
    const map = this?.props?.map?.map;
    const { features_demografi } = this.props.sini;

    //content
    const geojson_demografi = {
      type: "FeatureCollection",
      features: features_demografi,
    };
    const [minLng, minLat, maxLng, maxLat] = bbox(geojson_demografi);
    if (map) {
      let padding = { top: 100, bottom: 100, left: 500, right: 500 };
      if (window.innerWidth < 1172) {
        padding = {
          top: 50,
          bottom: 400,
          left: 10,
          right: 10,
        };
      }
      map?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding,
          maxZoom: 22,
        }
      );
    }
  };

  render() {
    return <div>{this.state.render_content}</div>;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  sini: state.sini,
});

export default connect(mapStateToProps, {})(LayerInsight);
