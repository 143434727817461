const format_date_formal = function (timestamp) {
  const language = localStorage?.language || "ina";
  const date = new Date(timestamp);
  const months_ina = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const months_eng = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let months_array;
  if (language === "ina") {
    months_array = months_ina;
  } else {
    months_array = months_eng;
  }

  const tanggal = date.getDate();
  let month_final = months_array[date.getMonth()];
  let date_final = tanggal + " " + month_final + " " + date.getFullYear();
  return date_final;
};

export default format_date_formal;
