/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Marker } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  set_value_sini,
  fly_init_update,
  get_ai_parent_object,
} from "../../App/actions/sini_v2";

/*PICTURE ASSET*/
import ICON_MARKER from "../../Assets/jsx/ICON_MARKER";
import ICON_MARKER_SELECTED from "../../Assets/jsx/ICON_MARKER_SELECTED";

/*GENERAL*/

/*NON IMPORT*/

class LAYER_SINI_CENTER extends Component {
  state = {
    render_content: null,
  };

  componentDidMount() {
    this.on_render_layer();
  }

  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    if (state_update_after !== state_update_before) {
      this.on_render_layer();
    }
  }

  on_render_layer = () => {
    const {
      request_id_loading,
      request_id_active,
      sini_v2_list,
      ai_parent_list, //di titik center tetap pakai ai_parent_list karena di list sudah ada lat & long (supaya data yang diload lebih ringan)
      is_sini_visible,
    } = this.props.sini;

    let render_content;

    if (is_sini_visible) {
      const local_layer = sini_v2_list.map((item, idx) => {
        const { request_id, lat, long } = item;

        let content;

        if (item.request_id !== request_id_loading) {
          content = (
            <>
              <Marker
                longitude={long}
                latitude={lat}
                draggable={false}
                anchor="bottom"
                onClick={this.on_click_marker.bind(this, request_id)}
              >
                {request_id === request_id_active ? (
                  <ICON_MARKER_SELECTED />
                ) : (
                  <ICON_MARKER />
                )}
              </Marker>
            </>
          );
        }
        return <div key={idx}>{content}</div>;
      });

      const db_layer = ai_parent_list.map((item, idx) => {
        const { request_id, lat, long } = item;
        let content;

        if (item.request_id !== request_id_loading) {
          content = (
            <>
              <Marker
                longitude={long}
                latitude={lat}
                draggable={false}
                anchor="bottom"
                onClick={this.on_click_marker.bind(this, request_id)}
              >
                {request_id === request_id_active ? (
                  <ICON_MARKER_SELECTED />
                ) : (
                  <ICON_MARKER />
                )}
              </Marker>
            </>
          );
        }
        return <div key={idx}>{content}</div>;
      });

      render_content = (
        <>
          {local_layer}
          {db_layer}
        </>
      );
    }

    this.setState({ render_content: render_content });
  };

  on_click_marker = (request_id) => {
    this.props.set_value_sini({
      key: "request_id_active",
      value: request_id,
    });

    this.props.fly_init_update();
    const { ai_parent_detail_list, sini_v2_list, ai_parent_list } =
      this.props.sini;
    const sini_db_object_test = ai_parent_detail_list.find(
      (item) => item.request_id === request_id
    );
    const sini_local_test = sini_v2_list.find(
      (item) => item.request_id === request_id
    );

    if (!sini_db_object_test?.request_id && !sini_local_test?.request_id) {
      const sini_object_test = ai_parent_list.find(
        (item) => item.request_id === request_id
      );

      const lat = sini_object_test?.lat;
      const long = sini_object_test?.long;

      if (lat && long) {
        this.props.set_value_sini({
          key: "request_id_loading",
          value: request_id,
        });
        this.props.set_value_sini({
          key: "sini_marker_active",
          value: {
            lat: lat,
            long: long,
          },
        });
      }

      const params = {
        request_id: request_id,
      };
      this.props.get_ai_parent_object(params);
    }
  };

  render() {
    return <>{this.state.render_content}</>;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
});

export default connect(mapStateToProps, {
  set_value_sini,
  fly_init_update,
  get_ai_parent_object,
})(LAYER_SINI_CENTER);
