/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import QRCode from "qrcode.react";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import ParentTable from "./PARENT_TABLE_NESTED_VIEWER";

/*REDUX FUNCTION*/
import { set_value_layer } from "../../App/actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import phoneNumberList from "../../Data/PhoneNumber.json";

/*NON IMPORT*/

class CellReturnValue extends Component {
  state = {
    modal_table: false,
    modal_view_image: false,
  };

  toggle_view_image = () => {
    this.setState({ modal_view_image: !this.state.modal_view_image });
  };

  toggle_table = (field_parent, feature_parent, child) => {
    this.setState({ modal_table: !this.state.modal_table });
    if (field_parent) {
      this.props.set_value_layer("field_parent", field_parent);
      this.props.set_value_layer("feature_parent", feature_parent);
      this.props.set_value_layer("child", child);
    } else {
      //bagaimana cara balikin ke parent fieldnya lagi
      this.props.set_value_layer("child", null);
      const child = this.props.child;
      if (child) {
        const { layer_id, geo_layer_list, feature_parent } = this.props.layer;
        const layer = geo_layer_list.find(
          (l) => l.geo_layer._id === layer_id
        )?.geo_layer;
        const fields = layer?.fields || [];
        let child_array = feature_parent?.child_array || [];
        const item = child_array.find(
          (c) => c?.parent_uuid_temp === child?.child_uuid
        );
        const parent_uuid = item?.parent_uuid;
        const field_parent_new = fields.find((f) => f.uuid === parent_uuid);
        this.props.set_value_layer("field_parent", field_parent_new);
        this.props.set_value_layer("child", undefined);
      }
    }
  };

  searchTheCountryCode = (value) => {
    const n_of_number = phoneNumberList.length;
    for (let index = 0; index < n_of_number; index++) {
      const countryCode = Number(
        String(value).slice(0, String(phoneNumberList[index]).length)
      );

      if (phoneNumberList[index] === countryCode) {
        return {
          countryCode: countryCode,
          phoneNumber: this.searchTheLocalNumber(index, value),
        };
      }
    }
  };

  searchTheLocalNumber = (index, value) => {
    return String(value).slice(String(phoneNumberList[index]).length);
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { modal_table, modal_view_image } = this.state;

    //global props
    const { shared_link_check } = this.props.properties;

    const field = this.props.field;
    const feature = this.props.feature;
    const child = this.props.child;

    //content
    const role = shared_link_check?.role;

    let value = this.props.value || "";
    if (
      !isNaN(value) &&
      (value || value === 0) &&
      field.type !== "phone_number"
    ) {
      value = new Intl.NumberFormat("id-ID", {
        style: "decimal",
      }).format(Number(value));
    }
    const modal_view_image_content = modal_view_image && (
      <Modal
        modalSize="large"
        isOpen={modal_view_image}
        onClose={this.toggle_view_image}
      >
        <div className="box-body">
          <main className="max_hight text_center">
            <img
              alt={value}
              src={value}
              style={{
                maxHeight: "90%",
                maxWidth: "90%",
              }}
            />
          </main>
        </div>
      </Modal>
    );

    const modal_table_content = modal_table && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_table}
        onClose={this.toggle_table}
      >
        <div className="box-body">
          <ParentTable />
        </div>
      </Modal>
    );

    let content = <>{value}</>;

    switch (field.type) {
      case "table":
        content = (
          <button
            className="button"
            onClick={this.toggle_table.bind(this, field, feature, child)}
          >
            Buka tabel
          </button>
        );
        break;

      case "text":
        content = <div>{value}</div>;
        break;

      case "phone_number":
        const result = this.searchTheCountryCode(value);
        const countryCode = result?.countryCode;
        const phoneNumber = result?.phoneNumber;
        if (
          phoneNumber === undefined ||
          `${phoneNumber}` === "undefined" ||
          `${phoneNumber}` === "null" ||
          `${phoneNumber}` === "NaN" ||
          `${phoneNumber}` === ""
        ) {
          content = "";
        } else {
          content = (
            <a
              rel="noopener noreferrer"
              href={`https://wa.me/${value}`}
              target="_blank"
              className="button no_wrap"
              id="blue_light"
            >
              {`+${countryCode} ${phoneNumber}`}
            </a>
          );
        }
        break;

      case "jarak_apraisal":
        content = <div>{value} meter</div>;
        break;

      case "nilai_pembanding":
        content = (
          <b>
            Rp.
            {value}
          </b>
        );
        break;

      case "textarea":
        content = (
          <div
            // className="badge_normal"
            style={{
              fontSize: "13px",
              overflowX: "none",
            }}
          >
            {value}
          </div>
        );
        break;

      case "number":
        content = <div style={{ textAlign: "right" }}>{value}</div>;
        break;

      case "math_operators":
        content = <div style={{ textAlign: "right" }}>{value}</div>;
        break;

      case "range":
        content = <div style={{ textAlign: "right" }}>{value}</div>;
        break;

      case "currency":
        content = <div style={{ textAlign: "right" }}>{value}</div>;
        break;

      case "year":
        content = <div style={{ textAlign: "right" }}>{value}</div>;
        break;

      case "date":
        content = (
          <div style={{ textAlign: "right" }}>
            {value === "NaN-NaN-NaN" ? "" : value}
          </div>
        );
        break;

      case "time":
        content = <div style={{ textAlign: "right" }}>{value}</div>;
        break;

      case "link":
        let link;
        if (value?.includes("http")) {
          link = value;
        } else {
          link = `https://${value}`;
        }
        content = (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            // style={{ maxWidth: "400px" }}
          >
            {value}
          </a>
        );
        break;

      case "document":
        content =
          role === "viewer" ? (
            <div className="button_small background_grey">Download disable</div>
          ) : value ? (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              className="button_small"
              id="blue"
            >
              {dict["Open file"][language]}
            </a>
          ) : (
            <div className="button_small" id="blue">
              {dict["No files"][language]}
            </div>
          );
        break;

      case "image":
        content = value ? (
          <img
            onClick={this.toggle_view_image}
            alt="GEO MAPID"
            style={{
              maxWidth: "100px",
              maxHeight: "100px",
              cursor: "pointer",
            }}
            src={value}
          />
        ) : (
          <div>No image</div>
        );
        break;

      case "qrcode":
        content = (
          <div style={{ textAlign: "center" }}>
            <QRCode value={value} />
            <p style={{ marginTop: 5, fontWeight: "bold" }}>{value}</p>
          </div>
        );
        break;

      case "driver_tracking":
        if (value) {
          const driver = JSON.parse(value);
          content = (
            <div style={{}}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    borderRadius: "50%",
                    width: "3rem",
                    height: "3rem",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundImage: `url('${driver.image}')`,
                  }}
                ></div>
                <div style={{ marginLeft: "4px" }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      marginBottom: "4px",
                    }}
                  >
                    {driver.name}
                  </span>
                  <br />
                  <span>{driver.phone}</span>
                </div>
              </div>
              <div>
                <button
                  className="button"
                  onClick={() => this.props.deleteDriver(feature?.key)}
                >
                  Hapus Pengemudi
                </button>
              </div>
            </div>
          );
        } else {
          content = <div>-</div>;
        }
        break;

      default:
        content = <div style={{ textAlign: "right" }}>{value}</div>;
        break;
    }

    return (
      <>
        {modal_table_content}
        {modal_view_image_content}
        {content}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_layer })(CellReturnValue);
