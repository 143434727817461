import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { upload_photo_profile } from "../../App/actions/authActions";
import icon_photo from "../../Assets/svg/icon_photo.svg";
import uuid from "../../App/validation/uuid";

class UploadPhotoProfile extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
  }
  handleSubmit(e) {
    e.preventDefault();
    let field = this.props.field;
    const file = this.fileInput.current.files[0];
    const date_now = moment(Date.now()).format("X");
    const objParams = {
      file: file,
      file_name_big: "pp_big_" + date_now + "_" + uuid(),
      file_name_small: "pp_small_" + date_now + "_" + uuid(),
      dirname_small: "foto_profile_small",
      dirname_big: "foto_profile_big",
    };
    let finalObject;
    finalObject = {
      objParams,
      field,
    };
    this.props.upload_photo_profile(finalObject);
  }
  render() {
    const itemContent = (
      <label
        className="fileContainer upload_pp_container vertical_center"
        style={{
          backgroundImage: `url(${icon_photo})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          className="vertical_horizontal_center_child"
          style={{ cursor: "pointer", fontSize: "20px", width: "100%" }}
        >
          <input
            style={{ cursor: "pointer" }}
            type="file"
            accept="image/*"
            ref={this.fileInput}
            onChange={this.handleSubmit}
          />
        </div>
      </label>
    );
    return <div>{itemContent}</div>;
  }
}
UploadPhotoProfile.propTypes = {
  upload_photo_profile: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({});
export default connect(mapStateToProps, {
  upload_photo_profile,
})(UploadPhotoProfile);
