import axios from "axios";
import uuid from "../validation/uuid";
import imageCompression from "browser-image-compression";
import his from "./history";
import { geoServerBaseUrl, uploadServerUrl } from "./baseUrl";
import { generate_array_templates } from "../../App/validation/generate_array_templates";
import { edit_child_cell } from "./crudNestedTable";
import { packThePropertiesDataToUseApiEditNested } from "../validation/convert_data";

const SERVER_URL = geoServerBaseUrl;
const UPLOAD_URL = uploadServerUrl;

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

/*PROTOTYPE*/

export const push_field_form_reorder = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    dispatch(setLoadingProcess("push_field_form_reorder"));

    let fields = body.fields;
    const geo_layer_id = body.geo_layer_id;
    const field = body.field;
    const to_uuid = body.to_uuid;
    const from_uuid = body.field.uuid;

    // step 1
    // push field yang baru saja dibuat ke fields
    fields.push(field);

    const from = fields.findIndex((f) => f.uuid === from_uuid);
    const to = fields.findIndex((f) => f.uuid === to_uuid);

    // step 2
    // slice reorder
    fields.splice(to, 0, fields.splice(from, 1)[0]);

    // step 3
    // panggil dispatch edit all field
    const newBody = {
      geo_layer_id: geo_layer_id,
      fields: fields,
    };

    await axios.post(SERVER_URL + "/layers/edit_all_fields", newBody, config);

    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Upload foto pertanyaan di form
export const upload_picture_field = (item) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("upload_picture_field"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.token_mapid,
        "Content-Type": "multipart/form-data",
      },
    };
    //Original file
    let { objParams, geo_layer_id, field } = item;
    const file = objParams.file;
    //Compressed file small
    const options_compression = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
    };
    const file_compressed = await imageCompression(file, options_compression);
    const file_name = objParams.file_name;
    // form data upload gambar
    const formData = new FormData();
    formData.append("original_file_name", `${file_name}`);
    formData.append("file", file_compressed);
    const result_upload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const url = result_upload.data.data.Location;
    //Generate body & axios to API
    field.picture_url = url;
    const body = {
      geo_layer_id,
      field,
    };
    await axios.post(SERVER_URL + "/layers/edit_field", body, config_header);
    dispatch(clearLoading());
    dispatch({
      type: "EDIT_FIELD_FORM",
      payload: body,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

/*NON-API*/

export const clearLayer = () => {
  return {
    type: "CLEAR_LAYER",
  };
};

export const setLoadingProcess = (itemLoading) => {
  return {
    type: "SET_LOADING_PROCESS_LAYER",
    payload: itemLoading,
  };
};

export const setLoadingDetail = () => {
  return {
    type: "SET_LOADING_DETAIL_LAYER",
  };
};

export const setLoadingList = () => {
  return {
    type: "SET_LOADING_LIST_LAYER",
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_LAYER",
  };
};

export const setLayerImport = (geo_layer) => {
  return {
    type: "SET_LAYER_DETAIL_IMPORT",
    payload: geo_layer,
  };
};

export const setEditGeometry = (status) => async (dispatch) => {
  dispatch({
    type: "SET_GEOMETRY_STATUS",
    payload: status,
  });
  dispatch({
    type: "status_action",
  });
};

export const setRealtimeStatus = (status) => async (dispatch) => {
  dispatch({
    type: "SET_REALTIME_STATUS",
    payload: status,
  });
  dispatch({
    type: "status_action",
  });
};

export const setTabelStatus = (status) => async (dispatch) => {
  dispatch({
    type: "status_action",
  });

  dispatch({
    type: "SET_TABEL_STATUS",
    payload: status,
  });
};

export const setChatStatus = (status) => async (dispatch) => {
  dispatch({
    type: "status_action",
  });

  dispatch({
    type: "SET_CHAT_STATUS",
    payload: status,
  });
};

export const setViewLayer = (geo_layer_id) => async (dispatch) => {
  /*
SET_VIEW_LAYER Documentation:
Ini adalah fungsi untuk toggle apakah layer akan diview atau tidak, 
setiap geo_layer memiliki attribut viewStatus
*/

  dispatch({
    type: "status_action",
  });

  dispatch({
    type: "SET_VIEW_LAYER",
    payload: geo_layer_id,
  });
};

export const setDatasetTable = (status) => {
  return {
    type: "SET_DATASET_TABEL",
    payload: status,
  };
};

//Clear layer
export const clearSearchLayer = () => (dispatch) => {
  dispatch({
    type: "SEARCH_LAYER",
    payload: [],
  });
};

//Refresh search status
export const refreshSearch = () => (dispatch) => {
  dispatch({
    type: "REFRESH_SEARCH",
  });
};

export const setLayer = (content) => (dispatch) => {
  let geo_layer = content.geo_layer;
  geo_layer.viewStatus = true;
  const payload = {
    geo_layer_item: geo_layer,
    content,
  };
  dispatch({
    type: "set_layer_id",
    payload: geo_layer?._id,
  });
  dispatch({
    type: "SET_LAYER_DETAIL",
    payload: payload,
  });
  if (content.tableStatus !== undefined) {
    dispatch({
      type: "SET_TABEL_STATUS",
      payload: content.tableStatus,
    });
  } else if (content.geometryStatus !== undefined) {
    dispatch({
      type: "SET_GEOMETRY_STATUS",
      payload: content.geometryStatus,
    });
  } else if (content.flyStatus !== undefined) {
    dispatch({
      type: "SET_FLY_STATUS",
      payload: content.flyStatus,
    });
  }
  dispatch({
    type: "status_action",
  });
};

export const setLayerWOgeojson = (content) => async (dispatch) => {
  dispatch({
    type: "SET_LAYER_WO_GEOJSON",
    payload: content.geo_layer,
  });
  dispatch({
    type: "status_action",
  });
};

export const setFlyFeatures = (features) => {
  return {
    type: "SET_FLY_FEATURES",
    payload: features,
  };
};

export const filterGeoLayerList = (param) => {
  return {
    type: "FILTER_GEO_LAYER_LIST",
    payload: param,
  };
};

export const filter_layer = (features) => {
  return {
    type: "filter_layer",
    payload: features,
  };
};

export const setFilteredFeatures = (features) => {
  return {
    type: "SET_FILTERED_FEATURES",
    payload: features,
  };
};

export const clearGeolayerList = () => {
  return {
    type: "CLEAR_LAYER_LIST",
  };
};

export const setAppliedColor = (item) => {
  return {
    type: "SET_APPLIED_COLOR",
    payload: item,
  };
};

export const resetFilter = (item) => (dispatch) => {
  const { link } = item;
  dispatch(setLoadingList());
  dispatch({
    type: "FILTER_GEO_LAYER_LIST",
    payload: [],
  });
  dispatch({
    type: "RESET_GEO_LAYER_LIST",
    payload: { link },
  });
  dispatch(clearLoading());
};

/*POSTY*/

//2. Upload Geojson
export const uploadLayer = (item) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("uploadLayer"));
    let data = item.data;
    const {
      name,
      description,
      type,
      folder,
      user_id,
      geo_project_id,
      fields,
      properties,
    } = data;
    const newItem = {
      name,
      description,
      type,
      folder,
      user_id,
      geo_project_id,
      fields,
      properties,
    };
    //create layer
    const res = await axios.post(
      UPLOAD_URL + "/layers/create",
      newItem,
      config
    );

    let geo_layer = res.data;
    geo_layer.viewStatus = true;
    geo_layer.flyStatus = false;

    dispatch({
      type: "CREATE_LAYER",
      payload: geo_layer,
    });
    //upload start
    let formData = new FormData();
    const imagefile = item.file;
    formData.append("json", imagefile);
    data.geo_layer_id = geo_layer._id;
    formData.set("data", JSON.stringify(data));
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const geojson = item.geojson;
    const geo_layer_id = geo_layer._id;
    const replace_item = {
      geo_layer_id,
      geojson,
    };

    await axios.post(UPLOAD_URL + "/layers/import", formData, headers);
    dispatch(clearLoading());
    dispatch({
      type: "REPLACE_GEOJSON",
      payload: replace_item,
    });
    //upload finish
    dispatch({
      type: "status_action",
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

//3. Edit layer form kolom
export const editAllFields = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(setLoadingProcess("editAllFields"));
  axios
    .post(SERVER_URL + "/layers/edit_all_fields", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "EDIT_ALL_FIELDS",
        payload: item,
      });
      dispatch({
        type: "SET_MESSAGE",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//4. Edit layer form
export const editLayerForm = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(setLoadingProcess("editLayerForm"));
  axios
    .post(SERVER_URL + "/layers_new/edit_general_form", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "EDIT_LAYER_FORM",
        payload: item,
      });
      dispatch({
        type: "SET_MESSAGE",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//5. Push feature
export const pushFeature = (item) => async (dispatch) => {
  //   /*
  // PUSH FEATURE Documentation:
  // methode: POST
  // body tersimpan dalam object item, isinya:
  // body : {
  //   geo_layer_id : geo_layer_id,
  //   feature: feature
  // }

  // Dengan feature berisi satu object feature yang baru saja dibuat, dengan ada beberapa tambahan attribute dari attribute geojson feature biasa, sebagai berikut:

  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("pushFeature"));
    const res = await axios.post(
      SERVER_URL + "/layers/push_feature",
      item,
      config
    );
    dispatch({
      type: "PUSH_FEATURE",
      payload: item,
    });
    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch({
      type: "SET_DRAW_MODE",
      payload: "simple_select",
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//5.a Push feature arcitecture v2
export const pushFeatureV2 = (item) => async (dispatch) => {
  //   /*
  // PUSH FEATURE Documentation:
  // methode: POST
  // body tersimpan dalam object item, isinya:
  // body : {
  //   geo_layer_id : geo_layer_id,
  //   feature: feature
  // }

  // Dengan feature berisi satu object feature yang baru saja dibuat, dengan ada beberapa tambahan attribute dari attribute geojson feature biasa, sebagai berikut:

  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("pushFeature"));
    const res = await axios.post(
      SERVER_URL + "/layers/v2/push_feature",
      item,
      config
    );
    dispatch({
      type: "PUSH_FEATURE",
      payload: item,
    });
    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch({
      type: "SET_DRAW_MODE",
      payload: "simple_select",
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//6. Edit Layer geojson geometry
export const editGeometry = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("edit_geometry"));
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.post(
      SERVER_URL + "/layers/edit_geometry",
      body,
      config
    );

    dispatch({
      type: "EDIT_GEOMETRY",
      payload: body,
    });

    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });

    if (body?.type === "IoT") {
      await axios.post(
        "https://api.mapid.io/items/update_manual_lat_long",
        {
          dataset_id: body.feature_key,
          latitude: body.geometry.coordinates[1],
          longitude: body.geometry.coordinates[0],
        },
        config
      );
    }

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
  dispatch({
    type: "status_action",
  });
};

//6. Edit Layer geojson geometry ARCHITECTURE V2
export const editGeometryV2 = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("edit_geometry"));
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res = await axios.post(
      SERVER_URL + "/layers/v2/edit_geometry",
      body,
      config
    );

    dispatch({
      type: "EDIT_GEOMETRY",
      payload: body,
    });

    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });

    if (body?.type === "IoT") {
      await axios.post(
        "https://api.mapid.io/items/update_manual_lat_long",
        {
          dataset_id: body.feature_key,
          latitude: body.geometry.coordinates[1],
          longitude: body.geometry.coordinates[0],
        },
        config
      );
    }

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
  dispatch({
    type: "status_action",
  });
};

//7. Edit Layer geojson properties
/*
const body = {
  geo_layer_id: _id,
  feature_key: feature_key,
  properties_key,
  sproperties_value,
};
*/
export const editProperties = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("edit_properties"));
    const res = await axios.post(
      SERVER_URL + "/layers/edit_properties",
      body,
      config
    );

    dispatch({
      type: "EDIT_PROPERTIES",
      payload: body,
    });

    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

export const editPropertiesV2 = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("edit_properties"));
    const res = await axios.post(
      SERVER_URL + "/layers/v2/edit_properties",
      body,
      config
    );

    dispatch({
      type: "EDIT_PROPERTIES",
      payload: body,
    });

    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
    // dispatch(snackbar({
    //   is_open: true,
    //   status: "success",
    //   message: "Table has been successfully updated"
    // }
    // ));
  } catch (error) {
    dispatch(clearLoading());
    // dispatch(snackbar({
    //   is_open: true,
    //   status: "error",
    //   message: "Table failed to be updated"
    // }))
  }
};

//7.b Edit Layer geojson properties khusus gambar
export const edit_properties_upload =
  (body, architecture, geo_layer_id, extra_params_for_nested_table) =>
  async (dispatch) => {
    dispatch(setLoadingProcess("edit_properties"));
    try {
      const config_database = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      const config_file = {
        headers: {
          accesstoken: localStorage.token_mapid,
          "Content-Type": "multipart/form-data",
        },
      };
      //Original file
      let { body_file, body_database, mode } = body;
      const file = body_file.file;
      let url = "";

      if (mode === "image") {
        // mode = image
        //Compressed file small
        const options_compression = {
          maxSizeMB: 1.6,
          maxWidthOrHeight: 1600,
          useWebWorker: true,
        };
        const file_compressed = await imageCompression(
          file,
          options_compression
        );
        const file_name = body_file.file_name;
        // form data upload gambar
        let body_upload = new FormData();
        body_upload.append("original_file_name", `${file_name}`);
        body_upload.append("file", file_compressed);
        const result_upload = await axios.post(
          SERVER_URL + "/upload",
          body_upload,
          config_file
        );
        url = result_upload.data.data.Location;
      } else {
        // mode = file
        const file_name = body_file.file_name;
        let body_upload = new FormData();
        body_upload.append("original_file_name", `${file_name}`);
        body_upload.append("file", file);

        const result_upload = await axios.post(
          SERVER_URL + "/upload",
          body_upload,
          config_file
        );
        url = result_upload.data.data.Location;
      }
      body_database.properties_value = url;

      let res = {};
      if (extra_params_for_nested_table) {
        extra_params_for_nested_table["value"] = url;
        const body = packThePropertiesDataToUseApiEditNested(
          geo_layer_id,
          extra_params_for_nested_table
        );
        dispatch(edit_child_cell(body));
      } else {
        let edit_properties_path = "/layers/v2/edit_properties";
        if (architecture !== "v2") {
          edit_properties_path = "/layers/edit_properties";
        } else {
          edit_properties_path = "/layers/v2/edit_properties";
        }

        res = await axios.post(
          SERVER_URL + edit_properties_path,
          body_database,
          config_database
        );

        dispatch({
          type: "EDIT_PROPERTIES",
          payload: body_database,
        });

        dispatch({
          type: "SET_MESSAGE",
          payload: res.data,
        });

        dispatch({
          type: "status_action",
        });
      }

      dispatch(clearLoading());

      return body_database;
    } catch (e) {
      dispatch(clearLoading());
    }
  };

export const realtime_update = (item) => async (dispatch) => {
  if (item?.action === "delete_feature") {
    dispatch({
      type: "DELETE_FEATURE",
      payload: item,
    });
  }
  dispatch({
    type: "status_action",
  });
};

//8. Delete feature
export const deleteFeature = (item) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("delete_feature"));
    const res = await axios.post(
      SERVER_URL + "/layers/delete_feature",
      item,
      config
    );
    dispatch({
      type: "DELETE_FEATURE",
      payload: item,
    });

    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

/**
 * @name : push_field
 * @endpoint : /layers/push_field
 * @body : geo_layer_id, field
 * @query : -
 * @methode : post
 */
export const pushField = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("push_field"));
    const res = await axios.post(
      SERVER_URL + "/layers/push_field",
      body,
      config
    );
    dispatch({
      type: "PUSH_FIELD",
      payload: body,
    });
    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//10. Push Field Attribute inside layer form version
export const pushFieldForm = (item) => async (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(setLoadingProcess("pushFieldForm"));
  await axios
    .post(SERVER_URL + "/layers/push_field", item, config)
    .then((res) => {
      dispatch({
        type: "PUSH_FIELD_FORM",
        payload: item,
      });
      dispatch({
        type: "SET_MESSAGE",
        payload: res.data,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//11. Clone Set Field Attribute inside layer
export const setField = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(setLoadingProcess("set_field"));
  axios
    .post(SERVER_URL + "/layers/set_field", item, config)
    .then((res) => {
      dispatch({
        type: "SET_FIELD",
        payload: item,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//12. Edit Field (name, type, key, array_values),
export const editField = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(setLoadingProcess("editField"));
  axios
    .post(SERVER_URL + "/layers/edit_field", item, config)
    .then((res) => {
      dispatch({
        type: "EDIT_FIELD",
        payload: item,
      });
      dispatch({
        type: "status_action",
      });
      dispatch({
        type: "SET_MESSAGE",
        payload: res.data,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//13. Edit Field (name, type, key, array_values),
export const editFieldForm = (item) => async (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(setLoadingProcess("editField"));
  await axios
    .post(SERVER_URL + "/layers/edit_field", item, config)
    .then((res) => {
      dispatch({
        type: "EDIT_FIELD_FORM",
        payload: item,
      });
      dispatch({
        type: "SET_MESSAGE",
        payload: res.data,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

/**
 * @name : deleteField
 * @endpoint : /layers/delete_field
 * @body :
 * @query : -
 * @methode : post
 */
export const deleteField = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("deleteField"));
    const res = axios.post(SERVER_URL + "/layers/delete_field", body, config);
    dispatch({
      type: "DELETE_FIELD",
      payload: body,
    });
    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "SET_MESSAGE",
      payload: error.data,
    });
  }
};

/**
 * @name : edit_child_array_bulk
 * @endpoint : /layers/push_field && /properties/edit_child_array_bulk
 * @body : geo_layer_id, field, feature_key, child_object_array, parent_uuid, field_uuid
 * @query : -
 * @methode : post
 */
export const edit_child_array_bulk = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    //STEP 1 PUSH FIELD
    const body_field = {
      geo_layer_id: body.geo_layer_id,
      field: body.field,
    };
    await axios.post(SERVER_URL + "/layers/push_field", body_field, config);
    dispatch({
      type: "PUSH_FIELD",
      payload: body_field,
    });
    //STEP 2 PUSH CHILD ARRAY
    const body_child = {
      geo_layer_id: body.geo_layer_id,
      feature_key: body.feature_key,
      child_object_array: body.child_object_array,
      parent_uuid: body.parent_uuid,
      field_key: body.field_key,
    };
    await axios.post(
      SERVER_URL + "/properties/edit_child_array_bulk",
      body_child,
      config
    );
    dispatch({
      type: "edit_child_array_bulk",
      payload: body_child,
    });
    dispatch({
      type: "status_action",
    });
  } catch (error) {}
};

/**
 * @name : push_child_array_bulk
 * @endpoint : /layers/push_field && /properties/push_child_array_bulk
 * @body : geo_layer_id, field, feature_key, child_object_array
 * @query : -
 * @methode : post
 */
export const push_child_array_bulk = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    //STEP 1 PUSH FIELD
    const body_field = {
      geo_layer_id: body.geo_layer_id,
      field: body.field,
    };
    await axios.post(SERVER_URL + "/layers/push_field", body_field, config);
    dispatch({
      type: "PUSH_FIELD",
      payload: body_field,
    });
    //STEP 2 PUSH CHILD ARRAY
    const body_child = {
      geo_layer_id: body.geo_layer_id,
      feature_key: body.feature_key,
      child_object_array: body.child_object_array,
    };
    await axios.post(
      SERVER_URL + "/properties/push_child_array_bulk",
      body_child,
      config
    );
    dispatch({
      type: "push_child_array_bulk",
      payload: body_child,
    });
    dispatch({
      type: "status_action",
    });
  } catch (error) {}
};

//15. Delete Field Form
export const deleteFieldForm = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("deleteFieldForm"));
    await axios.post(SERVER_URL + "/layers/delete_field", body, config);
    dispatch({
      type: "DELETE_FIELD_FORM",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//17. Push comment to layer [NOTIF]
export const commentToLayer = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  // dispatch(setLoadingProcess("commentToLayer"));
  axios
    .post(SERVER_URL + "/notifications/push_comment_to_layer", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "COMMENT_TO_LAYER",
        payload: item,
      });
      dispatch({
        type: "SET_MESSAGE",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//18. Client propose paid form
export const proposeForm = (item) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("propose_form"));
    const res = await axios.post(
      SERVER_URL + "/layers/propose_form",
      item,
      config
    );
    dispatch({
      type: "PROPOSE_FORM",
      payload: item,
    });
    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch(clearLoading());
    his.push(`/paid_form/${item.geo_layer_link}`);
  } catch (error) {
    dispatch(clearLoading());
  }
};

//19. Client edit propose paid form [BEFORE PAYMENT]
export const editProposeFormBeforePayment = (item) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("edit_propose_form_before_payment"));
    const res = await axios.post(
      SERVER_URL + "/layers/edit_propose_form_before_payment",
      item,
      config
    );
    dispatch({
      type: "EDIT_PROPOSE_FORM_BEFORE_PAYMENT",
      payload: item,
    });
    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//20. Client edit propose paid form [AFTER PAYMENT]
export const editProposeFormAfterPayment = (item) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("edit_propose_form_after_payment"));
    const res = await axios.post(
      SERVER_URL + "/layers/edit_propose_form_after_payment",
      item,
      config
    );
    dispatch({
      type: "EDIT_PROPOSE_FORM_AFTER_PAYMENT",
      payload: item,
    });
    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//21. Upload photo form
export const uploadBannerLayer = (item) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("upload_photo_form"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.token_mapid,
        "Content-Type": "multipart/form-data",
      },
    };
    //Original file
    const { objParams, geo_layer_id } = item;
    const file = objParams.file;
    //Compressed file small
    const options_compression = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
    };
    const file_compressed = await imageCompression(file, options_compression);
    const file_name = objParams.file_name;
    // form data upload gambar
    const formData = new FormData();
    formData.append("original_file_name", `${file_name}`);
    formData.append("file", file_compressed);
    const result_upload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const url = result_upload.data.data.Location;
    //Generate body & axios to API
    const body = {
      picture_url: url,
      geo_layer_id,
    };
    await axios.post(
      SERVER_URL + "/layers/upload_photo_form",
      body,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "UPLOAD_PHOTO_FORM",
      payload: url,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//22. Upload photo form square
export const uploadSquareLayer = (item) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("upload_photo_form_square"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.token_mapid,
        "Content-Type": "multipart/form-data",
      },
    };
    //Original file
    const { objParams, geo_layer_id } = item;
    const file = objParams.file;
    //Compressed file small
    const options_compression = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
    };
    const file_compressed = await imageCompression(file, options_compression);
    const file_name = objParams.file_name;
    // form data upload gambar
    const formData = new FormData();
    formData.append("original_file_name", `${file_name}`);
    formData.append("file", file_compressed);
    const result_upload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const url = result_upload.data.data.Location;
    //Generate body & axios to API
    const body = {
      picture_url: url,
      geo_layer_id,
    };
    await axios.post(
      SERVER_URL + "/layers/upload_photo_form_square",
      body,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "UPLOAD_PHOTO_FORM_SQUARE",
      payload: url,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//25. Paid form validation, if complete update status to post_payment
/*
   const body = {
      geo_layer_id: layer_id,
      feature_key: feature_state.key,
      formStatus: {
        status: "success",
        message: "",
        date: Date.now(),
      },
    };
*/
export const form_validation = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("form_validation"));
    const res = await axios.post(
      SERVER_URL + "/layers/form_validation",
      body,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch({
      type: "form_validation",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

/*GETTY*/

//1. Search inside layer (pengganti deep query)
export const searchInside =
  (geo_layer_id, search_params) => async (dispatch) => {
    let body = {
      geo_layer_id,
      value: true,
    };

    try {
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      const res = await axios.get(
        SERVER_URL +
          `/layers_new/search_inside/${geo_layer_id}/${search_params}`,
        config
      );
      let data = res.data;
      dispatch({
        type: "SEARCH_INSIDE",
        payload: data,
      });
      dispatch({
        type: "view_layer",
        payload: body,
      });
      dispatch({
        type: "status_action",
      });
      dispatch({
        type: "fly_action",
      });
      dispatch(clearLoading());
    } catch (error) {
      dispatch(clearLoading());
    }
  };

//2. Reset  Search inside layer (pengganti deep query)
export const reset_searchInside = (geo_layer_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/layers_new/get_layer_detail/${geo_layer_id}`,
      config
    );

    const body = {
      geojson: res.data.geojson,
      _id: res.data._id,
    };
    dispatch({
      type: "RESET_SEARCH_INSIDE",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });
    dispatch({
      type: "fly_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//3. Get Layer By link layer Form
export const get_detail_by_link_form = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingDetail());
    const res = await axios.get(
      SERVER_URL + `/layers/get_detail_by_link_form/${params.geo_layer_link}`,
      config
    );
    let geo_layer = res.data;
    const payload = {
      geo_layer_item: geo_layer,
      params,
    };
    dispatch({
      type: "get_detail_by_link_form",
      payload: payload,
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "SET_MESSAGE",
      payload: error,
    });
  }
};

//4. Donwload layer
export const downloadLayer = (body) => async (dispatch) => {
  try {
    const { geo_layer_id, user_id, geo_project_id } = body;
    dispatch(setLoadingProcess("download_layer"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const axios_res = await axios.get(
      SERVER_URL +
        `/layers_new/get_detail_layer_by_id/${geo_layer_id}/${geo_project_id}`,
      config_header
    );
    const axios_response = axios_res.data;
    // PROSES READ FEATURE UNTUK ARCHITECTURE BARU V2
    if (axios_res.data?.architecture === "v2") {
      axios_response.geojson.features = [];
      const count = await axios.get(
        bun_server +
          `/moneys_bun/v2/get_layer_count_by_id/${geo_layer_id}/${geo_project_id}`,
        config_header
      );
      const features_length = count.data;

      let condition = true;
      let skip = 0,
        limit = 100;
      while (condition) {
        const progressPercent = parseInt((skip / features_length) * 100);
        dispatch(
          setLoadingProcess(
            `Download ${progressPercent}% ${skip}/${features_length}`
          )
        );

        const res = await axios.get(
          bun_server +
            `/moneys_bun/v2/get_layer_detail_by_id/${geo_layer_id}/${geo_project_id}?skip=${skip}&limit=${limit}`,
          config_header
        );
        if (res.data.length === 0) {
          condition = false;
        } else {
          axios_response.geojson.features.push(...res.data);
          if (res.data.length !== limit) {
            condition = false;
          }
        }
        skip += limit;
        await delay(150);
      }
    }
    //PROSES START
    const geojson_ori = axios_response.geojson;
    const layer_name = axios_response.name;
    const layer_description = axios_response.description;
    const layer_type = axios_response.type;

    // GENERATE FIELDS & PROPERTIES
    const layer_fields = axios_response.fields;
    const layer_properties = axios_response.properties;
    const fields = layer_fields.map((field) => {
      let field_new = { ...field };
      field_new.uuid = uuid();
      field_new = generate_array_templates(field_new, geojson_ori);
      return field_new;
    });

    const properties = layer_properties.map((field) => {
      let field_new = { ...field };
      field_new.uuid = uuid();
      return field_new;
    });

    //GENERATE GEOJSON
    let geojson = {};
    const features = geojson_ori.features.map((feature) => {
      let color_feature = feature.properties.color;
      const geometry_type = feature?.geometry?.type
        ? feature?.geometry?.type
        : "Polygon";
      const prop_default =
        geometry_type === "Point" || geometry_type === "MultiPoint"
          ? {
              icon_image: "museum",
              text_field: "icon_image",
              circle_color: color_feature,
              circle_radius: 5,
              circle_stroke_width: 1,
              circle_stroke_color: "#ffffff",
            }
          : geometry_type === "Polygon" || geometry_type === "MultiPolygon"
          ? {
              color: color_feature,
              opacity: 0.5,
            }
          : {
              color: color_feature,
              width: 3,
              line_width: 3,
              opacity: 1,
              line_gap_width: 0,
            };
      const prop_ori = feature.properties;
      const properties = Object.assign(prop_default, prop_ori);
      return {
        key: uuid(),
        type: feature.type,
        properties,
        geometry: feature.geometry
          ? feature.geometry
          : { type: "Polygon", coordinates: [] },
        isHide: false,
      };
    });
    geojson.features = features;
    geojson.type = geojson_ori.type;

    const body_new = {
      //basic
      name: layer_name,
      description: layer_description,
      user_id: user_id,
      geo_project_id: geo_project_id,
      folder: "",
      //geojson
      type: layer_type,
      fields: fields,
      properties: properties,
      geojson,
      status: "original",
      architecture: axios_res.data?.architecture || "v1",
    };

    //PROSES CLEAR GEOJSON FEATURES KALAU ARCHITECTUR BARU V2
    if (axios_res.data?.architecture === "v2") {
      body_new.geojson.features = [];
    }

    const response = await axios.post(
      SERVER_URL + "/layers_new/create",
      body_new,
      config_header
    );
    const geo_layer_response = response.data;
    // geo_layer_response.viewStatus = true;
    // geo_layer_response.flyStatus = true;

    //PROSES PUSH GEOJSON FEATURES KALAU ARCHITECTUR BARU V2
    if (axios_res.data?.architecture === "v2") {
      const layer_id = response.data?._id;
      let skip = 100;
      for (let index = 0; index < features.length; index += skip) {
        const progressPercent = parseInt((index / features.length) * 100);
        dispatch(
          setLoadingProcess(
            `Progress ${progressPercent}% ${index}/${features.length}`
          )
        );
        const element = features.slice(index, index + skip);
        const body = element.map((e) => {
          return { geo_layer_id: layer_id, feature: e };
        });
        await axios.post(
          SERVER_URL + "/layers/v2/push_feature_bulk",
          body,
          config_header
        );
        await delay(300);
      }
      // geo_layer_response.geojson.features = features;
    }

    dispatch({
      type: "CREATE_LAYER",
      payload: geo_layer_response,
    });

    dispatch({
      type: "SET_TABEL_STATUS",
      payload: false,
    });

    dispatch({
      type: "status_action",
    });
    dispatch({
      type: "fly_action",
    });
    //PROSE FINISH
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//5. Get Layer By link layer wo geojson
export const getDetailLayerByLinkWOgeojson = (link) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("loading_layer_wo_geojson"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/layers/get_detail_wo_geojson_by_link/${link}`,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "SET_LAYER_WO_GEOJSON",
      payload: res.data,
    });
    dispatch({
      type: "status_action",
    });
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "SET_MESSAGE",
      payload: error && error.response ? error.response.data : {},
    });
  }
};

//6. Search layers
export const searchLayersOnly = (search_params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("search_layer"));
    const res = await axios.get(
      SERVER_URL + `/layers_new/search_layers/${search_params}`,
      config
    );

    dispatch(clearLoading());
    dispatch({
      type: "SEARCH_LAYER",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

//7. Search project
export const searchProjects = (search_params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(setLoadingProcess("search_projects"));

    const res = await axios.get(
      SERVER_URL + `/layers_new/search_projects/${search_params}`,
      config
    );

    dispatch(clearLoading());
    dispatch({
      type: "SEARCH_PROJECTS",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

//8. Search group
export const searchGroups = (search_params) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(setLoadingProcess("search_groups"));
  axios
    .get(SERVER_URL + `/layers_new/search_groups/${search_params}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "SEARCH_GROUPS",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//10. Search layers public
export const searchLayersOnlyPublic = (search_params) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(setLoadingProcess("search_layer"));
  axios
    .get(
      SERVER_URL + `/layers_new/search_layers_public/${search_params}`,
      config
    )
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "SEARCH_LAYER",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//11. Search projects
export const searchProjectsOnly = (search_params) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(setLoadingProcess("search_project"));
  axios
    .get(SERVER_URL + `/layers/search_projects/${search_params}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "SEARCH_PROJECT",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//13. Get iot geojson
export const getIotGeojson = () => (dispatch) => {
  const config_header = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + `/layers/get_iot_geojson`, config_header)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_LAYER_LIST",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_MESSAGE",
        payload: err && err.response ? err.response.data : {},
      });
    });
};

//14. doSentimentAnalysis
export const doSentimentAnalysis = (geo_layer, field) => (dispatch) => {
  dispatch(setLoadingProcess("sentiment"));
  const name = "sentiment";
  const newField = {
    uuid: uuid(),
    key: name.replace(/ /g, "_").replace(/[^\w\s]/gi, ""),
    name: name,
    description: "-",
    type: "sentiment",
    array_templates: [],
    isHighlight: false,
    isRequired: false,
    date: Date.now(),
    isHide: false,
    min: null,
    max: null,
    step: null,
  };
  const content = {
    geo_layer_id: geo_layer._id,
    field: newField,
  };
  axios
    .post(SERVER_URL + "/layers/push_field", content)
    .then((res) => {
      dispatch({
        type: "PUSH_FIELD",
        payload: content,
      });
      dispatch({
        type: "SET_MESSAGE",
        payload: res.data,
      });
      const hasGeojson = geo_layer.hasOwnProperty("geojson");
      if (hasGeojson) {
        geo_layer.geojson.features.forEach((ft) => {
          const isNegative = Math.random() > 0.5 ? 1 : -1;
          const content_props = {
            geo_layer_id: geo_layer._id,
            feature_key: ft.key,
            properties_key: "sentiment",
            properties_value: Math.random() * isNegative,
          };
          axios
            .post(SERVER_URL + "/layers/edit_properties", content_props)
            .then((res) => {
              dispatch({
                type: "EDIT_PROPERTIES",
                payload: content_props,
              });
              dispatch({
                type: "SET_MESSAGE",
                payload: res.data,
              });
            });
        });
      } else {
        axios
          .get(SERVER_URL + `/layers/get_detail_by_link/${geo_layer.link}`)
          .then((res) => {
            const geojson = res.data.geojson;
            geojson.features.forEach((ft) => {
              const isNegative = Math.random() > 0.5 ? 1 : -1;
              const content_props = {
                geo_layer_id: geo_layer._id,
                feature_key: ft.key,
                properties_key: "sentiment",
                properties_value: Math.random() * isNegative,
              };

              axios
                .post(SERVER_URL + "/layers/edit_properties", content_props)
                .then((res) => {
                  dispatch({
                    type: "EDIT_PROPERTIES",
                    payload: content_props,
                  });
                  dispatch({
                    type: "SET_MESSAGE",
                    payload: res.data,
                  });
                });
            });
          });
      }
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//15. For calling crawl
export const doCrawl = (item) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.token_mapid,
    },
  };
  dispatch(setLoadingProcess());
  axios
    .post(SERVER_URL + "/ethno_requests/create", item, config)
    .then((res) => {
      dispatch(clearLoading());
    });
};

export const loadMoreSearchLayersOnly =
  (search_params, skip = 0) =>
  (dispatch) => {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const dataSkip = `?skip=${skip}`;
    dispatch(setLoadingProcess("search_layer"));
    axios
      .get(
        SERVER_URL + `/layers_new/search_layers/${search_params}${dataSkip}`,
        config
      )
      .then((res) => {
        dispatch(clearLoading());
        dispatch({
          type: "LOAD_MORE_SEARCH_LAYER",
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(clearLoading());
      });
  };

export const loadMoreSearchLayersOnlyPublic =
  (search_params, skip = 0) =>
  (dispatch) => {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const dataSkip = `?skip=${skip}`;
    dispatch(setLoadingProcess("search_layer"));
    axios
      .get(
        SERVER_URL +
          `/layers_new/search_layers_public/${search_params}${dataSkip}`,
        config
      )
      .then((res) => {
        dispatch(clearLoading());
        dispatch({
          type: "LOAD_MORE_SEARCH_LAYER",
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch(clearLoading());
      });
  };

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const set_value_layer = (body) => (dispatch) => {
  dispatch({
    type: "set_value_layer",
    payload: body,
  });
};
