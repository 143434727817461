/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_sini, post_ai_chat } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class TEXT_AREA_CHAT extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  on_change = (event) => {
    const { max_rows_text_area } = this.props.sini;
    const textarea_line_height = 24;
    const previous_rows = event.target.rows;
    event.target.rows = 1;
    const current_rows = Math.floor(
      event.target.scrollHeight / textarea_line_height
    );
    if (current_rows === previous_rows) {
      event.target.rows = current_rows;
    }
    if (current_rows >= max_rows_text_area) {
      event.target.rows = max_rows_text_area;
      event.target.scrollTop = event.target.scrollHeight;
    }
    const value = event.target.value;
    this.props.set_value_sini({
      key: "rows_text_area",
      value:
        current_rows < max_rows_text_area ? current_rows : max_rows_text_area,
    });
    this.props.set_value_sini({
      key: "user_message",
      value: value,
    });
  };

  handle_key_down = (event) => {
    const { max_rows_text_area } = this.props.sini;
    let value = event.target.value;
    if (event.key === "Enter" && event.shiftKey) {
      // Add a new line if Enter is pressed without Shift
      const new_value = value + "\n";
      const textarea_line_height = 24;
      const previous_rows = event.target.rows;
      event.target.rows = 1;
      const current_rows = Math.floor(
        event.target.scrollHeight / textarea_line_height
      );
      if (current_rows === previous_rows) {
        event.target.rows = current_rows;
      }
      if (current_rows >= max_rows_text_area) {
        event.target.rows = max_rows_text_area;
        event.target.scrollTop = event.target.scrollHeight;
      }
      this.props.set_value_sini({ key: "user_message", value: new_value });
      this.props.set_value_sini({
        key: "rows_text_area",
        value:
          current_rows < max_rows_text_area ? current_rows : max_rows_text_area,
      });
    } else if (event.key === "Enter") {
      event.preventDefault();
      this.on_post_ai_chat();
    }
  };

  on_post_ai_chat = () => {
    const {
      sini_v2_list,
      request_id_active,
      user_message,
      ai_parent_detail_list,
    } = this.props.sini;
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const body = {
      request_id: request_id_active,
      user_message,
      sini_object,
    };
    this.props.post_ai_chat(body);
    this.props.set_value_sini({
      key: "user_message",
      value: "",
    });
    this.props.set_value_sini({
      key: "rows_text_area",
      value: 1,
    });
    this.props.on_scroll_down();
  };

  render() {
    const { user_message, rows_text_area } = this.props.sini;

    return (
      <textarea
        rows={rows_text_area}
        value={user_message}
        placeholder={"Tulis pesan prompt"}
        className={"textarea"}
        onChange={this.on_change}
        onKeyDown={this.handle_key_down}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_sini,
  post_ai_chat,
})(TEXT_AREA_CHAT);
