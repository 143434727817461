import React, { Component } from "react";
import { connect } from "react-redux";
// import terbilang from "../../../App/validation/terbilang";
// import { dot_every_3_digits } from "../../../App/validation/format_number";
import {
  getAllChildrenRows,
  getAllFields,
  getFeatureByKey,
  getGeoLayer,
} from "../../../App/reduxHelper/layer";
import { calculateWithoutConvert } from "../../../App/validation/convert_data";
import is_equal_object from "../../../App/validation/is_equal_object";
import { edit_laporan_pdf } from "../../../App/actions/propertiesActions";
import kop_cilicis from "../../../Assets/img/kop_cilicis_2.png";
import DropdownOrData from "./DropdownOrData";
import list_id_that_contain_file_url from "./list_id_that_contain_file_url";

class SetupDownloadLaporanPdfCilicis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: "cilicis",
      body: {},
    };
  }

  setValue = (key, value) => {
    const { body, template } = this.state;
    body[key] = value;
    this.setState(
      {
        template,
        body,
      },
      () => this.submitTheSetup()
    );
  };

  submitTheSetup = () => {
    const layer_id = this.props?.layer?.layer_id;
    const { template, body } = this.state;
    const content = {
      geo_layer_id: layer_id,
      laporan_pdf: { template, body },
    };
    this.props.edit_laporan_pdf(content);
  };

  componentDidMount = async () => {
    // biar dapet semua data formula pada child muncul
    const { layer, row, mode } = this.props;
    if (mode === "preview") {
      const geo_layer = getGeoLayer(layer);
      const feature = getFeatureByKey({ geo_layer, feature_key: row?.key });
      const columns = getAllFields(geo_layer);
      const children_rows = getAllChildrenRows(feature);
      const source = "setup cilicis";
      calculateWithoutConvert({
        features: children_rows,
        fields: columns,
        source,
        feature_parent: feature,
      });
    }
    this.getSetupLaporanPdf();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { mode } = this.props;
    const { body } = this.state;
    if (mode === "preview") {
      if (!is_equal_object(prevState.body, body)) {
        const { row } = this.props;
        // const geo_layer = getGeoLayer(layer);
        // const feature = getFeatureByKey({ geo_layer, feature_key: row?.key });

        Object.keys(body).forEach((key) => {
          if (list_id_that_contain_file_url?.includes(key)) {
            const element = document.getElementById(key);
            if (element) {
              const fileNameOnServer = row[body[key]]?.split("/")?.at(-1);
              const arrayName = fileNameOnServer?.split("_%25");
              const originalName =
                arrayName?.at(0)?.split("_")?.at(1) === "doc"
                  ? arrayName?.at(1)?.replaceAll("%20", " ")
                  : "";
              element.href = row[body[key]];
              element.setHTML(originalName);
              element.setAttribute("class", "underline");
            }
          } else {
            const element = document.getElementById(key);
            if (element) {
              element.setHTML(row[body[key]] || "");
            }
          }
        });
      }
    }
  };

  getSetupLaporanPdf = () => {
    const { layer } = this.props;
    const geo_layer = getGeoLayer(layer);
    const laporan_pdf = geo_layer?.["laporan_pdf"] || {
      template: "cilicis",
      body: {},
    };
    this.setState({
      ...laporan_pdf,
    });
  };

  render() {
    const { mode = "setup", fields, row, layer } = this.props;
    const layer_id = layer?.layer_id;
    const { body } = this.state;
    // const geo_layer = getGeoLayer(layer);

    return (
      <section id="id_tabel_pdf" className="a4 cilicis_pdf paddingLeft_5">
        <table>
          <thead>
            <tr>
              <td>
                <div className="empty_header_cilicis"></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p
                  style={{
                    fontSize: "23px",
                  }}
                  className="text_center bold"
                >
                  FORM IDENTITAS PERMOHONAN IZIN REKOMTEK
                </p>
                <br />
                <p className="bold">Nama Pribadi/Perusahaan</p>
                <DropdownOrData
                  id="cilicis_nama_pribadi_perusahaan_pdf"
                  mode={mode}
                  fields={fields}
                  layer_id={layer_id}
                  onChange={this.setValue}
                  body={body}
                  row={row}
                />
                <br />
                <p className=" bold">Alamat Pribadi/Perusahaan</p>
                <DropdownOrData
                  id="cilicis_alamat_pribadi_perusahaan_pdf"
                  mode={mode}
                  fields={fields}
                  layer_id={layer_id}
                  onChange={this.setValue}
                  body={body}
                  row={row}
                />

                <br />

                <p className=" bold">Nomor Telepon Pribadi/Perusahaan</p>
                <DropdownOrData
                  id="cilicis_nomor_telepon_pribadi_perusahaan_pdf"
                  mode={mode}
                  fields={fields}
                  layer_id={layer_id}
                  onChange={this.setValue}
                  body={body}
                  row={row}
                />

                <br />

                <p className=" bold">Alamat Email Pribadi/Perusahaan</p>
                <DropdownOrData
                  id="cilicis_email_pribadi_perusahaan_pdf"
                  mode={mode}
                  fields={fields}
                  layer_id={layer_id}
                  onChange={this.setValue}
                  body={body}
                  row={row}
                  type="email"
                />
                {/* <p>
                  <a href="mailto:HRD.BITALASIA@GMAIL.COM">HRD@GMAIL.COM</a>
                </p> */}

                <br />

                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Lokasi Sumber Air
                </p>

                <br />
                <table className="w_full">
                  <thead>
                    <tr>
                      <td>
                        <p>Koordinat Lokasi</p>
                      </td>
                      <td>
                        <p>DAS, Wilayah Sungai</p>
                      </td>
                      <td>
                        <p>Kab/Kota</p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <DropdownOrData
                            id="cilicis_latitude_pdf"
                            placeholder="Pilih Latitude"
                            mode={mode}
                            fields={fields}
                            layer_id={layer_id}
                            onChange={this.setValue}
                            body={body}
                            row={row}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <DropdownOrData
                            id="cilicis_das_pdf"
                            placeholder="Pilih Wilayah Sungai"
                            mode={mode}
                            fields={fields}
                            layer_id={layer_id}
                            onChange={this.setValue}
                            body={body}
                            row={row}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <DropdownOrData
                            id="cilicis_kota_kabupaten_pdf"
                            placeholder="Pilih Wilayah Sungai"
                            mode={mode}
                            fields={fields}
                            layer_id={layer_id}
                            onChange={this.setValue}
                            body={body}
                            row={row}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <DropdownOrData
                            id="cilicis_longitude_pdf"
                            placeholder="Pilih Longitude"
                            mode={mode}
                            fields={fields}
                            layer_id={layer_id}
                            onChange={this.setValue}
                            body={body}
                            row={row}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <DropdownOrData
                            id="cilicis_wilayah_sungai_pdf"
                            placeholder="Pilih Wilayah Sungai"
                            mode={mode}
                            fields={fields}
                            layer_id={layer_id}
                            onChange={this.setValue}
                            body={body}
                            row={row}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  <br />
                </p>
                <p> </p>

                <br />

                <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Penggunaan Air/Daya Air
                </h2>

                <br />

                <table className="w_full">
                  <tbody>
                    <tr>
                      <td>
                        <p className=" bold">Tujuan Penggunaan</p>
                      </td>
                      <td>
                        <p className=" bold">Cara Pengambilan</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <DropdownOrData
                          id="cilicis_tujuan_penggunaan_pdf"
                          mode={mode}
                          fields={fields}
                          layer_id={layer_id}
                          onChange={this.setValue}
                          body={body}
                          row={row}
                        />
                      </td>
                      <td>
                        <DropdownOrData
                          id="cilicis_cara_pengambilan_pdf"
                          mode={mode}
                          fields={fields}
                          layer_id={layer_id}
                          onChange={this.setValue}
                          body={body}
                          row={row}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className=" bold">Cara Pembuangan</p>
                      </td>
                      <td>
                        <p className=" bold">Jumlah/Volume Pengambilan</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <DropdownOrData
                          id="cilicis_cara_pembuangan_pdf"
                          mode={mode}
                          fields={fields}
                          layer_id={layer_id}
                          onChange={this.setValue}
                          body={body}
                          row={row}
                        />
                      </td>
                      <td>
                        <DropdownOrData
                          id="cilicis_jumlah_volume_pengambilan_pdf"
                          mode={mode}
                          fields={fields}
                          layer_id={layer_id}
                          onChange={this.setValue}
                          body={body}
                          row={row}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <br />

                <br />

                <h2>Berkas Lampiran</h2>

                <br />

                <ol className="marginLeft_20" id="l1">
                  <li data-list-text={1}>
                    <p className="bold">Surat Permohonan</p>
                    <p />

                    <p>
                      <DropdownOrData
                        id="cilicis_surat_permohonan_pdf"
                        mode={mode}
                        fields={fields}
                        layer_id={layer_id}
                        onChange={this.setValue}
                        body={body}
                        row={row}
                        type="file"
                      />
                      {/* <a href="https://sda.pu.go.id/balai/bbwscilicis/wp-content/uploads/gravity_forms/7-d6de4cbfaac861c516e58c6b8744f6a8/2022/12/2022.PERMOHONAN-BBWS-CC.pdf">
                        2022.PERMOHONAN-BBWS-CC.pdf
                      </a> */}
                    </p>

                    <br />
                  </li>
                  <li data-list-text={2}>
                    <p className="bold">
                      Gambar Lokasi / Peta Situasi (Disertai titik koordinat
                      pengambilan dan / jalur konstruksi);
                    </p>
                    <p />
                    <p>
                      <DropdownOrData
                        id="cilicis_gambar_lokasi_pdf"
                        mode={mode}
                        fields={fields}
                        layer_id={layer_id}
                        onChange={this.setValue}
                        body={body}
                        row={row}
                        type="file"
                      />
                      {/* <a href="https://sda.pu.go.id/balai/bbwscilicis/wp-content/uploads/gravity_forms/7-d6de4cbfaac861c516e58c6b8744f6a8/2022/12/Gambar-WhatsApp-2022-12-22-pukul-11.00.04.pdf">
                        Gambar-WhatsApp-2022-12-22-pukul-11.00.04.pdf
                      </a> */}
                    </p>
                    <p>
                      <br />
                    </p>
                  </li>
                  <li data-list-text={3}>
                    <p className="bold">
                      Gambar Desain bangunan (pengambilan, pembuangan air maupun
                      prasarana lainnya);
                    </p>
                    <p />
                    <p>
                      <DropdownOrData
                        id="cilicis_gambar_desain_bangunan_pdf"
                        mode={mode}
                        fields={fields}
                        layer_id={layer_id}
                        onChange={this.setValue}
                        body={body}
                        row={row}
                        type="file"
                      />
                      {/* <a href="https://sda.pu.go.id/balai/bbwscilicis/wp-content/uploads/gravity_forms/7-d6de4cbfaac861c516e58c6b8744f6a8/2022/12/denah-pt-bital-asia-1.pdf">
                        denah-pt-bital-asia-1.pdf
                      </a> */}
                    </p>
                    <p>
                      <br />
                    </p>
                  </li>
                  <li data-list-text={4}>
                    <p className="bold">Spesifikasi Teknis bangunan;</p>
                    <p />
                    <p>
                      <DropdownOrData
                        id="cilicis_spesifikasi_teknis_bangunan_pdf"
                        mode={mode}
                        fields={fields}
                        layer_id={layer_id}
                        onChange={this.setValue}
                        body={body}
                        row={row}
                        type="file"
                      />
                      {/* <a href="https://sda.pu.go.id/balai/bbwscilicis/wp-content/uploads/gravity_forms/7-d6de4cbfaac861c516e58c6b8744f6a8/2022/12/img20221222_11015300.pdf">
                        img20221222_11015300.pdf
                      </a> */}
                    </p>
                    <p>
                      <br />
                    </p>
                  </li>
                  <li data-list-text={5}>
                    <p className="bold">
                      Proposal teknis/penjelasan penggunaan air;
                    </p>
                    <p />
                    <p>
                      <DropdownOrData
                        id="cilicis_proposal_teknis_penjelasan_penggunaan_air_pdf"
                        mode={mode}
                        fields={fields}
                        layer_id={layer_id}
                        onChange={this.setValue}
                        body={body}
                        row={row}
                        type="file"
                      />
                      {/* <a href="https://sda.pu.go.id/balai/bbwscilicis/wp-content/uploads/gravity_forms/7-d6de4cbfaac861c516e58c6b8744f6a8/2022/12/img20221222_11032505.pdf">
                        img20221222_11032505.pdf
                      </a> */}
                    </p>
                  </li>
                  <li data-list-text={6}>
                    <p className="bold">Manual Operasi dan Pemeliharaan;</p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }} />
                    <p>
                      <DropdownOrData
                        id="cilicis_manual_operasi_dan_pemeliharaan_pdf"
                        mode={mode}
                        fields={fields}
                        layer_id={layer_id}
                        onChange={this.setValue}
                        body={body}
                        row={row}
                        type="file"
                      />
                      {/* <a href="https://sda.pu.go.id/balai/bbwscilicis/wp-content/uploads/gravity_forms/7-d6de4cbfaac861c516e58c6b8744f6a8/2022/12/img20221222_11051050.pdf">
                        img20221222_11051050.pdf
                      </a> */}
                    </p>

                    <br />
                  </li>
                  <li data-list-text={7}>
                    <p className="bold">
                      Bukti kepemilikan atau penguasaan lahan (sertifikat
                      tanah);
                    </p>
                    <p />
                    <p>
                      <DropdownOrData
                        id="cilicis_sertifikat_tanah_pdf"
                        mode={mode}
                        fields={fields}
                        layer_id={layer_id}
                        onChange={this.setValue}
                        body={body}
                        row={row}
                        type="file"
                      />
                      {/* <a href="https://sda.pu.go.id/balai/bbwscilicis/wp-content/uploads/gravity_forms/7-d6de4cbfaac861c516e58c6b8744f6a8/2022/12/SHM-00178.pdf">
                        SHM-00178.pdf
                      </a> */}
                    </p>

                    <br />
                  </li>
                  <li data-list-text={8}>
                    <p className="bold">
                      Izin lingkungan dan persetujuan AMDAL/izin lingkungan dan
                      rekomendasi UKL-UPL/surat pernyataan kesanggupan
                      pengelolaan dan pemantauan lingkungan hidup dari Instansi
                      yang berwenang; dan
                    </p>
                    <p />
                    <p>
                      <DropdownOrData
                        id="cilicis_izin lingkungan dan persetujuan amdal_pdf"
                        mode={mode}
                        fields={fields}
                        layer_id={layer_id}
                        onChange={this.setValue}
                        body={body}
                        row={row}
                        type="file"
                      />
                      {/* <a href="https://sda.pu.go.id/balai/bbwscilicis/wp-content/uploads/gravity_forms/7-d6de4cbfaac861c516e58c6b8744f6a8/2022/12/2022.NIB_.IZIN_LINGKUNGAN-AMDAL.pdf">
                        2022.NIB_.IZIN_LINGKUNGAN-AMDAL.pdf
                      </a> */}
                    </p>

                    <br />
                  </li>
                  <li data-list-text={9}>
                    <p className="bold">Fotocopy KTP,KK atau Ketua Kelompok</p>
                    <p />
                    <p>
                      <DropdownOrData
                        id="cilicis_fotocopy_ktp_kk_ketua_kelompok_pdf"
                        mode={mode}
                        fields={fields}
                        layer_id={layer_id}
                        onChange={this.setValue}
                        body={body}
                        row={row}
                        type="file"
                      />
                      {/* <a href="https://sda.pu.go.id/balai/bbwscilicis/wp-content/uploads/gravity_forms/7-d6de4cbfaac861c516e58c6b8744f6a8/2022/12/KTP-PAK-DANIEL-SMET.pdf">
                        KTP-PAK-DANIEL-SMET.pdf
                      </a> */}
                    </p>

                    <br />
                  </li>
                  <li data-list-text={10}>
                    <p className="bold">
                      Fotocopy KK/Akta/bukti Pendirian Kelompok atau surat
                      keberadaan kelompok dari Kepala Desa atau Lurah
                    </p>
                    <p />
                    <p>
                      <DropdownOrData
                        id="cilicis_fotocopy_kk_akta_bukti_pendiri_kelompok_pdf"
                        mode={mode}
                        fields={fields}
                        layer_id={layer_id}
                        onChange={this.setValue}
                        body={body}
                        row={row}
                        type="file"
                      />
                      {/* <a href="https://sda.pu.go.id/balai/bbwscilicis/wp-content/uploads/gravity_forms/7-d6de4cbfaac861c516e58c6b8744f6a8/2022/12/Akta-Bital-Asia-No.01-15-April-2021.pdf">
                    Akta-Bital-Asia-No.01-15-April-2021.pdf
                  </a> */}
                    </p>
                  </li>
                </ol>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div className="empty_footer_cilicis"></div>
              </td>
            </tr>
          </tfoot>
        </table>
        <div
          id="kop_cilicis_container"
          className="paddingLeft_30 paddingRight_30 page_header_cilicis z_0 marginTop_25"
        >
          <img
            id="kop_cilicis"
            alt="Cilicis"
            src={kop_cilicis}
            className="hidden"
          />
        </div>

        <div id="kop_cilicis_container" className="page_footer_cilicis z_0">
          <hr
            style={{
              height: 1,
              color: "black",
              backgroundColor: "black",
            }}
          />
          <div className="flex justify_between w_700">
            <p className="bold">BBWS Ciliwung Cisadane</p>

            <p className="bold" id="date_footer_pdf"></p>
          </div>
        </div>

        {/* <div class="page_footer_cilicis">I'm The Footer</div> */}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  project: state.project,
});

export default connect(
  mapStateToProps,
  {
    edit_laporan_pdf,
  },
  null,
  { forwardRef: true }
)(SetupDownloadLaporanPdfCilicis);
