//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

//Personal Component

//Redux function
import {
  email_verification,
  code_verification,
  logoutUser,
} from "../../App/actions/authActions";
import { clearProjectList } from "../../App/actions/projectActions";
import { clearFormList } from "../../App/actions/layerNewActions";

//General Function
import dict from "../../Data/dict.json";

const Content = styled.section`
  background-color: #fff;
  width: 450px;
  padding: 2rem;
  border-radius: 10px;
  border: 2px solid #1265ae;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;

  p {
    font-size: 1rem;
    font-weight: 500;
    color: #a6a6aa;
    span {
      font-weight: 600;
      color: #61b930;
      cursor: pointer;
    }
  }

  button {
    padding: 7px 30px;
    background-color: #1265ae;
  }

  input {
    border-bottom: 1px solid #1265ae;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
`;

const Messages = ({ color, title }) => {
  return (
    <p
      style={{
        marginTop: "-30px",
        fontSize: "0.7rem",
        color: `${color}`,
      }}
    >
      {title}
    </p>
  );
};

const language = localStorage?.language ? localStorage?.language : "ina";

class EmailConfirm extends Component {
  constructor() {
    super();
    this.state = {
      time: {},
      seconds: 59,
      countdown: false,
      changeEmail: false,
      validasiEmail: false,
      validasiUser: false,
      verification: "",
      email: "",
      code: "",
      fullname: "",
      username: "",
      dateofbrithday: "",
      phonenumber: "",
      industry: "",
      messageCode: "",
      errors: {},
      intervalTime: {},
    };
    this.timer = 0;
    this.startTimer = this.handleSubmitEmail.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.state.intervalTime);
      this.setState({
        countdown: false,
      });
    } else {
      this.setState({
        countdown: true,
      });
    }
  }

  componentDidMount() {
    const user = this.props.auth.user;
    const email = user.email;
    const verification = user.is_email_confirm;
    const username = user.name;
    const fullname = user.full_name;
    const dateofbrithday = user.birthday;
    const phonenumber = user.phone_number;
    const industry = user.industry;
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({
      email,
      verification,
      username,
      time: timeLeftVar,
      fullname,
      dateofbrithday,
      phonenumber,
      industry,
    });
  }

  handleSubmitEmail = () => {
    //   e.preventDefault();
    const { email, username } = this.state;
    const lang = localStorage.getItem("language");
    let bodyLang = "en";
    if (lang === "ina") {
      bodyLang = "id";
    }
    const body = {
      lang: bodyLang,
      to: email,
      name: username,
    };
    this.props.email_verification(body);
    this.setState({
      intervalTime: setInterval(this.countDown, 1000),
      countdown: true,
      seconds: 59,
      changeEmail: true,
      validasiEmail: false,
    });
  };

  handleSubmitCode = (e) => {
    e.preventDefault();

    if (this.state.code === "") {
      this.setState({
        errors: {},
        messageCode: (
          <Messages
            color="#ff0000"
            title={dict["please input your code"][language]}
          />
        ),
      });
    } else {
      if (this.props.auth.user.is_email_confirm === "not_propose") {
        this.setState({
          errors: {},
          messageCode: (
            <Messages
              color="#ff0000"
              title={dict["Code not match"][language]}
            />
          ),
        });
      }
      const { code } = this.state;
      const lang = localStorage.getItem("language");
      let bodyLang = "en";
      if (lang === "ina") {
        bodyLang = "id";
      }
      const body = {
        code,
        lang: bodyLang,
      };
      this.props.code_verification(body);
      this.props.onClose();
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onLogoutClick() {
    this.props.logoutUser();
    this.props.clearProjectList();
    this.props.clearFormList();
  }

  onCheckUsername() {
    this.handleSubmitEmail();
    this.setState({ changeEmail: true });
  }

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { email, code } = this.state;

    var itemContent;
    itemContent = (
      <Content>
        {this.state.changeEmail ? (
          this.state.validasiEmail ? (
            <>
              <p>{dict["Enter your email correctly"][language]}</p>
              <input
                placeholder={dict["Email"][language]}
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={this.onChange}
                className="input_new"
              />
              <button
                id="resend"
                className="button"
                onClick={this.handleSubmitEmail}
              >
                {dict["Save"][language]}
              </button>
            </>
          ) : (
            <>
              <p>{dict["We have sent the security code to"][language]}</p>

              <h1>{email}</h1>
              <div>
                {this.state.countdown ? (
                  <p>
                    {dict["Didn't receive the code?"][language]} 0
                    {this.state.time.m}:{this.state.time.s}
                  </p>
                ) : (
                  <p>
                    {dict["Didn't receive the code?"][language]}{" "}
                    <span onClick={this.handleSubmitEmail}>
                      {dict["Resend"][language]}
                    </span>{" "}
                    or{" "}
                    <span
                      onClick={() =>
                        this.setState({
                          validasiEmail: true,
                        })
                      }
                    >
                      {dict["Change Email"][language]}
                    </span>
                  </p>
                )}
              </div>

              <input
                placeholder="code"
                name="code"
                id="code"
                value={code}
                onChange={this.onChange}
                className="input_new"
              />
              <button className="button" onClick={this.handleSubmitCode}>
                {dict["Verify"][language]}
              </button>
              <p>
                {dict["Any mistake? Back to"][language]}
                <span
                  onClick={() => this.onLogoutClick()}
                  style={{ marginLeft: "5px", color: "#ba0000" }}
                >
                  {dict["Login"][language]}
                </span>
              </p>
            </>
          )
        ) : this.state.validasiUser ? (
          <>
            <p>{dict["Is it true that your email is"][language]}</p>
            <h1>{this.state.email} ?</h1>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <button className="button" onClick={() => this.onCheckUsername()}>
                {dict["Yes"][language]}
              </button>
              <button
                className="button"
                id="green"
                onClick={() =>
                  this.setState({
                    validasiEmail: true,
                    changeEmail: true,
                  })
                }
              >
                {dict["Change Email"][language]}
              </button>
            </div>
          </>
        ) : (
          <>
            <p>{dict["Is it true that your username is"][language]}</p>
            <h1>{this.state.username} ?</h1>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <button
                className="button"
                onClick={() =>
                  this.setState({
                    validasiUser: true,
                  })
                }
              >
                {dict["Yes"][language]}
              </button>
              <button
                className="button"
                id="red"
                onClick={() => this.onLogoutClick()}
              >
                {dict["No"][language]}
              </button>
            </div>
          </>
        )}
      </Content>
    );

    return itemContent;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  email_verification,
  code_verification,
  logoutUser,
  clearProjectList,
  clearFormList,
})(EmailConfirm);
