/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer, Marker } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import colors_layer_poi from "../../Data/colors_layer_poi.json";

/*NON IMPORT*/

class LAYER_SINI_POI extends Component {
  state = {
    render_content: null,
  };

  componentDidMount() {
    this.on_render_layer();
  }

  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    if (state_update_after !== state_update_before) {
      this.on_render_layer();
    }
  }

  on_render_layer = () => {
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_show_poi_marker,
      sini_circle_radius,
      sini_logo_size,
    } = this.props.sini;
    const visibility = sini_map_show.includes("poi") ? "visible" : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const layer_content = merge_array.map((item, idx) => {
      const features = item?.poi_features || [];
      const geojson = {
        type: "FeatureCollection",
        features: features,
      };
      let marker_content;
      if (sini_show_poi_marker) {
        const features_marker = features.filter(
          (item) =>
            !["-", "BRAND LAINNYA", "NEGERI"].includes(item?.properties?.TIPE_3)
        );
        marker_content = features_marker.map((feature, idx) => {
          const long = feature?.geometry?.coordinates?.[0];
          const lat = feature?.geometry?.coordinates?.[1];
          const tipe_3 = String(feature?.properties?.TIPE_3);
          const tipe_3_text = tipe_3
            .replace(/ /g, "+")
            .replace(/\u00A0/g, "+")
            .replace(/\//g, "+");
          const url = `https://s3.ap-southeast-3.amazonaws.com/mapid.jakarta.public/poi_logo_sini_compressed/${tipe_3_text}.png`;
          return (
            <Marker
              key={idx}
              longitude={long}
              latitude={lat}
              draggable={false}
              anchor="bottom"
            >
              <main className="poi_marker_parent">
                <section className="poi_marker_box_container">
                  <img src={url} alt="" height={`${sini_logo_size}px`} />
                </section>
                <section className="poi_marker_pin"></section>
              </main>
            </Marker>
          );
        });
      }
      return (
        <div key={idx}>
          {marker_content}
          <Source
            key={`poi_${idx}`}
            id={`poi_${idx}`}
            type="geojson"
            data={geojson}
          />
          <Layer
            id={`poi_${idx}`}
            source={`poi_${idx}`}
            type="circle"
            paint={{
              "circle-color": colors_layer_poi,
              "circle-radius": Number(sini_circle_radius),
              "circle-stroke-width": 1,
              "circle-stroke-color": "#000",
            }}
            layout={{
              visibility: visibility,
            }}
          />
        </div>
      );
    });

    this.setState({
      render_content: <>{layer_content}</>,
    });
  };

  render() {
    return this.state.render_content;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
});

export default connect(mapStateToProps, {})(LAYER_SINI_POI);
