/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import BI_MAP from "./BI_MAP";

/*REDUX FUNCTION*/
import { set_value_map } from "../../App/actions/mapActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import basemap_styles from "../../App/validation/basemap_styles"
import { domain_mapid_basemap } from "../../App/validation/features_domain";


/*NON IMPORT*/


class BI_MAP_PARENT extends Component {
  state = {
    content: <BI_MAP />,
  };

  componentDidMount() {
    this.on_set_basemap();
  }

  //dibutuhkan update view karena ukuran width & height dari canvas map tidak ikut update jika tidak re render view parentnya
  componentDidUpdate(prevProps) {
    if (
      this.props.bi.trigger_rerender_css_map !==
      prevProps.bi.trigger_rerender_css_map
    ) {
      this.on_render();
    }
    if (
      this?.props?.bi?.project_object_selected?._id !==
      prevProps?.bi?.project_object_selected?._id
    ) {
      this.on_set_basemap();
    }
  }

  on_render = () => {
    const content_init = (
      <main style={{ backgroundColor: "rgb(235, 235, 235)" }}>MAP</main>
    );
    this.setState({ content: content_init });
    setTimeout(() => {
      this.setState({
        content: <BI_MAP />,
      });
    }, 1);
  };

  on_set_basemap = () => {
    //basemap_mode
    const { domain } = this.props.auth;
    const { bi_object, project_object_selected } = this.props.bi;
    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );
    const basemap_mode = setting_object?.basemap_mode;
    if (basemap_mode) {

      const basemap_props = basemap_styles().find(
        (item) => item.value === basemap_mode
      );

      const active_basemap = (domain_mapid_basemap.includes(domain)) ? "mapid" : "mapbox";
      const basemap_api = basemap_props?.api?.[active_basemap];

      if (basemap_api) {
        this.props.set_value_map({
          key: "basemap_api",
          value: basemap_api,
        });
      }
    }
  };

  render() {
    //local storage

    //local state
    const { content } = this.state;

    //global props

    //content
    return content;
  }
}

const mapStateToProps = (state) => ({
  bi: state.bi,
  auth: state.auth
});

export default connect(mapStateToProps, { set_value_map })(BI_MAP_PARENT);
