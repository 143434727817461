//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
//Redux function
import { deleteLayerAttach } from "../../App/actions/layerNewActions";
//Picture Asset
//General Function
import dict from "../../Data/dict.json";
class DeleteLayer extends Component {
  handleSubmit = () => {
    const { geo_project } = this.props.project;
    const { geo_layer_wo_geojson } = this.props.layer;
    const body = {
      geo_layer_id: geo_layer_wo_geojson.geo_layer._id,
      geo_project_id: geo_project._id,
    };
    this.props.toggle_delete_layer();
    this.props.deleteLayerAttach(body);
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { geo_layer_wo_geojson } = this.props.layer;
    const { loadingProcess } = this.props.layer;
    var itemContent;
    var buttonContent;
    if (loadingProcess) {
      buttonContent = (
        <div
          className="button_standard"
          id="deleteBright"
          style={{ marginTop: "1rem" }}
        >
          {dict["Process deletion"][language]}...
        </div>
      );
    } else {
      buttonContent = (
        <button
          className="button_standard"
          id="deleteBright"
          style={{ marginTop: "1rem" }}
          onClick={this.handleSubmit}
        >
          {dict["Process deletion"][language]}
        </button>
      );
    }
    itemContent = (
      <div style={{ textAlign: "center" }}>
        <div className="text_medium">
          {dict["Are you sure to unattach this layer?"][language]} <br />
          {
            dict[
              "The layer at the origin of the project will not be affected."
            ][language]
          }
          <br />
        </div>
        <br />
        <div className="text_bold">{geo_layer_wo_geojson?.geo_layer?.name}</div>
        <br />
        <div className="text_medium">
          {dict["This process can't be undone."][language]}
        </div>
        {buttonContent}
      </div>
    );
    return <div>{itemContent}</div>;
  }
}
const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
  project: state.project,
});
export default connect(mapStateToProps, {
  deleteLayerAttach,
})(DeleteLayer);
