//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import jsPDF from "jspdf";

// Components
import poi_list from "../../Data/Poi_List_Type_1.json";

import his from "../../App/actions/history";

import dict from "../../Data/dict.json";

class PDFRender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render_list_grid: [],
      all_poi: poi_list,

      viewport: {
        latitude: -0.811123, // -0.435161, 118.009574 tengah
        longitude: 113.921327,
        zoom: 4,
      },
      mapStyle:
        "https://api.maptiler.com/maps/streets/style.json?key=K156TFZjIQmhD3HPG1gE",
    };
  }

  componentDidMount = () => {
    this.render_list_grid();
    const content = document.getElementById("contnet");
    const doc = new jsPDF();
    // var elementHTML = document.querySelector("#contnet");

    doc.html(content, {
      callback: function (doc) {
        // Save the PDF
        doc.save("sample-document.pdf");
      },
      x: 15,
      y: 15,
      width: 180, //target width in the PDF document
      windowWidth: 650, //window width in CSS pixels
    });

    setTimeout(() => {
      his.push("/simple");
    }, 10000);
  };

  render_list_grid() {
    const { all_poi } = this.state;
    const { select_sort, select_name } = this.state;
    const { poi_select, sini_list, request_id } = this.props.layer;
    const {
      grid,
      // grid_status,
      provinsi,
      kota,
    } = this.props.map;
    const language = localStorage?.language ? localStorage?.language : "ina";

    // SORT
    const ascending = grid?.features
      ?.sort((a, b) =>
        a.properties[select_name] > b.properties[select_name] ? 1 : -1
      )
      .map((d) => d);

    const descending = grid?.features
      ?.sort((a, b) =>
        a.properties[select_name] < b.properties[select_name] ? 1 : -1
      )
      .map((d) => d);

    const grid_select =
      select_sort === "ascending"
        ? ascending
        : select_sort === "descending"
        ? descending
        : grid.features;

    // TOTAL POI
    let poi = sini_list?.filter((e) => e.request_id === request_id)?.[0]?.poi;

    const features = poi?.features || [];
    const total_poi = features?.length;
    const total_poi_type_1 = [
      ...new Set(features.map((f) => f.properties.TIPE_1)),
    ].map((e) => {
      const length = features.filter((f) => f.properties.TIPE_1 === e)?.length;
      return { name: e, length };
    });

    // Demografi
    const demografi = sini_list?.filter((e) => e.request_id === request_id)?.[0]
      ?.demografi;

    const render = (
      <main>
        <section style={{ margin: "1rem 0" }} className="simplemap_card">
          <div className="simplemap_content_top">
            <h2>{dict?.["Information"]?.[language]}</h2>
          </div>
          <p>
            {dict?.["Provinces"]?.[language]} : <b>{provinsi}</b>
          </p>
          <p>
            {dict?.["Cities"]?.[language]} : <b>{kota}</b>
          </p>
          <p>
            {dict?.["Total"]?.[language]} {dict?.["Urban Villages"]?.[language]}{" "}
            : <b>{demografi?.features?.length}</b>
          </p>
          <p>
            {dict?.["Total"]?.[language]} GRID : <b>{grid?.features?.length}</b>
          </p>
          <p>
            {dict?.["Total"]?.[language]} POI: <b>{total_poi}</b>
          </p>
        </section>

        <div style={{ margin: "1rem 0" }} className="simple_content_row">
          <figure
            className="sini_card_poi"
            style={{
              border: `2px solid  #000000`,
            }}
          >
            <div
              className="sini_card_poi_left"
              style={{
                backgroundColor: "#000000",
                width: "100px",
              }}
            >
              <p style={{ fontSize: "0.7rem" }}>
                {dict?.["Total POI"]?.[language]}
              </p>
              <h3 style={{ fontSize: "1.3rem" }}>{features?.length}</h3>
            </div>

            <div className="sini_card_poi_right">
              <p style={{ fontSize: "1rem" }}>{dict?.["Name"]?.[language]}</p>
            </div>
          </figure>
          {all_poi?.map((data, index) => {
            // const view_poi = poi_select.includes(data.name);
            const total_poi = total_poi_type_1.find(
              (d) => d?.name === data?.name
            )?.length;
            return total_poi !== undefined ? (
              <figure
                className="sini_card_poi"
                key={index}
                style={{
                  border: `2px solid ${data.color ? data.color : "#000000"}`,
                }}
              >
                <div
                  className="sini_card_poi_left"
                  style={{
                    backgroundColor: data.color ? data.color : "#000000",
                  }}
                >
                  <p>Total</p>
                  <h3>{total_poi}</h3>
                </div>

                <div className="sini_card_poi_right">
                  <p style={{ fontSize: "0.8rem" }}>
                    {
                      dict?.[data.name !== undefined ? data.name : "null"]?.[
                        language
                      ]
                    }
                  </p>
                </div>
              </figure>
            ) : null;
          })}
        </div>

        <section style={{ margin: "1rem 0" }} className="simplemap_card">
          <div className="simplemap_content_top">
            <h2>List GRID</h2>
          </div>
          <div className="simple_content_row">
            {grid_select?.map((data, index) => {
              let array_key = [];

              for (const key in data.properties) {
                array_key.push(key);
              }

              // console.log("array_key =", array_key);
              // console.log("poi_select =", poi_select);

              array_key = array_key.map((t) => {
                // Filter Data Dari Tabel
                const value_array = poi_select.includes(t);

                // console.log(value_array);

                // FUNGSINYA BUAT MUNCULIN JUMLAH PENDUDUK
                const body =
                  t === "JUMLAH PENDUDUK" ? t : value_array ? t : null;
                // console.log("body =", body);
                return {
                  [body]: data.properties[body],
                  name: body,
                  value: data.properties[body],
                };
              });

              const array_value = array_key.filter(
                (item) => item.name !== "key" && item.name !== "label"
              );

              const ascending_poi = array_value
                ?.sort((a, b) => (a[select_name] > b[select_name] ? 1 : -1))
                .map((d) => d);

              const descending_poi = array_value
                ?.sort((a, b) => (a[select_name] < b[select_name] ? 1 : -1))
                .map((d) => d);

              const grid_select_poi =
                select_sort === "ascending"
                  ? ascending_poi
                  : select_sort === "descending"
                  ? descending_poi
                  : array_value;

              return (
                <main
                  className="card_znt"
                  style={{ border: `2px solid #0CA5EB` }}
                  key={index}
                >
                  <div className="card_znt_right">
                    <h1>{data.properties.kelurahan}</h1>
                    <h3>
                      {dict?.[grid]?.[language]} {data.properties.label}
                    </h3>

                    {grid_select_poi.map((item, key) => {
                      // const grid_poi = all_poi.includes(item.name);
                      const color_poi = all_poi.find(
                        (c) => c?.name === item?.name
                      )?.color;

                      // console.log(item);

                      return item.name !== null ? (
                        <div
                          className="card_znt_content"
                          key={key}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                            marginBottom: "3px",
                          }}
                        >
                          <h2
                            style={
                              item.name !== "JUMLAH PENDUDUK"
                                ? {
                                    background: color_poi,
                                  }
                                : {
                                    background: "#000",
                                    width: "70px",
                                  }
                            }
                            className="simplemap_circle"
                          >
                            {item.value}
                          </h2>
                          <p>{dict?.[item.name]?.[language]}</p>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="card_znt_left"
                    style={{ backgroundColor: "#0CA5EB" }}
                  >
                    <p>POI</p>
                    <h2>{data?.total}</h2>
                  </div>
                </main>
              );
            })}
          </div>
        </section>
      </main>
    );

    this.setState({ render_list_grid: render });
  }

  layerOnClick = (event) => {
    const { geometryStatus } = this.props.layer;
    if (!geometryStatus) {
      try {
        this.setState({
          popupInfo: null,
          // key_properties: event?.features?.[0]?.properties?.key,
        });
        this.generatePopup(event);
      } catch (error) {}
    }
  };

  render() {
    // const domain = this.props.auth.domain;

    // const downloadPDF = () => {
    //   const content = document.getElementById("contnet");
    //   const doc = new jsPDF();
    //   // var elementHTML = document.querySelector("#contnet");

    //   doc.html(content, {
    //     callback: function (doc) {
    //       // Save the PDF
    //       doc.save("Data.pdf");
    //     },
    //     x: 15,
    //     y: 15,
    //     width: 180, //target width in the PDF document
    //     windowWidth: 650, //window width in CSS pixels
    //   });
    // };

    // const downloadPDF = () => {
    //   const content = document.getElementById("contnet");
    //   // setLoader(true);
    //   html2canvas(content).then((canvas) => {
    //     const imgData = canvas.toDataURL("img/png");
    //     const doc = new jsPDF("p", "mm", "a4");
    //     const componentWidth = doc.internal.pageSize.getWidth();
    //     const componentHeight = doc.internal.pageSize.getHeight();
    //     doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
    //     // setLoader(false);
    //     doc.save("receipt.pdf");
    //   });
    // };

    return (
      <main className="page_pdf">
        {/* <button className="button" onClick={() => downloadPDF()}>
          Download PDF
        </button> */}
        <section id="contnet">
          {/* <MapGL
          style={{
            width: "50vw",
            height: "300px",
            top: 0,
            left: 0,
          }}
          mapStyle={this.state.mapStyle}
          accessToken={
            "pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g"
          }
          // latitude={this.state.viewport.latitude}
          // longitude={this.state.viewport.longitude}
          latitude={
            this.props.latMap ? this.props.latMap : this.state.viewport.latitude
          }
          longitude={
            this.props.longMap
              ? this.props.longMap
              : this.state.viewport.longitude
          }
          zoom={this.props.zoom ? this.props.zoom : this.state.viewport.zoom}
          maxZoom={22}
          minZoom={2}
          onViewportChange={(e) => {
            return;
          }}
          attributionControl={false}
          {...this.props}
          ref={this._map}
        >
          <LayerSini layerOnClick={this.layerOnClick} />
          <LayerGrid />
        </MapGL> */}
          {this.state.render_list_grid}

          <style>{`
    body{
      padding-top: 0px;
      overflow-y: hidden;
    }
    #navbarDefault{ visibility: hidden;}
    .top_nav{ visibility: hidden;}
    .sidebar_home{ visibility: hidden;}
    .nav_bottom{ visibility: hidden;}
    `}</style>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  auth: state.auth,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(PDFRender);
