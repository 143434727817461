//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import { createLayer } from "../../App/actions/layerNewActions";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";
import { select_field_from_default_props } from "../../App/validation/generateGeoJson";

class CreateLayer extends Component {
  state = {
    name: "",
    description: "",
    type: "Point",
    user_id: this.props.auth.user._id,
    folder: "",
  };

  componentDidMount() {
    const { _id } = this.props.auth.user;
    this.setState({ user_id: _id });
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { geo_project } = this.props.project;
    const { name, description, type, folder, user_id } = this.state;
    const fields = select_field_from_default_props(type);

    //khusus_danamas
    const domain = window.location.hostname;
    let architecture;
    if (domain === "danamas.mapid.io") {
      architecture = "v1";
    } else {
      architecture = process.env.REACT_APP_ARCHITECTURE;
    }

    const body = {
      name,
      description,
      type,
      folder,
      user_id,
      geo_project_id: geo_project._id,
      fields: [],
      properties: fields,
      status: "digitasi",
      architecture: architecture, // penambahan versi untuk arcitekture data v2
    };
    this.props.createLayer(body);
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state

    //global props
    const { geo_project, loadingDetail } = this.props.project;
    const { folders } = geo_project;

    //content
    let folders_state = [];
    if (!loadingDetail && geo_project.folders) {
      folders_state = folders.slice();
      folders_state.unshift({
        name: dict["Outside folders"][language],
        _id: "",
      });
    }
    const { name, description, type, folder } = this.state;
    const { loadingProcess, itemLoading } = this.props.layer;
    const layer_type_array = ["Point", "LineString", "Polygon", "IoT"];
    let buttonContent;
    if (loadingProcess && itemLoading === "createLayer") {
      buttonContent = (
        <div type="submit" className="button">
          {dict["Create Layer"][language]}...
        </div>
      );
    } else {
      buttonContent = (
        <button type="submit" className="button">
          {dict["Create Layer"][language]}
        </button>
      );
    }
    const itemContent = (
      <main>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <input
            type="text"
            placeholder={dict["Layer name"][language]}
            autoComplete="off"
            className="input_new"
            name="name"
            value={name}
            onChange={this.handleChange.bind(this)}
          />
          <textarea
            type="text"
            placeholder={dict["Layer description"][language]}
            autoComplete="off"
            className="input_new"
            name="description"
            value={description}
            onChange={this.handleChange.bind(this)}
          />
          <select
            id="layer_type"
            name="type"
            value={type}
            className="input_new"
            onChange={this.handleChange.bind(this)}
          >
            {layer_type_array.map((type, idx) => {
              return (
                <option key={idx} value={type}>
                  {type}
                </option>
              );
            })}
          </select>
          <select
            id="folder"
            name="folder"
            value={folder}
            className="input_new"
            onChange={this.handleChange.bind(this)}
          >
            {folders_state.map(({ _id, name }, idx) => {
              return (
                <option key={idx} value={_id}>
                  {name}
                </option>
              );
            })}
          </select>
          <div>{buttonContent}</div>
        </form>
      </main>
    );
    return <main>{itemContent}</main>;
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  createLayer,
})(CreateLayer);
