/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Menu from "@mui/material/Menu";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import { generate_colors } from "./functions/generate_colors";
import { generate_paint } from "./functions/generate_paint";
import { extract_value_array } from "./functions/extract_value_array";
import dict from "../../Data/dict.json";

/*NON IMPORT*/
const WAIT_INTERVAL = 1_000;
const ENTER_KEY = 13;
const color_scheme_array = [
  {
    name: "Random",
    eng: "Random",
    ina: "Acak",
    key: "random",
    color_sample: generate_colors(5, "random"),
  },
  {
    name: "Red to Green",
    eng: "Red to Green",
    ina: "Merah ke Hijau",
    key: "red_to_green",
    color_sample: generate_colors(5, "red_to_green"),
  },
  {
    name: "Green to Red",
    eng: "Green to Red",
    ina: "Hijau ke Merah",
    key: "green_to_red",
    color_sample: generate_colors(5, "green_to_red"),
  },
  {
    name: "Blue to Yellow",
    eng: "Blue to Yellow",
    ina: "Biru ke Kuning",
    key: "blue_to_yellow",
    color_sample: generate_colors(5, "blue_to_yellow"),
  },
  {
    name: "Purple to Orange",
    eng: "Purple to Orange",
    ina: "Ungu ke Jingga",
    key: "purple_to_orange",
    color_sample: generate_colors(5, "purple_to_orange"),
  },
  {
    name: "Grayscale",
    eng: "Grayscale",
    ina: "Skala Abu-Abu",
    key: "grayscale",
    color_sample: generate_colors(5, "grayscale"),
  },
  {
    name: "Red to Blue",
    eng: "Red to Blue",
    ina: "Merah ke Biru",
    key: "red_to_blue",
    color_sample: generate_colors(5, "red_to_blue"),
  },
  {
    name: "Red Dark to Red Bright",
    eng: "Red Dark to Red Bright",
    ina: "Merah Gelap ke Merah Terang",
    key: "black_to_red",
    color_sample: generate_colors(5, "black_to_red"),
  },
  {
    name: "Red Bright to Red Dark",
    eng: "Red Bright to Red Dark",
    ina: "Merah Terang ke Merah Gelap",
    key: "red_to_black",
    color_sample: generate_colors(5, "red_to_black"),
  },
  {
    name: "Rainbow",
    eng: "Rainbow",
    ina: "Pelangi",
    key: "rainbow",
    color_sample: generate_colors(5, "rainbow"),
  },
  {
    name: "Light Red to Dark Red",
    eng: "Light Red to Dark Red",
    ina: "Merah Terang ke Merah Gelap",
    key: "light_red_to_dark_red",
    color_sample: generate_colors(5, "light_red_to_dark_red"),
  },
  {
    name: "Dark Red to Light Red",
    eng: "Dark Red to Light Red",
    ina: "Merah Gelap ke Merah Terang",
    key: "dark_red_to_light_red",
    color_sample: generate_colors(5, "dark_red_to_light_red"),
  },
  {
    name: "Blues",
    eng: "Blues",
    ina: "Biru",
    key: "blues",
    color_sample: generate_colors(5, "blues"),
  },
  {
    name: "Greens",
    eng: "Greens",
    ina: "Hijau",
    key: "greens",
    color_sample: generate_colors(5, "greens"),
  },
  {
    name: "Reds",
    eng: "Reds",
    ina: "Merah",
    key: "reds",
    color_sample: generate_colors(5, "reds"),
  },
  {
    name: "Oranges",
    eng: "Oranges",
    ina: "Jingga",
    key: "oranges",
    color_sample: generate_colors(5, "oranges"),
  },
  {
    name: "RdBu",
    eng: "RdBu",
    ina: "Merah Biru",
    key: "rd_bu",
    color_sample: generate_colors(5, "rd_bu"),
  },
  {
    name: "PuOr",
    eng: "PuOr",
    ina: "Ungu Jingga",
    key: "pu_or",
    color_sample: generate_colors(5, "pu_or"),
  },
  {
    name: "Spectral",
    eng: "Spectral",
    ina: "Spektral",
    key: "spectral",
    color_sample: generate_colors(5, "spectral"),
  },
  {
    name: "Viridis",
    eng: "Viridis",
    ina: "Viridis",
    key: "viridis",
    color_sample: generate_colors(5, "viridis"),
  },
  {
    name: "Plasma",
    eng: "Plasma",
    ina: "Plasma",
    key: "plasma",
    color_sample: generate_colors(5, "plasma"),
  },
  {
    name: "YlGnBu",
    eng: "YlGnBu",
    ina: "Kuning Hijau Biru",
    key: "yl_gn_bu",
    color_sample: generate_colors(5, "yl_gn_bu"),
  },
  {
    name: "MAPID Colors",
    eng: "MAPID Colors",
    ina: "Warna MAPID",
    key: "mapid_color",
    color_sample: generate_colors(5, "mapid_color"),
  },
];

class COLOR_SCHEME_EDITOR extends Component {
  state = {
    anchor: null,
    modal_edit_color: false,
    search_query: "",
    color_scheme_array_filtered: [],
  };

  componentDidMount() {
    this.timer = null;
  }

  on_reset = () => {
    this.setState({ search_query: "", color_scheme_array_filtered: [] });
  };

  on_change = (e) => {
    clearTimeout(this.timer);
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
    if (name === "search_query" && value === "") {
      this.on_reset();
    }
    this.timer = setTimeout(this.trigger_action.bind(this), WAIT_INTERVAL);
  };

  handle_enter(e) {
    if (e.keyCode === ENTER_KEY) {
      this.trigger_action();
    }
  }

  trigger_action = () => {
    const { search_query } = this.state;
    if (search_query.length >= 2) {
      const regexString = search_query
        .split(" ")
        .map((word) => `(?=.*?${word})`)
        .join("|"); // Join with OR condition to match any part of the search_query
      const regex = new RegExp(regexString, "i");
      const list = color_scheme_array.filter(
        (item) => regex.test(item.ina) || regex.test(item.eng)
      );
      this.setState({ color_scheme_array_filtered: list });
    }
  };

  toggle_edit_color = (e) => {
    this.setState({
      modal_edit_color: !this.state.modal_edit_color,
      search_query: "",
      color_scheme_array_filtered: [],
    });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  on_generate_color = (color_mode) => {
    this.setState({ modal_edit_color: false });
    const { paint_object_edited, style_parameter } = this.props.properties;
    const { color_logic, style_type } = style_parameter;
    let value_array = style_parameter?.value_array || [];
    if (color_logic === "by_text") {
      value_array = extract_value_array(paint_object_edited, style_type);
    }
    const style_parameter_new = {
      ...style_parameter,
      value_array: value_array,
      color_mode: color_mode,
    };
    const style_object_new = generate_paint(style_parameter_new);
    this.props.set_value_properties({
      key: "paint_object_edited",
      value: style_object_new,
    });
    this.props.set_value_properties({
      key: "style_parameter",
      value: style_parameter_new,
    });
    this.props.status_action();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const {
      modal_edit_color,
      anchor,
      search_query,
      color_scheme_array_filtered,
    } = this.state;

    //global props
    const { style_parameter } = this.props.properties;

    //content
    const { color_mode, color_logic } = style_parameter;
    const color_selected = color_scheme_array.find(
      (item) => item.key === color_mode
    );
    let color_list_content;
    let list_final;

    if (color_scheme_array_filtered.length > 0) {
      list_final = color_scheme_array_filtered;
    } else {
      list_final = color_scheme_array;
    }

    if (!!search_query && color_scheme_array_filtered.length === 0) {
      color_list_content = <div>Tidak ada warna tersaring</div>;
    } else {
      color_list_content = (
        <>
          {list_final.map((item, index) => (
            <div
              key={index}
              className="margin_bottom_extra cursor_pointer"
              onClick={this.on_generate_color.bind(this, item.key)}
            >
              <div className="text_medium">{item[language]}</div>
              <div
                style={{
                  border: "1px solid black",
                  boxSizing: "border-box",
                  display: "flex",
                }}
              >
                {item.color_sample.map((color, idx) => (
                  <div
                    key={idx}
                    style={{
                      backgroundColor: color,
                      width: `${100 / item.color_sample.length}%`,
                      height: "20px",
                    }}
                  />
                ))}
              </div>
            </div>
          ))}
        </>
      );
    }

    const modal_edit_color_content = modal_edit_color && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_edit_color}
        onClose={this.toggle_edit_color}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "0px 15px 0px 15px",
            width: "250px",
          }}
        >
          <section
            className="text_right background_white"
            style={{ position: "sticky", top: "0px", padding: "5px" }}
          >
            <button
              className="button_small background_black"
              onClick={this.toggle_edit_color}
            >
              Close
            </button>
            <input
              placeholder="Search to filter color"
              name="search_query"
              value={search_query}
              onChange={this.on_change}
            />
          </section>
          <section style={{ height: "80vh", overflowY: "scroll" }}>
            {color_list_content}
          </section>
        </div>
      </Menu>
    );

    return (
      <main>
        {modal_edit_color_content}
        <p className="text_medium margin_bottom">
          {dict?.["Choose color scheme"]?.[language]}
        </p>
        <section className="cursor_pointer" onClick={this.toggle_edit_color}>
          <p className="badge_small background_black margin_bottom">
            {color_selected?.[language]}
          </p>
          {["by_text", "by_number_step"].includes(color_logic) ? (
            <div
              className="margin_bottom"
              style={{
                border: "1px solid black",
                boxSizing: "border-box",
                display: "flex",
              }}
            >
              {color_selected?.color_sample.map((color, idx) => (
                <div
                  key={idx}
                  style={{
                    backgroundColor: color,
                    width: `${100 / color_selected?.color_sample.length}%`,
                    height: "20px",
                  }}
                />
              ))}
            </div>
          ) : ["by_number_gradient"].includes(color_logic) ? (
            <div
              className="margin_bottom"
              style={{
                border: "1px solid black",
                height: "20px",
                background: `linear-gradient(to right, ${color_selected?.color_sample.join(
                  ", "
                )})`,
              }}
            />
          ) : (
            <div
              className="margin_bottom"
              style={{
                border: "1px solid black",
                boxSizing: "border-box",
                display: "flex",
              }}
            >
              {color_selected?.color_sample.map((color, idx) => (
                <div
                  key={idx}
                  style={{
                    backgroundColor: color,
                    width: `${100 / color_selected?.color_sample.length}%`,
                    height: "20px",
                  }}
                />
              ))}
            </div>
          )}
          <button
            className="button_small margin_bottom"
            onClick={this.toggle_edit_color}
          >
            {dict?.["Choose another color"]?.[language]}
          </button>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
})(COLOR_SCHEME_EDITOR);
