import React, { useRef } from "react";
import styled from "styled-components";
import background_mapid from "../../Assets/img/new_background.png";

import NavigationLanding from "../layout/NavigationLanding";

const Modal = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Top = styled.section`
  position: absolute;
  width: 100%;
  top: 0;
`;

// const Logo = styled.img`
//   position: absolute;
//   top: 2rem;
//   left: 3rem;
// `;

const ModalEmailConfirm = ({ isOpen, onClose, children, auth }) => {
  const { mode_balai, domain } = auth;

  const modalRef = useRef();

  let background;
  // let logo;
  if (
    domain === "bbwsciliwungcisadane.mapid.io" ||
    domain === "kabalai.mapid.io" ||
    mode_balai === "cilicis"
  ) {
    background = "";
    // logo = logoCilicis;
  } else if (
    domain === "bbwsbrantas.mapid.io" ||
    mode_balai === "brantas" ||
    !isNaN(parseInt(domain))
  ) {
    background = "";
    // logo = logoBrantas;
  } else if (domain === "bwsmalukuutara.mapid.io") {
    background = "";
    // logo = logoBrantas;
  } else if (
    domain === "ditjenikma.mapid.io" ||
    domain === "ditjenikma-kemenperin.mapid.io"
  ) {
    background = "";
  } else if (domain === "mrt.mapid.io") {
    background = "";
    // logo = logoMrt;
  } else if (domain === "hud.mapid.io") {
    background = "";
    // logo = logoHud;
  } else if (domain === "danamas.mapid.io") {
    background = "";
    // logo = logo_danamas;
  } else if (domain === "desaku.mapid.io") {
    background = "";
    // logo = logo_kemendagri;
  } else if (
    domain === "ditjenikma.mapid.io" ||
    domain === "ditjenikma-kemenperin.mapid.io"
  ) {
    background = "";
    // logo = logoKemenperin;
  } else {
    background = background_mapid;
    // logo = logoMapid;
  }

  return (
    <>
      {isOpen ? (
        <Modal
          ref={modalRef}
          style={
            background !== ""
              ? {
                  backgroundImage: `url(${background})`,
                  backgroundAttachment: "fixed",
                }
              : {
                  backgroundAttachment: "fixed",
                  background: `linear-gradient(
                    162deg,
                    rgba(147, 197, 253, 1) 5%,
                    rgba(12, 165, 235, 1) 53%,
                    rgba(134, 239, 172, 1) 94%
                )`,
                }
          }
        >
          <Top>
            <NavigationLanding />
          </Top>
          {children}
        </Modal>
      ) : null}
    </>
  );
};

export default ModalEmailConfirm;
