//Library modul
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//Personal Component

//Redux function
import { setLanguage } from "../../App/actions/authActions";

//Picture Asset

//General Function
// import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

class NavigationLanding extends Component {
  state = {
    width: window.innerWidth,
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  onSetLanguage = () => {
    const language = localStorage?.language ? localStorage?.language : "ina";

    if (language === "ina") {
      this.props.setLanguage("eng");
    } else {
      this.props.setLanguage("ina");
    }
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const domain = this.props.auth.domain;
    // const domain = "kai.mapid.io";
    const language_content = (
      <button onClick={() => this.onSetLanguage()} className="button_language">
        {/* {e.name} */}
        {language === "ina" ? (
          <img
            src="https://mapid.co.id/img/icon/indo-flag.png"
            alt="language"
            width="100%"
            height="100%"
          />
        ) : (
          <img
            src="https://mapid.co.id/img/icon/uk-flag.png"
            alt="language"
            width="100%"
            height="100%"
          />
        )}
      </button>
    );
    // let is_show = true;

    const content_3 = (
      <nav className="nav_login">
        <div className="nav_login_left">
          <Link to="/">
            <img src={domain_list?.[domain]?.logo} alt="mapid" />
          </Link>
        </div>
        <div className="nav_login_right">
          <div>
            <a
              href="https://mapid.co.id/documentation"
              target="_blank"
              rel="noopener noreferrer"
            >
              Docs
            </a>
            <a
              href="https://www.youtube.com/playlist?list=PLExQBc2gAI8uopeN3Xaj9V9b1QLPp8wPj"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tutorial
            </a>
          </div>
          {language_content}
        </div>
      </nav>
    );
    return <div>{content_3}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setLanguage })(NavigationLanding);
