/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import GeoJsonGeometriesLookup from "geojson-geometries-lookup";
import bbox from "@turf/bbox";
import calculate_area from "@turf/area";
import points_within_polygon from "@turf/points-within-polygon";

/*PERSONAL COMPONENT*/
import ProgressBar from "../common_spinner/ProgressBar";
import ErrorFree from "../../Components/user/ErrorFree";
import Modal from "../../Components/common_modal/Modal";

/*REDUX FUNCTION*/
import { set_value_layer } from "../../App/actions/dataActions";
import { importLayer } from "../../App/actions/layerNewActions";
import { set_value_user } from "../../App/actions/authActions";
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/
import uuid from "../../App/validation/uuid";
import fields_simple_map from "../../App/validation/fields_simple_map";
import format_date_formal from "../../App/validation/format_date_formal";
import { select_field_from_default_props } from "../../App/validation/generateGeoJson";
import demographic_theme from "../../Data/demographic_theme.json";

/*NON IMPORT*/

class InputGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      km_grid: 1,
      count_grid: 500,
      is_loading: false,
      total_grid: 1,
      current_grid: 0,
      current_overlay: 0,

      loading_status_grid: false,
      loading_status_overlay: false,
      modal_payment: false,
    };
  }

  on_set_mode_grid = (mode_grid) => {
    this.props.set_value_properties({
      key: "mode_grid",
      value: mode_grid,
    });
  };

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  on_save = ({
    features,
    min_demografi,
    max_demografi,
    min_poi,
    max_poi,
    min_skor_akhir,
    max_skor_akhir,
  }) => {
    //state
    const { km_grid } = this.state;

    //props
    const { provinsi, kota, kecamatan, kelurahan } = this.props.map;
    const { poi_tipe_1_selected, poi_tipe_2_selected } = this.props.properties;
    const { features_demografi, features_poi } = this.props.sini;
    const { geo_project } = this.props.project;
    const { domain } = this.props.auth;
    const { demographic_parent, demographic_child_idx_list } =
      this.props.properties;

    //result
    const demographic_theme_child = demographic_theme.find(
      (item) => item.key === demographic_parent
    ).list;
    const demographic_field_list = demographic_child_idx_list.map(
      (index) => demographic_theme_child[index]
    );

    let geojson_grid = {
      type: "FeatureCollection",
      features: features,
    };

    const fields = fields_simple_map({
      poi_tipe_1_selected,
      features,
      demographic_field_list,
      min_demografi,
      max_demografi,
      min_poi,
      max_poi,
    });

    const properties = select_field_from_default_props("Polygon");
    // const value_style_props_number = {
    //   range: [
    //     {
    //       min: 0,
    //       max: 20,
    //     },
    //     {
    //       min: 21,
    //       max: 40,
    //     },
    //     {
    //       min: 41,
    //       max: 60,
    //     },
    //     {
    //       min: 61,
    //       max: 80,
    //     },
    //     {
    //       min: 81,
    //       max: 100,
    //     },
    //   ],
    //   class_number: "5",
    //   selected_column: "SKOR TOTAL",
    //   color: ["#8b0000", "#c58000", "#ffff00", "#80b200", "#006400"],
    //   selected_color: "darkred,yellow,darkgreen",
    //   classify_mode: "number",
    //   isNumber: true,
    // };

    const value_style_props_text = {
      range: [
        "Sangat Sesuai",
        "Sesuai",
        "Cukup Sesuai",
        "Tidak Sesuai",
        "Sangat Tidak Sesuai",
      ],
      class_number: "5",
      selected_column: "HASIL",
      color: [
        "#006400", //"Sangat Sesuai",
        "#80b200", //"Sesuai",
        "#ffff00", //"Cukup Sesuai",
        "#c58000", //"Tidak Sesuai",
        "#8b0000", //"Sangat Tidak Sesuai",
      ],
      selected_color: "darkred,yellow,darkgreen",
      classify_mode: "text",
      isNumber: true,
    };

    /*
    generate insight_object
    insight_object adalah object yang berisi atribut-atribut parameter ketika mengakses insight

    1. area kelurahan yang mencakup
    2. poi yang dipilih
    3. next tema industri yang dipilih

    */

    const insight_object = {
      is_insight: true,
      provinsi,
      kota,
      kecamatan,
      kelurahan,
      poi_tipe_1_selected,
      poi_tipe_2_selected,
      km_grid,
      total_grid: geojson_grid?.features?.length,
      total_kelurahan: features_demografi?.length,
      total_poi_initial: features_poi?.length,
      demographic_parent: [demographic_parent],
      demographic_field_list,
    };

    const body = {
      name: `${kota} MAPID INSIGHT ${format_date_formal(Date.now())}`,
      description: "",
      type: "Polygon",
      folder: "",
      geo_project_id: geo_project?._id,
      fields: fields,
      properties: properties,
      geojson: geojson_grid,
      status: "digitasi",
      domain: domain,

      //atribut styling
      isGlobalStyle: true,
      valueStyleList: [],
      globalStyleMode: "by_value",
      valueStyleProps: value_style_props_text,
      is_show_and_fly: true,
      insight_object,
    };

    this.props.importLayer(body, true);
  };

  calculate_total_grids = (
    latitude_min,
    latitude_max,
    longitude_min,
    longitude_max,
    grid_size
  ) => {
    const lat_step = grid_size / 110.54;
    const lng_step =
      grid_size / (111.32 * Math.cos((latitude_min * Math.PI) / 180));
    const lat_count = Math.ceil((latitude_max - latitude_min) / lat_step);
    const lng_count = Math.ceil((longitude_max - longitude_min) / lng_step);
    return parseInt(lat_count * lng_count);
  };

  generate_grid = async (
    latitude_min,
    latitude_max,
    longitude_min,
    longitude_max,
    grid_size,
    grid_step_size,
    delay
  ) => {
    const features = [];
    const lat_step = grid_size / 110.54;
    const lng_step =
      grid_size / (111.32 * Math.cos((latitude_min * Math.PI) / 180));
    let id = 0;
    for (let lat = latitude_min; lat < latitude_max; lat += lat_step) {
      for (let lng = longitude_min; lng < longitude_max; lng += lng_step) {
        const polygon = {
          type: "Polygon",
          coordinates: [
            [
              [lng, lat],
              [lng + lng_step, lat],
              [lng + lng_step, lat + lat_step],
              [lng, lat + lat_step],
              [lng, lat],
            ],
          ],
        };
        features.push({
          key: uuid(),
          type: "Feature",
          id: id++,
          geometry: polygon,
          properties: {},
        });
        if (id % grid_step_size === 0) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
        const current_grid = features.length;
        this.setState(
          {
            current_grid,
            grid_features: features,
          },
          () => {
            const total_grid = this.state.total_grid;
            if (total_grid === current_grid) {
              this.generate_overlay();
            }
          }
        );
      }
    }
  };

  set_timeout_before_get_grid = () => {
    this.setState({ loading_status_grid: true });
    setTimeout(() => {
      this.get_grid();
    }, 100);
  };

  get_grid = () => {
    let { km_grid } = this.state;
    this.props.set_value_layer("current_progress_create_layer", 0);
    this.setState({ current_overlay: 0, total_grid: 1 });

    const { features_demografi } = this.props.sini;
    const geojson_demografi = {
      type: "FeatureCollection",
      features: features_demografi,
    };

    const [minLng, minLat, maxLng, maxLat] = bbox(geojson_demografi);

    const latitude_min = minLat - 0.01;
    const latitude_max = maxLat + 0.01;
    const longitude_min = minLng - 0.01;
    const longitude_max = maxLng + 0.01;

    const grid_size = km_grid;
    const grid_step_size = 100;
    const delay_grid = 1;

    const total_grid = this.calculate_total_grids(
      latitude_min,
      latitude_max,
      longitude_min,
      longitude_max,
      grid_size
    );

    this.setState({ total_grid });

    this.generate_grid(
      latitude_min,
      latitude_max,
      longitude_min,
      longitude_max,
      grid_size,
      grid_step_size,
      delay_grid
    );
  };

  generate_overlay = () => {
    this.setState({ loading_status_overlay: true });

    //state
    const { grid_features, total_grid } = this.state;

    //props
    const { features_demografi, features_poi } = this.props.sini;
    const { demographic_parent, demographic_child_idx_list } =
      this.props.properties;

    //result
    const geojson_grid = {
      type: "FeatureCollection",
      features: [grid_features[0]],
    };
    const grid_area_km = calculate_area(geojson_grid) / 1_000_000;
    const demographic_theme_child = demographic_theme.find(
      (item) => item.key === demographic_parent
    ).list;
    const demographic_field_list = demographic_child_idx_list.map(
      (index) => demographic_theme_child[index]
    );

    const geojson_demografi = {
      type: "FeatureCollection",
      features: features_demografi,
    };
    const geojson_poi = {
      type: "FeatureCollection",
      features: features_poi,
    };
    let features_overlay = [];
    const limit = 500;
    const iteration = Math.ceil(total_grid / limit);
    let array_loop = [];
    for (let i = 0; i < iteration; i++) {
      array_loop.push({
        start: i * limit,
        end: i * limit + limit,
      });
    }
    const delay_c = 0;
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array_loop.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    function get_mid_point(lat1, long1, lat2, long2, lat3, long3, lat4, long4) {
      const lat_center = (lat1 + lat2 + lat3 + lat4) / 4;
      const long_center = (long1 + long2 + long3 + long4) / 4;
      return {
        type: "Point",
        coordinates: [long_center, lat_center],
      };
    }
    const glookup = new GeoJsonGeometriesLookup(geojson_demografi);
    const child_function = async (object) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const { start, end } = object;
            let features_slice = grid_features.slice(start, end);
            features_slice = features_slice?.map((feature_grid) => {
              let properties = {};

              const data_poi = points_within_polygon(
                geojson_poi,
                feature_grid?.geometry
              );
              const long1 = feature_grid.geometry.coordinates[0][0][0];
              const lat1 = feature_grid.geometry.coordinates[0][0][1];
              const long2 = feature_grid.geometry.coordinates[0][1][0];
              const lat2 = feature_grid.geometry.coordinates[0][1][1];
              const long3 = feature_grid.geometry.coordinates[0][2][0];
              const lat3 = feature_grid.geometry.coordinates[0][2][1];
              const long4 = feature_grid.geometry.coordinates[0][3][0];
              const lat4 = feature_grid.geometry.coordinates[0][3][1];
              const grid_geojson = get_mid_point(
                lat1,
                long1,
                lat2,
                long2,
                lat3,
                long3,
                lat4,
                long4
              );
              const geojson_filtered = glookup.getContainers(grid_geojson);

              //NAMA KELURAHAN
              const kelurahan =
                geojson_filtered?.features?.[0]?.properties?.[
                  "DESA ATAU KELURAHAN"
                ] || "";
              const kelurahan_area_km =
                geojson_filtered?.features?.[0]?.properties?.[
                  "LUAS WILAYAH (KM2)"
                ] || 1;

              //SKORING DEMOGRAFI
              let total_population_initial = 0;
              const ratio_grid_kel = grid_area_km / kelurahan_area_km;
              demographic_field_list.forEach((item) => {
                const value =
                  (geojson_filtered?.features?.[0]?.properties?.[item] || 0) *
                  ratio_grid_kel;
                total_population_initial = total_population_initial + value;
                properties[item] = value;
              });

              total_population_initial = parseInt(total_population_initial);

              //SKORING POI
              const total_poi_initial = data_poi?.features?.length;
              const type_1 = [
                ...new Set(
                  data_poi?.features?.map((f) => f?.properties?.TIPE_1)
                ),
              ];
              type_1.forEach((e) => {
                const length = data_poi?.features?.filter(
                  (f) => f?.properties?.TIPE_1 === e
                )?.length;
                properties[e] = length;
              });

              //RESULT
              return {
                ...feature_grid,
                properties: {
                  ...properties,
                  key: feature_grid.key,
                  kelurahan: kelurahan,
                  total_population_initial,
                  total_poi_initial,
                  grid_area_km: parseInt(grid_area_km),
                },
              };
            });
            features_overlay = [...features_overlay, ...features_slice];
            this.setState({ current_overlay: features_overlay?.length });
          } catch (error) {}
        };
        return core_function();
      });
    };

    parent_function().then(() => {
      let features = features_overlay;
      features = features.map((item) => {
        item.date_in = Date.now();
        return item;
      });
      features = features.filter((item) => item.properties["kelurahan"]);
      features = features.map((d, index) => {
        // console.log("SKOR DEMOGRAFI",d?.properties?.["SKOR DEMOGRAFI"])
        return {
          ...d,
          properties: {
            ...d.properties,
            label: index + 1,
          },
        };
      });

      //generate min max untuk demografi & poi
      const initial_total_demografi =
        features?.[0]?.properties.total_population_initial;
      const initial_total_poi = features?.[0]?.properties.total_poi_initial;

      let min_demografi = initial_total_demografi;
      let max_demografi = initial_total_demografi;
      let min_poi = initial_total_poi;
      let max_poi = initial_total_poi;
      features.forEach((item) => {
        const demografi = item?.properties?.["total_population_initial"];
        const poi = item?.properties?.["total_poi_initial"];

        if (min_demografi > demografi) min_demografi = demografi;
        if (max_demografi < demografi) max_demografi = demografi;

        if (min_poi > poi) min_poi = poi;
        if (max_poi < poi) max_poi = poi;
      });

      this.on_save({
        features,
        min_demografi,
        max_demografi,
        min_poi,
        max_poi,
      });
      this.setState({
        loading_status_grid: false,
        loading_status_overlay: false,
      });
    });
  };

  toggle_payment = () => {
    this.setState({ modal_payment: !this.state.modal_payment });
  };

  render() {
    //local storage

    //local state
    const {
      km_grid,
      count_grid,
      loading_status_grid,
      total_grid,
      current_grid,
      current_overlay,
      loading_status_overlay,
      modal_payment,
    } = this.state;

    //global props
    const { loadingProcess } = this.props.layer;
    const { quota_access, isAuthenticated } = this.props.auth;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const { features_demografi, features_poi } = this.props.sini;
    const { save_layer_progress, mode_grid } = this.props.properties;

    //content
    let button_content;

    if (
      license_user_status?.is_has_license ||
      license_group_status?.is_has_license ||
      quota_access > 0
    ) {
      if (loading_status_grid) {
        button_content = (
          <div className="button" id="grey" disabled>
            Membuat grid (1/3)...
          </div>
        );
      } else if (loading_status_overlay) {
        button_content = (
          <div className="button" id="grey" disabled>
            Proses overlay (2/3)...
          </div>
        );
      } else if (loadingProcess && save_layer_progress === 0) {
        button_content = (
          <div className="button" id="grey">
            Menyimpan layer (3/3)...
          </div>
        );
      } else if (!features_demografi.length && !features_poi.length) {
        button_content = (
          <button className="button" id="grey">
            Dapatkan area terbaik
          </button>
        );
      } else {
        button_content = (
          <button className="button" onClick={this.set_timeout_before_get_grid}>
            Dapatkan area terbaik
          </button>
        );
      }
    } else if (!isAuthenticated) {
      button_content = (
        <div className="button" id="grey" onClick={this.toggle_login}>
          Dapatkan area terbaik
        </div>
      );
    } else {
      button_content = (
        <div className="button" id="grey" onClick={this.toggle_payment}>
          Dapatkan area terbaik
        </div>
      );
    }

    const modal_payment_content = modal_payment && (
      <Modal
        modalSize="medium"
        id="modal_payment"
        isOpen={modal_payment}
        onClose={this.toggle_payment}
      >
        <div className="box-body">
          <ErrorFree />
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_payment_content}
        <section className="margin_bottom">
          <div className="badge_pill" id="blue">
            Step 2
          </div>
          <p className="text_header">Dapatkan area terbaik</p>
        </section>
        <section className="container_light">
          {/* <p className="text_bold">
            Pilih mode parameter kotak <i>(grid)</i>
          </p>

          <section className="margin_bottom">
            <button
              className={`button margin_right margin_bottom ${
                mode_grid === "size_grid"
                  ? "background_black"
                  : "background_white"
              }`}
              onClick={this.on_set_mode_grid.bind(this, "size_grid")}
            >
              Ukuran
            </button>
            <button
              className={`button margin_right margin_bottom ${
                mode_grid === "count_grid"
                  ? "background_black"
                  : "background_white"
              }`}
              onClick={this.on_set_mode_grid.bind(this, "count_grid")}
            >
              Jumlah
            </button>
          </section> */}

          {mode_grid === "size_grid" ? (
            <>
              <p className="text_bold">
                Atur ukuran kotak <i>(grid)</i>
              </p>
              <input
                style={{ width: "100px" }}
                className="inline margin_right"
                type="number"
                id="km_grid"
                name="km_grid"
                value={km_grid}
                onChange={(e) => this.setState({ km_grid: e.target.value })}
              />
              <p className="inline text_medium">km</p>
            </>
          ) : (
            <>
              <p className="text_bold">
                Atur jumlah kotak <i>(grid)</i>
              </p>
              <input
                style={{ width: "100px" }}
                className="inline margin_right"
                type="number"
                id="count_grid"
                name="count_grid"
                value={count_grid}
                onChange={(e) => this.setState({ count_grid: e.target.value })}
              />
              <p className="inline text_medium">grid</p>
            </>
          )}

          <br />
          <br />

          <div className="container_light margin_bottom" id="background_white">
            <ProgressBar
              current_number={current_grid}
              total_number={total_grid}
              name="Membuat grid"
            />
            <br />
            <ProgressBar
              current_number={current_overlay}
              total_number={total_grid}
              name="Overlay data ke grid"
            />
            <br />
            <ProgressBar
              current_number={save_layer_progress}
              total_number={100}
              name="Menyimpan data"
            />
          </div>
          {button_content}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  map: state.map,
  project: state.project,
  payment: state.payment,
  sini: state.sini,
  properties: state.properties,
  license_reducer: state.license_reducer,
});

export default connect(mapStateToProps, {
  set_value_layer,
  importLayer,
  set_value_user,
  set_value_properties,
})(InputGrid);
