/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer, Marker } from "@urbica/react-map-gl";
import bbox from "@turf/bbox";
import centroid from "@turf/centroid";
import along from "@turf/along";
import calculate_length from "@turf/length";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
import generate_color_map from "../../App/validation/generate_color_map";
import generate_color_interpolate from "../../App/validation/generate_color_interpolate";

/*NON IMPORT*/
let valueColor = "#1b659d";

class LAYER_BASIC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render_content: [],
    };
  }

  componentDidMount() {
    this.on_render_layer(this.props.layer.layer_id_active);
  }

  componentDidUpdate(prevProps) {
    const geometryStatus = this.props.layer.geometryStatus;
    let layer_id_active_after = this?.props?.layer?.layer_id_active;
    const layer_id_after = this.props.layer?.layer_id;
    const geo_layer_list_after = this?.props?.layer?.geo_layer_list;
    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;
    const fly_action_after = this.props.map.fly_action;
    const fly_action_before = prevProps.map.fly_action;
    const layer_after = geo_layer_list_after.find(
      (l) => l.geo_layer?._id === layer_id_after
    );
    const features_after = layer_after?.geo_layer?.geojson_filtered?.features
      ? layer_after?.geo_layer?.geojson_filtered?.features
      : [];
    if (
      status_action_after !== status_action_before ||
      fly_action_after !== fly_action_before
    ) {
      //STEP 1 render_content layer
      this.on_render_layer(layer_id_active_after);
    }
    //STEP 2 fly to jika diperlukan
    //fly to ke layer yang baru saja diaktifkan
    let { geo_layer_list } = this.props.layer;
    const layer = geo_layer_list.find(
      (e) => e?.geo_layer?._id === layer_id_after
    );
    const { map } = this.props?.map;
    const geojson_filtered = layer?.geo_layer?.geojson_filtered
      ? layer?.geo_layer?.geojson_filtered
      : { type: "FeatureCollection", features: [] };
    const viewStatus = layer_id_active_after?.includes(layer_id_after);
    if (
      geojson_filtered?.features?.length > 0 &&
      map &&
      viewStatus &&
      features_after.length > 0 &&
      !geometryStatus &&
      fly_action_after !== fly_action_before
    ) {
      this.on_fly_layer();
    }
  }

  on_fly_layer = () => {
    const layer_id_after = this.props.layer?.layer_id;
    const { geo_layer_list } = this.props.layer;
    const layer = geo_layer_list.find(
      (e) => e.geo_layer?._id === layer_id_after
    );
    const geojson_filtered = layer?.geo_layer?.geojson_filtered || {
      type: "FeatureCollection",
      features: [],
    };
    if (
      layer_id_after &&
      geo_layer_list.length > 0 &&
      layer?.geo_layer?._id &&
      geojson_filtered?.features?.length > 0
    ) {
      const map = this?.props?.map?.map;
      if (map) {
        let [minLng, minLat, maxLng, maxLat] = bbox(geojson_filtered);
        const padding = { top: 150, bottom: 300, left: 400, right: 400 };
        map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding,
            maxZoom: 22,
          }
        );
      }
    }
  };

  on_render_layer = () => {
    let { geo_layer_list, layer_id_heatmap, layer_apply_color, layer_id } =
      this.props.layer;
    const layer_id_active = this.props.layer.layer_id_active;
    const { is_preview_on, paint_object_edited } = this.props.properties;
    geo_layer_list = geo_layer_list.map((data) => {
      let color_key;
      if (
        data.geo_layer?.type === "Point" ||
        data.geo_layer?.type === "MultiPoint"
      ) {
        color_key = "circle-color";
      } else if (
        data.geo_layer?.type === "LineString" ||
        data.geo_layer?.type === "MultiLineString"
      ) {
        color_key = "line-color";
      } else if (
        data.geo_layer?.type === "Polygon" ||
        data.geo_layer?.type === "MultiPolygon"
      ) {
        color_key = "fill-color";
      }
      const style_array = data?.geo_layer?.style_array || [];
      const default_style_key = data?.geo_layer?.default_style_key;
      //convert tipe geojson (point, line, poligon) ke tipe visual peta (circle, line, fill, symbol)
      data.type_new =
        data.geo_layer?.type_2 === "pch"
          ? "symbol"
          : data.geo_layer?.type === "Point" ||
            data.geo_layer?.type === "MultiPoint"
          ? "circle"
          : data.geo_layer?.type === "LineString" ||
            data.geo_layer?.type === "MultiLineString"
          ? "line"
          : data.geo_layer?.type === "Polygon" ||
            data.geo_layer?.type === "MultiPolygon"
          ? "fill"
          : "circle";
      let features_filtered;
      //handle jika geojson kosong atau undefined, masukan geojson kosong dengan format yang benar
      if (
        !data?.geo_layer?.geojson_filtered?.features?.length &&
        data?.geo_layer
      ) {
        data.geo_layer.geojson_new = {
          type: "FeatureCollection",
          features: [],
        };
      } else {
        features_filtered = data?.geo_layer?.geojson_filtered?.features
          ? data?.geo_layer?.geojson_filtered?.features
          : [];
        if (data.geo_layer?.type === "Point") {
          features_filtered = features_filtered.filter((f) => {
            return (
              f.geometry?.coordinates?.[1] <= 90 &&
              f.geometry?.coordinates?.[1] >= -90 &&
              f.geometry?.coordinates?.[0] <= 180 &&
              f.geometry?.coordinates?.[0] >= -180
            );
          });
        }
        features_filtered = features_filtered.map((f) => {
          f.properties.key = f?.key;
          return f;
        });
        //handle untuk titik masukan long lat ke dalam properties supaya bisa diakses lewat pop up
        if (
          data?.geo_layer?.type === "Point" ||
          data?.geo_layer?.type === "IoT"
        ) {
          data.geo_layer.geojson_filtered.features = features_filtered.map(
            (f) => {
              f.properties.long = f.geometry?.coordinates?.[0];
              f.properties.lat = f.geometry?.coordinates?.[1];
              return f;
            }
          );
        } else {
          data.geo_layer.geojson_filtered.features = features_filtered;
        }
        data.geo_layer.geojson_new = {
          type: "FeatureCollection",
          features: features_filtered,
        };
      }
      let paint_object = {};
      let paint_object_line_polygon = {};
      let selected_column = data?.geo_layer?.valueStyleProps?.selected_column;
      let classify_mode = data?.geo_layer?.valueStyleProps?.classify_mode;
      let mode_color = data.geo_layer?.globalStyleMode;
      let global_style = data?.geo_layer?.isGlobalStyle || false;
      let color = data?.geo_layer?.valueStyleProps?.color || [];
      let range = data?.geo_layer?.valueStyleProps?.range || [];
      let range_filter = range?.map((d) => d?.max);
      const type = data.geo_layer.type;
      let properties_raw = data?.geo_layer?.properties;
      const is_error = !!properties_raw?.[0]?.["LineString"];
      if (is_error) {
        if (type === "Point" || type === "MultiPoint") {
          properties_raw = properties_raw?.[0]?.["Point"];
        } else if (type === "LineString" || type === "MultiLineString") {
          properties_raw = properties_raw?.[0]?.["LineString"];
        } else if (type === "Polygon" || type === "MultiPolygon") {
          properties_raw = properties_raw?.[0]?.["Polygon"];
        }
      }
      let circle_radius = Number(
        properties_raw?.find((d) => d?.key === "circle_radius")?.defaultValue
      );
      let circle_stroke_width = Number(
        properties_raw?.find((d) => d?.key === "circle_stroke_width")
          ?.defaultValue
      );
      let circle_stroke_color = properties_raw?.find(
        (d) => d?.key === "circle_stroke_color"
      )?.defaultValue;
      let line_width = Number(
        properties_raw?.find((d) => d?.key === "line_width")?.defaultValue
      );
      let outline_color = properties_raw?.find(
        (d) => d?.key === "outline_color"
      )?.defaultValue;
      let opacity = Number(
        properties_raw?.find((d) => d?.key === "opacity")?.defaultValue
      );
      let outline_width = Number(
        properties_raw?.find((d) => d?.key === "outline_width")?.defaultValue
      );
      paint_object_line_polygon = {
        "line-color": outline_color,
        "line-width": outline_width,
      };
      let outline;
      let stroke;
      if (
        data.geo_layer?.type === "Point" ||
        data.geo_layer?.type === "MultiPoint"
      ) {
        stroke = circle_stroke_width;
        outline = circle_stroke_color;
      } else if (
        data.geo_layer?.type === "LineString" ||
        data.geo_layer?.type === "MultiLineString"
      ) {
        outline = 1;
      } else if (
        data.geo_layer?.type === "Polygon" ||
        data.geo_layer?.type === "MultiPolygon"
      ) {
        outline = outline_color;
      }
      let apply_color_id = layer_apply_color.find(
        (item) => item.layer_id === data.geo_layer?._id
      );
      /*
        Case styling, diurutkan dari prioritas logic

        CASE 1 : dari apply color
        CASE 2 : dari style bawaan
        CASE 3 : dari value style solid 
        CASE 4 : dari value style text || classify by group
        CASE 5 : dari value style number || classify by number

        nextnya adalah:

        CASE 1 : dari apply color

        CASE 2 : dari preview jika aktif
        CASE 3 : dari style_array kode baru

        CASE 4 : dari style bawaan
        CASE 5 : dari value style solid 
        CASE 6 : dari value style text || classify by group
        CASE 7 : dari value style number || classify by number
      */
      let case_color_style = "";
      if (apply_color_id) {
        case_color_style = 1;
        //CASE 1 : dari apply color
        let apply_value = apply_color_id?.config?.array_color?.labels || [];
        apply_value = apply_value.map((item) => item);
        let apply_color =
          apply_color_id?.config?.array_color?.datasets?.[0]?.backgroundColor;
        const array_apply_color = generate_color_map(
          apply_value,
          apply_color,
          apply_color_id?.config?.field_key
        );
        //pakai codingan baru apply_color_status
        if (
          data.geo_layer?.type === "Point" ||
          data.geo_layer?.type === "MultiPoint"
        ) {
          paint_object = {
            "circle-color": array_apply_color,
            "circle-radius": circle_radius ? circle_radius : 7,
            "circle-stroke-width": stroke ? stroke : 2,
            "circle-stroke-color": outline ? outline : "#fff",
          };
        } else if (
          data.geo_layer?.type === "LineString" ||
          data.geo_layer?.type === "MultiLineString"
        ) {
          paint_object = {
            "line-color": array_apply_color,
            "line-width": 3,
            "line-opacity": opacity ? opacity : 0.5,
          };
        } else if (
          data.geo_layer?.type === "Polygon" ||
          data.geo_layer?.type === "MultiPolygon"
        ) {
          paint_object = {
            "fill-color": array_apply_color,
            "fill-opacity": 0.4,
          };
        }
      } else if (
        is_preview_on &&
        !!paint_object_edited?.[color_key] &&
        layer_id === data?.geo_layer?._id
      ) {
        if (color_key === "fill-color") {
          paint_object_line_polygon = {
            "line-color": paint_object_edited?.["line-color"],
            "line-width": paint_object_edited?.["line-width"],
            "line-opacity": paint_object_edited?.["line-opacity"],
            "line-dasharray": paint_object_edited?.["line-dasharray"],
            "line-gap-width": paint_object_edited?.["line-gap-width"],
            "line-blur": paint_object_edited?.["line-blur"],
          };
          paint_object = {
            "fill-color": paint_object_edited?.["fill-color"],
            "fill-opacity": paint_object_edited?.["fill-opacity"],
            "fill-outline-color": paint_object_edited?.["fill-outline-color"],
          };
        } else {
          paint_object = paint_object_edited;
        }
      } else if (style_array.length > 0) {
        case_color_style = 2;
        paint_object =
          style_array.find((item) => item?.key === default_style_key)
            ?.paint_object || {};
        if (
          data.geo_layer?.type === "Polygon" ||
          data.geo_layer?.type === "MultiPolygon"
        ) {
          paint_object_line_polygon = {
            "line-color": paint_object["line-color"],
            "line-width": paint_object["line-width"],
            "line-opacity": paint_object["line-opacity"],
            "line-dasharray": paint_object["line-dasharray"],
            "line-gap-width": paint_object["line-gap-width"],
            "line-blur": paint_object["line-blur"],
          };
          paint_object = {
            "fill-color": paint_object["fill-color"],
            "fill-opacity": paint_object["fill-opacity"],
            "fill-outline-color": paint_object["fill-outline-color"],
          };
        }
      } else {
        if (global_style === false) {
          //CASE 3 : dari style bawaan
          case_color_style = 3;
          if (
            data.geo_layer?.type === "Point" ||
            data.geo_layer?.type === "MultiPoint"
          ) {
            paint_object = {
              "circle-color": ["get", "circle_color"],
              "circle-radius": circle_radius
                ? circle_radius
                : ["get", "circle_radius"],
              "circle-stroke-width": stroke
                ? stroke
                : ["get", "circle_stroke_width"],
              "circle-stroke-color": outline
                ? outline
                : ["get", "circle_stroke_color"],
            };
          } else if (
            data.geo_layer?.type === "LineString" ||
            data.geo_layer?.type === "MultiLineString"
          ) {
            paint_object = {
              "line-color": ["get", "color"],
              "line-width": line_width ? line_width : ["get", "line_width"],
              "line-opacity": opacity ? opacity : ["get", "opacity"],
              "line-gap-width": ["get", "line_gap_width"],
            };
          } else if (
            data.geo_layer?.type === "Polygon" ||
            data.geo_layer?.type === "MultiPolygon"
          ) {
            paint_object = {
              "fill-color": ["get", "color"],
              "fill-opacity": opacity ? opacity : ["get", "opacity"],
            };
          }
        } else if (global_style === true) {
          if (mode_color === "solid") {
            //CASE 4 : dari value style solid
            case_color_style = 4;
            if (
              data.geo_layer?.type === "Point" ||
              data.geo_layer?.type === "MultiPoint"
            ) {
              paint_object = {
                "circle-color": properties_raw[2]?.defaultValue,
                "circle-radius": circle_radius
                  ? circle_radius
                  : ["get", "circle_radius"],
                "circle-stroke-width": stroke
                  ? stroke
                  : ["get", "circle_stroke_width"],
                "circle-stroke-color": outline
                  ? outline
                  : ["get", "circle_stroke_color"],
              };
            } else if (
              data.geo_layer?.type === "LineString" ||
              data.geo_layer?.type === "MultiLineString"
            ) {
              paint_object = {
                "line-color": properties_raw?.[0]?.defaultValue,
                "line-width": line_width ? line_width : ["get", "line_width"],
                "line-opacity": opacity ? opacity : ["get", "opacity"],
                "line-gap-width": ["get", "line_gap_width"],
              };
            } else if (
              data.geo_layer?.type === "Polygon" ||
              data.geo_layer?.type === "MultiPolygon"
            ) {
              paint_object = {
                "fill-color": properties_raw?.[0]?.defaultValue,
                "fill-opacity": opacity ? opacity : ["get", "opacity"],
              };
            }
          } else if (mode_color === "by_value") {
            if (classify_mode === "text") {
              //CASE 5 : dari value style text || classify by group
              case_color_style = 5;
              valueColor = generate_color_map(range, color, selected_column);
              if (
                data.geo_layer?.type === "Point" ||
                data.geo_layer?.type === "MultiPoint"
              ) {
                paint_object = {
                  "circle-color": valueColor,
                  "circle-radius": circle_radius
                    ? circle_radius
                    : ["get", "circle_radius"],
                  "circle-stroke-width": stroke
                    ? stroke
                    : ["get", "circle_stroke_width"],
                  "circle-stroke-color": outline
                    ? outline
                    : ["get", "circle_stroke_color"],
                };
              } else if (
                data.geo_layer?.type === "LineString" ||
                data.geo_layer?.type === "MultiLineString"
              ) {
                paint_object = {
                  "line-color": valueColor,
                  "line-width": line_width ? line_width : ["get", "line_width"],
                  "line-opacity": opacity ? opacity : ["get", "opacity"],
                  "line-gap-width": ["get", "line_gap_width"],
                };
              } else if (
                data.geo_layer?.type === "Polygon" ||
                data.geo_layer?.type === "MultiPolygon"
              ) {
                paint_object = {
                  "fill-color": valueColor,
                  "fill-opacity": opacity ? opacity : ["get", "opacity"],
                };
              }
            } else if (classify_mode === "number") {
              //CASE 6 : dari value style number || classify by number
              case_color_style = 6;
              valueColor = generate_color_interpolate(
                range_filter,
                color,
                selected_column
              );
              if (
                data.geo_layer?.type === "Point" ||
                data.geo_layer?.type === "MultiPoint"
              ) {
                paint_object = {
                  "circle-color": valueColor,
                  "circle-radius": circle_radius
                    ? circle_radius
                    : ["get", "circle_radius"],
                  "circle-stroke-width": stroke
                    ? stroke
                    : ["get", "circle_stroke_width"],
                  "circle-stroke-color": outline
                    ? outline
                    : ["get", "circle_stroke_color"],
                };
              } else if (
                data.geo_layer?.type === "LineString" ||
                data.geo_layer?.type === "MultiLineString"
              ) {
                paint_object = {
                  "line-color": valueColor,
                  "line-width": line_width ? line_width : ["get", "line_width"],
                  "line-opacity": opacity ? opacity : ["get", "opacity"],
                  "line-gap-width": ["get", "line_gap_width"],
                };
              } else if (
                data.geo_layer?.type === "Polygon" ||
                data.geo_layer?.type === "MultiPolygon"
              ) {
                paint_object = {
                  "fill-color": valueColor,
                  "fill-opacity": opacity ? opacity : ["get", "opacity"],
                };
              }
            }
          }
        }
      }
      //styling khusus untuk pos duga air
      if (data.geo_layer.type_2 === "pda") {
        paint_object = {
          "circle-color": [
            "match",
            ["get", "status"],
            "siaga1",
            "#ff0000",
            "siaga2",
            "#ffff00",
            "siaga3",
            "#0000ff",
            "normal",
            "#008000",
            "Offline",
            "#000000",
            "delay",
            "#000000",
            "tanpa",
            "#000000",
            "#000000",
          ],
          "circle-radius": 5,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#fff",
        };
      }
      //styling khusus untuk pos curah hujan
      if (data?.geo_layer?.type_2 === "pch") {
        paint_object = {
          "circle-color": "#000000",
          "circle-radius": 5,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#fff",
        };
        const icon_image = ["get", "status"];
        data.icon_image = icon_image;
      }
      data.paint_object = paint_object;
      data.paint_object_line_polygon = paint_object_line_polygon;
      data.case_color_style = case_color_style;
      return data;
    });
    const render_content = geo_layer_list.map((data, index) => {
      const visibility = layer_id_active.includes(data?.geo_layer?._id)
        ? "visible"
        : "none";
      const fields = data?.geo_layer?.fields || [];
      const type = data?.geo_layer?.type;
      const features_filtered = data?.geo_layer?.geojson_filtered?.features
        ? data?.geo_layer?.geojson_filtered?.features
        : [];
      const label_config = data?.geo_layer?.label_config || {};
      let label_content;
      if (
        (label_config?.is_use_label_text || label_config?.is_use_label_image) &&
        visibility === "visible"
      ) {
        let features_center_marker = [];
        if (
          ["Polygon", "MultiPolygon", "MultiLineString", "MultiPoint"].includes(
            type
          )
        ) {
          features_center_marker = features_filtered.map((item) => {
            let feature = {};
            const geojson = {
              type: "FeatureCollection",
              features: [item],
            };
            feature = centroid(geojson, { properties: item.properties });
            return feature;
          });
        } else if (type === "Point") {
          features_center_marker = features_filtered;
        } else if (type === "LineString") {
          features_center_marker = features_filtered.map((item) => {
            const length_km = calculate_length(item, {
              units: "kilometers",
            }).toFixed(2);
            const center_length = length_km / 2;
            let point = along(item, center_length, { units: "kilometers" });
            point.properties = item.properties;
            return point;
          });
        }
        const label_text_field_key_array =
          label_config?.label_text_field_key_array || [];
        const label_image_field_key_array =
          label_config?.label_image_field_key_array || [];
        const style_mode = label_config?.style_mode || "style_1";
        const background_color_primary =
          label_config?.background_color_primary || "#292571";
        const outline_color_primary =
          label_config?.outline_color_primary || "#ffffff";
        const text_color_primary =
          label_config?.text_color_primary || "#ffffff";
        const background_color_secondary =
          label_config?.background_color_secondary || "#ee6b1e";
        const outline_color_secondary =
          label_config?.outline_color_secondary || "#ffffff";
        const text_color_secondary =
          label_config?.text_color_secondary || "#ffffff";
        label_content = features_center_marker.map((item, idx) => {
          let label_image;
          let label_text;
          if (label_config?.is_use_label_image) {
            label_image = (
              <div>
                {label_image_field_key_array.map((field_key, idx) => {
                  let content;
                  if (item?.properties?.[field_key]) {
                    content = (
                      <div
                        key={idx}
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "5px",
                          backgroundImage: `url(${item?.properties?.[field_key]})`,
                          backgroundSize: "cover",
                        }}
                        className="margin_bottom_minor margin_right_minor"
                      />
                    );
                  } else {
                    content = <div key={idx} />;
                  }
                  return content;
                })}
              </div>
            );
          }
          if (label_config?.is_use_label_text) {
            label_text = (
              <div>
                {label_text_field_key_array.map((field_key, idx) => {
                  const field = fields.find((item) => item.key === field_key);
                  const field_type = field?.type;
                  let text = item?.properties?.[field_key];
                  if (["number"].includes(field_type)) {
                    text = new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(text);
                  } else if (field_type === "currency") {
                    text =
                      "Rp." +
                      new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(text);
                  }
                  let content;
                  if (item?.properties?.[field_key]) {
                    content = (
                      <div key={idx}>
                        <p
                          className={`margin_bottom_minor badge_pill_small text_small`}
                          style={{
                            color:
                              idx === 0
                                ? text_color_primary
                                : text_color_secondary,
                            backgroundColor:
                              idx === 0
                                ? background_color_primary
                                : background_color_secondary,
                            borderColor:
                              idx === 0
                                ? outline_color_primary
                                : outline_color_secondary,
                            borderWidth: "1px",
                            borderStyle: "solid",
                          }}
                        >
                          {text}
                        </p>
                      </div>
                    );
                  } else {
                    content = <div key={idx}></div>;
                  }
                  return content;
                })}
              </div>
            );
          }
          let label_final;
          if (style_mode === "style_1") {
            //style_1
            label_final = (
              <>
                {label_image}
                {label_text}
              </>
            );
          } else {
            //style_2
            //image
            const field_key_image_first = label_image_field_key_array[0];
            const image_first_url = item?.properties?.[field_key_image_first];
            let label_image_first;
            if (label_config?.is_use_label_image) {
              label_image_first = (
                <span
                  className="badge_circle margin_right"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "2rem",
                    backgroundImage: `url(${image_first_url})`,
                    backgroundSize: "cover",
                  }}
                />
              );
            }
            //text
            let field_key_text_first = label_text_field_key_array[0];
            const field = fields.find(
              (item) => item.key === field_key_text_first
            );
            const field_type = field?.type;
            let text_first;
            if (label_config?.is_use_label_text) {
              text_first = item?.properties?.[field_key_text_first];
              if (["number"].includes(field_type)) {
                text_first = new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(text_first);
              } else if (field_type === "currency") {
                text_first =
                  "Rp." +
                  new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(text_first);
              }
            }
            const label_text_field_key_array_wo_first = [
              ...label_text_field_key_array,
            ];
            label_text_field_key_array_wo_first.shift();
            let text_label;
            if (label_config?.is_use_label_text) {
              text_label = (
                <div>
                  {label_text_field_key_array_wo_first.map((field_key, idx) => {
                    const field = fields.find((item) => item.key === field_key);
                    const field_type = field?.type;
                    let text = item?.properties?.[field_key];
                    if (["number"].includes(field_type)) {
                      text = new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(text);
                    } else if (field_type === "currency") {
                      text =
                        "Rp." +
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(text);
                    }
                    let content;
                    if (item?.properties?.[field_key]) {
                      content = (
                        <div key={idx}>
                          <p
                            className="text_small margin_bottom_minor badge_pill_small"
                            style={{
                              color: text_color_secondary,
                              backgroundColor: background_color_secondary,
                              borderColor: outline_color_secondary,
                              borderWidth: "1px",
                              borderStyle: "solid",
                            }}
                          >
                            {text}
                          </p>
                        </div>
                      );
                    } else {
                      content = <div key={idx}></div>;
                    }
                    return content;
                  })}
                </div>
              );
            }
            if (image_first_url || text_first) {
              label_final = (
                <div>
                  <div
                    className="button_pill margin_bottom_minor"
                    style={{
                      paddingRight:
                        label_image_first && text_first ? "15px" : "0px",
                      color: text_color_primary,
                      backgroundColor: background_color_primary,
                      borderColor: outline_color_primary,
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    {label_image_first}
                    {text_first}
                  </div>
                  {text_label}
                </div>
              );
            }
          }
          return (
            <Marker
              key={idx}
              longitude={item.geometry.coordinates[0]}
              latitude={item.geometry.coordinates[1]}
              draggable={false}
            >
              {label_final}
            </Marker>
          );
        });
      }
      let display_render;
      if (layer_id_heatmap.includes(data?.geo_layer?._id)) {
        display_render = (
          <section>
            <Source
              key={data?.geo_layer?._id}
              id={data?.geo_layer?._id}
              type="geojson"
              data={data?.geo_layer?.geojson_new}
            />
            <Layer
              source={data?.geo_layer?._id}
              id={data?.geo_layer?._id}
              type="heatmap"
              paint={{
                "heatmap-weight": [
                  "interpolate",
                  ["linear"],
                  ["get", "mag"],
                  0,
                  0,
                  6,
                  1,
                ],
                "heatmap-radius": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  0,
                  2,
                  9,
                  20,
                ],
              }}
            />
          </section>
        );
      } else if (data?.geo_layer?.is_cluster && data?.type_new === "circle") {
        const convert_multi_point_to_points = (geojson) => {
          const features = geojson.features.flatMap((feature) => {
            if (feature.geometry.type === "MultiPoint") {
              return feature.geometry.coordinates.map((coord) => ({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: coord,
                },
                properties: feature.properties,
              }));
            }
            return feature;
          });
          return {
            type: "FeatureCollection",
            features,
          };
        };
        let new_data = data?.geo_layer?.geojson_new;
        if (data?.geo_layer?.type === "MultiPoint") {
          new_data = convert_multi_point_to_points(
            data?.geo_layer?.geojson_new
          );
        }
        display_render = (
          <div>
            <Source
              key={data?.geo_layer?._id + "_cluster"}
              id={data?.geo_layer?._id + "_cluster"}
              type="geojson"
              data={new_data}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={50}
            />
            <Source
              key={data?.geo_layer?._id}
              id={data?.geo_layer?._id}
              type="geojson"
              data={data?.geo_layer?.geojson_new}
            />
            {/* Cluster layer */}
            <Layer
              source={data?.geo_layer?._id + "_cluster"}
              id={`${data?.geo_layer?._id}_cluster_layer`}
              type="circle"
              filter={["has", "point_count"]}
              paint={{
                "circle-color": "#51bbd6",
                "circle-radius": [
                  "interpolate",
                  ["linear"],
                  ["get", "point_count"],
                  0,
                  10,
                  1000,
                  100,
                ],
              }}
              layout={{ visibility: visibility }}
              onClick={this.props.layerOnClick.bind(this)}
            />
            {/* Cluster count layer */}
            <Layer
              source={data?.geo_layer?._id + "_cluster"}
              id={`${data?.geo_layer?._id}_cluster_count`}
              type="symbol"
              filter={["has", "point_count"]}
              layout={{
                "text-field": "{point_count_abbreviated}",
                "text-font": ["Figtree"],
                "text-size": 12,
                visibility: visibility,
              }}
              paint={{
                "text-color": "#ffffff",
              }}
            />
            {/* Unclustered points layer */}
            <Layer
              source={data?.geo_layer?._id + "_cluster"}
              id={data?.geo_layer?._id}
              type={data?.type_new}
              filter={["!", ["has", "point_count"]]}
              paint={data?.paint_object}
              layout={{ visibility: visibility }}
              onClick={this.props.layerOnClick.bind(this)}
            />
          </div>
        );
      } else {
        display_render = (
          <div>
            <Source
              key={data?.geo_layer?._id + "_cluster"}
              id={data?.geo_layer?._id + "_cluster"}
              type="geojson"
              data={data?.geo_layer?.geojson_new}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={50}
            />
            <Source
              key={data?.geo_layer?._id}
              id={data?.geo_layer?._id}
              type="geojson"
              data={data?.geo_layer?.geojson_new}
            />
            <Layer
              source={data?.geo_layer?._id}
              id={data?.geo_layer?._id}
              type={data?.type_new}
              paint={data?.paint_object}
              layout={{ visibility: visibility }}
              onClick={this.props.layerOnClick.bind(this)}
            />
            {data?.type_new === "fill" && (
              <Layer
                source={data?.geo_layer?._id}
                id={`${data?.geo_layer?._id}_line`}
                type="line"
                paint={data?.paint_object_line_polygon}
                layout={{ visibility: visibility }}
              />
            )}
          </div>
        );
      }
      return (
        <section key={index}>
          {display_render}
          {label_content}
        </section>
      );
    });
    this.setState({ render_content });
  };
  render() {
    const { render_content } = this.state;
    return render_content;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_BASIC);
