/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ChartTemplate from "../viewer_chart/ChartTemplate";

/*REDUX FUNCTION*/
import {
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
} from "../../App/actions/dataActions";

/*PICTURE ASSET*/
import close from "../../Assets/svg/close.svg";

/*GENERAL*/

class ResultInsight extends Component {
  state = {};

  on_filter_isi_layer = (field_key, value) => {
    const { layer_id } = this.props.layer;

    const body = {
      geo_layer_id: layer_id,
      field_key,
      value,
    };

    this.props.filter_isi_layer(body);
  };

  on_reset_filter = () => {
    const { layer_id } = this.props.layer;
    const body = { geo_layer_id: layer_id };
    this.props.reset_filter(body);
  };

  render() {
    //local storage

    //local state

    //global props
    const { geo_layer_list, layer_id } = this.props.layer;
    const layer_object = geo_layer_list.find(
      (item) => item.geo_layer._id === layer_id
    )?.geo_layer;

    //content
    let content = <>Tidak ada data</>;

    if (layer_object?.insight_object?.is_insight) {
      const insight_object = layer_object.insight_object;

      const {
        kota,
        kecamatan,
        kelurahan,
        // poi_tipe_1_selected,
        // poi_tipe_2_selected,
        km_grid,
        total_grid,
        total_kelurahan,
        total_poi,

        demographic_parent,
        demographic_field_list,
      } = insight_object;

      let place;

      if (kelurahan) {
        place = kelurahan;
      } else if (kecamatan) {
        place = kecamatan;
      } else if (kota) {
        place = kota;
      }

      let content_data = <>Data belum dimuat</>;

      const features = layer_object?.geojson?.features;

      if (features?.length > 0) {
        const { valueStyleProps } = layer_object;
        const range = valueStyleProps?.range || [];
        const color = valueStyleProps?.color || [];
        const selected_column = valueStyleProps?.selected_column;

        let result_number_list = [0, 0, 0, 0, 0];

        features.forEach((feature) => {
          const value = feature?.properties?.HASIL;
          const index = range.findIndex((item) => item === value);
          result_number_list[index] = result_number_list[index] + 1;
        });

        const chart_js_data = {
          labels: range,
          datasets: [
            {
              label: "INSIGHT",
              labels: range,
              data: result_number_list,
              backgroundColor: color,
            },
          ],
        };

        content_data = (
          <main>
            <ChartTemplate
              type="pie"
              width={100}
              height={100}
              style={{ width: "100px", margin: "auto" }}
              options={{
                hoverBackgroundColor: "#f38026",
                animation: false,
                maintainAspectRatio: true,
                responsive: false,
                legend: {
                  display: false,
                },
                indexAxis: "x",
                title: {
                  display: false,
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
              data={chart_js_data}
            />
            <br />
            <section className="container background_white">
              <table className="full_width">
                <tbody>
                  <tr>
                    <td>
                      <p className="text_bold margin_bottom">Legenda teks:</p>
                    </td>
                    <td className="text_right">
                      <img
                        className="cursor_pointer"
                        alt="close"
                        src={close}
                        style={{ height: "20px" }}
                        onClick={this.on_reset_filter}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table>
                <tbody>
                  {range.map((item, idx) => {
                    return (
                      <tr
                        key={idx}
                        onClick={this.on_filter_isi_layer.bind(
                          this,
                          selected_column,
                          item
                        )}
                      >
                        <td>
                          <div
                            style={{
                              width: "15px",
                              height: "15px",
                              backgroundColor: color[idx],
                            }}
                          />
                        </td>
                        <td>{item}</td>
                        <td>{result_number_list[idx]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>
          </main>
        );
      }

      content = (
        <main>
          <section className="margin_bottom">
            <p>
              <i>Insight</i> di{" "}
              <span className="badge background_white">{place}</span> adalah
              sebagai berikut.
            </p>
            <br />
            <p>
              <span className="badge background_white">{total_grid}</span>{" "}
              <i>grid</i> dengan ukuran{" "}
              <span className="badge background_white">{km_grid}</span> km telah
              berhasil di <i>generate</i> secara otomatis dan dilakukan skoring.
            </p>
            <p>
              Skoring dilakukan dengan metode <i>overlay</i> antara grid dengan
              data demografi populasi penduduk pada{" "}
              <span className="badge background_white">{total_kelurahan}</span>{" "}
              wilayah dan{" "}
              <span className="badge background_white">{total_poi}</span> data
              titik <i>point of interest</i> sesuai tema yang kamu pilih.
            </p>
            <table className="table full_width">
              <thead>
                <tr>
                  <th colSpan={2}>Parameter Demografi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tema</td>
                  <td>
                    {demographic_parent?.[0]
                      ? demographic_parent?.[0]
                      : "Belum memilih tema demografi"}
                  </td>
                </tr>
                <tr>
                  <td>Parameter terpilih</td>
                  <td>
                    {demographic_field_list?.map((item, idx) => {
                      return (
                        <p className="text_small margin_bottom" key={idx}>
                          {item}
                        </p>
                      );
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          {content_data}
        </main>
      );
    }

    return (
      <main>
        <div className="badge_pill" id="blue">
          Hasil
        </div>
        <h1 className="text_header">Rangkuman hasil insight</h1>
        <br />
        <section className="container_light">{content}</section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
})(ResultInsight);
