// icon
import offline from "./icon/offline.png";
import iconCerah from "./icon/cerah.png";
import iconLebat from "./icon/lebat.png";
import iconRingan from "./icon/ringan.png";
import iconSangatLebat from "./icon/sangatlebat.png";
import accounting from "./icon/accounting.png";
import airport from "./icon/airport.png";
import amusement_park from "./icon/amusement park.png";
import art_gallery from "./icon/art gallery.png";
import atm from "./icon/atm.png";
import bakery from "./icon/bakery.png";
import bank from "./icon/bank.png";
import bar from "./icon/bar.png";
import beauty_salon from "./icon/beauty salon.png";
import bicycle_store from "./icon/bicycle store.png";
import book_store from "./icon/book store.png";
import bowling from "./icon/bowling.png";
import bus_station from "./icon/bus station.png";
import campground from "./icon/campground.png";
import car_dealer from "./icon/car dealer.png";
import car_rental from "./icon/car rental.png";
import car_repair from "./icon/car repair.png";
import car_wash from "./icon/car wash.png";
import cemetary from "./icon/cemetary.png";
import church from "./icon/church.png";
import city_hall from "./icon/city hall.png";
import cloth_store from "./icon/cloth store.png";
import coffee_and_tea from "./icon/coffee and tea.png";
import convinience_store from "./icon/convinience store.png";
import courthouse from "./icon/courthouse.png";
import dam from "./icon/dam.png";
import dentist from "./icon/dentist.png";
import doctor from "./icon/doctor.png";
import drainase from "./icon/drainase.png";
import electrician from "./icon/electrician.png";
import electronic_store from "./icon/electronic store.png";
import embassy from "./icon/embassy.png";
import finance from "./icon/finance.png";
import fire_station from "./icon/fire station.png";
import florist from "./icon/florist.png";
import food from "./icon/food.png";
import funeral_home from "./icon/food.png";
import furniture_store from "./icon/furniture store.png";
import gas_station from "./icon/gas station.png";
import general_constractor from "./icon/general constractor.png";
import government from "./icon/government.png";
import grocery_or_supermarket from "./icon/grocery or supermarket.png";
import gym from "./icon/gym.png";
import hair_care from "./icon/hair care.png";
import hardware_store from "./icon/hardware store.png";
import health_care from "./icon/health care.png";
import hindu_tample from "./icon/hindu tample.png";
import hospital from "./icon/hospital.png";
import insurance from "./icon/insurance.png";
import irigation from "./icon/irigation.png";
import jewelry_store from "./icon/jewelry stor.png";
import laundry from "./icon/laundry.png";
import lawyer from "./icon/lawyer.png";
import library from "./icon/library.png";
import liquor_store from "./icon/liquor store.png";
import locksmith from "./icon/locksmith.png";
import lodging from "./icon/lodging.png";
import mosque from "./icon/mosque.png";
import movie_rental from "./icon/movie rental.png";
import movie_theater from "./icon/movie theater.png";
import moving_company from "./icon/moving company.png";
import museum from "./icon/museum.png";
import mushola from "./icon/mushola.png";
import natural_feature from "./icon/natural feature.png";
import night_club from "./icon/night club.png";
import painters from "./icon/painters.png";
import park from "./icon/park.png";
import parking from "./icon/parking.png";
import pet_shop from "./icon/pet shop.png";
import pharmacy from "./icon/pharmacy.png";
import physioterapist from "./icon/Physioterapist.png";
import place_of_worhsip from "./icon/place of worhsip.png";
import plumber from "./icon/plumber.png";
import point_of_interest from "./icon/point of interest.png";
import police from "./icon/police.png";
import political from "./icon/political.png";
import post_box from "./icon/post box.png";
import post_service from "./icon/post service.png";
import postal_code_prefix from "./icon/postal code prefix.png";
import real_estate from "./icon/real estate.png";
import restaurant from "./icon/restaurant.png";
import roofing_company from "./icon/roofing company.png";
import route from "./icon/route.png";
import school from "./icon/school.png";
import shopping_mall from "./icon/shopping mall.png";
import spa from "./icon/spa.png";
import stadium from "./icon/stadium.png";
import storage from "./icon/storage.png";
import store from "./icon/store.png";
import subway_station from "./icon/subway station.png";
import synagogue from "./icon/synagogue.png";
import taxi_stand from "./icon/taxi stand.png";
import toilet_female from "./icon/toilet female.png";
import toilet_male from "./icon/toilet male.png";
import toilet_unisex from "./icon/toilet unisex.png";
import train_station from "./icon/train station.png";
import transit_station from "./icon/transit station.png";
import travel_agency from "./icon/travel agency.png";
import university from "./icon/university.png";
import veterinary_pets from "./icon/veterinary pets.png";
import water_dam from "./icon/water dam.png";
import water_pos from "./icon/water pos.png";
import water_sensor from "./icon/water sensor.png";
import zoo from "./icon/zoo.png";
import truck from "./icon/truck.png";

// svg
import icon_reorder from "./svg/icon_reorder.svg";
import icon_reorder_white from "./svg/icon_reorder_white.svg";
import icon_plus from "./svg/icon_plus.svg";
import icon_edit_text from "./svg/icon_edit_text.svg";
import icon_type_text from "./svg/icon_type_text.svg";
import icon_type_long from "./svg/icon_type_long.svg";
import icon_type_number from "./svg/icon_type_number.svg";
import icon_type_range from "./svg/icon_type_range.svg";
import icon_type_phone from "./svg/icon_type_phone.svg";
import icon_type_currency from "./svg/icon_type_currency.svg";
import icon_type_year from "./svg/icon_type_year.svg";
import icon_type_date from "./svg/icon_type_date.svg";
import icon_type_time from "./svg/icon_type_time.svg";
import icon_type_selection from "./svg/icon_type_selection.svg";
import icon_type_radio from "./svg/icon_type_radio.svg";
import icon_type_checklist from "./svg/icon_type_checklist.svg";
import icon_type_image from "./svg/icon_type_image.svg";
import icon_type_document from "./svg/icon_type_document.svg";
import icon_type_link from "./svg/icon_type_link.svg";
import icon_type_address from "./svg/icon_type_address.svg";
import icon_type_address_poi from "./svg/icon_type_address_poi.svg";
import icon_delete_2 from "./svg/icon_delete_2.svg";
import icon_delete_image from "./svg/icon_delete_image.svg";
import icon_search from "../Assets/svg/icon_search.svg";
import checklist from "./svg/checklist.svg";
import icon_404 from "./svg/icon_404.svg";
import official_account from "./svg/official_account.svg";
import landing_background from "./svg/landing_background.svg";

export const icon_map = {
  // icon
  accounting,
  airport,
  amusement_park,
  art_gallery,
  atm,
  bakery,
  bank,
  bar,
  beauty_salon,
  bicycle_store,
  book_store,
  bowling,
  bus_station,
  campground,
  car_dealer,
  car_rental,
  car_repair,
  car_wash,
  cemetary,
  church,
  city_hall,
  cloth_store,
  coffee_and_tea,
  convinience_store,
  courthouse,
  dam,
  dentist,
  doctor,
  drainase,
  electrician,
  electronic_store,
  embassy,
  finance,
  fire_station,
  florist,
  food,
  funeral_home,
  furniture_store,
  gas_station,
  general_constractor,
  government,
  grocery_or_supermarket,
  gym,
  hair_care,
  hardware_store,
  health_care,
  hindu_tample,
  hospital,
  insurance,
  irigation,
  jewelry_store,
  laundry,
  lawyer,
  library,
  liquor_store,
  locksmith,
  lodging,
  mosque,
  movie_rental,
  movie_theater,
  moving_company,
  museum,
  mushola,
  natural_feature,
  night_club,
  painters,
  park,
  parking,
  pet_shop,
  pharmacy,
  physioterapist,
  place_of_worhsip,
  plumber,
  point_of_interest,
  police,
  political,
  post_box,
  post_service,
  postal_code_prefix,
  real_estate,
  restaurant,
  roofing_company,
  route,
  school,
  shopping_mall,
  spa,
  stadium,
  storage,
  store,
  subway_station,
  synagogue,
  taxi_stand,
  toilet_female,
  toilet_male,
  toilet_unisex,
  train_station,
  transit_station,
  travel_agency,
  university,
  veterinary_pets,
  water_dam,
  water_pos,
  water_sensor,
  zoo,
  truck,
};

export const icon = {
  // icon
  offline,
  iconCerah,
  iconLebat,
  iconRingan,
  iconSangatLebat,
  accounting,
  airport,
  amusement_park,
  art_gallery,
  atm,
  bakery,
  bank,
  bar,
  beauty_salon,
  bicycle_store,
  book_store,
  bowling,
  bus_station,
  campground,
  car_dealer,
  car_rental,
  car_repair,
  car_wash,
  cemetary,
  church,
  city_hall,
  cloth_store,
  coffee_and_tea,
  convinience_store,
  courthouse,
  dam,
  dentist,
  doctor,
  drainase,
  electrician,
  electronic_store,
  embassy,
  finance,
  fire_station,
  florist,
  food,
  funeral_home,
  furniture_store,
  gas_station,
  general_constractor,
  government,
  grocery_or_supermarket,
  gym,
  hair_care,
  hardware_store,
  health_care,
  hindu_tample,
  hospital,
  insurance,
  irigation,
  jewelry_store,
  laundry,
  lawyer,
  library,
  liquor_store,
  locksmith,
  lodging,
  mosque,
  movie_rental,
  movie_theater,
  moving_company,
  museum,
  mushola,
  natural_feature,
  night_club,
  painters,
  park,
  parking,
  pet_shop,
  pharmacy,
  physioterapist,
  place_of_worhsip,
  plumber,
  point_of_interest,
  police,
  political,
  post_box,
  post_service,
  postal_code_prefix,
  real_estate,
  restaurant,
  roofing_company,
  route,
  school,
  shopping_mall,
  spa,
  stadium,
  storage,
  store,
  subway_station,
  synagogue,
  taxi_stand,
  toilet_female,
  toilet_male,
  toilet_unisex,
  train_station,
  transit_station,
  travel_agency,
  university,
  veterinary_pets,
  water_dam,
  water_pos,
  water_sensor,
  zoo,
  truck,
  // svg
  icon_reorder,
  icon_reorder_white,
  icon_plus,
  icon_edit_text,
  icon_type_text,
  icon_type_long,
  icon_type_number,
  icon_type_range,
  icon_type_phone,
  icon_type_currency,
  icon_type_year,
  icon_type_date,
  icon_type_time,
  icon_type_selection,
  icon_type_radio,
  icon_type_checklist,
  icon_type_image,
  icon_type_document,
  icon_type_link,
  icon_type_address,
  icon_type_address_poi,
  icon_delete_2,
  icon_delete_image,
  icon_search,
  checklist,
  icon_404,
  official_account,
  landing_background,
};
