/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class LAYER_SELECTED extends Component {
  state = {
    render_content: null,
  };

  componentDidMount() {
    this.on_render_content();
  }

  componentDidUpdate(prevProps) {
    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;
    const fly_action_after = this.props.map.fly_action;
    const fly_action_before = prevProps.map.fly_action;
    const feature_key_selected_after =
      this.props.properties.feature_key_selected;
    const feature_key_selected_before =
      prevProps.properties.feature_key_selected;
    if (
      status_action_after !== status_action_before ||
      fly_action_after !== fly_action_before ||
      feature_key_selected_after !== feature_key_selected_before
    ) {
      this.on_render_content();
    }
  }

  on_render_content = () => {
    const { feature_key_selected, feature_object_selected } =
      this.props.properties;
    let render_content;
    const geometry = feature_object_selected?.geometry;
    if (
      geometry?.type === "Polygon" ||
      geometry?.type === "LineString" ||
      geometry?.type === "MultiPolygon" ||
      geometry?.type === "MultiLineString"
    ) {
      let geojson;
      if (feature_object_selected?.geometry) {
        geojson = {
          type: "FeatureCollection",
          features: [feature_object_selected],
        };
      } else {
        geojson = {
          type: "FeatureCollection",
          features: [],
        };
      }
      render_content = (
        <>
          <Source
            key="LAYER_SELECTED"
            id="LAYER_SELECTED"
            type="geojson"
            data={geojson}
          />
          <Layer
            id="LAYER_SELECTED"
            source="LAYER_SELECTED"
            type="line"
            paint={{
              "line-color": "#ff0000",
              "line-width": 2,
              "line-opacity": 1,
              "line-gap-width": 1,
            }}
            layout={{
              visibility: feature_key_selected ? "visible" : "none",
            }}
          />
        </>
      );
    }
    this.setState({ render_content });
  };

  render() {
    //local storage

    //local state

    //global props

    //content
    return <div>{this.state.render_content}</div>;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_SELECTED);
