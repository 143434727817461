/*LIBRARY MODULE*/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { Resizable } from "re-resizable";

/*PERSONAL COMPONENT*/
import LayerList from "../viewer_bottom_bar/LayerList";
import EditorProperties from "../viewer_table/EditorProperties";
import TableFix from "../viewer_table/TableFix";
import Map from "../layer/Map";
import RIGHT_SIDEBAR from "../map_reusable/RIGHT_SIDEBAR";
import BASEMAP_MODE from "../layer/BASEMAP_MODE";
import SEARCH_BAR_MAP from "../search_map/SEARCH_BAR_MAP";
import LegendButton from "../map_legend/LegendButton";

/*REDUX FUNCTION*/
import {
  setTabelStatus,
  setFilteredFeatures,
  clearGeolayerList,
  clearLayer,
  filterGeoLayerList,
  resetFilter,
} from "../../App/actions/layerActions";
import { view_layer } from "../../App/actions/dataActions";
import {
  getDetailLayerById,
  viewData,
  setMapMode,
  setStatisticMode,
} from "../../App/actions/layerNewActions";
import { setLanguage } from "../../App/actions/authActions";
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/
import icon_tool_new from "../../Assets/svg/icon_tool_new.svg";

/*GENERAL*/
import isEmpty from "../../App/validation/is-empty";
import domain_list from "../../Data/domain_list";
import dict from "../../Data/dict.json";
import decode_params_to_object from "../../App/validation/decode_params_to_object";
import { domain_logo_mapid_triangle } from "../../App/validation/features_domain";

/*NON IMPORT*/
const batasPixel = 1172;
let valueLanguage = localStorage.getItem("language");
let geocoderContainerRef = React.createRef();

class MAP_VIEWER extends Component {
  state = {
    width: window.innerWidth,
    modal_migration: false,
    selectedMap: "street",
    modalStatistic: false,
    modal_payment: false,
    language: false,
    tableComponent: {
      height: 0,
    },
  };

  handleTableComponent = () => {
    if (document.getElementById("tablebar")) {
      const height = document.getElementById("tablebar").offsetHeight;
      this.setState({
        tableComponent: { height: height },
      });
      localStorage.setItem("table_height", height);
    }
  };

  on_start = () => {
    const { geo_project } = this.props.project;
    const { geo_project_link } = this.props;
    const { geo_layer_list } = this.props.layer;
    const geo_layer_link = this?.props?.geo_layer_link;
    const mode = this?.props?.mode;

    //get layer dengan flow terbaru
    if (geo_project?._id && geo_layer_list?.length > 0) {
      const params = window?.location?.search?.substring(1);
      let object_params = {};
      let layer_id;
      if (params) {
        object_params = decode_params_to_object(params);
        layer_id = object_params?.layer_id;
      }

      if (layer_id) {
        const body = {
          geo_layer_id: layer_id,
          geo_project_id: geo_project._id,
          flyStatus: true,
        };
        this.props.getDetailLayerById(body);
      }
    }

    localStorage.setItem("leftbar_width", 0);
    this.timer = null;
    this.closeTable();

    if (geo_project_link !== "data") {
      let layer = geo_layer_list.filter(
        (e) => e.geo_layer.link === geo_layer_link
      );
      if (mode === "view" || layer.length > 0) {
        layer = layer[0].geo_layer;
        if (layer?._id) {
          const body = {
            geo_layer_id: layer._id,
            geo_project_id: geo_project._id,
            flyStatus: true,
          };
          this.props.getDetailLayerById(body);
        }
      }
    }

    if (
      geo_project?.layer_list_load_first?.length > 0 &&
      geo_project?.layer_list_load_first?.[0]
    ) {
      if (geo_project?.layer_list_load_first?.[0]) {
        const body = {
          geo_layer_id: geo_project?.layer_list_load_first?.[0],
          geo_project_id: geo_project._id,
          tableStatus: false,
        };
        this.props.getDetailLayerById(body);
      }
    }

    // handle language
    if (valueLanguage === "eng") {
      this.setState({
        language: false,
      });
    } else if (valueLanguage === "ina") {
      this.setState({
        language: true,
      });
    }
  };

  componentDidMount() {
    this.on_start();
    this.toggle_sidebar_right();
  }

  //open close table
  componentDidUpdate(prevProps, prevState) {
    const { geo_project } = this.props.project;
    const { geo_layer_list } = this.props.layer;
    const geo_layer_list_prev = prevProps.layer.geo_layer_list;

    if (prevState.tableComponent.height !== this.state.tableComponent.height) {
      document.getElementById(
        "statistic_resume_box"
      ).style.height = `calc((100vh - 200px - ${this.state.tableComponent.height}px))`;
    }

    if (this.props.layer.tableStatus !== prevProps.layer.tableStatus) {
      if (this.props.layer.tableStatus) {
        this.openTable();
      } else {
        this.closeTable();
      }
    }

    if (
      geo_layer_list.length !== geo_layer_list_prev.length &&
      geo_layer_list_prev.length === 0
    ) {
      const geo_layer_link = this?.props?.geo_layer_link;
      const mode = this?.props?.mode;
      let layer = geo_layer_list.filter(
        (e) => e.geo_layer.link === geo_layer_link
      );
      if (mode === "view" && layer.length > 0) {
        layer = layer[0].geo_layer;
        const { geo_project } = this.props.project;
        const body = {
          geo_layer_id: layer._id,
          geo_project_id: geo_project._id,
          flyStatus: true,
        };
        this.props.getDetailLayerById(body);
      }

      if (
        geo_project?.layer_list_load_first?.length > 0 &&
        geo_project?.layer_list_load_first?.[0]
      ) {
        const body = {
          geo_layer_id: geo_project?.layer_list_load_first?.[0],
          geo_project_id: geo_project._id,
          tableStatus: false,
        };
        this.props.getDetailLayerById(body);
      }
    }

    if (this.props.layer.tableStatus) {
      if (document.getElementById("tablebar")) {
        const height = document.getElementById("tablebar").offsetHeight;
        localStorage.setItem("table_height", height);
      }
    } else {
      localStorage.setItem("table_height", 0);
    }
  }

  componentWillUnmount() {
    // this.props.clearGeolayerList();
    window.removeEventListener("resize", this.handleWindowSizeChange);
    clearTimeout(this.timer);
    this.props.setTabelStatus(false);
    this.props.clearLayer();
  }

  toggle_payment = () => {
    this.setState({ modal_payment: !this.state.modal_payment });
  };

  toggleData = () => {
    const { data_view_status } = this.props.layer;
    this.props.viewData(!data_view_status);
  };

  //close table
  closeTable = () => {
    document.getElementById("tableBar").style.bottom = "-500px";
    document.getElementById("bottomBar").style.bottom = "30px";
    document.getElementById("statistic_resume_box").style.height = "100vh";
    // document.getElementById("statistic_resume_box").style.maxHeight = "100vh";
  };

  //open table
  openTable = () => {
    document.getElementById("tableBar").style.bottom = "0px";
    document.getElementById("bottomBar").style.bottom = "340px";
    this.handleTableComponent();
    // document.getElementById("statistic_resume_box").style.height = ("calc((100vh - 340px))");
    // document.getElementById("statistic_resume_box").style.maxHeight =
    //   "calc((100vh - 60px - 340px))";
  };

  setStatisticModeFunc = (mode) => {
    this.props.setStatisticMode(mode);
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: true,
    });
  };

  //SideBar action
  toggle_sidebar_right = () => {
    const { sidebar_right_status } = this.props.properties;
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: !sidebar_right_status,
    });
  };

  openStat = () => {
    this.setState({ openStatus: true }, () => {
      document.getElementById("statistic_resume_box").style.right = "0";
      localStorage.setItem("rightbar_width", 0);
    });
  };

  closeStat = () => {
    this.setState({ openStatus: false }, () => {
      document.getElementById(
        "statistic_resume_box"
      ).style.right = `-${window.innerWidth}px`;
      const width = document.getElementById("statistic_resume_box").offsetWidth;
      localStorage.setItem("rightbar_width", width);
    });
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth }, () => {
      if (this.state.width < batasPixel) {
        this.closeNav();
      } else {
        this.openNav();
      }
    });
  };

  clearFeatures = () => {
    const { geo_project_link } = this.props.match.params;
    const { link } = this.props.layer?.geo_layer;

    try {
      this.props.resetFilter({ geo_project_link, link });
    } catch (e) {}
  };

  setSelectedMap = (val) => {
    this.props.setMapMode(val);
  };

  toggleModalStatisticMode = () => {
    const { modalStatistic } = this.state;
    this.setState({
      modalStatistic: !modalStatistic,
    });
  };

  onSetLanguageIna = () => {
    this.setState({
      language: true,
    });
    this.props.setLanguage("ina");
  };

  onSetLanguageEng = () => {
    this.setState({
      language: false,
    });
    this.props.setLanguage("eng");
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { width } = this.state;

    //global props
    const { geo_project, loadingDetail } = this.props.project;
    const { loadingList, table_full, tableStatus } = this.props.layer;
    const { domain } = this.props.auth;
    const geo_project_link = this.props.geo_project_link;
    const { sidebar_right_status } = this.props.properties;

    //content
    const is_mobile = width <= 650;
    const itemContent =
      loadingList ||
      loadingDetail ||
      (isEmpty(geo_project) && geo_project_link !== "data") ? (
        <div className="button_standard">
          {dict["Loading GIS data"][language]} ...
        </div>
      ) : (
        <>
          {!is_mobile && !tableStatus && (
            <div>
              <LayerList />
            </div>
          )}
        </>
      );

    const menuSidebar = (
      <div className="menu_sidebar_view">
        {!is_mobile ? (
          <section
            title={`${!sidebar_right_status ? "Open" : "Close"} Sidebar`}
            onClick={this.toggle_sidebar_right}
          >
            <img src={icon_tool_new} alt={"Sidebar"} />
          </section>
        ) : null}
        <LegendButton />
        <BASEMAP_MODE />
        {this.state.language ? (
          <section onClick={this.onSetLanguageEng}>
            <img
              alt="Indonesia"
              src="https://mapid.co.id/img/icon/indo-flag.png"
            />
          </section>
        ) : (
          <section onClick={this.onSetLanguageIna}>
            <img
              alt="English"
              style={{
                width: "25px",
                height: "25px",
              }}
              src="https://mapid.co.id/img/icon/uk-flag.png"
            />
          </section>
        )}
      </div>
    );

    let box_mobile_height = null;
    if (is_mobile) {
      box_mobile_height = window.innerHeight;
    }

    return (
      <main>
        <SEARCH_BAR_MAP />
        {table_full ? (
          <TableFix />
        ) : (
          <main>
            <div id="bottomBar" className="bottomBar">
              {itemContent}
            </div>
            {menuSidebar}
            {is_mobile ? (
              <motion.section
                className="box_mobile"
                id="box_mobile"
                drag="y"
                dragConstraints={{
                  left: 0,
                  right: 0,
                  top: -(box_mobile_height - 250),
                  bottom: 100,
                }}
                dragElastic={0.3}
                dragMomentum={false}
              >
                <div className="button_mobile">
                  <div className="button_show_mobile" />
                </div>
                <div className="mobile_scroll">
                  {tableStatus ? (
                    <div
                      style={{
                        overflowX: "auto",
                        height: "100%",
                      }}
                    >
                      <button
                        className="button"
                        id="delete"
                        onClick={() => this.props.setTabelStatus(false)}
                      >
                        Close Table
                      </button>
                      <EditorProperties />
                    </div>
                  ) : (
                    <RIGHT_SIDEBAR
                      loadingList={loadingList}
                      loadingDetail={loadingDetail}
                      geo_project={geo_project}
                      geo_project_link={geo_project_link}
                      language={language}
                      geocoderContainerRef={geocoderContainerRef}
                      tableComponent={this.state.tableComponent}
                      extraHeight={500}
                    />
                  )}
                </div>
              </motion.section>
            ) : (
              sidebar_right_status && (
                <RIGHT_SIDEBAR
                  loadingList={loadingList}
                  loadingDetail={loadingDetail}
                  geo_project={geo_project}
                  geo_project_link={geo_project_link}
                  language={language}
                  geocoderContainerRef={geocoderContainerRef}
                  tableComponent={this.state.tableComponent}
                />
              )
            )}

            {tableStatus && !is_mobile && (
              <>
                <Resizable
                  id="tablebar"
                  onResizeStop={(e, direction, ref, d) => {
                    this.handleTableComponent(d);
                  }}
                  defaultSize={{
                    width: "100%",
                    height: 220,
                  }}
                  minWidth="100%"
                  maxWidth="100%"
                  minHeight="52px"
                  maxHeight="100vh"
                  // top={true}
                  style={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                    maxHeight: window.innerHeight - 5,
                    zIndex: 2,
                    // width: "calc((100vw - 335px))",
                    backgroundColor: "#efefef",
                    color: "#1e5e96ff",
                    opacity: 1,
                    boxShadow: "0 0 30px #67676764",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: -120,
                      width: "100%",
                    }}
                  >
                    <LayerList />
                  </div>

                  <div
                    style={{
                      overflowX: "auto",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <EditorProperties />
                  </div>
                </Resizable>
              </>
            )}
          </main>
        )}

        <Map geocoderContainerRef={geocoderContainerRef} />
        <Link to="/dashboard" className="brand_map noselect">
          {is_mobile && domain_logo_mapid_triangle.includes(domain) ? (
            <img
              src={domain_list?.[domain]?.mobile_logo}
              height="35px"
              alt={domain_list?.[domain]?.short_title}
            />
          ) : (
            <img
              src={domain_list?.[domain]?.logo}
              height="30px"
              alt={domain_list?.[domain]?.short_title}
            />
          )}
        </Link>

        <div id="bottomBar"></div>
        <div id="toggle_statistik"></div>
        <div id="statistic_resume_box"> </div>
        <div id="tableBar"> </div>
        <style>{`
      body{
        padding-top: 0px;
        overflow-y: hidden;
      }
      #navbarDefault{ visibility: hidden;}
      .top_nav{ visibility: hidden;}
      .sidebar_home{ visibility: hidden;}
      .nav_bottom{ visibility: hidden;}
   `}</style>
        <Helmet>
          <title>
            {geo_project._id ? `${geo_project.name} | VIEWER` : "VIEWER"}
          </title>
          <meta
            name="description"
            content={
              geo_project._id ? `${geo_project.name} | VIEWER` : "VIEWER"
            }
          />
        </Helmet>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  setTabelStatus,
  setFilteredFeatures,
  clearGeolayerList,
  clearLayer,
  filterGeoLayerList,
  resetFilter,
  getDetailLayerById,
  viewData,
  setMapMode,
  setStatisticMode,
  view_layer,
  setLanguage,
  set_value_properties,
})(MAP_VIEWER);
