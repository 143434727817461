import React, { useEffect, useState } from "react";
import useLanguage from "../common_hooks/useLanguage";
import { useDispatch, useSelector } from "react-redux";
import { pin_project, unpin_project } from "../../App/actions/projectActions";

function PINNED_BUTTON(props = {}) {
  const dispatch = useDispatch();
  const { dict, language } = useLanguage();
  const [is_project_inside_pinned, set_is_project_inside_pinned] =
    useState(false);
  const { project } = useSelector((state) => {
    return {
      project: state.project,
    };
  });

  /** Perintah pertama yang dijalankan */
  useEffect(() => {
    const { geo_project_pinned_list } = project;
    const is_inside_pinned = geo_project_pinned_list?.some(
      (pinned_project) => pinned_project?._id === project?.geo_project?._id
    );
    set_is_project_inside_pinned(is_inside_pinned);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /** Aksi pin, sudah termasuk hit api pin maupun unpin */
  const handle_pin = async (e) => {
    const { toggle_menu } = props;
    if (toggle_menu) toggle_menu(e);
    const geo_project_id = project?.geo_project?._id;
    const body = { geo_project_id };
    if (is_project_inside_pinned) {
      dispatch(unpin_project(body, project?.geo_project));
    } else {
      dispatch(pin_project(body, project?.geo_project));
    }
  };

  const button_text = is_project_inside_pinned ? "Unpin" : "Pin";

  return (
    <>
      <button
        className="button margin_bottom button_white"
        onClick={handle_pin}
      >
        {dict[button_text][language]}
      </button>
    </>
  );
}

export default PINNED_BUTTON;
