import st_colors from "../../../Data/st_colors.json";

export function generate_colors(steps, color_mode) {
  switch (color_mode) {
    case "random":
      return generate_random_colors(steps);
    case "mapid_color":
      return generate_mapid_colors(steps); // Updated to generate_mapid_colors
    case "red_to_green":
      return generate_red_to_green_colors(steps);
    case "green_to_red":
      return generate_green_to_red_colors(steps);
    case "blue_to_yellow":
      return generate_blue_to_yellow_colors(steps);
    case "purple_to_orange":
      return generate_purple_to_orange_colors(steps);
    case "grayscale":
      return generate_grayscale_colors(steps);
    case "red_to_blue":
      return generate_red_to_blue_colors(steps);
    case "black_to_red":
      return generate_red_dark_to_red_bright_colors(steps);
    case "red_to_black":
      return generate_red_bright_to_red_dark_colors(steps);
    case "dark_red_to_light_red":
      return generate_dark_red_to_light_red_colors(steps);
    case "light_red_to_dark_red":
      return generate_light_red_to_dark_red_colors(steps);
    case "rainbow":
      return generate_rainbow_colors(steps);
    case "blues":
      return generate_blues_colors(steps);
    case "greens":
      return generate_greens_colors(steps);
    case "reds":
      return generate_reds_colors(steps);
    case "oranges":
      return generate_oranges_colors(steps);
    case "rd_bu":
      return generate_rd_bu_colors(steps);
    case "pu_or":
      return generate_pu_or_colors(steps);
    case "spectral":
      return generate_spectral_colors(steps);
    case "viridis":
      return generate_viridis_colors(steps);
    case "plasma":
      return generate_plasma_colors(steps);
    case "yl_gn_bu":
      return generate_yl_gn_bu_colors(steps);

    default:
      console.error("Invalid color mode. Use a valid color mode.");
      return [];
  }
}

function generate_mapid_colors(steps) {
  const colors = [];
  const colorSet = [
    { r: 31, g: 159, b: 238 }, // rgb(31, 159, 238)
    { r: 155, g: 201, b: 94 }, // rgb(155, 201, 94)
    { r: 26, g: 97, b: 152 }, // rgb(26, 97, 152)
  ];

  const totalTransitions = colorSet.length - 1;
  const stepsPerTransition = Math.floor(steps / totalTransitions);
  const remainingSteps = steps % totalTransitions;

  for (let i = 0; i < totalTransitions; i++) {
    const startColor = colorSet[i];
    const endColor = colorSet[i + 1];
    const transitionSteps = stepsPerTransition + (i < remainingSteps ? 1 : 0);

    for (let j = 0; j < transitionSteps; j++) {
      const t = j / (transitionSteps - 1);
      const r = Math.floor(startColor.r + t * (endColor.r - startColor.r));
      const g = Math.floor(startColor.g + t * (endColor.g - startColor.g));
      const b = Math.floor(startColor.b + t * (endColor.b - startColor.b));
      colors.push(`rgb(${r},${g},${b})`);
    }
  }

  return colors;
}

// New Dark Red to Light Red Color Generator
function generate_dark_red_to_light_red_colors(steps) {
  const colors = [];
  const startColor = { r: 166, g: 0, b: 0 }; // Dark Red
  const endColor = { r: 255, g: 187, b: 186 }; // Light Red

  for (let i = 0; i < steps; i++) {
    const r = Math.floor(
      startColor.r + (endColor.r - startColor.r) * (i / (steps - 1))
    );
    const g = Math.floor(
      startColor.g + (endColor.g - startColor.g) * (i / (steps - 1))
    );
    const b = Math.floor(
      startColor.b + (endColor.b - startColor.b) * (i / (steps - 1))
    );
    const color = `rgb(${r},${g},${b})`;
    colors.push(color);
  }
  return colors;
}

function generate_light_red_to_dark_red_colors(steps) {
  const colors = [];
  const startColor = { r: 255, g: 187, b: 186 };
  const endColor = { r: 166, g: 0, b: 0 };

  for (let i = 0; i < steps; i++) {
    const r = Math.floor(
      startColor.r + (endColor.r - startColor.r) * (i / (steps - 1))
    );
    const g = Math.floor(
      startColor.g + (endColor.g - startColor.g) * (i / (steps - 1))
    );
    const b = Math.floor(
      startColor.b + (endColor.b - startColor.b) * (i / (steps - 1))
    );
    const color = `rgb(${r},${g},${b})`;
    colors.push(color);
  }
  return colors;
}

// Random Color Generator using st_colors
function generate_random_colors(steps) {
  const colors = [];
  for (let i = 0; i < steps; i++) {
    const color_index = i % st_colors.length;
    colors.push(st_colors[color_index]);
  }
  return colors;
}

// Red to Green Color Generator
function generate_red_to_green_colors(steps) {
  const colors = [];
  for (let i = 0; i < steps; i++) {
    const red = Math.floor(255 - (255 * i) / (steps - 1));
    const green = Math.floor((255 * i) / (steps - 1));
    const color = `rgb(${red},${green},0)`;
    colors.push(color);
  }
  return colors;
}

// Green to Red Color Generator
function generate_green_to_red_colors(steps) {
  const colors = [];
  for (let i = 0; i < steps; i++) {
    const red = Math.floor((255 * i) / (steps - 1));
    const green = Math.floor(255 - (255 * i) / (steps - 1));
    const color = `rgb(${red},${green},0)`;
    colors.push(color);
  }
  return colors;
}

// Blue to Yellow Color Generator
function generate_blue_to_yellow_colors(steps) {
  const colors = [];
  for (let i = 0; i < steps; i++) {
    const blue = Math.floor(255 - (255 * i) / (steps - 1));
    const red_green = Math.floor((255 * i) / (steps - 1));
    const color = `rgb(${red_green},${red_green},${blue})`;
    colors.push(color);
  }
  return colors;
}

// Purple to Orange Color Generator
function generate_purple_to_orange_colors(steps) {
  const colors = [];
  for (let i = 0; i < steps; i++) {
    const red = Math.floor(128 + (127 * i) / (steps - 1));
    const green = Math.floor(0 + (165 * i) / (steps - 1));
    const blue = Math.floor(128 - (128 * i) / (steps - 1));
    const color = `rgb(${red},${green},${blue})`;
    colors.push(color);
  }
  return colors;
}

// Grayscale Color Generator
function generate_grayscale_colors(steps) {
  const colors = [];
  for (let i = 0; i < steps; i++) {
    const gray = Math.floor((255 * i) / (steps - 1));
    const color = `rgb(${gray},${gray},${gray})`;
    colors.push(color);
  }
  return colors;
}

// Red to Blue Color Generator
function generate_red_to_blue_colors(steps) {
  const colors = [];
  for (let i = 0; i < steps; i++) {
    const red = Math.floor(255 - (255 * i) / (steps - 1));
    const blue = Math.floor((255 * i) / (steps - 1));
    const color = `rgb(${red},0,${blue})`;
    colors.push(color);
  }
  return colors;
}

// Red Dark to Red Bright Color Generator
function generate_red_dark_to_red_bright_colors(steps) {
  const colors = [];
  for (let i = 0; i < steps; i++) {
    const red = Math.floor((255 * i) / (steps - 1));
    const color = `rgb(${red},0,0)`;
    colors.push(color);
  }
  return colors;
}

// Red Bright to Red Dark Color Generator
function generate_red_bright_to_red_dark_colors(steps) {
  const colors = [];
  for (let i = 0; i < steps; i++) {
    const red = Math.floor(255 - (255 * i) / (steps - 1));
    const color = `rgb(${red},0,0)`;
    colors.push(color);
  }
  return colors;
}

// Rainbow Color Generator
function generate_rainbow_colors(steps) {
  const colors = [];
  const frequency = (2 * Math.PI) / steps;
  for (let i = 0; i < steps; i++) {
    const red = Math.sin(frequency * i + 0) * 127 + 128;
    const green = Math.sin(frequency * i + 2) * 127 + 128;
    const blue = Math.sin(frequency * i + 4) * 127 + 128;
    const color = `rgb(${Math.floor(red)},${Math.floor(green)},${Math.floor(
      blue
    )})`;
    colors.push(color);
  }
  return colors;
}

// Blues Color Generator
function generate_blues_colors(steps) {
  const startColor = { r: 240, g: 248, b: 255 };
  const endColor = { r: 0, g: 0, b: 255 };
  return generate_gradient_colors(steps, startColor, endColor);
}

// Greens Color Generator
function generate_greens_colors(steps) {
  const startColor = { r: 240, g: 255, b: 240 };
  const endColor = { r: 0, g: 128, b: 0 };
  return generate_gradient_colors(steps, startColor, endColor);
}

// Reds Color Generator
function generate_reds_colors(steps) {
  const startColor = { r: 255, g: 240, b: 240 };
  const endColor = { r: 255, g: 0, b: 0 };
  return generate_gradient_colors(steps, startColor, endColor);
}

// Oranges Color Generator
function generate_oranges_colors(steps) {
  const startColor = { r: 255, g: 239, b: 213 };
  const endColor = { r: 255, g: 69, b: 0 };
  return generate_gradient_colors(steps, startColor, endColor);
}

// RdBu (Red to Blue) Color Generator
function generate_rd_bu_colors(steps) {
  const startColor = { r: 165, g: 0, b: 38 };
  const endColor = { r: 0, g: 0, b: 255 };
  return generate_gradient_colors(steps, startColor, endColor);
}

// PuOr (Purple to Orange) Color Generator
function generate_pu_or_colors(steps) {
  const startColor = { r: 230, g: 97, b: 1 };
  const endColor = { r: 94, g: 60, b: 153 };
  return generate_gradient_colors(steps, startColor, endColor);
}

// Spectral Color Generator
function generate_spectral_colors(steps) {
  const startColor = { r: 158, g: 1, b: 66 };
  const endColor = { r: 94, g: 79, b: 162 };
  return generate_gradient_colors(steps, startColor, endColor);
}

// Viridis Color Generator
function generate_viridis_colors(steps) {
  const startColor = { r: 68, g: 1, b: 84 };
  const endColor = { r: 253, g: 231, b: 37 };
  return generate_gradient_colors(steps, startColor, endColor);
}

// Plasma Color Generator
function generate_plasma_colors(steps) {
  const startColor = { r: 12, g: 7, b: 134 };
  const endColor = { r: 239, g: 248, b: 33 };
  return generate_gradient_colors(steps, startColor, endColor);
}

// YlGnBu (Yellow to Green to Blue) Color Generator
function generate_yl_gn_bu_colors(steps) {
  const startColor = { r: 255, g: 255, b: 229 };
  const endColor = { r: 8, g: 29, b: 88 };
  return generate_gradient_colors(steps, startColor, endColor);
}

// Helper Function to Generate Gradient Colors
function generate_gradient_colors(steps, startColor, endColor) {
  const colors = [];
  for (let i = 0; i < steps; i++) {
    const r = Math.floor(
      startColor.r + (endColor.r - startColor.r) * (i / (steps - 1))
    );
    const g = Math.floor(
      startColor.g + (endColor.g - startColor.g) * (i / (steps - 1))
    );
    const b = Math.floor(
      startColor.b + (endColor.b - startColor.b) * (i / (steps - 1))
    );
    const color = `rgb(${r},${g},${b})`;
    colors.push(color);
  }
  return colors;
}
