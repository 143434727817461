import React, { Component } from 'react'
import Tabs from '../reuseable/Tabs'
import dict from "../../Data/dict.json";
import PaymentTable from './PaymentTable';
import QoutaPriceList from './QoutaPriceList';
import RedeemButton from './RedeemButton';

const menu_tabs = [
  "license",
  "access",
  "redeem code"
]

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actived_tab: "license",
      modal_buy_now: false,
      license_selected: "Personal",
    }
  }

  set_license_selected = (value) => {
    this.setState({
      license_selected: value
    })
  }

  toggleModalBuyNow = () => {
    this.setState({ modal_buy_now: !this.state.modal_buy_now })
  };

  handle_tab = (value) => {
    this.setState({
      actived_tab: value,
      modal_buy_now: false
    })
  }

  render() {
    // local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    // state
    const { actived_tab, modal_buy_now, license_selected } = this.state;

    let content, title, description;
    if (actived_tab === "license") {

      if (!modal_buy_now) {
        title = dict["Best license price for you"][language]
        description = dict["Get features at the best price on the market! Limited timeoffer to boost your productivity."][language]
      } else {
        const text = dict["Bundle Package"][language]
        if (language === "ina") {
          title = `${text} ${license_selected}`
        } else {
          title = `${license_selected} ${text}`
        }
        description = dict["Exclude VAT"][language]
      }


      content = <PaymentTable set_license_selected={this.set_license_selected} modal_buy_now={modal_buy_now} toggleModalBuyNow={this.toggleModalBuyNow} />
    } else if (actived_tab === "access") {
      title = dict["Best access quota price for you"][language]
      description = dict["Get features at the best price on the market! Limited timeoffer to boost your productivity."][language]
      content = <QoutaPriceList />
    } else if (actived_tab === "redeem code") {
      title = "Redeem MAPID Code"
      description = dict["Enter your code to get discount."][language]
      content = <RedeemButton />
    }

    return (
      <main className='flex flex_col gap_30'>
        <header>
          <label className='font_30 bold'>{title}</label>
          <p>{description}</p>
        </header>
        <section className='center_perfect gap_20 h_full'>
          {modal_buy_now && <div
            onClick={() => this.toggleModalBuyNow()}
            className="pointer hover_bigger bg_darkBlue w_100 h_40 center_perfect text_white rounded_50"
          >
            {dict["Back"][language]}
          </div>}
          {!modal_buy_now && <Tabs actived_tab={actived_tab} menu_tabs={menu_tabs} handle_tab={this.handle_tab} />}
        </section>
        <footer style={{ minHeight: "300px" }}>
          {content}
        </footer>
      </main>
    )
  }
}
