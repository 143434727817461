/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import CheckBox from "../common_input/CheckBox";
import SelectDataPembanding from "../editor_search_import/SelectDataPembanding";
import DataPembanding from "./DeleteDataPembanding";
import DeleteDataFormOverlay from "./DeleteDataFormOverlay";
import SelectDataForm from "../editor_search_import/SelectDataForm";
import SpatialJoin from "../editor_map/SpatialJoin";
import EditRadiusPembanding from "./EditRadiusPembanding";
import MANAGE_CHART from "./MANAGE_CHART";

/*REDUX FUNCTION*/
import {
  setField,
  doSentimentAnalysis,
  setLayerWOgeojson,
} from "../../App/actions/layerActions";
import {
  editLayerGeneral,
  editLayerGeneralAttached,
  getDetailLayerById,
} from "../../App/actions/layerNewActions";
import { openModal, initApi } from "../../App/actions/authActions";
import { set_value_layer } from "../../App/actions/dataActions";
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import uuid from "../../App/validation/uuid";
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

/*NON IMPORT*/

class EditLayer extends Component {
  state = {
    geo_layer: {},
    modal_clone_field: false,
    modal_select_comparison_data: false,
    modal_select_data_form: false,
    modal_spatial_join: false,
    modal_edit_radius_pembanding: false,
    modal_manage_chart: false,
  };

  componentDidMount() {
    const { layer_id, geo_layer_list } = this.props.layer;
    const geo_layer = geo_layer_list.find(
      (item) => item.geo_layer._id === layer_id
    );
    this.setState({ geo_layer });
  }

  on_open_modal_edit_label_config = () => {
    this.props.set_value_properties({
      key: "modal_edit_label_config",
      value: true,
    });
    this.props.toggle_edit_general();
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: false,
    });
  };

  on_open_modal_edit_layer_style = () => {
    this.props.set_value_properties({
      key: "modal_edit_layer_style",
      value: true,
    });
    this.props.toggle_edit_general();
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: false,
    });
  };

  toggle_edit_chart = () => {
    this.setState({ modal_manage_chart: !this.state.modal_manage_chart });
  };

  toggle_edit_radius_pembanding = () => {
    this.setState({
      modal_edit_radius_pembanding: !this.state.modal_edit_radius_pembanding,
    });
  };

  toggle_spatial_join = () => {
    this.setState({ modal_spatial_join: !this.state.modal_spatial_join });
  };

  toggle_link_edit = () => {
    this.props.set_value_layer(
      "modal_link_edit",
      !this.props.layer.modal_link_edit
    );
  };

  toggle_select_comparison_data = () => {
    this.setState({
      modal_select_comparison_data: !this.state.modal_select_comparison_data,
    });
  };

  toggle_select_data_form = () => {
    this.setState({
      modal_select_data_form: !this.state.modal_select_data_form,
    });
  };

  toggle_clone_field = () => {
    this.setState({ modal_clone_field: !this.state.modal_clone_field });
  };

  initApi = () => {
    const body = {};
    this.props.initApi(body);
  };

  onOpenModal = (name) => {
    this.props.openModal(name);
  };

  setLayerWOgeojson = (geo_layer) => {
    const content = {
      geo_layer: geo_layer,
    };
    this.props.setLayerWOgeojson(content);
  };

  handleChange = (e) => {
    e.preventDefault();
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    if (name === "fields") {
      this.setState({
        [name]: value,
      });
    } else if (name === "folder") {
      let geo_layer = this.state.geo_layer;
      geo_layer.folder = value;
      this.setState({
        geo_layer,
      });
    } else {
      let geo_layer = this.state.geo_layer.geo_layer;
      geo_layer[name] = value;
      geo_layer.geo_layer = geo_layer;
      this.setState({
        geo_layer,
      });
    }
  };

  handleToggle = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    let geo_layer = this.state.geo_layer;
    geo_layer.geo_layer[name] = value;
    this.setState({
      geo_layer,
    });
  };

  handleSubmitField = (e) => {
    e.preventDefault();
    let { geo_layer, fields } = this.state;
    geo_layer = geo_layer.geo_layer;
    const { geo_layer_list } = this.props.layer;
    const field = geo_layer_list[fields].geo_layer.fields;
    const new_field = field.map((f) => {
      let new_field = { ...f, uuid: uuid() };
      return new_field;
    });
    const { _id } = geo_layer;
    const body = {
      geo_layer_id: _id,
      field: new_field,
    };
    this.props.setField(body);
    this.props.toggleEdit();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { geo_layer } = this.state;
    geo_layer = geo_layer.geo_layer;
    const {
      _id,
      name,
      description,
      is_form_login_req,
      isPublic,
      show_only_highlight_field,
      isFormPublic,
      isLocationChangeable,
      picture_url,
      isCustomFormMessage,
      customFormMessageHeader,
      customFormMessageDesc,
      is_survey,
    } = geo_layer;
    let folder = this.state.geo_layer.folder;
    if (folder === undefined) {
      folder = this.props.layer.geo_layer_wo_geojson.folder;
    }
    const body = {
      geo_layer_id: _id,
      folder,
      name,
      description,
      is_form_login_req: is_form_login_req ? is_form_login_req : false,
      isPublic,
      show_only_highlight_field,
      isFormPublic,
      isLocationChangeable,
      picture_url,
      isCustomFormMessage,
      customFormMessageHeader,
      customFormMessageDesc,
      is_survey,
    };
    this.props.editLayerGeneral(body);
  };

  handleSubmitAttached = (e) => {
    e.preventDefault();
    let { geo_layer } = this.state;
    geo_layer = geo_layer.geo_layer;
    const { geo_project } = this.props.project;
    const { _id } = geo_layer;
    const folder = this.state.geo_layer.folder;
    const body = {
      geo_project_id: geo_project._id,
      geo_layer_id: _id,
      folder,
    };
    this.props.editLayerGeneralAttached(body);
  };

  handle_open_api = (key, open_api) => {
    const { geo_layer } = this.state;
    this.setState({
      geo_layer: {
        ...geo_layer,
        geo_layer: {
          ...geo_layer?.geo_layer,
          [key]: open_api,
        },
      },
    });
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      modal_select_data_form,
      geo_layer,
      fields,
      modal_clone_field,
      modal_select_comparison_data,
      modal_spatial_join,
      modal_edit_radius_pembanding,
      modal_manage_chart,
    } = this.state;

    //global props
    const { domain, user } = this.props.auth;
    const { geo_project } = this.props.project;
    const { geo_layer_list, loadingProcess, itemLoading } = this.props.layer;
    const geo_layer_id = this.props.layer?.layer_id;

    //content
    const api_keys = user?.api_keys ? user.api_keys : [];
    const domain_temp = domain_list?.[domain]?.form_link;
    const layer_data_list =
      this.props.layer?.geo_layer?.geo_layer?.layer_data_list || [];
    const layer_form_list =
      this.props.layer?.geo_layer?.geo_layer?.layer_form_list || [];
    const { folders } = geo_project;
    let folders_state = folders.slice();
    folders_state.unshift({ name: "Outside folders", _id: "" });
    const layer_inside = geo_layer?.geo_layer || {};
    const status = geo_layer?.status;
    const architecture = geo_layer?.geo_layer?.architecture;
    let final_content;
    let button_save_content;
    let button_edit_attached;
    if (loadingProcess && itemLoading === "edit_general") {
      button_save_content = (
        <div className="button">{dict?.["Saving"]?.[language]}...</div>
      );
    } else {
      button_save_content = (
        <button type="submit" className="button">
          {dict?.["Save"]?.[language]}
        </button>
      );
    }
    if (loadingProcess && itemLoading === "edit_general_attached") {
      button_edit_attached = <div className="button">Saving...</div>;
    } else {
      button_edit_attached = (
        <button type="submit" className="button">
          {dict?.["Save"]?.[language]}
        </button>
      );
    }
    let open_api_content = (
      <main className="container_flat margin_bottom">
        <p className="text_bold">OPEN API</p>
        <p className="text_inferior">
          {dict?.["You don't have an API key yet"]?.[language]}
        </p>
        <button className="button" onClick={this.initApi}>
          {dict?.["Create a new API key"]?.[language]}
        </button>
      </main>
    );
    if (api_keys.length > 0) {
      open_api_content = (
        <main className="container_flat margin_bottom">
          <section className="text_bold">OPEN API</section>
          <textarea
            disabled
            value={`https://geoserver.mapid.io/layers_new/get_layer?api_key=${api_keys?.[0]?.key}&layer_id=${layer_inside?._id}&project_id=${geo_project?._id}`}
            rows="5"
          />
        </main>
      );
    }
    const form_configuration_content = (
      <section>
        {architecture !== "api" && (
          <section className="container_flat margin_bottom">
            <div className="text_bold">{dict?.["Form config"]?.[language]}</div>
            <div className="text_inferior margin_bottom">
              {
                dict?.[
                  "GEO MAPID is integrated with FORM MAPID, edit form configuration here. You can test the link by click on it, if you set this layer as public form you can just test it without logging in, if not you need to login."
                ]?.[language]
              }
            </div>
            <CheckBox
              text="isLocationChangeable"
              title={dict?.["Changeable Location (in FORM MAPID)"]?.[language]}
              value={layer_inside?.isLocationChangeable || false}
              handle={this.handleToggle.bind(this)}
            />
            <CheckBox
              text="is_form_login_req"
              title="Required to login"
              value={layer_inside?.is_form_login_req || false}
              handle={this.handleToggle.bind(this)}
            />
            <CheckBox
              text="isFormPublic"
              title={dict?.["Public Form"]?.[language]}
              value={layer_inside?.isFormPublic || false}
              handle={this.handleToggle.bind(this)}
            />
            <CheckBox
              text="is_survey"
              title={dict?.["Survey manager"]?.[language]}
              value={layer_inside?.is_survey || false}
              handle={this.handleToggle.bind(this)}
            />
            <div style={{ marginTop: "5px" }}>
              Link:{" "}
              <a
                href={`${domain_temp}${layer_inside?.link}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#2196f3" }}
              >
                {`${domain_temp}${layer_inside?.link}`}
              </a>
            </div>
            <div style={{ display: "flex", marginTop: "10px", gap: "10px" }}>
              <Link
                to={`/form_editor/${layer_inside?.link}`}
                className="button"
                style={{
                  backgroundColor: "#2196f3",
                  color: "#ffffff",
                  display: "inline",
                }}
              >
                {dict?.["Open form editor"]?.[language]}
              </Link>
              <button
                onClick={this.toggle_link_edit}
                style={{ backgroundColor: "#2196f3", display: "inline" }}
                className="button"
              >
                Link Edit
              </button>
            </div>
            <hr />
            <section>
              <label className="text_bold w_full">Data Pembanding</label>
              <p>
                Pilih data pembanding untuk alat bantu surveyor memilih data
                pembanding terdekat dengan lokasi survei
              </p>
              <div
                onClick={this.toggle_select_comparison_data}
                style={{ backgroundColor: "#2196f3" }}
                className="button"
              >
                {dict?.["Select comparison data"]?.[language]}
              </div>
              <p>{dict?.["Selected comparison data:"]?.[language]}</p>
              <div className="overflow_x flex gap_10 w_full">
                {layer_data_list.length > 0 ? (
                  layer_data_list.map((row, idx) => {
                    return (
                      <DataPembanding
                        key={idx}
                        name={row.name}
                        geo_layer_id={geo_layer_id}
                      />
                    );
                  })
                ) : (
                  <p>Belum ada</p>
                )}
              </div>
              <p>
                Radius:{" "}
                {layer_inside?.radius_pembanding
                  ? `${layer_inside?.radius_pembanding} meter`
                  : "Belum atur radius"}
              </p>
              <div
                className="button"
                onClick={this.toggle_edit_radius_pembanding}
              >
                Edit radius pembanding
              </div>
            </section>
            <hr />
            <section>
              <label className="text_bold w_full">
                Data Overlay FORM MAPID
              </label>
              <p>Pilih data yang akan di-overlay-kan di peta FORM MAPID</p>
              <div
                onClick={this.toggle_select_data_form}
                style={{ backgroundColor: "#2196f3" }}
                className="button"
              >
                Pilih
              </div>
              <p>Data overlay yang sudah dipilih</p>
              <div className="overflow_x flex gap_10 w_full">
                {layer_form_list.length > 0 ? (
                  layer_form_list.map((row, idx) => {
                    return (
                      <DeleteDataFormOverlay
                        key={idx}
                        name={row.name}
                        geo_layer_id={geo_layer_id}
                      />
                    );
                  })
                ) : (
                  <p>Belum ada</p>
                )}
              </div>
            </section>
          </section>
        )}
        <section className="container_flat margin_bottom">
          <div className="text_bold">Layer Style</div>
          <p className="text_inferior margin_bottom">
            {
              dict?.[
                "This configuration is to set the layer style homogeneously. For each layer type (Point, Line, Polygon) it has a different style configuration."
              ]?.[language]
            }
          </p>
          <button
            className="button margin_bottom margin_right"
            onClick={this.on_open_modal_edit_layer_style}
            type="button"
          >
            Layer styling
          </button>
          <button
            className="button margin_bottom margin_right"
            onClick={this.on_open_modal_edit_label_config}
            type="button"
          >
            {dict?.["Map labeling"]?.[language]}
          </button>
        </section>
        <section className="container_flat margin_bottom">
          <div className="text_bold">
            Penggabungan spasial (geospatial join attribute)
          </div>
          <p className="text_inferior margin_bottom">
            Penggabungan spasial adalah fitur untuk menggabungkan dua data
            lokasi berdasarkan hubungan spasial antara geometri keduanya.
          </p>
          <div className="button" onClick={this.toggle_spatial_join}>
            Pilih data layer
          </div>
        </section>
      </section>
    );
    if (status === "attached") {
      //untuk kasus layer yang dilampirkan, hanya bisa mengedit folder di dalam project yang sedang dibuka
      final_content = (
        <main>
          <form onSubmit={this.handleSubmitAttached}>
            <section className="container_flat margin_bottom">
              <p className="text_bold">General</p>
              <p className="text_inferior">_id: {geo_layer?.geo_layer?._id}</p>
              <p className="text_inferior">
                link: {geo_layer?.geo_layer?.link}
              </p>
            </section>
            <section className="container_flat margin_bottom">
              <section className="text_bold">
                {dict?.["Edit Folder"]?.[language]}
              </section>
              <section className="text_inferior margin_bottom">
                {
                  dict?.[
                    "In the case of attached layer, you only can edit folder in this project"
                  ]?.[language]
                }
              </section>
              <select
                id="folder"
                name="folder"
                value={geo_layer?.folder || ""}
                onChange={this.handleChange}
              >
                {folders_state.map(({ _id, name }, idx) => {
                  return (
                    <option key={idx} value={_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </section>
            <section
              style={{
                textAlign: "center",
              }}
            >
              <div>{button_edit_attached}</div>
            </section>
          </form>
        </main>
      );
    } else {
      //untuk kasus layer asli yang ada di dalam project, tersedia semua kolom pengeditan
      final_content = (
        <main>
          <p className="text_header">Edit Layer</p>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <main
              style={{
                maxHeight: "calc(( 100vh - 255px ))",
                overflowY: "scroll",
              }}
            >
              <section className="container_flat margin_bottom">
                <section className="text_bold">
                  {dict?.["General"]?.[language]}
                </section>
                <section className="text_inferior margin_bottom">
                  {
                    dict?.[
                      "Rename layer, edit description, group position and toggle publication status of the layer. If you set this layer as public, your layer will be shown on our community dashboard."
                    ]?.[language]
                  }
                </section>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={layer_inside?.name || ""}
                  onChange={this.handleChange}
                  className="margin_bottom"
                />
                <textarea
                  type="text"
                  name="description"
                  id="description"
                  value={layer_inside?.description || ""}
                  onChange={this.handleChange}
                  rows="4"
                  className="margin_bottom"
                />
                <select
                  id="folder"
                  name="folder"
                  value={geo_layer?.folder || ""}
                  onChange={this.handleChange}
                >
                  {folders_state.map(({ _id, name }, idx) => {
                    return (
                      <option key={idx} value={_id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                <CheckBox
                  text="isPublic"
                  title="Public Data"
                  value={layer_inside?.isPublic || false}
                  handle={this.handleToggle.bind(this)}
                />
                <CheckBox
                  text="show_only_highlight_field"
                  title="Hanya tampilkan pertanyaan yang di highlight saja"
                  value={layer_inside?.show_only_highlight_field || false}
                  handle={this.handleToggle.bind(this)}
                />
                <br />
                <div className="button" onClick={this.toggle_edit_chart}>
                  Buat Grafik
                </div>
              </section>
              {form_configuration_content}
              {open_api_content}
            </main>
            <section
              style={{
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              {button_save_content}
            </section>
          </form>
        </main>
      );
    }

    const modal_manage_chart_content = modal_manage_chart && (
      <Modal
        modalSize="medium"
        id="modal_manage_chart"
        isOpen={modal_manage_chart}
        onClose={this.toggle_edit_chart}
      >
        <div className="box-body">
          <MANAGE_CHART />
        </div>
      </Modal>
    );

    const modal_select_comparison_data_content =
      modal_select_comparison_data && (
        <Modal
          modalSize="medium"
          id="modal_select_comparison_data"
          isOpen={modal_select_comparison_data}
          onClose={this.toggle_select_comparison_data}
        >
          <div className="box-body">
            <SelectDataPembanding
              toggle_select_comparison_data={this.toggle_select_comparison_data}
            />
          </div>
        </Modal>
      );

    const modal_select_data_form_content = modal_select_data_form && (
      <Modal
        modalSize="medium"
        id="modal_select_comparison_data"
        isOpen={modal_select_data_form}
        onClose={this.toggle_select_data_form}
      >
        <div className="box-body">
          <SelectDataForm
            toggle_select_data_form={this.toggle_select_data_form}
          />
        </div>
      </Modal>
    );

    const modal_clone_field_content = modal_clone_field && (
      <Modal
        modalSize="small"
        id="modal_clone_field"
        isOpen={modal_clone_field}
        onClose={this.toggle_clone_field}
      >
        <div className="box-body">
          <form onSubmit={this.handleSubmitField.bind(this)}>
            <div className="text_bold">
              {dict?.["Clone attributes from other layer"]?.[language]}
            </div>
            <div className="text_inferior margin_bottom">
              {
                dict?.[
                  "This action will overwrite the attribute list from other layer to this layer, do this if you want to clone attribute. Be careful this action can't be undone."
                ]?.[language]
              }
            </div>
            <select
              id="fields"
              name="fields"
              value={fields}
              onChange={this.handleChange.bind(this)}
            >
              {geo_layer_list.map((layer, idx) => {
                return (
                  <option key={idx} value={idx}>
                    {layer?.geo_layer?.name}
                  </option>
                );
              })}
            </select>
          </form>
        </div>
      </Modal>
    );

    const modal_spatial_join_content = modal_spatial_join && (
      <Modal
        modalSize="medium"
        id="modal_spatial_join"
        isOpen={modal_spatial_join}
        onClose={this.toggle_spatial_join}
      >
        <div className="box-body">
          <SpatialJoin />
        </div>
      </Modal>
    );

    const modal_edit_radius_pembanding_content =
      modal_edit_radius_pembanding && (
        <Modal
          modalSize="small"
          id="modal_edit_radius_pembanding"
          isOpen={modal_edit_radius_pembanding}
          onClose={this.toggle_edit_radius_pembanding}
        >
          <div className="box-body">
            <EditRadiusPembanding
              toggle_edit_radius_pembanding={this.toggle_edit_radius_pembanding}
            />
          </div>
        </Modal>
      );

    return (
      <main>
        {modal_manage_chart_content}
        {modal_select_comparison_data_content}
        {modal_clone_field_content}
        {modal_select_data_form_content}
        {modal_spatial_join_content}
        {modal_edit_radius_pembanding_content}
        {final_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  project: state.project,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setField,
  doSentimentAnalysis,
  setLayerWOgeojson,
  openModal,
  editLayerGeneral,
  editLayerGeneralAttached,
  getDetailLayerById,
  initApi,
  set_value_layer,
  set_value_properties,
})(EditLayer);
