import logoStreet3d from "../../Assets/img/icon_map_street-3d-building.jpg";
import logoStreet2d from "../../Assets/img/icon_map_street-2d-building.jpg";
import logoSatellite from "../../Assets/img/icon_map_satellite.png";
import logoLight from "../../Assets/img/icon_map_light.png";
import logoDark from "../../Assets/img/icon_map_dark.png";

const basemap_url = process.env.REACT_APP_MAPID_BASEMAP
const basemap_key = process.env.REACT_APP_BASEMAP_KEY

const basemap_styles = () => {
  return [
    {
      "name": "Street 2D Building",
      "value": "street-2d-building",
      "api": {
        "mapid": `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`
      },
      "icon": logoStreet2d
    },
    {
      "name": "Street 3D Building",
      "value": "street",
      "api": {
        "mapbox": "https://api.maptiler.com/maps/streets/style.json?key=CVYOeJckfxqURN5wjKEB",
        "mapid": `${basemap_url}/styles/basic/style.json?key=${basemap_key}`
      },
      "icon": logoStreet3d
    },
    {
      "name": "Light",
      "value": "light",
      "api": {
        "mapbox": "mapbox://styles/mapbox/light-v10",
        "mapid": `${basemap_url}/styles/light/style.json?key=${basemap_key}`
      },
      "icon": logoLight
    },
    {
      "name": "Dark",
      "value": "dark",
      "api": {
        "mapbox": "mapbox://styles/mapbox/dark-v10",
        "mapid": `${basemap_url}/styles/dark/style.json?key=${basemap_key}`
      },
      "icon": logoDark
    },
    {
      "name": "Satellite",
      "value": "satellite",
      "api": {
        "mapbox": "mapbox://styles/mapbox/satellite-streets-v11",
        "mapid": `${basemap_url}/styles/satellite/style.json?key=${basemap_key}`
      },
      "icon": logoSatellite
    },
  ]
}

export default basemap_styles;

