/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

/*PERSONAL COMPONENT*/
import BI_SIDEBAR_PARENT from "../../Components/bi_sidebar/BI_SIDEBAR_PARENT";
import BI_MAP_PARENT from "../../Components/bi_map/BI_MAP_PARENT";
import BI_CARD_CONTAINER_PARENT from "../../Components/bi_card/BI_CARD_CONTAINER_PARENT";

/*REDUX FUNCTION*/
import { get_bi_object_by_link, reset_bi } from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import css_string_init from "../../Components/bi_sidebar/css_string_init";

/*NON IMPORT*/

class BI_EDIT_PAGE extends Component {
  componentDidMount() {
    if (this?.props?.match?.params?.link) {
      const query = { link: this.props.match.params.link };
      this.props.get_bi_object_by_link(query);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.bi.full_card !== prevProps.bi.full_card) {
      if (this.props.bi.full_card) {
        document.querySelectorAll(".bi_sidebar_left").forEach((element) => {
          element.style.display = "none";
        });
        document.querySelectorAll(".bi_map").forEach((element) => {
          element.style.display = "none";
        });
        document.querySelectorAll(".bi_card_container").forEach((element) => {
          element.style.height = "calc(100vh - 20px)";
        });
        document.querySelectorAll(".bi_card_container").forEach((element) => {
          element.style.width = "calc(100vw - 20px)";
        });
      } else {
        document.querySelectorAll(".bi_sidebar_left").forEach((element) => {
          element.style.display = "";
        });
        document.querySelectorAll(".bi_map").forEach((element) => {
          element.style.display = "";
        });
        document.querySelectorAll(".bi_card_container").forEach((element) => {
          element.style.height = "";
        });
        document.querySelectorAll(".bi_card_container").forEach((element) => {
          element.style.width = "";
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.reset_bi();
  }

  render() {
    //local storage
    // const language = localStorage?.language || "ina";

    //local state

    //global props
    const { bi_object } = this.props.bi;
    const link = this?.props?.match?.params?.link;

    //content
    let content = null;

    if (bi_object?._id) {
      const css_string = bi_object?.css_string || css_string_init();

      content = (
        <div>
          <main className="bi_parent">
            <section className="bi_sidebar_left">
              <BI_SIDEBAR_PARENT link={link} />
            </section>
            <section className="bi_content">
              <div className="bi_map">
                <BI_MAP_PARENT link={link} />
              </div>
              <div className="bi_card_container">
                <BI_CARD_CONTAINER_PARENT link={link} />
              </div>
            </section>
          </main>

          <Helmet>
            <title>{bi_object?.name || "BI EDITOR"}</title>
            <meta name="description" content={bi_object?.name || "BI EDITOR"} />
          </Helmet>

          <style>{css_string}</style>
        </div>
      );
    } else {
      content = <div>Loading</div>;
    }

    return (
      <main>
        {content}
        <style>{`
    body{
      padding-top: 0px;
      overflow-y: hidden;
    }
    #navbarDefault{ visibility: hidden;}
    .top_nav{ visibility: hidden;}
    .sidebar_home{ visibility: hidden;}
    .nav_bottom{ visibility: hidden;}
 `}</style>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, { get_bi_object_by_link, reset_bi })(
  BI_EDIT_PAGE
);
