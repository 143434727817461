/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import ProgressBar from "../common_spinner/ProgressBar";
import InputAreaHirarki from "./InputAreaHirarki";
import InputAreaSingle from "./InputAreaSingle";
import InputPrompt from "./InputPrompt";
import InputTipe from "./InputTipe";
import InputDemographic from "./InputDemographic";

/*REDUX FUNCTION*/
import { get_insight } from "../../App/actions/siniActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { set_value_user } from "../../App/actions/authActions";

/*PICTURE ASSET*/
import checklist from "../../Assets/svg/checklist.svg";
import checklist_grey from "../../Assets/svg/checklist_grey.svg";
import expand from "../../Assets/svg/expand.svg";

/*GENERAL FUNCTION & DATA*/
import uuid from "../../App/validation/uuid";
import dict from "../../Data/dict.json";
import hirarki_tipe_list from "../../Data/hirarki_tipe_list.json";
import tipe_1_string_array from "../../Data/tipe_1_string_array.json";
import tipe_2_string_array from "../../Data/tipe_2_string_array.json";

/*NON IMPORT*/
const list_development_url = [
  "localhost",
  "geo-alpha.mapid.io",
  "geo-beta.mapid.io",
];

class INPUT_AREA extends Component {
  state = {
    mode_input: "single_input", //hirarki || single_input || prompt
    modal_payment: false,
    modal_choose_poi: false,
  };

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  toggle_payment_universal = () => {
    const { modal_payment_universal } = this.props.auth;
    this.props.set_value_user(
      "modal_payment_universal",
      !modal_payment_universal
    );
  };

  on_set_mode_input = (mode_input) => {
    this.setState({ mode_input });
  };

  toggle_choose_poi = () => {
    this.setState({ modal_choose_poi: !this.state.modal_choose_poi });
  };

  toggle_payment = () => {
    const { modal_payment_universal } = this.props.auth;
    this.props.set_value_user(
      "modal_payment_universal",
      !modal_payment_universal
    );
  };

  on_get_insight = () => {
    const { isAuthenticated, quota_access } = this.props.auth;
    const quota_access_sini_ai = this.props.auth.quota_access_sini_ai || 0;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = !["license_1", "license_2", "license_3"].includes(
      payment_type
    );
    const logic_2 = !license_group_status?.is_has_license;
    const logic_3 = quota_access <= 0;
    const logic_4 = quota_access_sini_ai <= 0;
    if (!isAuthenticated) {
      this.toggle_login();
    } else if (logic_1 && logic_2 && logic_3 && logic_4) {
      this.toggle_payment_universal();
    } else {
      const { poi_tipe_2_selected, poi_all_selected } = this.props.properties;
      const { provinsi, kota, kecamatan, kelurahan } = this.props.map;
      let poi_tipe_2_selected_string = "";
      if (poi_tipe_2_selected.length > 0) {
        poi_tipe_2_selected_string = poi_tipe_2_selected.join(",");
      }
      const params = {
        request_id: uuid(),
        provinsi,
        kota,
        kecamatan,
        kelurahan,
        poi_tipe_2_selected_string,
        poi_all_selected,
      };
      this.props.get_insight(params);
    }
  };

  on_toggle_tipe_1 = (tipe_1) => {
    this.props.set_value_properties({
      key: "poi_all_selected",
      value: false,
    });
    let { poi_tipe_1_selected, poi_tipe_2_selected } = this.props.properties;
    const tipe_2_clicked = hirarki_tipe_list
      .find((item) => item.TIPE_1 === tipe_1)
      .tipe_2_list.map((tipe_2) => tipe_2.TIPE_2);
    if (poi_tipe_1_selected.includes(tipe_1)) {
      poi_tipe_1_selected = poi_tipe_1_selected.filter(
        (item) => item !== tipe_1
      );
      poi_tipe_2_selected = poi_tipe_2_selected.filter(
        (tipe_2) => !tipe_2_clicked.includes(tipe_2)
      );
    } else {
      poi_tipe_1_selected.push(tipe_1);
      tipe_2_clicked.forEach((tipe_2) => {
        if (!poi_tipe_2_selected.includes(tipe_2)) {
          poi_tipe_2_selected.push(tipe_2);
        }
      });
    }
    this.props.set_value_properties({
      key: "poi_tipe_1_selected",
      value: poi_tipe_1_selected,
    });
    this.props.set_value_properties({
      key: "poi_tipe_2_selected",
      value: poi_tipe_2_selected,
    });
  };

  toggle_all_tipe = () => {
    const { poi_all_selected } = this.props.properties;
    if (poi_all_selected) {
      this.props.set_value_properties({
        key: "poi_tipe_1_selected",
        value: [],
      });
      this.props.set_value_properties({
        key: "poi_tipe_2_selected",
        value: [],
      });
      this.props.set_value_properties({
        key: "poi_all_selected",
        value: false,
      });
    } else {
      this.props.set_value_properties({
        key: "poi_tipe_1_selected",
        value: tipe_1_string_array,
      });
      this.props.set_value_properties({
        key: "poi_tipe_2_selected",
        value: tipe_2_string_array,
      });
      this.props.set_value_properties({
        key: "poi_all_selected",
        value: true,
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { mode_input, modal_choose_poi } = this.state;

    //global props
    let { kota } = this.props.map;
    const { poi_tipe_1_selected, poi_all_selected } = this.props.properties;
    const {
      loading_item,
      loading_status,
      progress_poi_total,
      progress_poi_current,
    } = this.props.sini;
    const { domain } = this.props.auth;

    //content
    let button_content;
    if (!kota) {
      button_content = (
        <div className="button" id="grey">
          {dict?.["Search data"]?.[language]}
        </div>
      );
    } else if (loading_status && loading_item === "get_insight") {
      button_content = (
        <div className="button" id="grey">
          {dict?.["Loading"]?.[language]}
        </div>
      );
    } else {
      button_content = (
        <button className="button" onClick={this.on_get_insight}>
          {dict?.["Search data"]?.[language]}
        </button>
      );
    }

    const modal_choose_poi_content = modal_choose_poi && (
      <Modal
        modalSize="large"
        id="modal_choose_poi"
        isOpen={modal_choose_poi}
        onClose={this.toggle_choose_poi}
      >
        <div className="box-body" style={{ overflowY: "scroll" }}>
          <main className="max_height">
            <InputTipe toggle_choose_poi={this.toggle_choose_poi} />
          </main>
        </div>
      </Modal>
    );

    let input_area_content = <InputAreaSingle />;
    if (mode_input === "hirarki") {
      input_area_content = <InputAreaHirarki />;
    } else if (mode_input === "prompt") {
      input_area_content = <InputPrompt />;
    }

    let prompt_button_content = <></>;
    if (
      list_development_url?.includes(domain) ||
      domain?.includes("192.168.")
    ) {
      prompt_button_content = (
        <button
          className={`button margin_right margin_bottom ${
            mode_input === "prompt" ? "background_black" : "background_white"
          }`}
          onClick={this.on_set_mode_input.bind(this, "prompt")}
        >
          Prompt
        </button>
      );
    }

    return (
      <main>
        {modal_choose_poi_content}

        <section className="margin_bottom">
          <div className="badge_pill" id="blue">
            Step 1
          </div>
          <p className="text_header">Input lokasi</p>
        </section>
        <main className="container_light">
          <section className="margin_bottom">
            <button
              className={`button margin_right margin_bottom ${
                mode_input === "single_input"
                  ? "background_black"
                  : "background_white"
              }`}
              onClick={this.on_set_mode_input.bind(this, "single_input")}
            >
              Pencarian
            </button>
            <button
              className={`button margin_right margin_bottom ${
                mode_input === "hirarki"
                  ? "background_black"
                  : "background_white"
              }`}
              onClick={this.on_set_mode_input.bind(this, "hirarki")}
            >
              Level Administrasi
            </button>
            {prompt_button_content}
          </section>
          {input_area_content}
          {mode_input !== "prompt" && (
            <>
              <br />
              <br />

              <section>
                <table className="full_width">
                  <tbody>
                    <tr>
                      <td>
                        <p className="text_bold">
                          Pilih tema lokasi point of interest
                        </p>
                      </td>
                      <td className="text_right">
                        <img
                          className="cursor_pointer"
                          alt="expand"
                          src={expand}
                          style={{ height: "20px" }}
                          onClick={this.toggle_choose_poi}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <section id="poi_insight_child">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            onClick={this.toggle_all_tipe}
                            className="cursor_pointer margin_right"
                            width="17px"
                            alt="select all tipe poi"
                            src={poi_all_selected ? checklist : checklist_grey}
                          />
                        </td>
                        <td onClick={this.toggle_all_tipe} className="side">
                          ALL
                        </td>
                      </tr>
                      {hirarki_tipe_list.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <img
                                onClick={this.on_toggle_tipe_1.bind(
                                  this,
                                  item?.TIPE_1
                                )}
                                className="cursor_pointer margin_right"
                                width="17px"
                                alt={item.name}
                                src={
                                  poi_tipe_1_selected.includes(item?.TIPE_1)
                                    ? checklist
                                    : checklist_grey
                                }
                              />
                            </td>
                            <td
                              onClick={this.on_toggle_tipe_1.bind(
                                this,
                                item?.TIPE_1
                              )}
                              className="side"
                            >
                              {item?.TIPE_1}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </section>
                <br />
              </section>

              <InputDemographic />

              <section className="margin_top">
                <ProgressBar
                  current_number={progress_poi_current}
                  total_number={progress_poi_total}
                  name="Memuat data"
                />
              </section>
              {button_content}
            </>
          )}
        </main>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  map: state.map,
  layer: state.layer,
  payment: state.payment,
  properties: state.properties,
  sini: state.sini,
  license_reducer: state.license_reducer,
});

export default connect(mapStateToProps, {
  get_insight,
  set_value_properties,
  set_value_user,
})(INPUT_AREA);
