/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_map, status_action } from "../../App/actions/mapActions";

/*PICTURE ASSET*/
// import logoStreet from "../../Assets/img/icon_map_street.png";
// import logoSatellite from "../../Assets/img/icon_map_satellite.png";
// import logoLight from "../../Assets/img/icon_map_light.png";
// import logoDark from "../../Assets/img/icon_map_dark.png";

/*GENERAL FUNCTION & DATA*/
import basemap_styles from "../../App/validation/basemap_styles";
import { domain_mapid_basemap } from "../../App/validation/features_domain";

/*NON IMPORT*/

class BASEMAP_MODE extends Component {
  state = {
    modal_basemap: false,
  };

  toggle_basemap = () => {
    this.setState({ modal_basemap: !this.state.modal_basemap });
  };

  on_set_basemap = (value) => {
    const { domain } = this.props.auth;
    this.setState({ modal_basemap: false });
    this.props.set_value_map({
      key: "basemap_mode",
      value: value,
    });
    const basemap_props = basemap_styles().find((item) => item.value === value);
    const active_basemap = domain_mapid_basemap.includes(domain)
      ? "mapid"
      : "mapbox";
    const basemap_api = basemap_props?.api?.[active_basemap];
    this.props.set_value_map({
      key: "basemap_api",
      value: basemap_api,
    });

  };

  render() {
    //local storage

    //local state
    const { modal_basemap } = this.state;

    //global props
    const { basemap_mode } = this.props.map;

    //content
    let logo_map = basemap_styles().find(
      (item) => item.value === basemap_mode
    )?.icon;
    const { domain } = this.props.auth;
    const active_basemap = domain_mapid_basemap.includes(domain)
      ? "mapid"
      : "mapbox";
    const filtered_map_style = basemap_styles().filter((style) =>
      Object.keys(style?.api)?.includes(active_basemap)
    );

    const content = (
      <div className="modal_left_side">
        <div className="w_150 h_100 flex wrap gap_10 padding_10">
          {filtered_map_style?.map((style, idx) => (
            <img
              key={idx}
              className={"img-rounded pointer hover_bigger noselect"}
              src={style?.icon}
              style={{
                width: "40px",
                height: "40px",
              }}
              alt={style?.name}
              title={style?.name}
              onClick={this.on_set_basemap.bind(this, style?.value)}
            />
          ))}
        </div>
        {/* <table
          style={{ textAlign: "center", width: 150, padding: 5, fontSize: 11 }}
        >
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>
                <div>
                  <img
                    className={"img-rounded pointer hover_bigger"}
                    src={logoStreet}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    alt={"Street mode"}
                    onClick={this.on_set_basemap.bind(this, "street")}
                  />
                </div>
                <div>Street</div>
              </td>
              <td>
                <div>
                  <img
                    className={"img-rounded pointer hover_bigger"}
                    src={logoSatellite}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    alt={"Satellite mode"}
                    onClick={this.on_set_basemap.bind(this, "satellite")}
                  />
                </div>
                <div>
                  <span>Satellite</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <img
                    className={"img-rounded pointer hover_bigger"}
                    src={logoDark}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    alt={"Dark mode"}
                    onClick={this.on_set_basemap.bind(this, "dark")}
                  />
                </div>
                <div>Dark</div>
              </td>
              <td>
                <div>
                  <img
                    className={"img-rounded pointer hover_bigger"}
                    src={logoLight}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    alt={"Light mode"}
                    onClick={this.on_set_basemap.bind(this, "light")}
                  />
                </div>
                <div>Light</div>
              </td>
            </tr>
          </tbody>
        </table> */}
      </div>
    );

    return (
      <main>
        <section
          style={{
            backgroundImage: `url(${logo_map})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "25px",
            height: "25px",
          }}
          onClick={this.toggle_basemap}
          title="Change Style Map"
        ></section>
        {modal_basemap && <div>{content}</div>}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  auth: state.auth,
});

export default connect(mapStateToProps, { set_value_map, status_action })(
  BASEMAP_MODE
);
