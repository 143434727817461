/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import SINI_POLYGON_CONFIG from "./SINI_POLYGON_CONFIG";

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/
import ICON_BACK from "../../Assets/jsx/ICON_BACK";

/*GENERAL FUNCTION & DATA*/
// import dict from "../../Data/dict.json";
import colors_rdtr from "../../Data/colors_rdtr.json";
import calculate_rdtr from "../../App/validation/calculate_rdtr";

/*NON IMPORT*/
// const rdtr_calc_mode_array = [
//   {
//     name: "Reset",
//     value: "",
//   },
//   {
//     name: "Area terpilih",
//     value: "one_mode",
//   },
//   {
//     name: "Area tetangga",
//     value: "neighbor_mode",
//   },
// ];

class SINI_RDTR_FULL extends Component {
  state = { anchor: null, modal_layer_config: false };

  on_calc = () => {
    const {
      rdtr_calc_mode,
      rdtr_input_area_m2,
      rdtr_input_KDB,
      rdtr_input_KLB,
      rdtr_input_KDH,
    } = this.props.sini;

    if (rdtr_calc_mode === "one_mode") {
      const result = calculate_rdtr({
        KDB: rdtr_input_KDB,
        KLB: rdtr_input_KLB,
        KDH: rdtr_input_KDH,
        rdtr_input_area_m2,
      });
      const {
        rdtr_result_LDB,
        rdtr_result_LLB,
        rdtr_result_LRH,
        rdtr_result_JLB,
      } = result;

      this.props.set_value_sini({
        key: "rdtr_result_LDB",
        value: rdtr_result_LDB,
      });
      this.props.set_value_sini({
        key: "rdtr_result_LLB",
        value: rdtr_result_LLB,
      });
      this.props.set_value_sini({
        key: "rdtr_result_JLB",
        value: rdtr_result_JLB,
      });
      this.props.set_value_sini({
        key: "rdtr_result_LRH",
        value: rdtr_result_LRH,
      });
      this.props.set_value_sini({
        key: "rdtr_area_changes",
        value: false,
      });
    }
  };

  on_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.props.set_value_sini({
      key: name,
      value: value,
    });
    if (name === "rdtr_input_area_m2") {
      this.props.set_value_sini({
        key: "rdtr_area_changes",
        value: true,
      });
    }
  };

  toggle_layer_config = (e) => {
    this.setState({ modal_layer_config: !this.state.modal_layer_config });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  toggle_sini_map_show = (item) => {
    let { sini_map_show } = this.props.sini;
    if (sini_map_show.includes(item)) {
      sini_map_show = sini_map_show.filter((element) => element !== item);
    } else {
      sini_map_show.push(item);
    }
    this.props.set_value_sini({
      key: "sini_map_show",
      value: sini_map_show,
    });
  };

  on_set_sini_menu_active = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "",
    });
    this.props.set_value_sini({
      key: "sini_map_show",
      value: ["poi", "isochrone"],
    });
  };

  render() {
    //local storage
    // const language = localStorage?.language || "ina";

    //local state
    const { modal_layer_config, anchor } = this.state;

    //global props
    const {
      sini_v2_list,
      request_id_active,
      ai_parent_detail_list,
      rdtr_calc_mode,
      rdtr_input_area_m2,
      // rdtr_input_OBJECTID,
      // rdtr_input_long,
      // rdtr_input_lat,
      rdtr_input_NAMOBJ,
      rdtr_input_KDB,
      rdtr_input_KLB,
      rdtr_input_KDH,
      rdtr_area_changes,
      rdtr_result_LDB,
      rdtr_result_LLB,
      rdtr_result_JLB,
      rdtr_result_LRH,
    } = this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const is_loading_rdtr = loading_item_array.includes("rdtr");

    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const rdtr_one = sini_object?.rdtr_one;
    // const rdtr_neighbor = sini_object?.rdtr_neighbor;

    let content;

    if (is_loading_rdtr || rdtr_one !== undefined) {
      const rdtr_value = rdtr_one?.properties?.["NAMZON_1"];
      const is_rdtr_empty = rdtr_value === undefined;
      let rdtr_content;
      if (is_loading_rdtr) {
        rdtr_content = (
          <>
            <p className="text_small">Loading...</p>
            <br />
          </>
        );
      } else if (!is_rdtr_empty) {
        rdtr_content = (
          <>
            {/* <main className="container_light margin_bottom">
              <section className="margin_bottom">
                <p className="text_small">Data di titik terpilih</p>
                <p className="text_bold">{rdtr_value}</p>
              </section>
              <section className="margin_bottom">
                <p className="text_small">Data di sekitar</p>
                <p className="text_bold">
                  {rdtr_neighbor.length}{" "}
                  {dict?.["RDTR data nearby"]?.[language]}
                </p>
              </section>
            </main> */}
            <main className="container_light margin_bottom">
              {/* <section className="margin_bottom">
                <p className="text_medium margin_bottom">Step 1: Pilih mode</p>
                <div className="container_light background_white outline_black">
                  {rdtr_calc_mode_array.map(({ value, name }, index) => {
                    return (
                      <button
                        key={index}
                        value={value}
                        name="rdtr_calc_mode"
                        onClick={this.on_change}
                        className={`button_small margin_right margin_bottom outline_black ${
                          rdtr_calc_mode === value
                            ? "background_black"
                            : "background_grey"
                        }`}
                      >
                        {name}
                      </button>
                    );
                  })}
                </div>
              </section> */}
              {rdtr_calc_mode !== "" && (
                <>
                  <section className="margin_bottom">
                    <p className="text_medium">Step 1: Pilih poligon</p>
                    <main className="container_light background_white outline_black">
                      {rdtr_calc_mode !== "" ? (
                        <section>
                          Pilih area dengan klik poligon di peta
                        </section>
                      ) : (
                        <section>Pilih mode kalkulasi terlebih dahulu</section>
                      )}
                    </main>
                  </section>
                  {!!rdtr_input_NAMOBJ && (
                    <>
                      <section className="margin_bottom">
                        <p className="text_medium">
                          Step 2: Masukan luas lahan
                        </p>
                        <div className="container_light background_white outline_black">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    name="rdtr_input_area_m2"
                                    value={rdtr_input_area_m2}
                                    onChange={this.on_change}
                                    type="number"
                                    className="margin_bottom"
                                  />
                                </td>
                                <td>
                                  <p className="text_medium">meter persegi</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <button
                            className="button_small"
                            onClick={this.on_calc}
                          >
                            Hitung ulang
                          </button>
                        </div>
                      </section>
                      {!rdtr_area_changes && (
                        <section className="margin_bottom">
                          <p className="text_medium">Hasil kalkulator</p>
                          <main className="container_light background_white outline_black">
                            <table
                              className="table full_width margin_bottom"
                              style={{ tableLayout: "fixed" }}
                            >
                              <tbody>
                                <tr>
                                  <td colSpan={3} className="text_bold">
                                    Input
                                  </td>
                                </tr>

                                <tr>
                                  <td>Nama objek</td>
                                  <td colSpan={2}>{rdtr_input_NAMOBJ}</td>
                                </tr>
                                <tr>
                                  <td>KDB</td>
                                  <td>{rdtr_input_KDB}</td>
                                  <td>%</td>
                                </tr>
                                <tr>
                                  <td>KLB</td>
                                  <td>{rdtr_input_KLB}</td>
                                  <td>desimal</td>
                                </tr>
                                <tr>
                                  <td>KDH</td>
                                  <td>{rdtr_input_KDH}</td>
                                  <td>%</td>
                                </tr>
                                <tr>
                                  <td>Luas input</td>
                                  <td>{rdtr_input_area_m2}</td>
                                  <td>meter persegi</td>
                                </tr>
                                <tr>
                                  <td colSpan={3} className="text_bold">
                                    Output
                                  </td>
                                </tr>
                                <tr>
                                  <td>Luas dasar bangunan (LDB)</td>
                                  <td>
                                    {new Intl.NumberFormat("id-ID", {
                                      style: "decimal",
                                    }).format(rdtr_result_LDB)}
                                  </td>
                                  <td>meter persegi</td>
                                </tr>
                                <tr>
                                  <td>Luas lantai bangunan (LLB)</td>
                                  <td>
                                    {new Intl.NumberFormat("id-ID", {
                                      style: "decimal",
                                    }).format(rdtr_result_LLB)}
                                  </td>
                                  <td>meter persegi</td>
                                </tr>
                                <tr>
                                  <td>Jumlah lantai bangunan (JLB)</td>
                                  <td>
                                    {new Intl.NumberFormat("id-ID", {
                                      style: "decimal",
                                    }).format(rdtr_result_JLB)}
                                  </td>
                                  <td>lantai</td>
                                </tr>
                                <tr>
                                  <td>Luas ruang hijau (LRH)</td>
                                  <td>
                                    {new Intl.NumberFormat("id-ID", {
                                      style: "decimal",
                                    }).format(rdtr_result_LRH)}
                                  </td>
                                  <td>meter persegi</td>
                                </tr>
                              </tbody>
                            </table>
                            {/* <button className="button_small">
                              Simpan perhitungan
                            </button> */}
                          </main>
                        </section>
                      )}
                    </>
                  )}
                </>
              )}
            </main>
          </>
        );
      } else {
        rdtr_content = (
          <section className="margin_bottom">
            <p className="text_small">Tidak ada data RDTR</p>
          </section>
        );
      }
      content = <main>{rdtr_content}</main>;
    }

    const modal_layer_config_content = modal_layer_config && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_layer_config}
        onClose={this.toggle_layer_config}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
            width: "250px",
          }}
        >
          <SINI_POLYGON_CONFIG />
        </div>
      </Menu>
    );

    const legend_content = (
      <main className="container_light">
        <p className="text_bold margin_bottom">Legenda RDTR</p>
        <button
          className="button_small margin_bottom"
          onClick={this.toggle_layer_config}
        >
          Edit tampilan
        </button>
        <br />
        <table className="text_small">
          <tbody>
            {colors_rdtr.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "5px",
                        backgroundColor: item.color,
                      }}
                    />
                  </td>
                  <td>{item.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    );
    return (
      <main className="sini_full_parent">
        {modal_layer_config_content}
        <section className="sini_full_header">
          <table>
            <tbody>
              <tr>
                <td
                  style={{ width: "30px" }}
                  onClick={this.on_set_sini_menu_active}
                >
                  <ICON_BACK />
                </td>
                <td className="text_left">
                  <div className="badge background_green margin_bottom">
                    Kalkulator RDTR
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <br />
        <section className="sini_full_scroll_area">
          {content}
          {legend_content}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { set_value_sini })(SINI_RDTR_FULL);
