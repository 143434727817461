/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/
const color_line = "#104585";
const color_fill = "#104585";

class LAYER_SINI_DEMOGRAPHY_ONE extends Component {
  state = {
    render_content: null,
  };

  componentDidMount() {
    this.on_render_layer();
  }

  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    if (state_update_after !== state_update_before) {
      this.on_render_layer();
    }
    const fly_init_update_after = this.props.sini.fly_init_update;
    const fly_init_update_before = prevProps.sini.fly_init_update;
    if (
      fly_init_update_after !== fly_init_update_before &&
      !this.props.sini.is_stop_zoom
    ) {
      this.on_fly_init();
    }
  }

  on_fly_init = () => {
    const { request_id_active, sini_v2_list, ai_parent_detail_list } =
      this.props.sini;
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];

    if (merge_array.length > 0) {
      const sini_object = merge_array.find(
        (item) => item.request_id === request_id_active
      );

      if (sini_object) {
        const demography_one = sini_object?.demography_one;
        const long = sini_object?.long;
        const lat = sini_object?.lat;
        if (
          demography_one?._id &&
          demography_one?.geometry &&
          demography_one?.properties
        ) {
          const map = this?.props?.map?.map;
          if (map) {
            map.flyTo({
              center: [long, lat],
              zoom: 13,
            });
          } else {
          }
        }
      }
    }
  };

  on_render_layer = () => {
    const { sini_v2_list, ai_parent_detail_list, sini_map_show } =
      this.props.sini;
    const visibility = sini_map_show.includes("demography_one")
      ? "visible"
      : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const render_content = merge_array.map((item, idx) => {
      const demography_one = item?.demography_one;
      let features_demography_one = [];
      if (demography_one?._id) {
        features_demography_one = [demography_one];
      }
      const geojson_demography_one = {
        type: "FeatureCollection",
        features: features_demography_one,
      };
      return (
        <div key={idx}>
          <Source
            key={`geojson_demography_one_${idx}`}
            id={`geojson_demography_one_${idx}`}
            type="geojson"
            data={geojson_demography_one}
          />
          <Layer
            id={`geojson_demography_one_${idx}`}
            source={`geojson_demography_one_${idx}`}
            before={`poi_${idx}`}
            type="fill"
            paint={{
              "fill-color": color_fill,
              "fill-opacity": 0.1,
            }}
            layout={{
              visibility: visibility,
            }}
          />
          <Layer
            id={`geojson_demography_one_line_${idx}`}
            source={`geojson_demography_one_${idx}`}
            before={`poi_${idx}`}
            type="line"
            paint={{
              "line-color": color_line,
              "line-width": 1,
              "line-opacity": 1,
              "line-gap-width": 0,
            }}
            layout={{ visibility: visibility }}
          />
        </div>
      );
    });
    this.setState({ render_content: render_content });
  };

  render() {
    return this.state.render_content;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  sini: state.sini,
});

export default connect(mapStateToProps, {})(LAYER_SINI_DEMOGRAPHY_ONE);
