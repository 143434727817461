/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import LayerListRender from "./LayerListRender";
import ModalFolder from "../common_modal/ModalFolder";

/*REDUX FUNCTION*/
import {
  setCloseFolder,
  setHeightFolders,
} from "../../App/actions/projectActions";
import { set_value_layer } from "../../App/actions/dataActions";
import {
  set_value_properties,
  get_detail_many_layer,
  clear_layer_id,
} from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";

class LayerList extends Component {
  state = { modal_folder: false };

  on_resume_kai = (folder) => {
    this.props.clear_layer_id();

    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: true,
    });

    this.props.set_value_properties({
      key: "folder_kai_timeseries",
      value: folder,
    });

    const { geo_layer_list } = this.props.layer;
    const { geo_project } = this.props.project;

    if (folder) {
      this.props.set_value_properties({
        key: "sidebar_right_status",
        value: true,
      });

      this.props.set_value_properties({
        key: "folder_kai_timeseries",
        value: folder,
      });

      const layer_list = geo_layer_list
        .filter(
          (element) =>
            element?.folder === folder?._id &&
            !element?.geo_layer?.geojson?.features?.length
        )
        .map((item) => item?.geo_layer?._id);

      if (layer_list.length > 0) {
        const params = {
          layer_list,
          geo_project_id: geo_project._id,
          folder_id: folder._id,
        };

        this.props.get_detail_many_layer(params);
      }
    }
  };
  toggleCursorVisibility = (user, idLayer) => {
    this.setState((prevState) => ({ showCursor: !prevState.showCursor }));
  };

  setFolder = (folder) => {
    this.props.set_value_layer("folder", folder);
    this.toggleFolder();
  };

  toggleFolder = () => {
    this.setState({ modal_folder: !this.state.modal_folder });
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const isMobile = window.innerWidth <= 650;

    const { modal_folder } = this.state;

    let { loadingList, geo_layer_list, folder } = this.props.layer;

    const { loadingDetail, geo_project } = this.props.project;

    const folders = geo_project?.folders ? geo_project.folders : [];
    const folders_id = folders.map(({ _id }) => {
      return _id;
    });

    let folder_name = folders.filter((e) => e._id === folder)[0]?.name;

    if (["All", "Others"].includes(folder)) {
      folder_name = folder;
    }

    let geo_layer_list_filtered = [];

    if (folder === "All" || !folder) {
      geo_layer_list_filtered = geo_layer_list;
    } else if (folder === "Others") {
      geo_layer_list_filtered = geo_layer_list.filter(
        (l) => !folders_id.includes(l?.folder)
      );
    } else {
      geo_layer_list_filtered = geo_layer_list.filter(
        (l) => l?.folder === folder
      );
    }

    const content =
      loadingList || loadingDetail ? (
        <div id="loading" style={{ height: "auto" }}></div>
      ) : (
        <span>
          <section
            onClick={this.toggleFolder}
            className="button_very_small select_folder"
            style={{
              position: isMobile ? "relative" : "absolute",
              top: isMobile ? "0" : "-3rem",
            }}
            id="blue"
          >
            <div style={{ fontSize: "15px" }}>
              {dict["Select a folder"][language]}
            </div>
            <div
              style={{ fontSize: "10px", height: "12px", overflow: "hidden" }}
            >
              {folder_name}
            </div>
          </section>
          {geo_layer_list_filtered.length > 0 ? (
            <LayerListRender
              geo_layer_list_local={geo_layer_list_filtered}
              onToggleCursor={this.toggleCursorVisibility}
            />
          ) : (
            <div
              className="layerBoxBottom non-active"
              style={{ display: "block", marginLeft: "10px" }}
            >
              <p className="text_medium">
                {dict["No layer in this folder"][language]}
              </p>
            </div>
          )}
        </span>
      );

    const modal_folder_content = modal_folder && (
      <ModalFolder
        modalSize="small"
        id="modal"
        isOpen={modal_folder}
        onClose={this.toggleFolder}
      >
        <div className="box-body">
          <div
            className="button_block"
            id={folder === "All" ? "blue" : "almost_white"}
            style={{ textAlign: "left", marginBottom: "10px" }}
            onClick={this.setFolder.bind(this, "All")}
          >
            {dict["All"][language]}
          </div>
          {folders.map((folder_e, idx) => {
            return (
              <div
                onClick={this.setFolder.bind(this, folder_e._id)}
                key={idx}
                className="button_block"
                id={folder === folder_e._id ? "blue" : "almost_white"}
                style={{ textAlign: "left", marginBottom: "10px" }}
              >
                {folder_e.name}
                {folder_e?.folder_type === "kai_timeseries" && (
                  <button
                    className="badge_pill"
                    id="orange"
                    onClick={this.on_resume_kai.bind(this, folder_e)}
                    style={{ display: "inline-block" }}
                  >
                    Resume
                  </button>
                )}
              </div>
            );
          })}
          <div
            className="button_block"
            id={folder === "Others" ? "blue" : "almost_white"}
            style={{ textAlign: "left", marginBottom: "10px" }}
            onClick={this.setFolder.bind(this, "Others")}
          >
            {dict["Outside folders"][language]}
          </div>
        </div>
      </ModalFolder>
    );

    return (
      <div>
        {modal_folder_content}
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setCloseFolder,
  setHeightFolders,
  set_value_layer,
  set_value_properties,
  get_detail_many_layer,
  clear_layer_id,
})(LayerList);
