/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer, Marker } from 'react-map-gl'

import bbox from "@turf/bbox";
import centroid from "@turf/centroid";
import along from "@turf/along";
import calculate_length from "@turf/length";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*GENERAL*/
import generate_color_map from "../../App/validation/generate_color_map";
import generate_color_interpolate from "../../App/validation/generate_color_interpolate";

/*NON IMPORT*/
let valueColor = "#1b659d";

class BI_LAYER extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render_content: [],
    };
  }

  componentDidMount() {
    this.on_render_layer();
  }

  componentDidUpdate(prevProps) {
    if (this.props.bi.trigger_rerender !== prevProps.bi.trigger_rerender) {
      this.on_render_layer();
    }
    if (this.props.bi.trigger_fly_map !== prevProps.bi.trigger_fly_map) {
      this.on_fly_layer();
    }
  }

  on_fly_layer = () => {
    const { project_list, bi_object, project_object_selected, layer_id } =
      this.props.bi;
    const map = this?.props?.map?.map;
    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );
    const layer_view_map_list = setting_object?.layer_view_map_list || [];
    let geo_layer_list = [];
    project_list.forEach((item) => {
      let layer_list = item?.layer_list || [];
      layer_list = layer_list.filter((layer) => {
        return (
          layer?.geo_layer?.geojson?.features?.length > 0 &&
          !!layer?.geo_layer?.geojson?.features?.length &&
          layer_view_map_list.includes(layer?.geo_layer?._id)
        );
      });
      if (layer_list.length > 0) {
        geo_layer_list = [...geo_layer_list, ...layer_list];
      }
    });

    if (layer_id) {
      geo_layer_list = geo_layer_list.filter(
        (item) => item?.geo_layer?._id === layer_id
      );
    }
    let features = [];
    geo_layer_list.forEach((layer) => {
      let features_inside = layer?.geo_layer?.geojson?.features || [];
      if (features_inside.length > 0) {
        features = [...features, ...features_inside];
      }
    });
    const geojson = {
      type: "FeatureCollection",
      features: features,
    };
    const [minLng, minLat, maxLng, maxLat] = bbox(geojson);
    if (map) {
      let padding = { top: 50, bottom: 50, left: 50, right: 50 };
      map?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding,
          maxZoom: 22,
        }
      );
    }
  };

  on_render_layer = () => {
    const { project_list, bi_object, project_object_selected, layer_id } =
      this.props.bi;

    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );
    const layer_view_map_list = setting_object?.layer_view_map_list || [];
    let geo_layer_list = [];
    project_list.forEach((item) => {
      let layer_list = item?.layer_list || [];
      layer_list = layer_list.filter((layer) => {
        return (
          layer?.geo_layer?.geojson?.features?.length > 0 &&
          !!layer?.geo_layer?.geojson?.features?.length &&
          layer_view_map_list.includes(layer?.geo_layer?._id)
        );
      });
      if (layer_list.length > 0) {
        geo_layer_list = [...geo_layer_list, ...layer_list];
      }
    });

    const render_content = geo_layer_list.map((data, idx) => {
      let visibility = "visible";
      if (!layer_id) {
        visibility = "visible";
      } else {
        visibility = data?.geo_layer?._id === layer_id ? "visible" : "none";
      }

      let paint_object = {};
      let case_var = "";

      const type = data?.geo_layer?.type;
      const _id = data?.geo_layer?._id;
      const properties_raw = data?.geo_layer?.properties;
      let global_style = data?.geo_layer?.isGlobalStyle || false;
      let selected_column =
        data?.geo_layer?.valueStyleProps?.selected_column || "";
      let classify_mode = data?.geo_layer?.valueStyleProps?.classify_mode || "";
      let mode_color = data.geo_layer?.globalStyleMode || "";
      let color = data?.geo_layer?.valueStyleProps?.color || [];
      let range = data?.geo_layer?.valueStyleProps?.range || [];
      let range_filter = range?.map((d) => d?.max); // kalo ga diginiin ada 2 array {min, max}
      let circle_radius = Number(
        properties_raw?.find((d) => d?.key === "circle_radius")?.defaultValue
      );
      let line_width = Number(
        properties_raw?.find((d) => d?.key === "line_width")?.defaultValue
      );
      let opacity = Number(
        properties_raw?.find((d) => d?.key === "opacity")?.defaultValue
      );

      let outline;
      let stroke;

      //convert tipe geojson (point, line, poligon) ke tipe visual peta (circle, line, fill, symbol)
      data.type_new =
        data.geo_layer?.type_2 === "pch"
          ? "symbol"
          : data.geo_layer?.type === "Point" ||
            data.geo_layer?.type === "MultiPoint"
            ? "circle"
            : data.geo_layer?.type === "LineString" ||
              data.geo_layer?.type === "MultiLineString"
              ? "line"
              : data.geo_layer?.type === "Polygon" ||
                data.geo_layer?.type === "MultiPolygon"
                ? "fill"
                : "circle";

      //Konversi tipe layer menjadi tipe visual map
      const type_layer =
        data?.geo_layer?.type_2 === "pch"
          ? "symbol"
          : data?.geo_layer?.type === "Point" ||
            data.geo_layer?.type === "MultiPoint"
            ? "circle"
            : data.geo_layer?.type === "LineString" ||
              data.geo_layer?.type === "MultiLineString"
              ? "line"
              : data.geo_layer?.type === "Polygon" ||
                data.geo_layer?.type === "MultiPolygon"
                ? "fill"
                : "circle";

      let features = data?.geo_layer?.geojson?.features || [];
      features = features.map((f) => {
        f.properties.key = f?.key;
        return f;
      });

      if (
        data?.geo_layer?.type === "Point" ||
        data?.geo_layer?.type === "IoT"
      ) {
        features = features.filter((f) => {
          return (
            f.geometry?.coordinates?.[1] <= 90 &&
            f.geometry?.coordinates?.[1] >= -90 &&
            f.geometry?.coordinates?.[0] <= 180 &&
            f.geometry?.coordinates?.[0] >= -180
          );
        });
        data.geo_layer.geojson.features = features.map((f) => {
          f.properties.long = f.geometry?.coordinates?.[0];
          f.properties.lat = f.geometry?.coordinates?.[1];
          return f;
        });
      }
      const geojson = {
        type: "FeatureCollection",
        features: features,
      };

      //membuat paint_object sebagai parameter warna

      /*
      case-case styling:
      
      CASE 1 : dari style bawaan
      CASE 2 : dari value style solid 
      CASE 3 : dari value style text || classify by group
      CASE 4 : dari value style number || classify by number
      */

      if (["Point", "MultiPoint"].includes(type)) {
        paint_object = {
          "circle-color": "#000000",
          "circle-radius": 3,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#fff",
        };
      } else if (["LineString", "MultiLineString"].includes(type)) {
        paint_object = {
          "line-color": "rgb(150, 30, 255)",
          "line-width": 3,
        };
      } else if (["Polygon", "MultiPolygon"].includes(type)) {
        paint_object = {
          "fill-color": "rgb(30, 255, 255)",
          "fill-opacity": 0.5,
        };
      }

      if (global_style === false) {
        // CASE 1 : dari style bawaan
        case_var = 1;
        if (
          data.geo_layer?.type === "Point" ||
          data.geo_layer?.type === "MultiPoint"
        ) {
          paint_object = {
            "circle-color": ["get", "circle_color"],
            "circle-radius": circle_radius
              ? circle_radius
              : ["get", "circle_radius"],
            "circle-stroke-width": stroke
              ? stroke
              : ["get", "circle_stroke_width"],
            "circle-stroke-color": outline
              ? outline
              : ["get", "circle_stroke_color"],
          };
        } else if (
          data.geo_layer?.type === "LineString" ||
          data.geo_layer?.type === "MultiLineString"
        ) {
          paint_object = {
            "line-color": ["get", "color"],
            "line-width": line_width ? line_width : ["get", "line_width"],
            "line-opacity": opacity ? opacity : ["get", "opacity"],
            "line-gap-width": ["get", "line_gap_width"],
          };
        } else if (
          data.geo_layer?.type === "Polygon" ||
          data.geo_layer?.type === "MultiPolygon"
        ) {
          paint_object = {
            "fill-color": ["get", "color"],
            "fill-opacity": opacity ? opacity : ["get", "opacity"],
            "fill-outline-color": "#000000",
          };
        }
      } else {
        if (mode_color === "solid") {
          // CASE 2 : dari value style solid
          case_var = 2;
          if (
            data.geo_layer?.type === "Point" ||
            data.geo_layer?.type === "MultiPoint"
          ) {
            paint_object = {
              "circle-color": properties_raw[2]?.defaultValue,
              "circle-radius": circle_radius
                ? circle_radius
                : ["get", "circle_radius"],
              "circle-stroke-width": stroke
                ? stroke
                : ["get", "circle_stroke_width"],
              "circle-stroke-color": outline
                ? outline
                : ["get", "circle_stroke_color"],
            };
          } else if (
            data.geo_layer?.type === "LineString" ||
            data.geo_layer?.type === "MultiLineString"
          ) {
            paint_object = {
              "line-color": properties_raw[0]?.defaultValue,
              "line-width": line_width ? line_width : ["get", "line_width"],
              "line-opacity": opacity ? opacity : ["get", "opacity"],
              "line-gap-width": ["get", "line_gap_width"],
            };
          } else if (
            data.geo_layer?.type === "Polygon" ||
            data.geo_layer?.type === "MultiPolygon"
          ) {
            paint_object = {
              "fill-color": properties_raw[0]?.defaultValue,
              "fill-opacity": opacity ? opacity : ["get", "opacity"],
              "fill-outline-color": "#000000",
            };
          }
        } else {
          if (classify_mode === "text") {
            // CASE 3 : dari value style text || classify by group
            case_var = 3;
            // mode classify mode match by text
            valueColor = generate_color_map(range, color, selected_column);
            if (
              data.geo_layer?.type === "Point" ||
              data.geo_layer?.type === "MultiPoint"
            ) {
              paint_object = {
                "circle-color": valueColor,
                "circle-radius": circle_radius
                  ? circle_radius
                  : ["get", "circle_radius"],
                "circle-stroke-width": stroke
                  ? stroke
                  : ["get", "circle_stroke_width"],
                "circle-stroke-color": outline
                  ? outline
                  : ["get", "circle_stroke_color"],
              };
            } else if (
              data.geo_layer?.type === "LineString" ||
              data.geo_layer?.type === "MultiLineString"
            ) {
              paint_object = {
                "line-color": valueColor,
                "line-width": line_width ? line_width : ["get", "line_width"],
                "line-opacity": opacity ? opacity : ["get", "opacity"],
                "line-gap-width": ["get", "line_gap_width"],
              };
            } else if (
              data.geo_layer?.type === "Polygon" ||
              data.geo_layer?.type === "MultiPolygon"
            ) {
              paint_object = {
                "fill-color": valueColor,
                "fill-opacity": opacity ? opacity : ["get", "opacity"],
                "fill-outline-color": "#000000",
              };
            }
          } else {
            // CASE 4 : dari value style number || classify by number
            case_var = 4;
            // mode classify mode step by number
            valueColor = generate_color_interpolate(
              range_filter,
              color,
              selected_column
            );
            if (
              data.geo_layer?.type === "Point" ||
              data.geo_layer?.type === "MultiPoint"
            ) {
              paint_object = {
                "circle-color": valueColor,
                "circle-radius": circle_radius
                  ? circle_radius
                  : ["get", "circle_radius"],
                "circle-stroke-width": stroke
                  ? stroke
                  : ["get", "circle_stroke_width"],
                "circle-stroke-color": outline
                  ? outline
                  : ["get", "circle_stroke_color"],
              };
            } else if (
              data.geo_layer?.type === "LineString" ||
              data.geo_layer?.type === "MultiLineString"
            ) {
              paint_object = {
                "line-color": valueColor,
                "line-width": line_width ? line_width : ["get", "line_width"],
                "line-opacity": opacity ? opacity : ["get", "opacity"],
                "line-gap-width": ["get", "line_gap_width"],
              };
            } else if (
              data.geo_layer?.type === "Polygon" ||
              data.geo_layer?.type === "MultiPolygon"
            ) {
              paint_object = {
                "fill-color": valueColor,
                "fill-opacity": opacity ? opacity : ["get", "opacity"],
                "fill-outline-color": "#000000",
              };
            }
          }
        }
      }

      const fields = data?.geo_layer?.fields || [];

      const label_config = data?.geo_layer?.label_config || {};
      let label_content;



      if (
        (label_config?.is_use_label_text || label_config?.is_use_label_image) &&
        visibility === "visible"
      ) {
        let features_center_marker = [];

        if (
          ["Polygon", "MultiPolygon", "MultiLineString", "MultiPoint"].includes(
            type
          )
        ) {
          features_center_marker = features.map((item) => {
            let feature = {};
            const geojson = {
              type: "FeatureCollection",
              features: [item],
            };
            feature = centroid(geojson, { properties: item.properties });
            return feature;
          });
        } else if (type === "Point") {
          features_center_marker = features;
        } else if (type === "LineString") {
          features_center_marker = features.map((item) => {
            const length_km = calculate_length(item, {
              units: "kilometers",
            }).toFixed(2);
            const center_length = length_km / 2;
            let point = along(item, center_length, { units: "kilometers" });
            point.properties = item.properties;
            return point;
          });
        }
        const label_text_field_key_array =
          label_config?.label_text_field_key_array || [];
        const label_image_field_key_array =
          label_config?.label_image_field_key_array || [];
        const style_mode = label_config?.style_mode || "style_1";

        const background_color_primary =
          label_config?.background_color_primary || "#292571";
        const outline_color_primary =
          label_config?.outline_color_primary || "#000000";
        const text_color_primary =
          label_config?.text_color_primary || "#ffffff";
        const background_color_secondary =
          label_config?.background_color_secondary || "#ee6b1e";
        const outline_color_secondary =
          label_config?.outline_color_secondary || "#000000";
        const text_color_secondary =
          label_config?.text_color_secondary || "#ffffff";

        label_content = features_center_marker.map((item, idx) => {
          let label_image;
          let label_text;
          if (label_config?.is_use_label_image) {
            label_image = (
              <div>
                {label_image_field_key_array.map((field_key, idx) => {
                  let content;
                  if (item?.properties?.[field_key]) {
                    content = (
                      <div
                        key={idx}
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "5px",
                          backgroundImage: `url(${item?.properties?.[field_key]})`,
                          backgroundSize: "cover",
                        }}
                        className="margin_bottom_minor margin_right_minor"
                      />
                    );
                  } else {
                    content = <div key={idx} />;
                  }
                  return content;
                })}
              </div>
            );
          }

          if (label_config?.is_use_label_text) {
            label_text = (
              <div>
                {label_text_field_key_array.map((field_key, idx) => {
                  const field = fields.find((item) => item.key === field_key);
                  const field_type = field?.type;
                  let text = item?.properties?.[field_key];
                  if (["number"].includes(field_type)) {
                    text = new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(text);
                  } else if (field_type === "currency") {
                    text =
                      "Rp." +
                      new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(text);
                  }
                  let content;
                  if (item?.properties?.[field_key]) {
                    content = (
                      <div key={idx}>
                        <p
                          className={`margin_bottom_minor badge_pill_small text_small`}
                          style={{
                            color:
                              idx === 0
                                ? text_color_primary
                                : text_color_secondary,
                            backgroundColor:
                              idx === 0
                                ? background_color_primary
                                : background_color_secondary,
                            borderColor:
                              idx === 0
                                ? outline_color_primary
                                : outline_color_secondary,
                            borderWidth: "1px",
                            borderStyle: "solid",
                          }}
                        >
                          {text}
                        </p>
                      </div>
                    );
                  } else {
                    content = <div key={idx}></div>;
                  }
                  return content;
                })}
              </div>
            );
          }
          let label_final;
          if (style_mode === "style_1") {
            //style_1
            label_final = (
              <>
                {label_image}
                {label_text}
              </>
            );
          } else {
            //style_2

            //image
            const field_key_image_first = label_image_field_key_array[0];
            const image_first_url = item?.properties?.[field_key_image_first];
            let label_image_first;
            if (label_config?.is_use_label_image) {
              label_image_first = (
                <span
                  className="badge_circle margin_right"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "2rem",
                    backgroundImage: `url(${image_first_url})`,
                    backgroundSize: "cover",
                  }}
                />
              );
            }
            let field_key_text_first = label_text_field_key_array[0];
            const field = fields.find(
              (item) => item.key === field_key_text_first
            );
            const field_type = field?.type;
            let text_first;

            if (label_config?.is_use_label_text) {
              text_first = item?.properties?.[field_key_text_first];
              if (["number"].includes(field_type)) {
                text_first = new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(text_first);
              } else if (field_type === "currency") {
                text_first =
                  "Rp." +
                  new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(text_first);
              }
            }

            const label_text_field_key_array_wo_first = [
              ...label_text_field_key_array,
            ];
            label_text_field_key_array_wo_first.shift();
            let text_label;
            if (label_config?.is_use_label_text) {
              text_label = (
                <div>
                  {label_text_field_key_array_wo_first.map((field_key, idx) => {
                    const field = fields.find((item) => item.key === field_key);
                    const field_type = field?.type;
                    let text = item?.properties?.[field_key];
                    if (["number"].includes(field_type)) {
                      text = new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(text);
                    } else if (field_type === "currency") {
                      text =
                        "Rp." +
                        new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(text);
                    }
                    let content;
                    if (item?.properties?.[field_key]) {
                      content = (
                        <div key={idx}>
                          <p
                            className="text_small margin_bottom_minor badge_pill_small"
                            style={{
                              color: text_color_secondary,
                              backgroundColor: background_color_secondary,
                              borderColor: outline_color_secondary,
                              borderWidth: "1px",
                              borderStyle: "solid",
                            }}
                          >
                            {text}
                          </p>
                        </div>
                      );
                    } else {
                      content = <div key={idx}></div>;
                    }
                    return content;
                  })}
                </div>
              );
            }
            if (image_first_url || text_first) {
              label_final = (
                <div>
                  <div
                    className="button_pill margin_bottom_minor"
                    style={{
                      paddingRight:
                        label_image_first && text_first ? "15px" : "0px",
                      color: text_color_primary,
                      backgroundColor: background_color_primary,
                      borderColor: outline_color_primary,
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    {label_image_first}
                    {text_first}
                  </div>
                  {text_label}
                </div>
              );
            }
          }
          return (
            <Marker
              key={idx}
              longitude={item.geometry.coordinates[0]}
              latitude={item.geometry.coordinates[1]}
              draggable={false}
            >
              {label_final}
            </Marker>
          );
        });
      }

      return (
        <main key={idx} case_var={case_var}>
          <Source key={_id} id={_id} type="geojson" data={geojson} />
          <Layer
            id={_id}
            source={_id}
            type={type_layer}
            paint={paint_object}
            layout={{
              visibility: visibility,
            }}
          />
          {visibility === "visible" && label_content}
        </main>
      );
    });
    this.setState({ render_content });
  };

  render() {
    const { render_content } = this.state;
    return render_content;
  }
}
const mapStateToProps = (state) => ({
  map: state.map,
  bi: state.bi,
});

export default connect(mapStateToProps, {})(BI_LAYER);
