//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component

//Redux function

//Picture Asset
import checklist from "../../Assets/svg/checklist.svg";

//General Function
import dict from "../../Data/dict.json";

class StepPaid extends Component {
  state = {};
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const { blog } = this.props.blog;

    const { geo_layer_wo_geojson } = this.props.layer;
    const geo_layer = geo_layer_wo_geojson.geo_layer;

    let content;

    if (geo_layer?._id) {
      const paidFormStatus = geo_layer?.formStatus?.status;
      const steps = [
        {
          text: dict["Upload the banner image"][language],
          status: geo_layer?.picture_url ? (
            <img alt="checklist" src={checklist} width="20px" />
          ) : (
            <div className="button_block_tight">
              {dict["Upload banner"][language]}
            </div>
          ),
        },
        {
          text: dict[
            "Write down instructions about how to filling out the survey"
          ][language],
          status:
            blog !== null ? (
              <img alt="checklist" src={checklist} width="20px" />
            ) : (
              <div
                onClick={this.handle_create_doc}
                className="button_block_tight"
              >
                {dict["Edit Instructions"][language]}
              </div>
            ),
        },
        {
          text: dict["Fill in the apply payment form"][language],
          status:
            paidFormStatus === "not_propose" || paidFormStatus === undefined ? (
              <div
                onClick={this.props.toggle_billing_form}
                className="button_block_tight"
              >
                {dict["Apply Now"][language]}
              </div>
            ) : (
              <Link
                to={`/paid_form/${geo_layer.link}`}
                className="button_block_tight"
              >
                {dict["Paid Form Dashboard"][language]}
              </Link>
            ),
        },
      ];

      content = (
        <>
          <div>{dict["Steps to apply a paid survey"][language]}</div>
          <table className="table_paid_form">
            <tbody>
              {steps.map((step, idx) => {
                return (
                  <tr
                    key={idx}
                    style={{
                      border: "1px solid #ddd",
                      padding: "20px",
                    }}
                  >
                    <td>{idx + 1}</td>
                    <td style={{ width: "80%" }}>{step.text}</td>
                    <td style={{ textAlign: "center", width: "100%" }}>
                      {step.status}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
    }

    return content;
  }
}
const mapStateToProps = (state) => ({
  blog: state.blog,
  layer: state.layer,
});
export default connect(mapStateToProps, {})(StepPaid);
