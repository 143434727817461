/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ProgressBar from "../common_spinner/ProgressBar";

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/
import icon_expand_bottom from "../../Assets/svg/icon_expand_bottom.svg";

/*GENERAL FUNCTION & DATA*/
import sort_array from "../../App/validation/sort_array";
import colors_poi_tipe_1 from "../../Data/colors_poi_tipe_1.json";

/*NON IMPORT*/

class SINI_POI extends Component {
  state = {};

  componentDidMount() {
    const { sini_v2_list, request_id_active, ai_parent_detail_list } =
      this.props.sini;
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const poi_features = sini_object?.poi_features;
    if (poi_features !== undefined) {
      this.add_slide_in_class();
    }
  }

  componentDidUpdate(prevProps) {
    const loading_item_array_before =
      prevProps.loading_reducer.loading_item_array;
    const loading_item_array_after =
      this.props.loading_reducer.loading_item_array;
    const last_loading =
      loading_item_array_after[loading_item_array_after.length - 1];
    if (
      loading_item_array_after.length !== loading_item_array_before.length &&
      (last_loading === "poi" || last_loading === "get_ai_parent_object")
    ) {
      this.add_slide_in_class();
    }
  }

  add_slide_in_class = () => {
    const element = document.querySelector("#poi_parent");
    if (element) {
      element.classList.add("slide-in");
    }
  };

  on_set_sini_menu_active = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "poi",
    });
    this.props.set_value_sini({
      key: "sini_map_show",
      value: ["poi", "isochrone"],
    });
  };

  render() {
    //local storage
    // const language = localStorage?.language || "ina";

    //local state

    //global props
    const { sini_v2_list, request_id_active, ai_parent_detail_list } =
      this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const is_loading_poi = loading_item_array.includes("poi");
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const poi_features = sini_object?.poi_features || [];
    let poi_resume_1 = sini_object?.poi_resume_1 || [];
    poi_resume_1 = sort_array(poi_resume_1, "number", false);

    let content;
    if (is_loading_poi || poi_features.length > 0 || poi_resume_1.length > 0) {
      const max_tipe_1 = poi_resume_1?.[0]?.number;

      content = (
        <main className="container_light background_white margin_bottom">
          <div
            className="badge background_green margin_bottom margin_right"
            onClick={this.on_set_sini_menu_active}
          >
            Point of Interest
          </div>
          {is_loading_poi && (
            <div className="badge background_green margin_bottom margin_right">
              Loading...
            </div>
          )}
          <div className="badge background_green margin_bottom margin_right">
            {poi_features.length}
          </div>

          <br />
          <section style={{ maxHeight: "100px", overflowY: "auto" }}>
            {poi_resume_1.map((element, idx) => {
              const color =
                colors_poi_tipe_1?.find(
                  (color_element) => color_element?.name === element?.name
                )?.color || "#dedede";
              let formated_number;
              if (element.number) {
                formated_number = parseInt(element.number);
                formated_number = new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(formated_number);
              }

              return (
                <section
                  key={idx}
                  className="button_transparent"
                  style={{
                    paddingBottom: "10px",
                    paddingTop: "10px",
                  }}
                >
                  <div className="parent_half text_small">
                    <div className="left_half text_left full_width">
                      {element.name}
                    </div>
                    <div className="right_half text_right">
                      {formated_number}
                    </div>
                  </div>
                  <ProgressBar
                    current_number={element.number}
                    total_number={max_tipe_1}
                    color={color}
                    is_hide_text={true}
                  />
                </section>
              );
            })}
          </section>
          <br />
          <div className="text_center">
            <img
              onClick={this.on_set_sini_menu_active}
              alt="expand"
              src={icon_expand_bottom}
              width={45}
              className="cursor_pointer"
            />
          </div>
        </main>
      );
    }
    return <main id="poi_parent">{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { set_value_sini })(SINI_POI);
