/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_layer } from "../../App/actions/dataActions";
import {
  get_list_kota_by_search,
  get_list_kecamatan_by_search,
  get_list_kelurahan_by_search,
  get_list_all_provinsi,
  get_list_all_kota_by_provinsi,
  get_list_all_kecamatan_by_kota,
  get_list_all_kelurahan_by_kecamatan,
} from "../../App/actions/insightActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/
const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class InputAreaSingle extends Component {
  state = {
    text_input: "",
    typing_status: false,
    kota_list_filtered: [],
    kecamatan_list_filtered: [],
    kelurahan_list_filtered: [],
    level: "",
  };

  on_click_adm = (object_adm, level) => {
    this.setState({ typing_status: false, level });

    const PROVINSI = object_adm?.["PROVINSI"];
    const KABKOT = object_adm?.["KABUPATEN ATAU KOTA"];
    const KECAMATAN = object_adm?.["KECAMATAN"];
    const DESA = object_adm?.["DESA ATAU KELURAHAN"];

    if (level === "provinsi") {
      this.props.set_value_layer("provinsi", PROVINSI);
      this.props.set_value_layer("kota", "");
      this.props.set_value_layer("kecamatan", "");
      this.props.set_value_layer("kelurahan", "");
      this.props.get_list_all_kota_by_provinsi({
        provinsi: PROVINSI,
      });
    } else if (level === "kota") {
      this.props.set_value_layer("provinsi", PROVINSI);
      this.props.set_value_layer("kota", KABKOT);
      this.props.set_value_layer("kecamatan", "");
      this.props.set_value_layer("kelurahan", "");
      this.props.get_list_all_kota_by_provinsi({
        provinsi: PROVINSI,
      });
      this.props.get_list_all_kecamatan_by_kota({
        provinsi: PROVINSI,
        kota: KABKOT,
      });
    } else if (level === "kecamatan") {
      this.props.set_value_layer("provinsi", PROVINSI);
      this.props.set_value_layer("kota", KABKOT);
      this.props.set_value_layer("kecamatan", KECAMATAN);
      this.props.set_value_layer("kelurahan", "");
      this.props.get_list_all_kota_by_provinsi({
        provinsi: PROVINSI,
      });
      this.props.get_list_all_kecamatan_by_kota({
        provinsi: PROVINSI,
        kota: KABKOT,
      });
      this.props.get_list_all_kelurahan_by_kecamatan({
        provinsi: PROVINSI,
        kota: KABKOT,
        kecamatan: KECAMATAN,
      });
    } else if (level === "kelurahan") {
      this.props.set_value_layer("provinsi", PROVINSI);
      this.props.set_value_layer("kota", KABKOT);
      this.props.set_value_layer("kecamatan", KECAMATAN);
      this.props.set_value_layer("kelurahan", DESA);
      this.props.get_list_all_kota_by_provinsi({
        provinsi: PROVINSI,
      });
      this.props.get_list_all_kecamatan_by_kota({
        provinsi: PROVINSI,
        kota: KABKOT,
      });
      this.props.get_list_all_kelurahan_by_kecamatan({
        provinsi: PROVINSI,
        kota: KABKOT,
        kecamatan: KECAMATAN,
      });
    }
  };

  on_change_single_input(e) {
    e.preventDefault();
    clearTimeout(this.timer);
    this.setState({
      [e.target.name]: e.target.value,
      typing_status: true,
    });
    if (!e.target.value) {
      this.setState({
        kota_list_filtered: [],
        kecamatan_list_filtered: [],
        kelurahan_list_filtered: [],
        typing_status: false,
      });
    }
    this.timer = setTimeout(this.trigger_action.bind(this), WAIT_INTERVAL);
  }

  handle_key_down(e) {
    if (e.keyCode === ENTER_KEY) {
      this.trigger_action.bind(this);
    }
  }

  trigger_action() {
    try {
      const { text_input } = this.state;
      if (text_input.length > 3) {
        const params = {
          search: text_input,
        };
        this.props.get_list_kota_by_search(params);
        this.props.get_list_kecamatan_by_search(params);
        this.props.get_list_kelurahan_by_search(params);
      }

      /*
      const regex_string = `${text_input
        ?.toUpperCase()
        ?.split(" ")
        ?.map((word) => `(?=.*?${word})`)
        ?.join("")}.*`;
      const regex_pattern = new RegExp(regex_string);
      */

      // if (text_input.length > 2) {
      //   const kota_list_filtered = kota_list.filter((item) =>
      //     regex_pattern.test(item.KABKOT?.toUpperCase())
      //   );
      //   const kecamatan_list_filtered = kecamatan_list.filter((item) =>
      //     regex_pattern.test(item.KECAMATAN?.toUpperCase())
      //   );
      //   const kelurahan_list_filtered = kelurahan_list.filter((item) =>
      //     regex_pattern.test(item.DESA?.toUpperCase())
      //   );
      //   this.setState({
      //     kota_list_filtered,
      //     kecamatan_list_filtered,
      //     kelurahan_list_filtered,
      //   });
      // }
    } catch (error) {}
  }

  render() {
    //local storage

    //local state
    const { level, text_input, typing_status } = this.state;

    //global props
    const { provinsi, kota, kecamatan, kelurahan } = this.props.map;
    const {
      list_kota_by_search,
      list_kecamatan_by_search,
      list_kelurahan_by_search,
    } = this.props.properties;

    //content
    return (
      <main>
        <p className="text_bold">Cari wilayah administrasi</p>
        <input
          type="text"
          placeholder="Misal: Bandung, Tebet, Sukamenak"
          autoComplete="off"
          name="text_input"
          value={text_input}
          onChange={this.on_change_single_input.bind(this)}
          onKeyDown={this.handle_key_down.bind(this)}
        />
        {typing_status && (
          <section
            className="container background_white"
            style={{ overflowY: "scroll", maxHeight: "300px" }}
          >
            {list_kota_by_search.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="cursor_pointer"
                  onClick={this.on_click_adm.bind(this, item, "kota")}
                >
                  {item?.["KABUPATEN ATAU KOTA"]}{" "}
                  <span className="badge_small background_teal">KOTA</span>
                  <hr />
                </div>
              );
            })}
            {list_kecamatan_by_search.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="cursor_pointer"
                  onClick={this.on_click_adm.bind(this, item, "kecamatan")}
                >
                  {item?.["KECAMATAN"]}, {item?.["KABUPATEN ATAU KOTA"]}{" "}
                  <span className="badge_small background_teal">KEC</span>
                  <hr />
                </div>
              );
            })}
            {list_kelurahan_by_search.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="cursor_pointer"
                  onClick={this.on_click_adm.bind(this, item, "kelurahan")}
                >
                  {item?.["DESA ATAU KELURAHAN"]}, {item?.["KECAMATAN"]},{" "}
                  {item?.["KABUPATEN ATAU KOTA"]}{" "}
                  <span className="badge_small background_teal">DESA</span>{" "}
                  <hr />
                </div>
              );
            })}
          </section>
        )}
        {level && (
          <section className="container background_white margin_top">
            {level === "provinsi" ? (
              <>{provinsi}</>
            ) : level === "kota" ? (
              <>
                {kota}, {provinsi}
              </>
            ) : level === "kecamatan" ? (
              <>
                {kecamatan}, {kota}, {provinsi}
              </>
            ) : level === "kelurahan" ? (
              <>
                {kelurahan}, {kecamatan}, {kota}, {provinsi}
              </>
            ) : (
              <></>
            )}
          </section>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_layer,
  get_list_kota_by_search,
  get_list_kecamatan_by_search,
  get_list_kelurahan_by_search,
  get_list_all_provinsi,
  get_list_all_kota_by_provinsi,
  get_list_all_kecamatan_by_kota,
  get_list_all_kelurahan_by_kecamatan,
})(InputAreaSingle);
