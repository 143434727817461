import React from "react";
import { useDispatch, useSelector } from "react-redux";

/*REDUX FUNCTION*/
import { set_value_layer } from "../../App/actions/dataActions";
import Legend from "./Legend";

/* ASSETS */
import icon_legend from "../../Assets/svg/icon_legends.svg";
import useLanguage from "../common_hooks/useLanguage";

function LegendButton() {
  const { language, dict } = useLanguage();
  const dispatch = useDispatch();
  const { _map } = useSelector((state) => ({
    _map: state.map,
  }));

  const toggle_legend = (e) => {
    const { legend_status } = _map;
    dispatch(set_value_layer("legend_status", !legend_status));
  };

  //content

  const button_content = (
    <main
      className="text_right"
      title={dict["Legend"][language]}
      onClick={toggle_legend}
    >
      <div
        className="rounded_5 center_perfect"
        id="blue_outline"
        style={{ height: "20px", width: "20px", boxSizing: "border-box" }}
      >
        <img src={icon_legend} alt={dict["Legend"][language]} />
      </div>
    </main>
  );

  const final_content = <section>{button_content}</section>;

  return (
    <>
      {final_content}
      <Legend />
    </>
  );
}

export default LegendButton;
