//import
import axios from "axios";
import along from "@turf/along";
import calculate_length from "@turf/length";
import calculate_area from "@turf/area";
import convex from "@turf/convex";
import {
  geoServerBaseUrl,
  // alphaServerUrl
} from "../actions/baseUrl";

//constant
const SERVER_URL = geoServerBaseUrl;
// const ALPHA_URL = alphaServerUrl;

const flood_propagation_v3 =
  ({
    longitude,
    latitude,
    genangan_cm,
    feature_key,
    features_point_flood,
    index_iteration,
    index_max,
    genangan_absolute_m,
    radius_start,
    delta_sample_perimeter_m,
    delta_sample_final_m,
    radius_start_propagation,
  }) =>
  async (dispatch) => {
    try {
      if (features_point_flood.length > 2 && !!features_point_flood.length) {
        //STEP 1: generate poligon area convex
        const geojson_polygon = {
          type: "FeatureCollection",
          features: features_point_flood,
        };
        const feature_polygon = convex(geojson_polygon, { concavity: 1 });

        if (feature_polygon) {
          //STEP 2: generate line perimeter
          const feature_line = {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: feature_polygon?.geometry?.coordinates?.[0],
            },
          };
          const length_km = parseFloat(
            calculate_length(feature_line, {
              units: "kilometers",
            })
          );
          const delta_km = delta_sample_perimeter_m / 1000;
          let unit = Math.floor(length_km / delta_km); //jumlah sample di perimeter
          if (unit < 2) {
            unit = 2;
          }
          let array_along = [];
          for (let index = 0; index < unit; index++) {
            const value = parseFloat(delta_km * index);
            array_along.push(value);
          }
          let coordinates_body = [];
          array_along.forEach((item) => {
            const options = { units: "kilometers" };
            let point = along(feature_line, item, options);
            const longitude_local = point.geometry.coordinates[0];
            const latitude_local = point.geometry.coordinates[1];
            coordinates_body.push([longitude_local, latitude_local]);
          });

          const body = {
            coordinates_body,
            genangan_absolute_m,
            radius_start_propagation,
            index_iteration,
          };
          const res = await axios.post(
            SERVER_URL + `/algoritma_cilicis/generate_propagation_by_buffer`,
            body
          );

          const features_point = res.data;

          //pre data
          index_iteration = index_iteration + 1;
          dispatch({
            type: "set_value_properties",
            payload: {
              key: "current_progress_genangan_global",
              value: index_iteration,
            },
          });
          const raw_date = new Date(Date.now());
          let date = String(raw_date.getDate());
          let month = raw_date.getMonth();
          month = month + 1;
          month = String(month);
          if (month.length === 1) {
            month = `0${month}`;
          }
          if (date.length === 1) {
            date = `0${date}`;
          }
          const year = raw_date.getFullYear();
          const date_run = `${year}-${month}-${date}`; //Format tanggal: 2024-01-31
          let hour = String(raw_date.getHours());
          let minute = String(raw_date.getMinutes());
          if (hour.length === 1) {
            hour = `0${hour}`;
          }
          if (minute.length === 1) {
            minute = `0${minute}`;
          }
          const time_run = `${hour}:${minute}`; //Format waktu: 21:22

          //data
          const geojson_final = {
            type: "FeatureCollection",
            features: features_point,
          };
          const feature_convex = convex(geojson_final, {
            concavity: 1,
          });
          const geojson_polygon_convex = {
            type: "FeatureCollection",
            features: [feature_convex],
          };
          let feature_polygon_convex = geojson_polygon_convex.features[0];

          let coordinates_final = [];
          const feature_line_final = {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: feature_polygon_convex?.geometry?.coordinates?.[0],
            },
          };
          const length_km_final = parseFloat(
            calculate_length(feature_line_final, {
              units: "kilometers",
            })
          );
          const delta_km_final = delta_sample_final_m / 1000;
          let unit_final = Math.floor(length_km_final / delta_km); //jumlah sample di perimeter
          if (unit_final < 4) {
            unit_final = 4;
          }
          let array_along_final = [];
          for (let index = 0; index < unit_final; index++) {
            const value = parseFloat(delta_km_final * index);
            array_along_final.push(value);
          }

          array_along_final.forEach((item) => {
            const options = { units: "kilometers" };
            let point = along(feature_line, item, options);
            const longitude_local = point.geometry.coordinates[0];
            const latitude_local = point.geometry.coordinates[1];
            coordinates_final.push([longitude_local, latitude_local]);
          });

          coordinates_final.push([
            coordinates_final[0][0],
            coordinates_final[0][1],
          ]);

          let feature_polygon_final = {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [coordinates_final],
            },
          };

          const area_meter_square_final = parseFloat(
            calculate_area(feature_polygon_final).toFixed(0)
          );
          const area_hectare_final = parseFloat(
            (area_meter_square_final / 10_000).toFixed(4)
          );
          feature_polygon_final.properties = {
            date_run,
            time_run,
            longitude,
            latitude,
            area_meter_square: area_meter_square_final,
            area_hectare: area_hectare_final,
            genangan_cm,
            feature_key,
            index_iteration,
            index_max,
            genangan_absolute_m,
            radius_start,
            delta_sample_perimeter_m,
            delta_sample_final_m,
            color: "#ff0000",
          };
          feature_polygon_final.feature_key = feature_key;

          if (index_iteration < index_max) {
            //generate poligon baru, masukkan ke value sebelumnya dengan fungsi edit_value_sini
            dispatch({
              type: "edit_value_sini",
              payload: {
                key: "features_genangan",
                value: feature_polygon_final,
                id_key: "feature_key",
                id_value: feature_key,
              },
            });
            dispatch({
              type: "state_update",
            });
            //belum selesai iterasi, panggil lagi rekursif ke fungsi ini dengan parameter titik-titik terbaru
            dispatch(
              flood_propagation_v3({
                longitude,
                latitude,
                genangan_cm,
                feature_key,
                features_point_flood: features_point,
                index_iteration,
                index_max,
                genangan_absolute_m,
                radius_start,
                delta_sample_perimeter_m,
                delta_sample_final_m,
                radius_start_propagation,
              })
            );
          } else {
            //sudah selesai, simpan poligon ke db dan edit_value_sini untuk memperbarui reducer

            const geojson_final_to_save = {
              type: "FeatureCollection",
              features: [feature_polygon_final],
            };
            feature_polygon_final = convex(geojson_final_to_save, {
              concavity: 1,
            });

            feature_polygon_final.properties = {
              date_run,
              time_run,
              longitude,
              latitude,
              area_meter_square: area_meter_square_final,
              area_hectare: area_hectare_final,
              genangan_cm,
              feature_key,
              index_iteration,
              index_max,
              genangan_absolute_m,
              radius_start,
              delta_sample_perimeter_m,
              delta_sample_final_m,
              color: "#ff0000",
            };
            feature_polygon_final.feature_key = feature_key;

            const body = {
              geo_layer_id: "65b810d00ffdc02d99fd495d",
              feature: feature_polygon_final,
            };

            const config = {
              headers: {
                accesstoken: localStorage.token_mapid,
              },
            };
            await axios.post(
              SERVER_URL + "/layers/v2/push_feature",
              body,
              config
            );
            dispatch({
              type: "edit_value_sini",
              payload: {
                key: "features_genangan",
                value: feature_polygon_final,
                id_key: "feature_key",
                id_value: feature_key,
              },
            });
            dispatch({
              type: "state_update",
            });
            dispatch({
              type: "set_value_properties",
              payload: {
                key: "success_status",
                value: true,
              },
            });
            dispatch({
              type: "set_value_properties",
              payload: {
                key: "loading_status",
                value: false,
              },
            });
          }
        }
      }
    } catch (error) {
      console.log("error main");
      console.log(error);
    }
  };

export default flood_propagation_v3;
