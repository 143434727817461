const initial_state = {
  //modal
  modal_ai_page: false,
  modal_error_limitation: false,
  modal_error_bill_ai: false,
  modal_sini_ai_trial: false,
  //basic
  is_sini_visible: false,
  error_limitation: {},
  is_show_download_button: false,
  loading_item: "",
  loading_status: false,
  state_update: 0,
  fly_update: 0,
  fly_init_update: 0,
  fly_init_marker: 0,
  progress_poi_total: 1,
  progress_poi_current: 0,
  request_id_active: "",
  request_id_loading: "",
  sini_choose_map_active: false,
  sini_marker_active: {},
  geojson_draw: { type: "FeatureCollection", features: [] },
  mode_draw: "simple_select",
  current_loading: "",
  sini_menu_active: "",
  user_message: "",
  rows_text_area: 1,
  max_rows_text_area: 7,
  is_stop_zoom: false,
  sini_map_show: [
    "poi",
    "isochrone",
  ] /*demography_one, landvalue, landzone, flood, landslide, tsunami, demography_neighbor, landvalue_neighbor, landzone_neighbor, flood_neighbor, landslide_neighbor, tsunami_neighbor, poi, isochrone*/,
  sini_polygon_fill_opacity: 0.5,
  sini_polygon_line_width: 1,
  sini_show_polygon_label: false,
  sini_use_label_color: false,
  sini_show_poi_marker: false,
  sini_circle_radius: 2,
  sini_logo_size: 20,

  sini_data_mode: "sini_general", //sini_general || sini_rdtr
  admin_information: {},
  //kalkulator rdtr
  rdtr_calc_mode: "one_mode", // "" || "one_mode" || "neighbor_mode"
  rdtr_input_area_m2: 1000,
  rdtr_input_OBJECTID: "",
  rdtr_input_LUASHA: 0,
  rdtr_input_long: 0,
  rdtr_input_lat: 0,
  rdtr_input_NAMOBJ: 0,
  rdtr_input_KDB: 0,
  rdtr_input_KLB: 0,
  rdtr_input_KDH: 0,
  rdtr_area_changes: false,
  rdtr_result_polygon_area: 0,
  rdtr_result_LDB: 0,
  rdtr_result_LLB: 0,
  rdtr_result_JLB: 0,
  rdtr_result_LRH: 0,
  //insight & sini
  features_demografi: [],
  features_poi: [],
  sini_object: {},
  //list ai & sini
  sini_v2_list: [],
  ai_parent_list: [],
  ai_parent_detail_list: [], //masukan detail di sini,nantinya ai_child_list tidak dipakai, logic ketika sudah ada dan tidak ada dihandle difungsi di bawah
  ai_parent_child_list: [], //masukan ai_child_list di sini,
  //spatial_ai
  geojson_spatial_ai: {
    type: "FeatureCollection",
    features: [],
  },
  message_spatial_ai: "",
  //params_genangan untuk init genangan
  feature_key_params_genangan: "",
  latitude_params_genangan: 0,
  longitude_params_genangan: 0,
  genangan_cm_params_genangan: 0,
  //view genangan
  features_genangan: [],
  features_point_genangan: [],
  features_genangan_feature_key: [],
  feature_key_loading_genangan: "",
  feature_key_fly_genangan: "",
  mode_genangan: "", //db || first_run || overwrite
  overwrite_longitude: 0,
  overwrite_latitude: 0,
  overwrite_genangan_cm: 0,
  overwrite_feature_key: "",
};

export default function dataReducer(state = initial_state, action) {
  switch (action.type) {
    case "set_value_sini":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case "push_value_sini":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          ...action.payload.value,
        ],
      };

    case "edit_inside_array_sini":
      return {
        ...state,
        ...edit_inside_array_sini({
          list: state[action.payload.key],
          ...action.payload,
        }),
      };

    case "push_inside_array_sini":
      return {
        ...state,
        ...push_inside_array_sini({
          list: state[action.payload.key],
          ...action.payload,
        }),
      };

    case "pull_value_sini_string":
      return {
        ...state,
        ...pull_value_sini_string(
          action.payload.key,
          state[action.payload.key],
          action.payload.value
        ),
      };

    case "pull_value_sini_object":
      return {
        ...state,
        ...pull_value_sini_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.value,
          action.payload.id
        ),
      };

    case "edit_value_sini":
      return {
        ...state,
        ...edit_value_sini(
          action.payload.key,
          state[action.payload.key],
          action.payload.value,
          action.payload.id_key,
          action.payload.id_value
        ),
      };

    case "state_update":
      return {
        ...state,
        state_update: state.state_update + 1,
      };

    case "fly_update":
      return {
        ...state,
        fly_update: state.fly_update + 1,
      };

    case "fly_init_update":
      return {
        ...state,
        fly_init_update: state.fly_init_update + 1,
      };

    case "fly_init_marker":
      return {
        ...state,
        fly_init_marker: state.fly_init_marker + 1,
      };

    case "set_loading_sini":
      return {
        ...state,
        loading_item: action.payload,
        loading_status: true,
      };

    case "clear_loading_sini":
      return {
        ...state,
        loading_item: "",
        loading_status: false,
      };

    default:
      return state;
  }
}

const pull_value_sini_string = (key, list, value) => {
  list = list.filter((item) => item !== value);
  return { [key]: list };
};

const pull_value_sini_object = (key, list, value, id) => {
  list = list.filter((item) => item[id] !== value);
  return { [key]: list };
};

const edit_value_sini = (key, list, value, id_key, id_value) => {
  const index = list.findIndex((item) => item[id_key] === id_value);
  list[index] = value;
  return { [key]: list };
};

const edit_inside_array_sini = ({
  key,
  list,
  id_key_parent,
  id_value_parent,
  id_key_child,
  value_child,
}) => {
  if (id_key_child) {
    /*
  key: "sini_v2_list",
  list: sini_v2_list,
  id_key_parent: "request_id",
  id_value_parent: request_id,
  id_key_child: "demography",
  value_child: demography
  */
    const index = list.findIndex(
      (item) => item[id_key_parent] === id_value_parent
    );
    list[index][id_key_child] = value_child;
  } else {
    /*
  key: "sini_v2_list",
  list: sini_v2_list,
  id_key_parent: "request_id",
  id_value_parent: request_id,
  id_key_child: "",
  value_child: demography

  jika tidak disematkan id_key_child, langsung masukan value_child ke dalam satu object
  */
    const index = list.findIndex(
      (item) => item[id_key_parent] === id_value_parent
    );
    if (index > -1) {
      list[index] = { ...list[index], ...value_child };
    } else {
      list.push(value_child);
    }
  }

  return { [key]: list };
};

const push_inside_array_sini = ({
  key,
  list,
  id_key_parent,
  id_value_parent,
  id_key_child,
  id_key_child_properties,
  value_array,
  value_object,
}) => {
  /*
  jika ada id_key_child_properties

  key: "sini_v2_list",
  list: sini_v2_list,
  id_key_parent: "request_id",
  id_value_parent: request_id,
  id_key_child: "poi",
  id_key_child_properties: "poi_features",
  value_array: [...]
  */

  if (id_key_child_properties) {
    const index = list.findIndex(
      (item) => item[id_key_parent] === id_value_parent
    );
    let child = list?.[index]?.[id_key_child] || {};
    let list_child = child?.[id_key_child_properties] || [];
    list_child = [...list_child, ...value_array];
    child[id_key_child_properties] = list_child;
    list[index][id_key_child] = child;
  } else {
    /*
    jika tidak ada id_key_child_properties
  
    key: "sini_v2_list",
    list: sini_v2_list,
    id_key_parent: "request_id",
    id_value_parent: request_id,
    id_key_child: "poi_features",
    id_key_child_properties: "",
    value_array: [...]
    */
    const index = list.findIndex(
      (item) => item[id_key_parent] === id_value_parent
    );
    if (index > -1) {
      let value_child = list?.[index]?.[id_key_child] || [];
      value_child = [...value_child, ...value_array];
      list[index][id_key_child] = value_child;
    } else {
      console.log("belum ada");
      list.push(value_object);
    }
  }
  return { [key]: list };
};
