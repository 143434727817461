/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import { generate_paint } from "./functions/generate_paint";
import { extract_value_array } from "./functions/extract_value_array";
// import dict from "../../Data/dict.json";

/*NON IMPORT*/

class RANGE_TEXT extends Component {
  state = {};

  on_move_up = (item) => {
    const { paint_object_edited, style_parameter } = this.props.properties;
    const { color_logic, style_type } = style_parameter;
    if (color_logic === "by_text") {
      let color_key;
      if (style_type === "circle") {
        color_key = "circle-color";
      } else if (style_type === "line") {
        color_key = "line-color";
      } else if (style_type === "fill") {
        color_key = "fill-color";
      }
      const array = paint_object_edited?.[color_key] || [];
      const index = array.indexOf(item);
      // Ensure it's a valid index, is a text element, and is not the first element
      if (index > 2 && index % 2 === 0) {
        const temp = array[index - 2];
        array[index - 2] = array[index];
        array[index] = temp;
        paint_object_edited[color_key] = array;
        const value_array = extract_value_array(
          paint_object_edited,
          style_type
        );
        const style_parameter_new = {
          ...style_parameter,
          paint_object_edited,
          value_array,
        };
        const paint_object_new = generate_paint(style_parameter_new);
        this.props.set_value_properties({
          key: "paint_object_edited",
          value: paint_object_new,
        });
        this.props.set_value_properties({
          key: "style_parameter",
          value: style_parameter_new,
        });
        this.props.status_action();
      }
    }
  };

  on_move_down = (item) => {
    const { paint_object_edited, style_parameter } = this.props.properties;
    const { color_logic, style_type } = style_parameter;
    if (color_logic === "by_text") {
      let color_key;
      if (style_type === "circle") {
        color_key = "circle-color";
      } else if (style_type === "line") {
        color_key = "line-color";
      } else if (style_type === "fill") {
        color_key = "fill-color";
      }
      const array = paint_object_edited?.[color_key] || [];
      const index = array.indexOf(item);
      // Ensure it's a valid index, is a text element, and is not the last element before the default color
      if (index >= 2 && index % 2 === 0 && index < array.length - 3) {
        const temp = array[index + 2];
        array[index + 2] = array[index];
        array[index] = temp;
        paint_object_edited[color_key] = array;
        const value_array = extract_value_array(
          paint_object_edited,
          style_type
        );
        const style_parameter_new = {
          ...style_parameter,
          value_array,
          paint_object_edited,
        };
        const paint_object_new = generate_paint(style_parameter_new);
        this.props.set_value_properties({
          key: "paint_object_edited",
          value: paint_object_new,
        });
        this.props.set_value_properties({
          key: "style_parameter",
          value: style_parameter_new,
        });
        this.props.status_action();
      }
    }
  };

  render() {
    //local storage
    // const language = localStorage?.language || "ina";

    //local state

    //global props
    const { style_parameter, paint_object_edited } = this.props.properties;

    //content

    const { style_type } = style_parameter;
    let color_key;
    if (style_type === "circle") {
      color_key = "circle-color";
    } else if (style_type === "line") {
      color_key = "line-color";
    } else if (style_type === "fill") {
      color_key = "fill-color";
    }

    let array = paint_object_edited?.[color_key] || [];
    const content = (
      <main style={{ maxHeight: "250px", overflowY: "scroll" }}>
        <table className="table full_width">
          <tbody>
            {array.map((item, idx) => {
              // Skip the first two elements (["get", field_key])
              if (idx < 2) return null;
              const is_first = idx === 2; // First valid text element position
              const is_last = idx >= array.length - 4; // Last valid text element position
              if (idx % 2 === 0 && idx < array.length - 1) {
                return (
                  <tr key={idx}>
                    <td
                      style={{ width: "25px", verticalAlign: "middle" }}
                      className="text_center"
                    >
                      <div>
                        {!is_first && (
                          <button
                            className={`button_icon background_black ${
                              !is_last && "margin_bottom"
                            }`}
                            onClick={this.on_move_up.bind(this, item)}
                          >
                            ↑
                          </button>
                        )}
                        {!is_last && (
                          <button
                            className="button_icon background_black"
                            onClick={this.on_move_down.bind(this, item)}
                          >
                            ↓
                          </button>
                        )}
                      </div>
                    </td>
                    <td>{item}</td>
                    <td style={{ width: "20px", verticalAlign: "middle" }}>
                      <div
                        style={{
                          backgroundColor: array[idx + 1],
                          width: "20px",
                          height: "20px",
                          border: "1px solid black",
                        }}
                      ></div>
                    </td>
                  </tr>
                );
              } else {
                return null;
              }
            })}
          </tbody>
        </table>
      </main>
    );
    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
})(RANGE_TEXT);
