/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import SINI_POLYGON_CONFIG from "./SINI_POLYGON_CONFIG";
// import SINI_DOWNLOAD_UNIVERSAL from "./SINI_DOWNLOAD_UNIVERSAL";

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/
import ICON_BACK from "../../Assets/jsx/ICON_BACK";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import colors_flood from "../../Data/colors_flood.json";

/*NON IMPORT*/

class SINI_DISASTER_RISK_FULL extends Component {
  state = {
    anchor: null,
    modal_layer_config: false,
    modal_download: false,
    download_item: "",
  };

  toggle_download = (e) => {
    const name = e?.target?.name;
    this.setState({ modal_download: !this.state.modal_download });
    if (name) {
      this.setState({
        download_item: name,
      });
    }
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  toggle_layer_config = (e) => {
    this.setState({ modal_layer_config: !this.state.modal_layer_config });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  toggle_sini_map_show = (item) => {
    let { sini_map_show } = this.props.sini;
    if (sini_map_show.includes(item)) {
      sini_map_show = sini_map_show.filter((element) => element !== item);
    } else {
      sini_map_show.push(item);
    }
    this.props.set_value_sini({
      key: "sini_map_show",
      value: sini_map_show,
    });
  };

  on_set_sini_menu_active = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "",
    });
    this.props.set_value_sini({
      key: "sini_map_show",
      value: ["poi", "isochrone"],
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_layer_config, anchor } = this.state;

    //global props
    const {
      sini_v2_list,
      request_id_active,
      ai_parent_detail_list,
      sini_map_show,
      is_show_download_button,
    } = this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const is_loading_flood = loading_item_array.includes("flood");
    const is_loading_landslide = loading_item_array.includes("landslide");
    const is_loading_tsunami = loading_item_array.includes("tsunami");

    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );

    const flood_one = sini_object?.flood_one;
    const landslide_one = sini_object?.landslide_one;
    const tsunami_one = sini_object?.tsunami_one;

    const flood_neighbor = sini_object?.flood_neighbor;
    const landslide_neighbor = sini_object?.landslide_neighbor;
    const tsunami_neighbor = sini_object?.tsunami_neighbor;

    let content;
    let final_content;

    if (
      is_loading_flood ||
      is_loading_landslide ||
      is_loading_tsunami ||
      flood_one ||
      landslide_one ||
      tsunami_one ||
      flood_neighbor ||
      landslide_neighbor ||
      tsunami_neighbor
    ) {
      let flood_content;
      if (is_loading_flood) {
        flood_content = (
          <>
            <p className="text_small">
              {dict?.["Loading data"]?.[language]}...
            </p>
            <br />
          </>
        );
      } else if (
        flood_one?.properties?.["Kelas"] ||
        flood_neighbor?.length > 0
      ) {
        flood_content = (
          <main>
            <table>
              <tbody>
                <tr>
                  <td>
                    <button
                      className={
                        sini_map_show.includes("flood")
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      onClick={this.toggle_sini_map_show.bind(this, "flood")}
                    />
                  </td>
                  <td>
                    <p className="text_small">Risiko banjir</p>
                    <p className="text_bold">
                      {flood_one?.properties?.["Kelas"] || "Tidak ada data"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className={
                        sini_map_show.includes("flood_neighbor")
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      onClick={this.toggle_sini_map_show.bind(
                        this,
                        "flood_neighbor"
                      )}
                    />
                  </td>
                  <td>
                    <p className="text_small margin_right">
                      Risiko banjir sekitar
                    </p>
                    <p className="text_bold">
                      {flood_neighbor?.length || 0} area
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            {is_show_download_button && (
              <button
                className="badge background_green margin_bottom"
                name="flood_one"
                onClick={this.toggle_download}
              >
                {dict?.["Download"]?.[language]}
              </button>
            )}
          </main>
        );
      } else {
        flood_content = (
          <p className="text_small">Tidak ada data kerawanan banjir</p>
        );
      }
      let landslide_content;
      if (is_loading_landslide) {
        landslide_content = (
          <>
            <p className="text_small">Memuat data kerawanan longsor...</p>
            <br />
          </>
        );
      } else if (is_loading_flood) {
        landslide_content = <></>;
      } else if (
        landslide_one?.properties?.["Kelas"] ||
        landslide_neighbor?.length > 0
      ) {
        landslide_content = (
          <main>
            <table>
              <tbody>
                <tr>
                  <td>
                    <button
                      className={
                        sini_map_show.includes("landslide")
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      onClick={this.toggle_sini_map_show.bind(
                        this,
                        "landslide"
                      )}
                    />
                  </td>
                  <td>
                    <p className="text_small">Risiko longsor</p>
                    <p className="text_bold">
                      {landslide_one?.properties?.["Kelas"] || "Tidak ada data"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className={
                        sini_map_show.includes("landslide_neighbor")
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      onClick={this.toggle_sini_map_show.bind(
                        this,
                        "landslide_neighbor"
                      )}
                    />
                  </td>
                  <td>
                    <p className="text_small margin_right">
                      Risiko longsor sekitar
                    </p>
                    <p className="text_bold">
                      {landslide_neighbor?.length || 0} area
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </main>
        );
      } else {
        landslide_content = (
          <p className="text_small">Tidak ada kerawanan longsor</p>
        );
      }

      let tsunami_content;
      if (is_loading_tsunami) {
        tsunami_content = (
          <>
            <p className="text_small">Memuat data kerawanan tsunami...</p>
            <br />
          </>
        );
      } else if (is_loading_landslide) {
        tsunami_content = <></>;
      } else if (
        tsunami_one?.properties?.["Kelas"] ||
        tsunami_neighbor?.length > 0
      ) {
        tsunami_content = (
          <table>
            <tbody>
              <tr>
                <td>
                  <button
                    className={
                      sini_map_show.includes("tsunami")
                        ? "checklist_active"
                        : "checklist_not"
                    }
                    onClick={this.toggle_sini_map_show.bind(this, "tsunami")}
                  />
                </td>
                <td>
                  <p className="text_small">Risiko tsunami</p>
                  <p className="text_bold">
                    {tsunami_one?.properties?.["Kelas"] || "Tidak ada data"}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <button
                    className={
                      sini_map_show.includes("tsunami_neighbor")
                        ? "checklist_active"
                        : "checklist_not"
                    }
                    onClick={this.toggle_sini_map_show.bind(
                      this,
                      "tsunami_neighbor"
                    )}
                  />
                  <button />
                </td>
                <td>
                  <p className="text_small margin_right">
                    Risiko tsunami sekitar
                  </p>
                  <p className="text_bold">
                    {tsunami_neighbor?.length || 0} area
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        );
      } else {
        tsunami_content = (
          <p className="text_small">Tidak ada data kerawanan tsunami</p>
        );
      }

      if (
        !flood_one?.properties?.["Kelas"] &&
        !landslide_one?.properties?.["Kelas"] &&
        !tsunami_one?.properties?.["Kelas"] &&
        flood_one !== undefined &&
        landslide_one !== undefined &&
        tsunami_one !== undefined
      ) {
        content = (
          <p className="text_small">
            Tidak ada data kerawanan bencana di titik ini.
          </p>
        );
      } else {
        content = (
          <main>
            <section className="container_light margin_bottom">
              <p className="text_medium">Kerawanan banjir</p>
              {flood_content}
            </section>
            <section className="container_light margin_bottom">
              <p className="text_medium">Kerawanan longsor</p>
              {landslide_content}
            </section>
            <section className="container_light margin_bottom">
              <p className="text_medium">Kerawanan tsunami</p>
              {tsunami_content}
            </section>
          </main>
        );
      }
      final_content = <main>{content}</main>;
    } else {
      final_content = null;
    }

    const legend_content = (
      <main className="container_light">
        <p className="text_bold margin_bottom">Legenda kerawanan bencana</p>
        <button
          className="button_small margin_bottom"
          onClick={this.toggle_layer_config}
        >
          Edit tampilan
        </button>
        <br />
        <table className="text_small">
          <tbody>
            {colors_flood.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "5px",
                        backgroundColor: item.color,
                      }}
                    />
                  </td>
                  <td>{item.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    );

    const modal_layer_config_content = modal_layer_config && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_layer_config}
        onClose={this.toggle_layer_config}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
            width: "250px",
          }}
        >
          <SINI_POLYGON_CONFIG />
        </div>
      </Menu>
    );

    return (
      <main className="sini_full_parent">
        {modal_layer_config_content}
        <section className="sini_full_header">
          <table>
            <tbody>
              <tr>
                <td
                  style={{ width: "30px" }}
                  onClick={this.on_set_sini_menu_active}
                >
                  <ICON_BACK />
                </td>
                <td className="text_left">
                  <div className="badge background_green margin_bottom margin_right">
                    Kerawanan bencana
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="sini_full_scroll_area">
          {final_content}
          {legend_content}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { set_value_sini })(
  SINI_DISASTER_RISK_FULL
);
