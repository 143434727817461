import React, { useState } from "react";
import { connect } from "react-redux";

import Modal from "../common_modal/Modal";
import VersionLog from "./VersionLog";
import domain_list from "../../Data/domain_list";

const LandingAuth = ({ nav, title, subtitle, content, auth, mode }) => {
  let { domain, version } = auth;
  // domain = "befa.mapid.io";
  const [modal_version, setModal_version] = useState(false);

  const toggle_version = () => setModal_version(!modal_version);

  return (
    <>
      {modal_version && (
        <Modal
          modalSize="medium"
          id="modal"
          isOpen={modal_version}
          onClose={toggle_version}
        >
          <div className="box-body">
            <VersionLog />
          </div>
        </Modal>
      )}
      <main
        className="landing_auth"
        style={
          domain_list?.[domain]?.background !== ""
            ? {
              backgroundImage: `url(${domain_list?.[domain]?.background})`,
              backgroundAttachment: "fixed",
            }
            : {
              backgroundAttachment: "fixed",
              background: `linear-gradient(
            162deg,
            rgba(147, 197, 253, 1) 5%,
            rgba(12, 165, 235, 1) 53%,
            rgba(134, 239, 172, 1) 94%
            )`,
            }
        }
      >
        {nav}
        <section className="auth_content">
          {mode !== "none" && <div className="auth_content_left">
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
            <p>
              V {version}
              <span
                className="button_pill_tight"
                id="green"
                onClick={toggle_version}
              >
                What's new
              </span>
            </p>
          </div>}
          <div className="auth_content_right">{content}</div>
        </section>
        <style>{`
  body{
    padding-top: 0px;
    overflow-y: auto;
  }
  .navbarDefault{ visibility: hidden;}
  #top_nav{ visibility: hidden;}
  #sidebar_home{ visibility: hidden;}
  .nav_bottom{ visibility: hidden;}
  `}</style>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(LandingAuth);
