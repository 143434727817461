/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Marker } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/
import ICON_MARKER from "../../Assets/jsx/ICON_MARKER";

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class SINI_MARKER extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { sini_marker_active } = this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const long = sini_marker_active.long;
    const lat = sini_marker_active.lat;
    let content;

    if (
      (loading_item_array.includes("sini_load_parent") ||
        loading_item_array.includes("get_ai_parent_object")) &&
      long !== undefined &&
      lat !== undefined
    ) {
      content = (
        <Marker
          longitude={long}
          latitude={lat}
          draggable={false}
          anchor="bottom"
        >
          <div className="map_marker_loading map_marker_center">
            <ICON_MARKER />
          </div>
        </Marker>
      );
    }
    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {})(SINI_MARKER);
