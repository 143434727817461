/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_layer } from "../../App/actions/dataActions";
import {
  get_list_all_provinsi,
  get_list_all_kota_by_provinsi,
  get_list_all_kecamatan_by_kota,
  get_list_all_kelurahan_by_kecamatan,
  ai_prompt,
} from "../../App/actions/insightActions";
import { get_insight } from "../../App/actions/siniActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import uuid from "../../App/validation/uuid";
import Modal from "../common_modal/Modal";

// import hirarki_administrasi from "../../Data/hirarki_administrasi.json";
// import poligon_provinsi from "../../Data/batas_admin/poligon_provinsi.geojson";
// import poligon_kota from "../../Data/batas_admin/poligon_kota.geojson";
// import poligon_kecamatan from "../../Data/batas_admin/poligon_kecamatan.geojson";
// import poligon_kelurahan from "../../Data/batas_admin/poligon_kelurahan.geojson";

class InputPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prompt_text: "",
      gpt_output: "",
      after_cross_check: "",
      str_params: "",
      modal_warning: false,
      warning_message: "",
    };
  }

  componentDidMount() {
    this.props.get_list_all_provinsi();
  }

  toggle_modal_warning = () => {
    const { modal_warning } = this.state;
    this.setState({
      modal_warning: !modal_warning,
    });
  };

  on_change_prompt = (e) => {
    const value = e.target.value;
    this.setState({
      prompt_text: value,
    });
  };

  submit = async () => {
    const { prompt_text } = this.state;
    const res = await this.props.ai_prompt({
      prompt: prompt_text,
    });

    if (res?.status === 200) {
      const location_res = res?.data;

      let params = {
        request_id: uuid(),
        provinsi: location_res?.after_cross_check?.["PROVINSI"] || "",
        kota: location_res?.after_cross_check?.["KABUPATEN ATAU KOTA"] || "",
        kecamatan: location_res?.after_cross_check?.["KECAMATAN"] || "",
        kelurahan:
          location_res?.after_cross_check?.["DESA ATAU KELURAHAN"] || "",
      };
      if (location_res?.after_cross_check?.TIPE_2?.length > 0) {
        params["poi_tipe_2_selected_string"] =
          location_res?.after_cross_check?.TIPE_2?.join(",");
      }

      const gpt_output = location_res?.gpt_output;
      const after_cross_check = location_res?.after_cross_check;
      const str_params = params;

      // let params = {
      //   request_id: uuid(),
      //   provinsi: location_res?.["PROVINSI"] || "",
      //   kota: location_res?.["KABUPATEN ATAU KOTA"] || "",
      //   kecamatan: location_res?.["KECAMATAN"] || "",
      //   kelurahan: location_res?.["DESA ATAU KELURAHAN"] || "",
      // };
      // if (location_res?.TIPE_2.length > 0) {
      //   params["poi_tipe_2_selected_string"] = location_res?.TIPE_2?.join(",");
      // }

      await this.props.get_insight(params);

      this.setState({
        gpt_output,
        after_cross_check,
        str_params,
      });
    } else if (res?.status === 404) {
      const location_res = res?.data;
      const gpt_output = location_res?.gpt_output;
      this.setState({
        gpt_output,
        warning_message:
          "Please retype your prompt, it seems your prompt doesn't match with our data!",
      });
      this.toggle_modal_warning();
    } else {
      this.setState({
        warning_message: "Please retype your prompt. We found an error",
      });
      this.toggle_modal_warning();
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      prompt_text,
      gpt_output,
      after_cross_check,
      str_params,
      modal_warning,
      warning_message,
    } = this.state;
    //local state

    //global props
    // let { provinsi, kota, kecamatan, kelurahan } = this.props.map;
    // const {
    //   list_all_provinsi,
    //   list_all_kota,
    //   list_all_kecamatan,
    //   list_all_kelurahan,
    // } = this.props.properties;

    //content

    const modal_warning_content = modal_warning && (
      <Modal
        modalSize="medium"
        id="modal_data_pembanding"
        isOpen={modal_warning}
        onClose={this.toggle_modal_warning}
      >
        <main className="box-body">{warning_message}</main>
      </Modal>
    );

    return (
      <main>
        <section className="margin_bottom">
          <p className="text_bold">{dict?.["Provinces"]?.[language]}</p>
          <textarea
            type="text"
            id="prompt"
            name="prompt"
            value={prompt_text}
            onChange={this.on_change_prompt}
            className="input_new h_150 rounded_5"
          />
          <div
            className="button_inactive bg_blue h_25 center_perfect text_white rounded_5"
            onClick={() => this.submit()}
          >
            Submit
          </div>
        </section>
        <section className="">
          <div className="">
            <label className="bold font_20">ChatGPT Response</label>
            <pre className="padding_10 pre">
              {JSON.stringify(gpt_output, null, 2)}
            </pre>
          </div>
          <div className="">
            <label className="bold font_20">After Cross Check</label>
            <pre className="padding_10 pre">
              {JSON.stringify(after_cross_check, null, 2)}
            </pre>
          </div>
          <div className="">
            <label className="bold font_20">Body Request</label>
            <pre className="padding_10 pre">
              {JSON.stringify(str_params, null, 2)}
            </pre>
          </div>
        </section>
        {modal_warning_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_layer,
  get_list_all_provinsi,
  get_list_all_kota_by_provinsi,
  get_list_all_kecamatan_by_kota,
  get_list_all_kelurahan_by_kecamatan,
  ai_prompt,
  get_insight,
})(InputPrompt);
