/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class LAYER_GENANGAN extends Component {
  state = {
    render_content: null,
  };

  componentDidMount() {
    this.on_render_content();
  }

  componentDidUpdate(prevProps) {
    if (this.props.sini.state_update !== prevProps.sini.state_update) {
      this.on_render_content();
    }
  }

  on_render_content = () => {
    const { features_genangan } = this.props.sini;
    const { genangan_view_status } = this.props.map;

    if (features_genangan.length > 0) {
      const geojson_genangan = {
        type: "FeatureCollection",
        features: features_genangan,
      };

      const render_content = (
        <>
          <Source
            key="GENANGAN_CLOUD"
            id="GENANGAN_CLOUD"
            type="geojson"
            data={geojson_genangan}
          />
          <Layer
            id="GENANGAN_CLOUD"
            before="5f7b23f598e081483029d569"
            type="fill"
            source="GENANGAN_CLOUD"
            paint={{
              "fill-color": "rgb(255, 13, 13)",
              "fill-opacity": 0.5,
            }}
            layout={{
              visibility: genangan_view_status ? "visible" : "none",
            }}
            onClick={this.props.layerOnClick}
          />
        </>
      );

      this.setState({ render_content });
    }
  };

  render() {
    //local storage

    //local state

    //global props

    //content
    return <div>{this.state.render_content}</div>;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  sini: state.sini,
});

export default connect(mapStateToProps, {})(LAYER_GENANGAN);
