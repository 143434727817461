/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer, Marker } from "@urbica/react-map-gl";
import centroid from "@turf/centroid";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import colors_layer_flood from "../../Data/colors_layer_flood.json";
import colors_flood from "../../Data/colors_flood.json";

/*NON IMPORT*/

class LAYER_SINI_TSUNAMI extends Component {
  state = {
    render_content: null,
  };

  componentDidMount() {
    this.on_render_layer();
  }

  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    if (state_update_after !== state_update_before) {
      this.on_render_layer();
    }
  }

  on_render_layer = () => {
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_polygon_fill_opacity,
      sini_polygon_line_width,
      sini_show_polygon_label,
      sini_use_label_color,
    } = this.props.sini;
    const visibility = sini_map_show.includes("tsunami") ? "visible" : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const render_content = merge_array.map((item, idx) => {
      const tsunami_one = item?.tsunami_one;
      let features = [];
      if (tsunami_one?._id) {
        features = [tsunami_one];
      }
      const geojson_tsunami_one = {
        type: "FeatureCollection",
        features: features,
      };

      let label_content;
      if (sini_show_polygon_label && visibility === "visible") {
        const features_center_label = features.map((item) => {
          let feature = {};
          const geojson = {
            type: "FeatureCollection",
            features: [item],
          };
          feature = centroid(geojson, { properties: item.properties });
          return feature;
        });
        label_content = features_center_label.map((item, idx) => {
          let style = {};
          if (sini_use_label_color) {
            const color = colors_flood.find(
              (element) => element.name === item?.properties?.["Kelas"]
            ).color;
            style = { backgroundColor: color, color: "#fff" };
          }
          return (
            <Marker
              key={idx}
              longitude={item.geometry.coordinates[0]}
              latitude={item.geometry.coordinates[1]}
              draggable={false}
            >
              <div className="badge_pill_small" style={style}>
                {item?.properties?.["Kelas"]}
              </div>
            </Marker>
          );
        });
      }

      return (
        <div key={idx}>
          <Source
            key={`geojson_tsunami_one${idx}`}
            id={`geojson_tsunami_one${idx}`}
            type="geojson"
            data={geojson_tsunami_one}
          />
          <Layer
            id={`geojson_tsunami_one${idx}`}
            source={`geojson_tsunami_one${idx}`}
            before={`poi_${idx}`}
            type="fill"
            paint={{
              "fill-color": colors_layer_flood,
              "fill-opacity": Number(sini_polygon_fill_opacity),
            }}
            layout={{
              visibility: visibility,
            }}
          />
          <Layer
            id={`geojson_tsunami_oneline_${idx}`}
            source={`geojson_tsunami_one${idx}`}
            before={`poi_${idx}`}
            type="line"
            paint={{
              "line-color": colors_layer_flood,
              "line-width": Number(sini_polygon_line_width),
              "line-opacity": 1,
              "line-gap-width": 0,
            }}
            layout={{ visibility: visibility }}
          />
          {label_content}
        </div>
      );
    });
    this.setState({ render_content: render_content });
  };

  render() {
    return this.state.render_content;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  sini: state.sini,
});

export default connect(mapStateToProps, {})(LAYER_SINI_TSUNAMI);
