/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ProgressBar from "../common_spinner/ProgressBar";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { get_genangan } from "../../App/actions/algoritma_genangan";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import format_date_formal from "../../App/validation/format_date_formal";

/*NON IMPORT*/

class STATUS_GET_GENANGAN_CONTENT extends Component {
  state = {
    index_max: 65,
    radius_start: 2.5,
    radius_start_propagation: 2.1,
    delta_sample_perimeter_m: 1.45,
    delta_sample_final_m: 7,
    overwrite_status: false,
  };

  on_overwrite_status_true = () => {
    this.setState({ overwrite_status: true });
  };

  on_change = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  on_close_modal = () => {
    this.props.set_value_properties({
      key: "modal_pop_up_genangan",
      value: false,
    });
  };

  on_overwrite = () => {
    const {
      overwrite_longitude,
      overwrite_latitude,
      overwrite_genangan_cm,
      overwrite_feature_key,
    } = this.props.sini;
    const body = {
      longitude: overwrite_longitude,
      latitude: overwrite_latitude,
      genangan_cm: overwrite_genangan_cm,
      feature_key: overwrite_feature_key,
      overwrite_status: true,
    };
    this.props.get_genangan(body);
  };

  on_get_genangan = (e) => {
    e.preventDefault();
    const {
      feature_key_params_genangan,
      genangan_cm_params_genangan,
      latitude_params_genangan,
      longitude_params_genangan,
    } = this.props.properties;
    const {
      index_max,
      radius_start,
      radius_start_propagation,
      delta_sample_perimeter_m,
      delta_sample_final_m,
      overwrite_status,
    } = this.state;
    const body = {
      longitude: longitude_params_genangan,
      latitude: latitude_params_genangan,
      genangan_cm: genangan_cm_params_genangan,
      feature_key: feature_key_params_genangan,
      overwrite_status,
      index_max,
      radius_start,
      radius_start_propagation,
      delta_sample_perimeter_m,
      delta_sample_final_m,
    };
    this.props.get_genangan(body);
    this.setState({
      overwrite_status: false,
    });
  };

  render() {
    //local storage

    //local state
    const { index_max, radius_start_propagation, overwrite_status } =
      this.state;

    //global props
    const {
      success_status,
      error_status,
      error_message,
      loading_status,
      loading_item,
      total_progress_genangan_global,
      current_progress_genangan_global,
      feature_key_params_genangan,
      genangan_cm_params_genangan,
      feature_object_selected,
    } = this.props.properties;
    const { mode_genangan, features_genangan } = this.props.sini;

    //content
    const feature_genangan = features_genangan.find(
      (item) => item?.properties?.feature_key === feature_key_params_genangan
    );

    const input_parameter_content = (
      <main>
        <form onSubmit={this.on_get_genangan}>
          <p className="text_medium">index_max</p>
          <p className="text_small">
            Total iterasi yang akan dilakukan semakin besar angka semakin banyak
            iterasi yang dilakukan, luas area kemungkinan menjadi semakin luas.
          </p>
          <input
            className="margin_bottom"
            type="number"
            onChange={this.on_change}
            min={5}
            value={index_max}
            name="index_max"
          />
          <br />
          <p className="text_medium">radius_start_propagation (meter)</p>
          <p className="text_small">
            Radius perambatan (buffer dari suatu line) dalam meter untuk
            mendapatkan data elevasi tetangga.
          </p>
          <input
            className="margin_bottom"
            type="number"
            onChange={this.on_change}
            value={radius_start_propagation}
            name="radius_start_propagation"
          />
          <br />

          <div
            onClick={this.on_get_genangan}
            className="button background_blue"
          >
            Jalankan algoritma genangan by LiDAR
          </div>
        </form>
      </main>
    );

    let content;
    if (loading_status && loading_item === "get_genangan") {
      content = (
        <>
          <p className="text_medium">Menjalankan algoritma genangan...</p>
          <br />
          <br />
          <br />

          <p className="text_bold">Proses utama</p>
          <ProgressBar
            current_number={current_progress_genangan_global}
            total_number={total_progress_genangan_global}
            name="Proses utama"
          />
          <br />
          <br />
        </>
      );
    } else if (success_status) {
      if (overwrite_status) {
        content = <>{input_parameter_content}</>;
      } else {
        content = (
          <>
            <p className="text_small">Status:</p>
            <p className="text_medium">Berhasil memuat genangan</p>
            <br />
            <p className="text_small">Cara pemanggilan:</p>
            <p className="text_medium">
              {mode_genangan === "db"
                ? "Dari database (algoritma sudah pernah dijalankan)"
                : mode_genangan === "first_run"
                ? "Fungsi perambatan genangan pertama kali dijalankan dan data poligon telah tersimpan"
                : "Fungsi perambatan baru saja dijalankan dan database telah menimpa/overwrite data poligon sebelumnya"}
            </p>
            <br />
            <br />

            <section>
              <button
                className="button margin_bottom"
                onClick={this.on_overwrite_status_true}
              >
                Jalankan ulang
              </button>
              <p className="text_small">
                Algoritma akan dijalankan ulang dan data poligon lama akan
                ditimpa dengan data baru.
              </p>
            </section>

            <br />
          </>
        );
      }
    } else if (error_status) {
      content = (
        <>
          <p className="text_small">Status:</p>
          <p className="text_medium">Gagal menjalankan algoritma genangan</p>
          <br />
          <p className="text_small">Pesan error:</p>
          <p className="container_light">{error_message}</p>
        </>
      );
    } else if (feature_key_params_genangan && genangan_cm_params_genangan) {
      content = <>{input_parameter_content}</>;
    }

    return (
      <main className="container_light background_white margin_top margin_bottom">
        <p className="text_bold">Detail laporan</p>
        <table className="table table_width">
          <tbody>
            <tr>
              <td>Ketinggian genangan(cm)</td>
              <td>{genangan_cm_params_genangan}</td>
            </tr>
            <tr>
              <td>Kota/Kabupaten</td>
              <td>{feature_object_selected?.properties?.["KotaKabupaten"]}</td>
            </tr>
            <tr>
              <td>Sungai_Terdekat</td>
              <td>
                {feature_object_selected?.properties?.["Sungai_Terdekat"]}
              </td>
            </tr>
            <tr>
              <td>Penyebab</td>
              <td>{feature_object_selected?.properties?.["Penyebab"]}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <p className="text_bold">Detail hasil algoritma</p>
        <table className="table full_width">
          <tbody>
            <tr>
              <td>Perkiraan luas genangan (meter persegi)</td>
              <td>
                {feature_genangan?.properties?.area_meter_square
                  ? feature_genangan?.properties?.area_meter_square
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Perkiraan luas genangan (hektar)</td>
              <td>
                {feature_genangan?.properties?.area_hectare
                  ? feature_genangan?.properties?.area_hectare
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Tanggal dijalankan</td>
              <td>
                {feature_genangan?.properties?.date_run
                  ? format_date_formal(feature_genangan?.properties?.date_run)
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Waktu dijalankan</td>
              <td>
                {feature_genangan?.properties?.time_run
                  ? feature_genangan?.properties?.time_run
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
  sini: state.sini,
});

export default connect(mapStateToProps, { set_value_properties, get_genangan })(
  STATUS_GET_GENANGAN_CONTENT
);
