/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Draw from "@urbica/react-map-gl-draw";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { get_sini_data, set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import { drawStyle } from "../layer/DrawStyleSini";
import uuid from "../../App/validation/uuid";

/*NON IMPORT*/

class SINI_DRAWING extends Component {
  state = {};

  on_draw_create = (event) => {
    const { sini_data_mode } = this.props.sini;
    const geojson = event;
    let feature = geojson?.features?.[0];
    const params = {
      request_id: uuid(),
      long: feature.geometry.coordinates[0],
      lat: feature.geometry.coordinates[1],
      km_rad: 1,
      sini_data_mode,
    };
    this.props.get_sini_data(params);
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "",
    });
  };

  on_draw_update = (event) => {
    // console.log("on_draw_update=", event);
  };

  on_change = (event) => {
    // console.log("on_change=", event);
  };

  on_draw_mode_change = (event) => {
    this.props.set_value_sini({
      key: "mode_draw",
      value: "simple_select",
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { geojson_draw, sini_choose_map_active, mode_draw } = this.props.sini;

    //content

    return (
      <main>
        {sini_choose_map_active && (
          <Draw
            data={geojson_draw}
            mode={mode_draw}
            onDrawCreate={this.on_draw_create}
            onDrawUpdate={this.on_draw_update}
            onChange={this.on_change}
            onDrawModeChange={this.on_draw_mode_change}
            combineFeaturesControl={false}
            uncombineFeaturesControl={false}
            pointControl={false}
            lineStringControl={false}
            polygonControl={false}
            trashControl={false}
            styles={drawStyle}
          />
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
});

export default connect(mapStateToProps, {
  get_sini_data,
  set_value_sini,
})(SINI_DRAWING);
