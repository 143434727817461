/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Resizable } from "re-resizable";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { setStatisticMode } from "../../App/actions/layerNewActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { spatial_ai } from "../../App/actions/spatial_ai";

/*PICTURE ASSET*/

/*GENERAL*/

class RIGHT_SIDEBAR_SPATIAL_AI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableComponent: props.tableComponent || { height: 0 },
      statusBarComponent: {
        width: 0,
      },
      message_input: "",
    };
  }

  setStatisticModeFunc = (event, value) => {
    this.props.setStatisticMode(value);
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: true,
    });
  };

  componentDidMount = () => {
    if (document.getElementById("statistic_resume_box")) {
      const width = document.getElementById("statistic_resume_box").offsetWidth;
      localStorage.setItem("rightbar_width", width);
    }
  };

  setStatusBarComponent = () => {
    const width = document.getElementById("statistic_resume_box").offsetWidth;
    this.setState({
      tableComponent: {
        ...this.state.statusBarComponent,
        width: width,
      },
    });
    localStorage.setItem("rightbar_width", width);
  };

  on_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  on_test = () => {
    const { message_input } = this.state;
    const body = {
      geojson_input: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: [106.9226225789019, -6.241467531960069],
            },
          },
        ],
      },
      message_input: message_input,
    };
    this.props.spatial_ai(body);
  };

  render() {
    //local storage
    const isMobile = window.innerWidth <= 650;

    //local state
    const { message_input } = this.state;

    //global props

    //content

    const content = (
      <main>
        <h1>Spatial AI Playground</h1>
        <br />
        <textarea
          name="message_input"
          value={message_input}
          onChange={this.on_change}
          rows={10}
        />
        <br />
        <button className="button" onClick={this.on_test}>
          Tes
        </button>
      </main>
    );

    return (
      <>
        {!isMobile ? (
          <Resizable
            id="statistic_resume_box"
            onResizeStop={(e, direction, ref, d) => {
              this.setStatusBarComponent(d);
            }}
            style={{
              zIndex: 1,
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "#fff",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
            defaultSize={{ width: 420, height: "100vh" }}
            minWidth={420}
            maxWidth="100vw"
          >
            {content}
          </Resizable>
        ) : (
          <div
            id="statistic_resume_box"
            style={{
              zIndex: 2,
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "white",
              width: "100%",
              paddingTop: "15px",
              maxWidth: "100vw",
            }}
          >
            {content}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
});

export default connect(mapStateToProps, {
  setStatisticMode,
  set_value_properties,
  spatial_ai,
})(RIGHT_SIDEBAR_SPATIAL_AI);
