/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer, Marker } from "@urbica/react-map-gl";
import centroid from "@turf/centroid";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/
const color_line = "#104585";
const color_fill = "#104585";
const properties_key = "HARGA";

class LAYER_SINI_LANDVALUE extends Component {
  state = {
    render_content: null,
  };

  componentDidMount() {
    this.on_render_layer();
  }

  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    if (state_update_after !== state_update_before) {
      this.on_render_layer();
    }
  }

  on_render_layer = () => {
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_polygon_fill_opacity,
      sini_polygon_line_width,
      sini_show_polygon_label,
    } = this.props.sini;
    const visibility = sini_map_show.includes("landvalue") ? "visible" : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const render_content = merge_array.map((item, idx) => {
      const landvalue_one = item?.landvalue_one;
      let features_landvalue_one = [];
      if (landvalue_one?._id) {
        features_landvalue_one = [landvalue_one];
      }
      const geojson_landvalue_one = {
        type: "FeatureCollection",
        features: features_landvalue_one,
      };
      let label_content;
      if (sini_show_polygon_label && visibility === "visible") {
        const features_center_label = features_landvalue_one.map((item) => {
          let feature = {};
          const geojson = {
            type: "FeatureCollection",
            features: [item],
          };
          feature = centroid(geojson, { properties: item.properties });
          return feature;
        });
        label_content = features_center_label.map((item, idx) => {
          return (
            <Marker
              key={idx}
              longitude={item.geometry.coordinates[0]}
              latitude={item.geometry.coordinates[1]}
              draggable={false}
            >
              <div className="badge_pill_small background_blue">
                Rp.
                {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                  item?.properties?.[properties_key]
                )}
              </div>
            </Marker>
          );
        });
      }

      return (
        <div key={idx}>
          <Source
            key={`geojson_landvalue_one_${idx}`}
            id={`geojson_landvalue_one_${idx}`}
            type="geojson"
            data={geojson_landvalue_one}
          />
          <Layer
            id={`geojson_landvalue_one_${idx}`}
            source={`geojson_landvalue_one_${idx}`}
            before={`poi_${idx}`}
            type="fill"
            paint={{
              "fill-color": color_fill,
              "fill-opacity": Number(sini_polygon_fill_opacity),
            }}
            layout={{
              visibility: visibility,
            }}
          />
          <Layer
            id={`geojson_landvalue_one_line_${idx}`}
            source={`geojson_landvalue_one_${idx}`}
            before={`poi_${idx}`}
            type="line"
            paint={{
              "line-color": color_line,
              "line-width": Number(sini_polygon_line_width),
              "line-opacity": 1,
              "line-gap-width": 0,
            }}
            layout={{ visibility: visibility }}
          />
          {label_content}
        </div>
      );
    });
    this.setState({ render_content: render_content });
  };

  render() {
    return this.state.render_content;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  sini: state.sini,
});

export default connect(mapStateToProps, {})(LAYER_SINI_LANDVALUE);
