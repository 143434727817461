/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

import Map, {
  GeolocateControl,
  NavigationControl,
  AttributionControl,
} from "react-map-gl";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";

/*PERSONAL COMPONENT*/
import BI_LAYER from "./BI_LAYER";

/*REDUX FUNCTION*/
import { set_map, set_value_map } from "../../App/actions/mapActions";
import { set_value_bi } from "../../App/actions/biActions";
import { domain_mapid_basemap } from "../../App/validation/features_domain";
import basemap_styles from "../../App/validation/basemap_styles";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class BI_MAP extends Component {
  constructor(props) {
    super(props);
    this._map = React.createRef();
    this._draw = React.createRef();
    // this.geocoderContainerRef = React.createRef();
    this.state = {
      viewport: {
        latitude: -0.811123,
        longitude: 113.921327,
        zoom: 4,
      },
      drag: {
        cliked: false,
        screenX: 10,
        screenY: 10,
      },
      mapMode: "street",
      geojson: { type: "FeatureCollection", features: [] },
      selectedFeatures: [],
      data: {
        type: "FeatureCollection",
        features: [],
      },
      mode: "simple_select",
      id_active: "",
      renderSini: [],
      pickCoord: [0, 0],
    };
  }

  set_basemap_api = () => {
    const { domain } = this.props.auth;
    const active_basemap = (domain_mapid_basemap.includes(domain)) ? "mapid" : "mapbox";
    let basemap_props = basemap_styles().find(item => item?.value === "street")
    const basemap_api = basemap_props?.api?.[active_basemap];
    this.props.set_value_map({
      key: "basemap_api",
      value: basemap_api
    })

    // remove logo mapbox
    if (active_basemap === "mapid") {
      const element = document.getElementsByClassName('mapboxgl-ctrl-logo')?.[0]
      if (element) {
        element.remove();
      }
    }
  }

  componentDidMount() {
    this.set_basemap_api();
  }

  onMapRefChange = (mapRef) => {
    const new_map = mapRef?.getMap();
    new_map?.once("load", () => {
      if (this) {
        this.props.set_map(new_map);
      }
    });
  };

  handle_basemap = (e) => {
    const { isUseMapidBasemap } = this.props.bi;
    const value = e.target.checked;
    this.props.set_value_bi({
      key: isUseMapidBasemap,
      value,
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { basemap_api } = this.props.map;

    // library switcher
    return (
      <>
        <Map
          {...this.props}
          mapStyle={basemap_api}
          mapboxAccessToken="pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g"
          // mapStyle={"mapbox://styles/mapbox/dark-v10"}
          // mapStyle={"http://108.137.93.186:8080/styles/osm-liberty/style.json"}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "10px",
          }}
          initialViewState={{
            latitude: this.state.viewport.latitude,
            longitude: this.state.viewport.longitude,
            zoom: this.state.viewport.zoom,
          }}
          maxZoom={22}
          minZoom={0}
          onViewportChange={(e) => {
            return;
          }}
          attributionControl={false}
          ref={this.onMapRefChange}
        >
          <BI_LAYER />
          <NavigationControl showCompass showZoom position="top-right" />
          <GeolocateControl position="top-right" />
          <AttributionControl
            position="bottom-right"
            customAttribution={`GEO MAPID V ${this.props.auth.version} | <a href="https://mapid.io/">© MAPID</a> | © MapBox`}
          />
        </Map>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  map: state.map,
});

export default connect(mapStateToProps, {
  set_map,
  set_value_map,
  set_value_bi,
})(BI_MAP);
