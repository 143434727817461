import axios from "axios";
import { set_value_sini } from "./sini_v2";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

function parse_response(response) {
  // Check if the response is already a JavaScript object
  if (typeof response === "object" && response !== null) {
    return response;
  } else {
    // Check if the response is a string
    if (typeof response === "string") {
      // Remove the enclosing triple backticks if they exist
      const tripleTickMatch = response.match(/```([\s\S]*?)```/);
      if (tripleTickMatch) {
        response = tripleTickMatch[1].trim();
      }
      // Parse the JSON string into a JavaScript object
      try {
        return JSON.parse(response);
      } catch (error) {
        try {
          response = String(response);
          return JSON.parse(response);
        } catch (error) {
          console.error("Invalid JSON string:", error);
          return {};
        }
      }
    }
  }

  // If the response is neither an object nor a string, return an empty object
  return {};
}

/**
 * @route : spatial_ai
 * @endpoint : /sini_v2/spatial_ai
 * @methode : post
 * @params : -
 */
export const spatial_ai = (body) => async (dispatch) => {
  try {
    console.log("spatial_ai loading");
    dispatch({
      type: "set_loading_action",
      payload: "spatial_ai",
    });

    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      bun_server + `/sini_v2/spatial_ai`,
      body,
      config
    );

    let data = res.data;
    console.log("data=", data);
    data = await parse_response(data);

    const geojson_spatial_ai = data?.geojson_spatial_ai || {
      type: "FeatureCollection",
      features: [],
    };
    const message_spatial_ai = data?.message_spatial_ai || "";

    dispatch(
      set_value_sini({
        key: "geojson_spatial_ai",
        value: geojson_spatial_ai,
      })
    );
    dispatch(
      set_value_sini({
        key: "message_spatial_ai",
        value: message_spatial_ai,
      })
    );

    dispatch({
      type: "clear_loading_action",
      payload: "spatial_ai",
    });
    console.log("done");
  } catch (error) {
    console.log("error=", error);
    dispatch({
      type: "clear_loading_action",
      payload: "spatial_ai",
    });
  }
};
