import phoneNumberList from "../../Data/PhoneNumber.json";

/**
 * Keterangan:
 * 
 * Function ini untuk memilah kode negara dengan nomor telepon pribadi
 */
export const get_country_code_and_phone_number = (value) => {
  if (value) {
    const n_of_number = phoneNumberList.length;
    for (let index = 0; index < n_of_number; index++) {
      const countryCode = Number(
        String(value).slice(0, String(phoneNumberList[index]).length)
      );

      if (phoneNumberList[index] === countryCode) {
        return {
          countryCode: countryCode,
          phoneNumber: search_the_rest_numbers(value, index),
        };
      }
    }
  }
  return {
    countryCode: "",
    phoneNumber: value || "",
  };
};

/**
 * Keterangan:
 * 
 * Function ini untuk mengambil nomor telepon pribadi TANPA kode negara.
 */
export const search_the_rest_numbers = (value, index) => {
  return String(value).slice(String(phoneNumberList[index]).length);
};
