/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import BI_CARD_CONTAINER from "./BI_CARD_CONTAINER";
import BI_ADD_SETTING from "../bi_map/BI_ADD_SETTING";
import Modal from "../common_modal/Modal";

/*REDUX FUNCTION*/
import {
  set_value_bi,
  trigger_rerender_css_map,
} from "../../App/actions/biActions";

/*PICTURE ASSET*/
import expand from "../../Assets/svg/expand.svg";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class BI_CARD_CONTAINER_PARENT extends Component {
  state = { modal_add_setting: false };

  toggle_full = () => {
    this.props.set_value_bi({
      key: "full_card",
      value: !this.props.bi.full_card,
    });
    // this.props.trigger_rerender_css_map();
  };

  toggle_add_setting = (props) => {
    this.setState({
      modal_add_setting: !this.state.modal_add_setting,
      modal_warn_no_setting: false,
    });
    this.props.set_value_bi({
      key: "setting_add_object",
      value: {
        _id: "",
        project_id: "",
        layer_load_mode: "selected_only",
        layer_view_map_mode: "selected_only",
        layer_load_list: [],
        layer_view_map_list: [],
        basemap_mode: "street",
      },
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_add_setting } = this.state;

    //global props
    let {
      bi_object,
      project_object_selected,
      group_card_id_selected,
      loading_item_array,
    } = this.props.bi;

    //content
    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );

    let group_card_list = setting_object?.group_card_list || [];
    let card_list = bi_object?.card_list || [];
    card_list = card_list.filter(
      (item) => item.project_id === project_object_selected?._id
    );
    if (group_card_id_selected && group_card_list.length > 0) {
      const group_card_object_selected = group_card_list.find(
        (item) => item?._id === group_card_id_selected
      );
      const card_id_list = group_card_object_selected?.card_id_list || [];
      card_list = card_list.filter((item) => card_id_list.includes(item?._id));
    }

    const layer_load_list = setting_object?.layer_load_list || [];
    const layer_view_map_list = setting_object?.layer_view_map_list || [];
    let layer_final_list = [...layer_load_list];
    layer_view_map_list.forEach((item) => {
      if (!layer_load_list.includes(item)) layer_final_list.push(item);
    });

    //content
    let content;

    if (loading_item_array.includes("get_list_layer_bi")) {
      content = (
        <div className="container_light">
          {dict?.["Loading list layer"]?.[language]}
        </div>
      );
    } else if (!project_object_selected?._id) {
      content = (
        <section
          className="container_light margin_bottom"
          style={{ maxWidth: "200px" }}
        >
          <p className="text_medium">
            {dict?.["Please select a project to show"]?.[language]}
          </p>
        </section>
      );
    } else if (!setting_object?.project_id) {
      content = (
        <section
          className="container_light margin_bottom"
          style={{ maxWidth: "200px" }}
        >
          <p className="text_medium">
            {
              dict?.[
              "Layer setup has not been done. Adding a data card cannot be done yet."
              ]?.[language]
            }
          </p>
          <button
            className="button_small margin_bottom inline margin_right"
            onClick={this.toggle_add_setting}
          >
            {dict?.["Data initiation settings"]?.[language]}
          </button>
        </section>
      );
    } else if (card_list.length === 0 && group_card_list.length === 0) {
      content = <BI_CARD_CONTAINER />;
    } else {
      content = <BI_CARD_CONTAINER />;
    }

    const modal_add_setting_content = modal_add_setting && (
      <Modal
        modalSize="large"
        isOpen={modal_add_setting}
        onClose={this.toggle_add_setting}
      >
        <div className="box-body" id="box-body">
          <BI_ADD_SETTING toggle_add_setting={this.toggle_add_setting} />
        </div>
      </Modal>
    );

    return (
      <main>
        <img
          className="cursor_pointer"
          alt="expand"
          src={expand}
          style={{ height: "20px" }}
          onClick={this.toggle_full}
        />
        {content}
        {modal_add_setting_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_bi,
  trigger_rerender_css_map,
})(BI_CARD_CONTAINER_PARENT);
