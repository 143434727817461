/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Resizable } from "re-resizable";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

/*PERSONAL COMPONENT*/
import Map from "../../Components/layer/Map";
import EditorProperties from "../../Components/editor_table/EditorProperties";
import BASEMAP_MODE from "../../Components/layer/BASEMAP_MODE";
import LegendButton from "../../Components/map_legend/LegendButton";
import SEARCH_BAR_MAP from "../../Components/search_map/SEARCH_BAR_MAP";
import RIGHT_SIDEBAR_SPATIAL_AI from "../../Components/map_reusable/RIGHT_SIDEBAR_SPATIAL_AI";

/*REDUX FUNCTION*/
import {
  setLanguage,
  get_quota_access,
  set_modal_quota_access,
} from "../../App/actions/authActions";
import { set_value_layer } from "../../App/actions/dataActions";
import { get_history_project } from "../../App/actions/projectActions";
import {
  setStatisticMode,
  setMapMode,
} from "../../App/actions/layerNewActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { set_value_user, logoutUser } from "../../App/actions/authActions";
import { clearProjectList } from "../../App/actions/projectActions";
import { clearFormList } from "../../App/actions/layerNewActions";
import {
  verify_license_user,
  verify_license_group,
} from "../../App/actions/license_actions";

/*PICTURE ASSET*/
import icon_tool_new from "../../Assets/svg/icon_tool_new.svg";

/*GENERAL*/
import poi_list from "../../Data/Poi_List_Type_1.json";
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

/*NON IMPORT*/

class PAGE_SPATIAL_AI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      boxStatisticStatus: false,
      openStatus: false,
      all_poi: poi_list,
      select_sort: "ascending",
      select_name: "label",
      tableComponent: {
        height: 0,
      },
    };
  }

  componentDidMount() {
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated) {
      this.props.verify_license_user();
      this.props.verify_license_group();
      this.props.get_history_project();
      this.props.get_quota_access();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tableComponent.height !== this.state.tableComponent.height) {
      if (document?.getElementById("statistic_resume_box")?.style?.height) {
        document.getElementById(
          "statistic_resume_box"
        ).style.height = `calc((100vh - 355px - ${this.state.tableComponent.height}px))`;
      }
    }

    if (this.props.layer.tableStatus) {
      const height = document?.getElementById("tableBar")?.offsetHeight;
      if (height) {
        localStorage.setItem("table_height", height);
      }
    } else {
      localStorage.setItem("table_height", 0);
    }
  }

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  onLogoutClick = () => {
    this.props.logoutUser();
    this.props.clearProjectList();
    this.props.clearFormList();
  };

  toggle_buy_license = () => {
    const { modal_buy_license } = this.props.properties;
    this.props.set_value_properties({
      key: "modal_buy_license",
      value: !modal_buy_license,
    });
  };

  toggle_modal_quota_price = () => {
    this.props.set_modal_quota_access(false);
  };

  setSelectedMap = (val) => {
    this.props.setMapMode(val);
  };

  toggleModalStatisticMode = () => {
    const { modalStatistic } = this.state;
    this.setState({
      modalStatistic: !modalStatistic,
    });
  };

  setStatisticModeFunc = (mode) => {
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: true,
    });
    this.props.setStatisticMode(mode);
    this.setState(
      {
        boxStatisticStatus: true,
      },
      () => this.closeStat()
    );
  };

  /*
  left --> kiri, list layer
  right --> kanan, detail layer, pie chart dsb
  */
  toggle_sidebar_right = () => {
    const { sidebar_right_status } = this.props.properties;
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: !sidebar_right_status,
    });
  };

  openStat = () => {
    this.setState({ boxStatisticStatus: true }, () => {
      localStorage.setItem("rightbar_width", 0);
    });
  };

  closeStat = () => {
    this.setState({ boxStatisticStatus: false }, () => {
      const width = document?.getElementById(
        "statistic_resume_box"
      )?.offsetWidth;
      if (width) {
        localStorage.setItem("rightbar_width", width);
      }
    });
  };

  setTableComponent = (d) => {
    const height = document?.getElementById("tableBar")?.offsetHeight;
    if (height) {
      this.setState({
        tableComponent: {
          ...this.state.tableComponent,
          height: height,
        },
      });
      localStorage.setItem("table_height", height);
    }
  };

  setRightSideBarComponent = () => {
    const width = document?.getElementById("statistic_resume_box")?.offsetWidth;
    if (width) {
      this.setState({
        tableComponent: {
          ...this.state.RightSideBarComponent,
          width: width,
        },
      });
      localStorage.setItem("rightbar_width", width);
    }
  };

  // Language
  onSetLanguageIna = () => {
    this.setState({
      language: true,
    });
    this.props.setLanguage("ina");
  };

  onSetLanguageEng = () => {
    this.setState({
      language: false,
    });
    this.props.setLanguage("eng");
  };

  onChangeSort = (data) => {
    this.setState({ select_sort: data });
  };

  onChangeSelect = (data) => {
    this.setState({ select_name: data });
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";
    const width_screen = window.innerWidth;

    //local state
    const { width } = this.state;

    //global props
    const {
      geometryStatus,
      loadingList,
      loadingProcess,
      itemLoading,
      chatStatus,
      realtimeStatus,
      tableStatus,
    } = this.props.layer;
    const { geo_project, loadingDetail } = this.props.project;
    const { sidebar_right_status } = this.props.properties;
    const { domain } = this.props.auth;

    //content
    const is_mobile = width <= 650;
    let box_mobile_height = null;
    if (is_mobile) {
      box_mobile_height = window.innerHeight;
    }

    const menuSidebar = (
      <div
        className="menu_sidebar_view"
        style={{
          top: geometryStatus ? "13.5rem" : "9rem",
        }}
      >
        <section
          onClick={this.toggle_sidebar_right}
          title={`${!sidebar_right_status ? "Open" : "Close"} Sidebar`}
        >
          <img src={icon_tool_new} alt={"Sidebar"} />
        </section>
        <LegendButton />
        <BASEMAP_MODE />
        {language === "ina" ? (
          <section onClick={this.onSetLanguageEng} title="Ganti Bahasa English">
            <img
              alt="Indonesia"
              src="https://mapid.co.id/img/icon/indo-flag.png"
            />
          </section>
        ) : (
          <section
            onClick={this.onSetLanguageIna}
            title="Change Language Indonesia"
          >
            <img
              alt="English"
              style={{
                width: "25px",
                height: "25px",
              }}
              src="https://mapid.co.id/img/icon/uk-flag.png"
            />
          </section>
        )}
      </div>
    );

    let textLoading = "Saving";
    switch (itemLoading) {
      case "createLayer":
        textLoading = dict["Creating layer"][language];
        break;
      case "importLayer":
        textLoading = dict["Importing layer"][language];
        break;
      case "uploadLayer":
        textLoading = dict["Importing layer"][language];
        break;
      case "edit_general":
        textLoading = dict["Saving changes"][language];
        break;
      case "editAllFields":
        textLoading = dict["Saving changes"][language];
        break;
      case "editLayerForm":
        textLoading = dict["Saving changes"][language];
        break;
      case "pushFeature":
        textLoading = dict["Adding feature"][language];
        break;
      case "push_project_attach":
        textLoading = dict["Attaching layer"][language];
        break;
      case "delete_project_attach":
        textLoading = dict["Deleting attached layer"][language];
        break;
      case "edit_geometry":
        textLoading = dict["Saving geometry changes"][language];
        break;
      case "edit_properties":
        textLoading = dict["Saving attribute value"][language];
        break;
      case "delete_feature":
        textLoading = dict["Deleting"][language];
        break;
      case "hide_feature":
        textLoading = dict["Hiding feature"][language];
        break;
      case "push_field":
        textLoading = dict["Adding column"][language];
        break;
      case "editField":
        textLoading = dict["Saving column"][language];
        break;
      case "deleteField":
        textLoading = dict["Deleting column"][language];
        break;
      case "delete_layer":
        textLoading = dict["Deleting layer"][language];
        break;
      case "get_detail":
        textLoading = dict["Getting GIS data"][language];
        break;
      case "genangan":
        textLoading = "Menjalankan algoritma genangan";
        break;
      case "deleteFieldForm":
        textLoading = "Menghapus kolom tabel";
        break;
      case "move_layers_into_any_folder":
        textLoading = dict["Moving layers"][language];
        break;
      case "delete_layers_bulk":
        textLoading = dict["Deleting layers"][language];
        break;
      default:
        textLoading = itemLoading;
        break;
    }

    let table_content;

    if (this.props.layer.tableStatus) {
      if (width_screen > 900) {
        table_content = (
          <Resizable
            onResizeStop={(e, direction, ref, d) => {
              this.setTableComponent(d);
            }}
            id="tableBar"
            boundsByDirection={true}
            defaultSize={{
              width: "100%",
              height: 300,
            }}
            maxHeight="100vh"
            minHeight="75px"
            minWidth="calc((100% - 355px))"
            maxWidth="calc((100% - 355px))"
            style={{
              position: "fixed",
              bottom: 0,
              right: 0,
              maxHeight: window.innerHeight - 5,
              minHeight: "30px",
              zIndex: 1,
              backgroundColor: "#ffffff",
              color: "#1e5e96ff",
            }}
          >
            <EditorProperties />
          </Resizable>
        );
      } else {
        table_content = (
          <Resizable
            onResizeStop={(e, direction, ref, d) => {
              this.setTableComponent(d);
            }}
            defaultSize={{
              width: "100%",
              height: "500px",
            }}
            minWidth="100%"
            maxWidth="100%"
            minHeight="100px"
            maxHeight="100vh"
            style={{
              position: "fixed",
              width: "100%",
              height: "500px",
              zIndex: 1,
              left: "0px",
              bottom: "0px",
              backgroundColor: "white",
              paddingRight: "0px",
              paddingLeft: "20px",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            <EditorProperties />
          </Resizable>
        );
      }
    }

    return (
      <main>
        <SEARCH_BAR_MAP />
        <Link to="/dashboard" className="brand_map noselect">
          <img
            src={domain_list?.[domain]?.logo}
            height="30px"
            alt={domain_list?.[domain]?.short_title}
          />
        </Link>
        {!!textLoading && !chatStatus && !realtimeStatus && loadingProcess && (
          <div
            style={{
              zIndex: "20",
              backgroundColor: "#0ca5eb",
              color: "#fff",
              position: "fixed",
              top: "0px",
              right: "0px",
              width: "100vw",
              textAlign: "center",
              paddingTop: "5px",
              paddingBottom: "5px",
              boxShadow: "0px 0px 7px 7px rgba(0, 0, 0, 0.09)",
              fontSize: "15px",
            }}
          >
            {textLoading}
          </div>
        )}
        <Map />
        {menuSidebar}

        {is_mobile ? (
          <motion.section
            className="box_mobile"
            id="box_mobile"
            drag="y"
            dragConstraints={{
              left: 0,
              right: 0,
              top: -(box_mobile_height - 250),
              bottom: 100,
            }}
            dragElastic={0.3}
            dragMomentum={false}
          >
            <div className="button_mobile">
              <div className="button_show_mobile" />
            </div>
            <div className="mobile_scroll">
              {tableStatus ? (
                <div
                  style={{
                    overflowX: "auto",
                    height: "100%",
                  }}
                >
                  <button
                    className="button"
                    id="delete"
                    onClick={() => this.props.setTabelStatus(false)}
                  >
                    Close Table
                  </button>
                  <EditorProperties />
                </div>
              ) : (
                <RIGHT_SIDEBAR_SPATIAL_AI
                  loadingList={loadingList}
                  loadingDetail={loadingDetail}
                  geo_project={geo_project}
                  language={language}
                  tableComponent={this.state.tableComponent}
                  extraHeight={500}
                />
              )}
            </div>
          </motion.section>
        ) : (
          sidebar_right_status && (
            <RIGHT_SIDEBAR_SPATIAL_AI
              loadingList={loadingList}
              loadingDetail={loadingDetail}
              geo_project={geo_project}
              language={language}
              tableComponent={this.state.tableComponent}
            />
          )
        )}

        {table_content}

        <style>{`
    body{
      padding-top: 0px;
      overflow-y: hidden;
    }
    #navbarDefault{ visibility: hidden;}
    .top_nav{ visibility: hidden;}
    .sidebar_home{ visibility: hidden;}
    .nav_bottom{ visibility: hidden;}
 `}</style>

        <Helmet>
          <title>SINI AI</title>
          <meta name="description" content="SINI AI" />
        </Helmet>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  map: state.map,
  layer: state.layer,
  project: state.project,
  payment: state.payment,
  properties: state.properties,
  license_reducer: state.license_reducer,
});

export default connect(mapStateToProps, {
  set_value_layer,
  setLanguage,
  get_history_project,
  setStatisticMode,
  setMapMode,
  get_quota_access,
  set_modal_quota_access,
  set_value_properties,
  set_value_user,
  logoutUser,
  clearProjectList,
  clearFormList,
  verify_license_user,
  verify_license_group,
})(PAGE_SPATIAL_AI);
