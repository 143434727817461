/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer, Marker } from "@urbica/react-map-gl";
import centroid from "@turf/centroid";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import colors_layer_rdtr from "../../Data/colors_layer_rdtr.json";
import colors_rdtr from "../../Data/colors_rdtr.json";
import calculate_rdtr from "../../App/validation/calculate_rdtr";

/*NON IMPORT*/

class LAYER_SINI_RDTR_NEIGHBOR extends Component {
  state = {
    render_content: null,
  };

  componentDidMount() {
    this.on_render_layer();
  }

  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    if (state_update_after !== state_update_before) {
      this.on_render_layer();
    }
  }

  on_click_rdtr = (event) => {
    const {
      rdtr_calc_mode,
      rdtr_input_area_m2,
      // ai_parent_detail_list,
      // request_id_active,
    } = this.props.sini;
    const long = event?.lngLat?.lng || 0;
    const lat = event?.lngLat?.lat || 0;
    const properties = event?.features?.[0]?.properties || {};
    const { OBJECTID, NAMOBJ, LUASHA } = properties;

    // const sini_object = ai_parent_detail_list.find(
    //   (item) => item.request_id === request_id_active
    // );
    // const rdtr_one = sini_object?.rdtr_one;
    // const rdtr_neighbor = sini_object?.rdtr_neighbor;
    // const rdtr_features = [rdtr_one, ...rdtr_neighbor];
    // let feature = rdtr_features.find(
    //   (item) => item.properties.OBJECTID === OBJECTID
    // );
    // console.log("feature=", feature);

    this.props.set_value_sini({
      key: "rdtr_input_OBJECTID",
      value: OBJECTID,
    });
    this.props.set_value_sini({
      key: "rdtr_input_long",
      value: long,
    });
    this.props.set_value_sini({
      key: "rdtr_input_lat",
      value: lat,
    });
    this.props.set_value_sini({
      key: "rdtr_input_NAMOBJ",
      value: NAMOBJ,
    });
    this.props.set_value_sini({
      key: "rdtr_input_LUASHA",
      value: LUASHA,
    });
    if (rdtr_calc_mode === "one_mode") {
      let { KDB, KLB, KDH } = properties;
      [KDB, KLB, KDH] = [KDB, KLB, KDH].map((value) =>
        isNaN(Number(value)) ? 0 : Number(value)
      );
      this.props.set_value_sini({
        key: "rdtr_input_KDB",
        value: KDB,
      });
      this.props.set_value_sini({
        key: "rdtr_input_KLB",
        value: KLB,
      });
      this.props.set_value_sini({
        key: "rdtr_input_KDH",
        value: KDH,
      });
      const result = calculate_rdtr({ KDB, KLB, KDH, rdtr_input_area_m2 });
      const {
        rdtr_result_LDB,
        rdtr_result_LLB,
        rdtr_result_LRH,
        rdtr_result_JLB,
      } = result;
      this.props.set_value_sini({
        key: "rdtr_result_LDB",
        value: rdtr_result_LDB,
      });
      this.props.set_value_sini({
        key: "rdtr_result_LLB",
        value: rdtr_result_LLB,
      });
      this.props.set_value_sini({
        key: "rdtr_result_JLB",
        value: rdtr_result_JLB,
      });
      this.props.set_value_sini({
        key: "rdtr_result_LRH",
        value: rdtr_result_LRH,
      });
    }
  };

  on_render_layer = () => {
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_polygon_fill_opacity,
      sini_polygon_line_width,
      sini_show_polygon_label,
      sini_use_label_color,
      request_id_active,
      rdtr_input_OBJECTID,
    } = this.props.sini;
    const visibility = sini_map_show.includes("rdtr_neighbor")
      ? "visible"
      : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];

    const render_content = merge_array.map((item, idx) => {
      const rdtr_neighbor = item?.rdtr_neighbor;
      let features = [];
      if (rdtr_neighbor?.length > 0) {
        features = rdtr_neighbor;
      }
      const geojson_rdtr_neighbor = {
        type: "FeatureCollection",
        features: features,
      };
      let label_content;
      if (sini_show_polygon_label && visibility === "visible") {
        const features_center_label = features.map((item) => {
          let feature = {};
          const geojson = {
            type: "FeatureCollection",
            features: [item],
          };
          feature = centroid(geojson, { properties: item.properties });
          return feature;
        });
        label_content = features_center_label.map((item, idx) => {
          let style = {};
          if (sini_use_label_color) {
            const color =
              colors_rdtr.find(
                (element) =>
                  String(element?.name) ===
                  String(item?.properties?.["NAMZON_1"])
              )?.color || "#581845";
            style = { backgroundColor: color, color: "#fff" };
          }
          return (
            <Marker
              key={idx}
              longitude={item.geometry.coordinates[0]}
              latitude={item.geometry.coordinates[1]}
              draggable={false}
            >
              <div className="badge_pill_small" style={style}>
                {item?.properties?.["NAMZON_1"]}
              </div>
            </Marker>
          );
        });
      }

      return (
        <div key={idx}>
          <Source
            key={`geojson_rdtr_neighbor_${idx}`}
            id={`geojson_rdtr_neighbor_${idx}`}
            type="geojson"
            data={geojson_rdtr_neighbor}
          />
          <Layer
            id={`geojson_rdtr_neighbor_${idx}`}
            source={`geojson_rdtr_neighbor_${idx}`}
            before={`poi_${idx}`}
            type="fill"
            paint={{
              "fill-color": colors_layer_rdtr,
              "fill-opacity": Number(sini_polygon_fill_opacity),
            }}
            layout={{
              visibility: visibility,
            }}
            onClick={this.on_click_rdtr}
          />
          <Layer
            id={`geojson_rdtr_neighbor_line_${idx}`}
            source={`geojson_rdtr_neighbor_${idx}`}
            before={`poi_${idx}`}
            type="line"
            paint={{
              "line-color": colors_layer_rdtr,
              "line-width": Number(sini_polygon_line_width),
              "line-opacity": 1,
              "line-gap-width": 0,
            }}
            layout={{ visibility: visibility }}
          />
          {label_content}
        </div>
      );
    });

    let selected_content;

    if (rdtr_input_OBJECTID && request_id_active) {
      const sini_object = merge_array.find(
        (item) => item.request_id === request_id_active
      );
      const rdtr_one = sini_object?.rdtr_one;
      const rdtr_neighbor = sini_object?.rdtr_neighbor || [];
      let rdtr_features = [rdtr_one, ...rdtr_neighbor];
      rdtr_features = rdtr_features.filter(
        (item) => item.properties.OBJECTID === rdtr_input_OBJECTID
      );
      const geojson_selected = {
        type: "FeatureCollection",
        features: rdtr_features,
      };
      selected_content = (
        <>
          <Source
            key="geojson_selected"
            id="geojson_selected"
            type="geojson"
            data={geojson_selected}
          />
          <Layer
            id="geojson_selected"
            source="geojson_selected"
            before=""
            type="line"
            paint={{
              "line-color": "#292571",
              "line-width": 3,
              "line-opacity": 1,
              "line-gap-width": 0,
            }}
            layout={{ visibility: visibility }}
          />
        </>
      );
    }

    this.setState({
      render_content: (
        <>
          {render_content}
          {selected_content}
        </>
      ),
    });
  };

  render() {
    return this.state.render_content;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  sini: state.sini,
});

export default connect(mapStateToProps, { set_value_sini })(
  LAYER_SINI_RDTR_NEIGHBOR
);
