/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import STATUS_GET_GENANGAN_CONTENT from "./STATUS_GET_GENANGAN_CONTENT";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class STATUS_GET_GENANGAN extends Component {
  state = {};

  on_close_modal = () => {
    this.props.set_value_properties({
      key: "modal_pop_up_genangan",
      value: false,
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { modal_pop_up_genangan } = this.props.properties;
    // const modal_pop_up_genangan = false;

    //content

    const modal_content = modal_pop_up_genangan && (
      <Modal
        modalSize="small"
        isOpen={modal_pop_up_genangan}
        onClose={this.on_close_modal}
      >
        <div className="box-body" id="box-body">
          <STATUS_GET_GENANGAN_CONTENT />
        </div>
      </Modal>
    );

    return <>{modal_content}</>;
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_properties })(
  STATUS_GET_GENANGAN
);
