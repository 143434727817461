/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import SINI_POLYGON_CONFIG from "./SINI_POLYGON_CONFIG";
import SINI_DOWNLOAD_UNIVERSAL from "./SINI_DOWNLOAD_UNIVERSAL";

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/
import ICON_BACK from "../../Assets/jsx/ICON_BACK";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import colors_landzone from "../../Data/colors_landzone.json";

/*NON IMPORT*/

class SINI_LAND_FULL extends Component {
  state = {
    anchor: null,
    modal_layer_config: false,
    modal_download_landvalue: false,
    modal_download_landzone: false,
    modal_detail: false,
  };

  toggle_detail = (e) => {
    this.setState({
      modal_detail: !this.state.modal_detail,
    });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  toggle_download_landvalue = (e) => {
    this.setState({
      modal_download_landvalue: !this.state.modal_download_landvalue,
    });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  toggle_download_landzone = (e) => {
    this.setState({
      modal_download_landzone: !this.state.modal_download_landzone,
    });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  toggle_layer_config = (e) => {
    this.setState({ modal_layer_config: !this.state.modal_layer_config });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  toggle_sini_map_show = (item) => {
    let { sini_map_show } = this.props.sini;
    if (sini_map_show.includes(item)) {
      sini_map_show = sini_map_show.filter((element) => element !== item);
    } else {
      sini_map_show.push(item);
    }
    this.props.set_value_sini({
      key: "sini_map_show",
      value: sini_map_show,
    });
  };

  on_set_sini_menu_active = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "",
    });
    this.props.set_value_sini({
      key: "sini_map_show",
      value: ["poi", "isochrone"],
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const {
      modal_detail,
      modal_layer_config,
      modal_download_landvalue,
      modal_download_landzone,
      anchor,
    } = this.state;

    //global props
    const {
      sini_v2_list,
      request_id_active,
      ai_parent_detail_list,
      sini_map_show,
      is_show_download_button,
    } = this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const is_loading_landvalue = loading_item_array.includes("landvalue");
    const is_loading_landzone = loading_item_array.includes("landzone");
    const is_loading_property = loading_item_array.includes("poi_property");

    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );

    const landvalue_one = sini_object?.landvalue_one;
    const landzone_one = sini_object?.landzone_one;
    const landvalue_neighbor = sini_object?.landvalue_neighbor || [];
    const landzone_neighbor = sini_object?.landzone_neighbor || [];

    const average_property_rumah_rp = sini_object?.average_property_rumah_rp;
    const average_property_apartemen_rp =
      sini_object?.average_property_apartemen_rp;
    const average_property_ruko_rp = sini_object?.average_property_ruko_rp;

    const min_property_rumah_rp = sini_object?.min_property_rumah_rp;
    const min_property_apartemen_rp = sini_object?.min_property_apartemen_rp;
    const min_property_ruko_rp = sini_object?.min_property_ruko_rp;

    const max_property_rumah_rp = sini_object?.max_property_rumah_rp;
    const max_property_apartemen_rp = sini_object?.max_property_apartemen_rp;
    const max_property_ruko_rp = sini_object?.max_property_ruko_rp;

    const count_property_rumah = sini_object?.count_property_rumah;
    const count_property_apartemen = sini_object?.count_property_apartemen;
    const count_property_ruko = sini_object?.count_property_ruko;

    /*
     "properties": {
    "color": "#1a8bc0",
    "opacity": 0.5,
    "fid": 3655,
    "NOMORZONA": "120",
    "TAHUN": "2021",
    "RANGE_HRG": "< 100.000",
    "HARGA": "0",
    "HARGARATA2": "120",
    "KELASNILAI": "1"
  }
    */

    let content;
    if (
      is_loading_landvalue ||
      landvalue_one !== undefined ||
      landzone_one !== undefined
    ) {
      const landvalue_value = landvalue_one?.properties?.["HARGA"];
      const landvalue_range = landvalue_one?.properties?.["RANGE_HRG"];
      const landzone_value = landzone_one?.properties?.["GUNATANAH"];

      const is_landvalue_empty =
        landvalue_value === undefined &&
        landvalue_range === undefined &&
        landvalue_neighbor.length === 0;

      const is_landzone_empty =
        !landzone_value ||
        landzone_value === undefined ||
        landzone_value === "undefined" ||
        landzone_neighbor.length === 0;

      let property_content;
      if (is_loading_landzone || is_loading_landvalue || is_loading_property) {
        property_content = (
          <>
            <p className="text_small">Loading...</p>
            <br />
          </>
        );
      } else {
        property_content = (
          <main className="margin_bottom">
            <section className="margin_bottom">
              <p className="text_small">Rata-rata harga rumah</p>
              <h1 className="text_bold">
                {!!average_property_rumah_rp ? (
                  <>
                    Rp.
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(average_property_rumah_rp)}
                  </>
                ) : (
                  <>Tidak ada data</>
                )}
              </h1>
            </section>
            <section className="margin_bottom">
              <p className="text_small">Rata-rata harga apartemen</p>
              <h1 className="text_bold">
                {!!average_property_apartemen_rp ? (
                  <>
                    Rp.
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(average_property_apartemen_rp)}
                  </>
                ) : (
                  <>Tidak ada data</>
                )}
              </h1>
            </section>
            <section className="margin_bottom">
              <p className="text_small">Rata-rata harga ruko</p>
              <h1 className="text_bold">
                {!!average_property_ruko_rp ? (
                  <>
                    Rp.
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(average_property_ruko_rp)}
                  </>
                ) : (
                  <>Tidak ada data</>
                )}
              </h1>
            </section>
            <button className="button" onClick={this.toggle_detail}>
              Detail
            </button>
          </main>
        );
      }

      let landvalue_content;
      if (is_loading_landvalue) {
        landvalue_content = (
          <>
            <p className="text_small">{dict?.["Loading"]?.[language]}...</p>
            <br />
          </>
        );
      } else if (is_landvalue_empty) {
        landvalue_content = (
          <section className="margin_bottom">
            <p className="text_small">Tidak ada data nilai tanah</p>
          </section>
        );
      } else {
        landvalue_content = (
          <main>
            <table>
              <tbody>
                <tr>
                  <td>
                    <button
                      className={
                        sini_map_show.includes("landvalue")
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      onClick={this.toggle_sini_map_show.bind(
                        this,
                        "landvalue"
                      )}
                    />
                  </td>
                  <td>
                    <p className="text_small">Perkiraan harga tanah</p>
                    <div>
                      <span className="text_bold margin_right">
                        Rp
                        {landvalue_value !== "0"
                          ? new Intl.NumberFormat("id-ID", {
                              style: "decimal",
                            }).format(parseInt(landvalue_value))
                          : landvalue_range}
                      </span>
                      <p className="text_small">
                        (per m<sup>2</sup>)
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className={
                        sini_map_show.includes("landvalue_neighbor")
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      onClick={this.toggle_sini_map_show.bind(
                        this,
                        "landvalue_neighbor"
                      )}
                    />
                  </td>
                  <td>
                    <p className="text_small margin_right">
                      Harga tanah sekitar
                    </p>
                    <p className="text_bold">
                      {landvalue_neighbor?.length || 0} area
                    </p>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>{property_content}</td>
                </tr>
              </tbody>
            </table>
            {is_show_download_button && (
              <button
                className="badge background_green margin_bottom"
                onClick={this.toggle_download_landvalue}
              >
                {dict?.["Download"]?.[language]}
              </button>
            )}
          </main>
        );
      }

      let landzone_content;
      if (is_loading_landzone || is_loading_landvalue) {
        landzone_content = (
          <>
            <p className="text_small">Loading...</p>
            <br />
          </>
        );
      } else if (!is_landzone_empty) {
        landzone_content = (
          <main>
            <table>
              <tbody>
                <tr>
                  <td>
                    <button
                      className={
                        sini_map_show.includes("landzone")
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      onClick={this.toggle_sini_map_show.bind(this, "landzone")}
                    />
                  </td>
                  <td>
                    <p className="text_small">Penggunaan lahan</p>
                    <div>
                      <span className="text_bold">{landzone_value}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className={
                        sini_map_show.includes("landzone_neighbor")
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      onClick={this.toggle_sini_map_show.bind(
                        this,
                        "landzone_neighbor"
                      )}
                    />
                  </td>
                  <td>
                    <p className="text_small margin_right">
                      Penggunaan lahan sekitar
                    </p>
                    <p className="text_bold">
                      {landzone_neighbor?.length || 0} area
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            {is_show_download_button && (
              <button
                className="badge background_green margin_bottom"
                onClick={this.toggle_download_landzone}
              >
                {dict?.["Download"]?.[language]}
              </button>
            )}
          </main>
        );
      } else {
        landzone_content = (
          <section className="margin_bottom">
            <p className="text_small">Tidak ada data guna lahan</p>
          </section>
        );
      }

      content = (
        <main>
          <section className="container_light margin_bottom">
            {landvalue_content}
          </section>

          <section className="container_light margin_bottom">
            {landzone_content}
          </section>
        </main>
      );
    }

    const legend_content = (
      <main className="container_light">
        <p className="text_bold margin_bottom">Legenda guna lahan</p>
        <button
          className="button_small margin_bottom"
          onClick={this.toggle_layer_config}
        >
          Edit tampilan
        </button>
        <br />
        <table className="text_small">
          <tbody>
            {colors_landzone.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "5px",
                        backgroundColor: item.color,
                      }}
                    />
                  </td>
                  <td>{item.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    );

    const modal_layer_config_content = modal_layer_config && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_layer_config}
        onClose={this.toggle_layer_config}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
            width: "250px",
          }}
        >
          <SINI_POLYGON_CONFIG />
        </div>
      </Menu>
    );

    const modal_download_landvalue_content = modal_download_landvalue && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_download_landvalue}
        onClose={this.toggle_download_landvalue}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
            width: "250px",
          }}
        >
          <SINI_DOWNLOAD_UNIVERSAL download_topic="landvalue_one" />
        </div>
      </Menu>
    );

    const modal_download_landzone_content = modal_download_landzone && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_download_landzone}
        onClose={this.toggle_download_landzone}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
            width: "250px",
          }}
        >
          <SINI_DOWNLOAD_UNIVERSAL download_topic="landzone_one" />
        </div>
      </Menu>
    );

    const modal_detail_content = modal_detail && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_detail}
        onClose={this.toggle_detail}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Nilai min</th>
                <th>Nilai max</th>
                <th>Rata-rata</th>
                <th>Jumlah data</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Rumah</td>
                <td>
                  Rp.
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    min_property_rumah_rp
                  )}
                </td>
                <td>
                  Rp.
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    max_property_rumah_rp
                  )}
                </td>
                <td>
                  Rp.
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    average_property_rumah_rp
                  )}
                </td>
                <td>{count_property_rumah}</td>
              </tr>
              <tr>
                <td>Apartemen</td>
                <td>
                  Rp.
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    min_property_apartemen_rp
                  )}
                </td>
                <td>
                  Rp.
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    max_property_apartemen_rp
                  )}
                </td>
                <td>
                  Rp.
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    average_property_apartemen_rp
                  )}
                </td>
                <td>{count_property_apartemen}</td>
              </tr>
              <tr>
                <td>Ruko</td>
                <td>
                  Rp.
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    min_property_ruko_rp
                  )}
                </td>
                <td>
                  Rp.
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    max_property_ruko_rp
                  )}
                </td>
                <td>
                  Rp.
                  {new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                    average_property_ruko_rp
                  )}
                </td>
                <td>{count_property_ruko}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Menu>
    );

    return (
      <main className="sini_full_parent">
        {modal_layer_config_content}
        {modal_download_landvalue_content}
        {modal_download_landzone_content}
        {modal_detail_content}
        <section className="sini_full_header">
          <table>
            <tbody>
              <tr>
                <td
                  style={{ width: "30px" }}
                  onClick={this.on_set_sini_menu_active}
                >
                  <ICON_BACK />
                </td>
                <td className="text_left">
                  <div className="badge background_green margin_bottom">
                    Guna dan perkiraan nilai tanah
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <br />
        <section className="sini_full_scroll_area">
          {content}
          {legend_content}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { set_value_sini })(SINI_LAND_FULL);
