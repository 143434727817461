import {
  Paragraph,
  Document,
  Packer,
  ImageRun,
  TextRun,
  AlignmentType,
  LevelFormat,
  TabStopType,
  UnderlineType,
} from "docx";
import { saveAs } from "file-saver";
import kop_brantas from "../../Assets/img/kop_a.png";
import formatDateFormal from "../../App/validation/format-date-formal";
const tab_b = 3200;
const tab = 3400;
const tab_polos = 3400;
const image_width = 420;
export const FormatA = async (feature, index) => {
  try {
    const properties = feature?.properties;
    const jumlah_korban =
      parseInt(
        !!properties?.["Jumlah_Korban_Meninggal"]
          ? properties?.["Jumlah_Korban_Meninggal"]
          : 0
      ) +
      parseInt(
        !!properties?.["Jumlah_Korban_Luka_Berat"]
          ? properties?.["Jumlah_Korban_Luka_Berat"]
          : 0
      ) +
      parseInt(
        !!properties?.["Jumlah_Korban_Luka_Ringan"]
          ? properties?.["Jumlah_Korban_Luka_Ringan"]
          : 0
      ) +
      parseInt(
        !!properties?.["Jumlah_Korban_Hilang"]
          ? properties?.["Jumlah_Korban_Hilang"]
          : 0
      ) +
      parseInt(
        !!properties?.["Jumlah_Korban_Hanyut"]
          ? properties?.["Jumlah_Korban_Hanyut"]
          : 0
      );
    let jumlah_meninggal = !!properties?.["Jumlah_Korban_Meninggal"]
      ? `${properties?.["Jumlah_Korban_Meninggal"]} Meninggal, `
      : "";
    let jumlah_luka_berat = !!properties?.["Jumlah_Korban_Luka_Berat"]
      ? `${properties?.["Jumlah_Korban_Luka_Berat"]} Luka Berat, `
      : "";
    let jumlah_luka_ringan = !!properties?.["Jumlah_Korban_Luka_Ringan"]
      ? `${properties?.["Jumlah_Korban_Luka_Ringan"]} Luka Ringan, `
      : "";
    let jumlah_hilang = !!properties?.["Jumlah_Korban_Hilang"]
      ? `${properties?.["Jumlah_Korban_Hilang"]} Hilang, `
      : "";
    let jumlah_hanyut = !!properties?.["Jumlah_Korban_Hanyut"]
      ? `${properties?.["Jumlah_Korban_Hanyut"]} Hanyut, `
      : "";
    let text_korban = `${jumlah_korban} Orang (${jumlah_meninggal}${jumlah_luka_berat}${jumlah_luka_ringan}${jumlah_hilang}${jumlah_hanyut})`;
    if (!jumlah_korban) {
      text_korban = "Nihil.";
    }
    let sumberdaya = properties?.["Sumberdaya_yang_Tersedia_Dilokasi_Bencana"];
    if (sumberdaya) {
      sumberdaya = sumberdaya.split("\n");
      sumberdaya = sumberdaya.map((s) => {
        return new Paragraph({
          style: "12",
          text: s,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let kerusakan = properties?.["Kerusakan"];
    if (kerusakan) {
      kerusakan = kerusakan.split("\n");
      kerusakan = kerusakan.join(", ");
    }
    let kendala = properties?.["KendalaHambatan"];
    if (kendala) {
      kendala = kendala.split("\n");
      kendala = kendala.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let kebutuhan = properties?.["Kebutuhan_Mendesak"];
    if (kebutuhan) {
      kebutuhan = kebutuhan.split("\n");
      kebutuhan = kebutuhan.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let dampak_pemukiman = properties?.["Dampak_Permukiman_Penduduk"]
      ? properties?.["Dampak_Permukiman_Penduduk"]
      : "-";
    let first_pemukiman;
    if (dampak_pemukiman) {
      dampak_pemukiman = dampak_pemukiman.split("\n");
      first_pemukiman = dampak_pemukiman.shift();
      dampak_pemukiman = dampak_pemukiman.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let dampak_perkotaan = properties?.["Dampak_Perkotaan"]
      ? properties?.["Dampak_Perkotaan"]
      : "-";
    let first_perkotaan;
    if (dampak_perkotaan) {
      dampak_perkotaan = dampak_perkotaan.split("\n");
      first_perkotaan = dampak_perkotaan.shift();
      dampak_perkotaan = dampak_perkotaan.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let dampak_industri = properties?.["Dampak_Kawasan_Industri"]
      ? properties?.["Dampak_Kawasan_Industri"]
      : "-";
    let first_industri;
    if (dampak_industri) {
      dampak_industri = dampak_industri.split("\n");
      first_industri = dampak_industri.shift();
      dampak_industri = dampak_industri.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let dampak_sarana = properties?.["Dampak_Sarana_Prasarana"]
      ? properties?.["Dampak_Sarana_Prasarana"]
      : "-";
    let first_sarana;
    if (dampak_sarana) {
      dampak_sarana = dampak_sarana.split("\n");
      first_sarana = dampak_sarana.shift();
      dampak_sarana = dampak_sarana.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let dampak_pertanian = properties?.["Dampak_Pertanian"]
      ? properties?.["Dampak_Pertanian"]
      : "-";
    let first_pertanian;
    if (dampak_pertanian) {
      dampak_pertanian = dampak_pertanian.split("\n");
      first_pertanian = dampak_pertanian.shift();
      dampak_pertanian = dampak_pertanian.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_s",
            level: 0,
          },
        });
      });
    }
    let penanganan = properties?.["Penanganan_Darurat_yang_Telah_Dilakukan"];
    if (penanganan) {
      penanganan = penanganan.split("\n");
      penanganan = penanganan.map((text) => {
        return new Paragraph({
          style: "12",
          text: text,
          numbering: {
            reference: "poin_wide",
            level: 0,
          },
        });
      });
    }
    const getImage = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const r = await fetch(url);
          const blob = await r.blob();
          resolve(blob);
        } catch (error) {
          reject({ error: error, status: "Image fetch error nih" });
        }
      });
    };
    const kop_img = await getImage(kop_brantas);
    const image_1 = await getImage(properties?.["Dokumentasi_1"]);
    const image_2 = await getImage(properties?.["Dokumentasi_2"]);
    const image_3 = await getImage(properties?.["Dokumentasi_3"]);
    const getMeta = (url) => {
      return new Promise((resolve) => {
        const img = new Image();
        let width;
        let height;
        img.addEventListener("load", function (e) {
          width = this.naturalWidth;
          height = this.naturalHeight;
          //r=w/h
          resolve(width / height);
        });
        img.src = url;
      });
    };
    const d1 = await getMeta(properties?.["Dokumentasi_1"]);
    const d2 = await getMeta(properties?.["Dokumentasi_2"]);
    const d3 = await getMeta(properties?.["Dokumentasi_3"]);
    const form_b = new Document({
      creator: "BBWS BRANTAS",
      description: "Laporan Banjir BBWS Brantas",
      title: "Laporan Banjir BBWS Brantas",
      styles: {
        paragraphStyles: [
          {
            id: "12",
            name: "12",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 24,
            },
          },
          {
            id: "judul_tengah",
            name: "Judul Tengah",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            paragraph: {
              alignment: AlignmentType.CENTER,
            },
            run: {
              size: 24,
            },
          },
          {
            id: "ttd",
            name: "ttd",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 24,
            },
            paragraph: {
              alignment: AlignmentType.CENTER,
              indent: { left: 4000, hanging: 0 },
            },
          },
        ],
      },
      numbering: {
        config: [
          {
            reference: "numbering_s",
            levels: [
              {
                level: 0,
                format: LevelFormat.DECIMAL,
                text: "%1.",
                alignment: AlignmentType.START,
                style: {
                  run: {
                    size: 24,
                  },
                  paragraph: {
                    indent: { left: 500, hanging: 500 },
                  },
                },
              },
              {
                level: 1,
                format: LevelFormat.LOWER_LETTER,
                text: "%2.",
                alignment: AlignmentType.START,
                style: {
                  run: {
                    size: 24,
                  },
                  paragraph: {
                    indent: { left: 800, hanging: 300 },
                  },
                },
              },
              {
                level: 2,
                format: LevelFormat.DECIMAL,
                text: "%3.",
                alignment: AlignmentType.START,
                style: {
                  run: {
                    size: 24,
                  },
                  paragraph: {
                    indent: { left: 1200, hanging: 300 },
                  },
                },
              },
            ],
          },
          {
            reference: "tembusan",
            levels: [
              {
                level: 0,
                format: LevelFormat.DECIMAL,
                text: "%1.",
                alignment: AlignmentType.START,
                style: {
                  run: {
                    size: 24,
                  },
                  paragraph: {
                    indent: { left: 500, hanging: 500 },
                  },
                },
              },
              {
                level: 1,
                format: LevelFormat.LOWER_LETTER,
                text: "%2.",
                alignment: AlignmentType.START,
                style: {
                  run: {
                    size: 24,
                  },
                  paragraph: {
                    indent: { left: 800, hanging: 300 },
                  },
                },
              },
              {
                level: 2,
                format: LevelFormat.DECIMAL,
                text: "%3.",
                alignment: AlignmentType.START,
                style: {
                  run: {
                    size: 24,
                  },
                  paragraph: {
                    indent: { left: 1200, hanging: 300 },
                  },
                },
              },
            ],
          },
          {
            reference: "poin_s",
            levels: [
              {
                level: 0,
                format: LevelFormat.BULLET,
                text: "-",
                alignment: AlignmentType.LEFT,
                style: {
                  run: {
                    size: 24,
                  },
                  paragraph: {
                    indent: { left: 3450, hanging: 150 },
                  },
                },
              },
            ],
          },
          {
            reference: "poin_wide",
            levels: [
              {
                level: 0,
                format: LevelFormat.BULLET,
                text: "-",
                alignment: AlignmentType.LEFT,
                style: {
                  run: {
                    size: 24,
                  },
                  paragraph: {
                    indent: { left: 1200, hanging: 300 },
                  },
                },
              },
            ],
          },
        ],
      },
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: 0,
                bottom: -100,
              },
            },
          },
          children: [
            new Paragraph({
              style: "12",
              children: [
                new ImageRun({
                  data: kop_img,
                  transformation: {
                    width: 597.015,
                    height: 100,
                  },
                }),
                new TextRun({
                  text: "Format A",
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              style: "judul_tengah",
              children: [
                new TextRun({
                  text: "Laporan Detail Kejadian Bencana",
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  text: "Kepada Yth.",
                }),
                new TextRun({
                  text: "POSKO PB PU",
                  break: 1,
                }),
                new TextRun({
                  text: "Ditjen Sumber Daya Air",
                  break: 1,
                }),
                new TextRun({
                  text: "Jl. Pattimura 20 Jakarta Selatan ",
                  break: 1,
                }),
                new TextRun({
                  text: "Di",
                  break: 1,
                }),
                new TextRun({
                  text: "  Jakarta",
                  break: 1,
                }),
              ],
            }),
            new Paragraph({
              style: "judul_tengah",
              children: [
                new TextRun({
                  text: "LAPORAN KEJADIAN BENCANA",
                  break: 0,
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              text: "BENCANA",
              numbering: {
                reference: "numbering_s",
                level: 0,
              },
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Jenis Bencana" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun("\t"),
                new TextRun(`${properties?.["Jenis_Bencana"]}`),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Waktu Kejadian" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun("\t"),
                new TextRun(
                  `${properties?.["Hari_Kejadian"]}, ${formatDateFormal(
                    properties?.["Tanggal_Kejadian"]
                  )}`
                ),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Tempat Kejadian" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun("\t"),
                new TextRun(`${properties?.["Tempat_Kejadian_Nama_Sungai"]}`),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun("\t"),
                new TextRun(`${properties?.["DesaKelurahan"]}`),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_polos,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun("\t"),
                new TextRun(`${properties?.["Kecamatan"]}`),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_polos,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun("\t"),
                new TextRun(`${properties?.["KabupatenKota"]}`),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_polos,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 0,
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              text: "KORBAN / KERUSAKAN YANG TELAH TERJADI",
              numbering: {
                reference: "numbering_s",
                level: 0,
              },
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Korban" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun("\t"),
                new TextRun(` ${text_korban}`),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Kerusakan" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun("\t"),
                new TextRun(` ${kerusakan}`),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 0,
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              text: "BAHAYA BENCANA MASIH MENGANCAM",
              numbering: {
                reference: "numbering_s",
                level: 0,
              },
            }),
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Permukiman Penduduk" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(
                  ` - ${first_pemukiman !== "-" ? first_pemukiman : ""}`
                ),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            ...dampak_pemukiman,
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Perkotaan" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(
                  ` - ${first_perkotaan !== "-" ? first_perkotaan : ""}`
                ),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            ...dampak_perkotaan,
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Kawasan Industri" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(
                  ` - ${first_industri !== "-" ? first_industri : ""}`
                ),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            ...dampak_industri,
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Sarana / prasarana" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(` - ${first_sarana !== "-" ? first_sarana : ""}`),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            ...dampak_sarana,
            new Paragraph({
              style: "12",
              numbering: {
                reference: "numbering_s",
                level: 1,
              },
              children: [
                new TextRun({ text: "Pertanian" }),
                new TextRun("\t"),
                new TextRun(":"),
                new TextRun(
                  ` - ${first_pertanian !== "-" ? first_pertanian : ""}`
                ),
              ],
              tabStops: [
                {
                  type: TabStopType.LEFT,
                  position: tab_b,
                },
                {
                  type: TabStopType.LEFT,
                  position: tab,
                },
              ],
            }),
            ...dampak_pertanian,
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 0,
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              text: `PERKIRAAN LAMANYA ANCAMAN BENCANA: ${properties?.["Perkiraan_Lamanya_Ancaman_BencanaJamHari"]}`,
              numbering: {
                reference: "numbering_s",
                level: 0,
              },
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 0,
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              text: `PENANGAN DARURAT YANG TELAH DILAKUKAN: `,
              numbering: {
                reference: "numbering_s",
                level: 0,
              },
            }),
            ...penanganan,
            new Paragraph({
              style: "ttd",
              children: [
                new TextRun({
                  text: `Surabaya, ${formatDateFormal(Date.now())}`,
                }),
              ],
            }),
            new Paragraph({
              style: "ttd",
              children: [
                new TextRun({
                  text: "An. Kepala Bidang Operasi dan Pemeliharaan SDA",
                }),
              ],
            }),
            new Paragraph({
              style: "ttd",
              children: [
                new TextRun({
                  text: "Subkoord. Pelaksanaan O&P",
                }),
              ],
            }),
            new Paragraph({
              style: "ttd",
              children: [
                new TextRun({
                  text: "Balai Besar Wilayah Sungai Brantas",
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  break: 2,
                }),
              ],
            }),
            new Paragraph({
              style: "ttd",
              children: [
                new TextRun({
                  text: "Lucky Dyah Ekorini, ST, MT",
                  bold: true,
                  underline: {
                    type: UnderlineType.SINGLE,
                    color: "000000",
                  },
                }),
              ],
            }),
            new Paragraph({
              style: "ttd",
              children: [
                new TextRun({
                  text: "NIP. 19751227 201012 2 001",
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              children: [
                new TextRun({
                  text: "Tembusan:",
                  underline: {
                    type: UnderlineType.SINGLE,
                    color: "000000",
                  },
                }),
              ],
            }),
            new Paragraph({
              style: "12",
              text: "Sekretariat Satgas PBPU",
              numbering: {
                reference: "tembusan",
                level: 0,
              },
            }),
            new Paragraph({
              style: "12",
              text: "Kepala Balai Besar Wilayah Sungai Brantas (sebagai laporan)",
              numbering: {
                reference: "tembusan",
                level: 0,
              },
            }),
          ],
        },
        {
          children: [
            new Paragraph({
              style: "judul_tengah",
              children: [
                new TextRun({
                  text: `${properties?.["Jenis_Bencana"]} di ${properties?.["Tempat_Kejadian_Nama_Sungai"]}`,
                  bold: true,
                }),
                new TextRun({
                  text: `${properties?.["DesaKelurahan"]} ${properties?.["Kecamatan"]}`,
                  break: 1,
                  bold: true,
                }),
                new TextRun({
                  text: `${properties?.["KabupatenKota"]}`,
                  break: 1,
                  bold: true,
                  underline: {
                    type: UnderlineType.SINGLE,
                    color: "000000",
                  },
                }),
                new TextRun({
                  break: 4,
                }),
                new ImageRun({
                  data: image_1,
                  transformation: {
                    width: image_width,
                    height: image_width / d1,
                  },
                }),
                new TextRun({
                  break: 2,
                }),
                new ImageRun({
                  data: image_2,
                  transformation: {
                    width: image_width,
                    height: image_width / d2,
                  },
                }),
                new TextRun({
                  break: 2,
                }),
                new ImageRun({
                  data: image_3,
                  transformation: {
                    width: image_width,
                    height: image_width / d3,
                  },
                }),
              ],
            }),
          ],
        },
      ],
    });
    Packer.toBlob(form_b).then((blob) => {
      saveAs(blob, `Form A Brantas Baris ke ${index + 1}.docx`);
    });
  } catch (e) {}
};
