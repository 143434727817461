import axios from "axios";

const DATA_URL = "https://data.mapid.io";
const KEY_MAPBOX = `pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g`;

//GET

//get_location_list
export const get_location_list = (params) => async (dispatch) => {
  try {
    params = JSON.stringify(params);
    const endpoint_api = `https://api.mapbox.com/geocoding/v5/mapbox.places/${params}.json?country=id&proximity=ip&access_token=${KEY_MAPBOX}`;
    const res = await axios.get(endpoint_api);
    dispatch({
      type: "get_location_list",
      payload: res?.data?.features || [],
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "location_result_list",
        value: res?.data?.features || [],
      },
    });
  } catch (e) {}
};

//filter_tanggal
export const filter_tanggal = ({ tanggal, mode }) => {
  return {
    type: "filter_tanggal",
    payload: { tanggal, mode },
  };
};

// filter tanggal new
/* 
  body = {
    start : "",
    end : ""
  }
*/
// export const filter_tanggal_new = (body) => {
//   return {
//     type: "filter_tanggal_new",
//     payload: body,
//   };
// };

export const filter_tanggal_new = (body) => async (dispatch) => {
  dispatch({
    type: "filter_tanggal_new",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const clear_tanggal = () => (dispatch) => {
  dispatch({
    type: "clear_tanggal",
  });
  dispatch({
    type: "status_action",
  });
};

//set_item_on_layer
export const set_item_on_layer = (body) => (dispatch) => {
  dispatch({
    type: "set_item_on_layer",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

//set_data_array
export const set_data_array = (body) => {
  return {
    type: "set_data_array",
    payload: body,
  };
};

//reset_fields
export const reset_fields = (body) => {
  return {
    type: "reset_fields",
    payload: body,
  };
};

//set_value_layer
export const set_value_layer = (key, value) => {
  return {
    type: "set_value_layer",
    payload: { key, value },
  };
};

//grid_cilicis
export const grid_cilicis = (params) => async (dispatch) => {
  const { feature_key, lat, long, genangan_cm } = params;
  try {
    dispatch(setLoading("grid_cilicis"));
    const res = await axios.get(
      DATA_URL +
        `/grid_cilicis?lat=${lat}&long=${long}&genangan_cm=${genangan_cm}`
    );
    let features = res?.data?.features ? res?.data?.features : [];
    features = features.map((f) => {
      return { ...f, feature_key };
    });
    dispatch({
      type: "grid_cilicis",
      payload: features,
    });
    //handle ketika success masukkan status success ke dalam array feature_list_genangan
    dispatch({
      type: "push_key_genangan_success",
      payload: feature_key,
    });

    const body = {
      feature_key,
      geojson: res.data,
    };
    dispatch({
      type: "push_luas_genangan_list",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    //handle ketika error masukkan status error ke dalam array feature_list_genangan
    dispatch({
      type: "push_key_genangan_error",
      payload: feature_key,
    });
    dispatch(clearLoading());
  }
};

//setLoading
export const setLoading = (itemLoading) => {
  return {
    type: "SET_LOADING_PROCESS_LAYER",
    payload: itemLoading,
  };
};

//clearLoading
export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_LAYER",
  };
};

//undo_filter_isi_layer
export const undo_filter_isi_layer = (body) => async (dispatch) => {
  /*
  body = {
  geo_layer_id,
  field_key,
  value,
  }
  */
  dispatch({
    type: "undo_filter_isi_layer",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
  dispatch({
    type: "fly_action",
  });
};

//
/**
 *filter_isi_layer --> Melakukan filter isi layer dengan parameter key-value dari sebuah kolom
 * @body  {
  geo_layer_id,
  field_key,
  value,
  start_date: tanggal,
  end_date: tanggal
  }
 * @returns
 */
export const filter_isi_layer = (body) => async (dispatch) => {
  dispatch({
    type: "filter_isi_layer",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
  dispatch({
    type: "fly_action",
  });
};

//reset_filter
export const reset_filter = (body) => async (dispatch) => {
  /*
  body = {
  geo_layer_id,
  }
  */
  dispatch({
    type: "reset_filter",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
  dispatch({
    type: "fly_action",
  });
};

//set_feature
export const set_feature = (body) => async (dispatch) => {
  dispatch({
    type: "set_feature",
    payload: body,
  });
};

//view_layer
export const view_layer = (body) => async (dispatch) => {
  /*
  body = {
  geo_layer_id,
  value,
  }
  */

  dispatch({
    type: "status_action",
  });

  dispatch({
    type: "view_layer",
    payload: body,
  });
  dispatch({
    type: "fly_action",
  });
};

export const view_heatmap = (body) => async (dispatch) => {
  /*
  body = {
  geo_layer_id,
  value,
  }
  */
  dispatch({
    type: "view_heatmap",
    payload: body,
  });
  dispatch({
    type: "fly_action",
  });
  dispatch({
    type: "status_action",
  });
};

//set_layer_id
export const set_layer_id = (layer_id) => async (dispatch) => {
  try {
    dispatch({
      type: "set_layer_id",
      payload: layer_id,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

// feature_key_array
export const set_feature_key_array = (body) => async (dispatch) => {
  try {
    dispatch({
      type: "array_key",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });
    dispatch({
      type: "simple_map_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};
