import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import editorReducer from "./editorReducer";
import projectReducer from "./projectReducer";
import layerReducer from "./layerReducer";
import paymentReducer from "./paymentReducer";
import ethnoReducer from "./globalReducer";
import blogReducer from "./blogReducer";
import iotReducer from "./iotReducer";
import playReducer from "./playReducer";
import mapReducer from "./mapReducer";
import propertiesReducer from "./propertiesReducer";
import siniReducer from "./siniReducer";
import toolboxReducer from "./toolboxReducer";
import biReducer from "./biReducer";
import loading_reducer from "./loading_reducer";
import license_reducer from "./license_reducer";
import snackbar_reducer from "./snackbar_reducer";
import map_service_reducer from "./map_service_reducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  editor: editorReducer,
  project: projectReducer,
  layer: layerReducer,
  payment: paymentReducer,
  ethno: ethnoReducer,
  blog: blogReducer,
  iot: iotReducer,
  play: playReducer,
  map: mapReducer,
  properties: propertiesReducer,
  sini: siniReducer,
  toolbox: toolboxReducer,
  bi: biReducer,
  loading_reducer: loading_reducer,
  license_reducer: license_reducer,
  snackbar: snackbar_reducer,
  map_service_reducer: map_service_reducer,
});
