import React, { Component } from "react";
import { connect } from "react-redux";
// import { Typography, Tooltip, IconButton } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { get_pch } from "../../App/actions/iotActions";
// import RefreshIcon from "@material-ui/icons/Refresh";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

class SidebarPCH extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bbwsCC: false,
      loading: true,
      loadingPCH: true,
      clock: "00:00:00",
      clockInterval: null,
      lastMinutes: 0,
      prevDataPDA: this?.props?.iot?.lastDataPDA.list
        ? this?.props?.iot?.lastDataPDA.list
        : [],
      prevDataPCH: this?.props?.iot?.lastDataPCH?.list
        ? this?.props?.iot?.lastDataPCH?.list
        : [],
    };
  }

  componentDidMount() {
    if (this?.props?.iot?.lastDataPCH?.length === 0) {
      this.props.get_pch();
    } else {
      this.setState({ loadingPCH: false });
    }

    const time = new Date();
    const m = time.getMinutes();
    this.setState({ lastMinutes: m });

    let clockInterval = setInterval(() => {
      const time = new Date();
      const h = time.getHours() < 10 ? `0${time.getHours()}` : time.getHours();
      const m =
        time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes();
      const s =
        time.getSeconds() < 10 ? `0${time.getSeconds()}` : time.getSeconds();
      this.setState({ clock: `${h}:${m}:${s}` });

      // if (parseInt(m) - this.state.lastMinutes === 5) {
      //   this.props.get_pch();
      //   this.setState({ lastMinutes: parseInt(m) });
      // }
    }, 1000);
    this.setState({ clockInterval });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this?.props?.iot?.lastDataPCH !== prevProps?.iot?.lastDataPCH) {
      this.setState({ loadingPCH: false });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.clockInterval);
  }

  refresh(e) {
    this.props.get_pch();
  }

  convertDate(params) {
    const res = params.split("-");
    return `${res[2]}/${res[1]}/${res[0].slice(2, 4)}`;
  }

  style = {
    title: {
      margin: "1.5rem 0rem",
    },
    table: {
      marginTop: "1rem",
      textAlign: "left",
      // borderCollapse: "collapse",
    },
    tr: {
      border: "solid 0.7px #c4c4c4",
    },
    normal: {
      color: "green",
    },
    siaga1: {
      color: "red",
    },
    siaga2: {
      color: "yellow",
    },
    siaga3: {
      color: "blue",
    },
    error: {
      color: "red",
    },
    delay: {
      color: "yellow",
    },
    tanpaSiaga: {
      color: "grey",
    },
    tma: {
      textAlign: "center",
    },
    iotStatistic: {
      marginBottom: "3rem",
    },
    clock: {
      justifyContent: "center",
      display: "flex",
      marginTop: "1rem",
    },
  };

  render() {
    const s = this.style;
    const contentPCH = (
      <main>
        <table className="table_pch">
          <tbody>
            <tr>
              <td>Jumlah Pos Curah Hujan</td>
              <td>
                :{" "}
                {this?.state?.loadingPCH
                  ? "loading.."
                  : this?.props?.iot?.lastDataPCH?.status?.sum}
              </td>
            </tr>
            <tr>
              <td>Cerah</td>
              <td>
                :{" "}
                <img
                  alt=""
                  src="http://bbwscc.sdatelemetry.com/images/icons/cerah.png"
                />{" "}
                {this.state.loadingPCH
                  ? "loading.."
                  : this?.props?.iot?.lastDataPCH?.status?.cerah}
              </td>
            </tr>
            <tr>
              <td>Ringan</td>
              <td>
                :{" "}
                <img
                  alt=""
                  src="http://bbwscc.sdatelemetry.com/images/icons/ringan.png"
                />{" "}
                {this.state.loadingPCH
                  ? "loading.."
                  : this?.props?.iot?.lastDataPCH?.status?.ringan}
              </td>
            </tr>
            <tr>
              <td>Sedang</td>
              <td>
                :{" "}
                <img
                  alt=""
                  src="http://bbwscc.sdatelemetry.com/images/icons/lebat.png"
                />{" "}
                {this.state.loadingPCH
                  ? "loading.."
                  : this?.props?.iot?.lastDataPCH?.status?.sedang}
              </td>
            </tr>
            <tr>
              <td>Lebat</td>
              <td>
                :{" "}
                <img
                  alt=""
                  src="http://bbwscc.sdatelemetry.com/images/icons/sangatlebat.png"
                />{" "}
                {this.state.loadingPCH
                  ? "loading.."
                  : this?.props?.iot?.lastDataPCH?.status?.lebat}
              </td>
            </tr>
            <tr>
              <td>Sangat Lebat</td>
              <td>
                :{" "}
                <img
                  alt=""
                  src="http://bbwscc.sdatelemetry.com/images/icons/sangatlebat.png"
                />{" "}
                {this.state.loadingPCH
                  ? "loading.."
                  : this?.props?.iot?.lastDataPCH?.status?.sangatlebat}
              </td>
            </tr>
            <tr>
              <td>Offline</td>
              <td>
                : <span style={s.error}>&#9746;</span>{" "}
                {this.state.loadingPCH
                  ? "loading.."
                  : this?.props?.iot?.lastDataPCH?.status?.offline}
              </td>
            </tr>
            <tr>
              <td>Delay</td>
              <td>
                : <span style={s.delay}>&#9746;</span>{" "}
                {this.state.loadingPCH
                  ? "loading.."
                  : this?.props?.iot?.lastDataPCH?.status?.delay}
              </td>
            </tr>
          </tbody>
        </table>

        <Typography style={s.clock}>
          <AccessTimeIcon></AccessTimeIcon> {this?.state?.clock}
        </Typography>

        <table className="table_pch">
          <tbody>
            <tr>
              <td></td>
              <td>Lokasi</td>
              <td>Tanggal</td>
              <td>Jam</td>
              <td>CH(mm)</td>
              <td></td>
            </tr>
            {(this?.props?.iot?.lastDataPCH?.list
              ? this?.props?.iot?.lastDataPCH?.list
              : []
            ).map((e, idx) => (
              <tr key={idx}>
                <td>
                  {e.status === "cerah" && (
                    <img
                      alt=""
                      src="http://bbwscc.sdatelemetry.com/images/icons/cerah.png"
                      width="20"
                    />
                  )}
                  {e.status === "ringan" && (
                    <img
                      alt=""
                      src="http://bbwscc.sdatelemetry.com/images/icons/ringan.png"
                      width="20"
                    />
                  )}
                  {e.status === "sedang" && (
                    <img
                      alt=""
                      src="http://bbwscc.sdatelemetry.com/images/icons/lebat.png"
                      width="20"
                    />
                  )}
                  {e.status === "lebat" && (
                    <img
                      alt=""
                      src="http://bbwscc.sdatelemetry.com/images/icons/sangatlebat.png"
                      width="20"
                    />
                  )}
                  {e.status === "sangatlebat" && (
                    <img
                      alt=""
                      src="http://bbwscc.sdatelemetry.com/images/icons/sangatlebat.png"
                      width="20"
                    />
                  )}
                  {e.status === "Delay" && <span style={s.delay}>&#9746;</span>}
                  {e.status === "Offline" && (
                    <span style={s.error}>&#9746;</span>
                  )}
                </td>
                <td>{e.nama_alat.toUpperCase()}</td>
                <td>{this.convertDate(e.ReceivedDate)}</td>
                <td>{e.ReceivedTime.slice(0, 5)}</td>
                <td>{parseFloat(e.Rain)}</td>
                <td>
                  {e.compare && e.compare.value === "up" && (
                    <span style={{ color: "red" }}>&uarr;</span>
                  )}
                  {e.compare && e.compare.value === "down" && (
                    <span style={{ color: "green" }}>&darr;</span>
                  )}
                  {e.compare && e.compare.value === "-" && <span>(-)</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <section style={{ textAlign: "center", marginTop: "1rem" }}>
          {/* <Tooltip title="Refresh Data">
            <IconButton size="small" onClick={this.refresh.bind(this, "pch")}>
              <RefreshIcon />
            </IconButton>
          </Tooltip> */}
        </section>
      </main>
    );

    return (
      <div>
        <p className="text_header">Pos Curah Hujan</p>
        {contentPCH}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  iot: state.iot,
});

export default connect(mapStateToProps, { get_pch })(SidebarPCH);
