/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/
import Modal from "../../Components/common_modal/Modal";
import ErrorFree from "../../Components/user/ErrorFree";
import PROJECT_CONTROL_PANEL from "../../Components/folder_project/PROJECT_CONTROL_PANEL";
import BI_CONTROL_PANEL from "../../Components/bi_basic/BI_CONTROL_PANEL";

import CreateProject from "../../Components/project_dashboard/CreateProject";
import CREATE_FOLDER_PROJECT from "../../Components/folder_project/CREATE_FOLDER_PROJECT";
import CREATE_BI from "../../Components/bi_basic/CREATE_BI";

import FOLDER_NAVIGATION from "../../Components/folder_project/FOLDER_NAVIGATION";
import FOLDER_PROJECT_MODE_BOX from "../../Components/folder_project/FOLDER_PROJECT_MODE_BOX";
import FOLDER_PROJECT_MODE_LIST from "../../Components/folder_project/FOLDER_PROJECT_MODE_LIST";
import PROJECT_MODE_BOX from "../../Components/project_dashboard/PROJECT_MODE_BOX";
import PROJECT_MODE_LIST from "../../Components/project_dashboard/PROJECT_MODE_LIST";

import BI_MENU_PAGE from "../../Components/dashboard_menu/BI_MENU_PAGE";
import FORM_MENU_PAGE from "../../Components/dashboard_menu/FORM_MENU_PAGE";
import MAP_SERVICE_MENU_PAGE from "../../Components/dashboard_menu/MAP_SERVICE_MENU_PAGE";

/*REDUX FUNCTION*/
import { getUserPaymentList } from "../../App/actions/paymentActions";
import { getGroups } from "../../App/actions/groupActions";
import { openModal } from "../../App/actions/authActions";
import { get_folder_project_list_by_user_id } from "../../App/actions/folderProjectAction";
import { get_pinned_project_list } from "../../App/actions/projectActions";
import { get_bi_list } from "../../App/actions/biActions";
import {
  verify_license_user,
  verify_license_group,
} from "../../App/actions/license_actions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { get_users_who_can_access_map_service } from "../../App/actions/map_service_actions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import get_url_query from "../../App/validation/get_url_query";

/*NON IMPORT*/

class ProjectListDashboard extends Component {
  state = {
    modal_create_project: false,
    modal_create_folder: false,
    modal_create_bi: false,
    mode_page: "projects",
    mode_view: "box",
    users_who_can_access_map_service: []
  };

  componentDidMount() {
    this.props.getUserPaymentList();
    this.props.getGroups();
    this.props.get_folder_project_list_by_user_id();
    this.props.get_pinned_project_list();
    this.props.get_bi_list();
    this.props.verify_license_user();
    this.props.verify_license_group();
    this.props.get_users_who_can_access_map_service();
  }

  toggle_create_folder = () => {
    this.setState({ modal_create_folder: !this.state.modal_create_folder });
  };

  toggle_create_project = () => {
    this.setState({
      modal_create_project: !this.state.modal_create_project,
    });
  };

  toggle_create_bi = () => {
    this.setState({
      modal_create_bi: !this.state.modal_create_bi,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    const mode_view = localStorage.getItem("mode_view") || "box";
    let menu = get_url_query("menu");
    menu = menu || "project";


    const { users_who_can_access_map_service } = this.props.map_service_reducer
    const { badge } = this.props.auth?.user

    //local state
    const { modal_create_project, modal_create_folder, modal_create_bi } =
      this.state;

    //global props
    const { license_user_status, license_group_status } =
      this.props.license_reducer;

    //content

    let create_project_content, create_folder_content, create_bi_content;

    if (
      license_user_status?.is_has_license ||
      license_group_status?.is_has_license
    ) {
      create_project_content = (
        <CreateProject toggle_create_project={this.toggle_create_project} />
      );
      create_folder_content = (
        <CREATE_FOLDER_PROJECT
          toggle_create_folder={this.toggle_create_folder}
        />
      );
      create_bi_content = (
        <CREATE_BI toggle_create_bi={this.toggle_create_bi} />
      );
    } else {
      create_project_content = <ErrorFree />;
      create_folder_content = <ErrorFree />;
      create_bi_content = <ErrorFree />;
    }

    const modal_create_project_content = modal_create_project && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_create_project}
        onClose={this.toggle_create_project}
      >
        <div className="box-body">{create_project_content}</div>
      </Modal>
    );

    const modal_create_folder_content = modal_create_folder && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_create_folder}
        onClose={this.toggle_create_folder}
      >
        <div className="box-body">{create_folder_content}</div>
      </Modal>
    );

    const modal_create_bi_content = modal_create_bi && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_create_bi}
        onClose={this.toggle_create_bi}
      >
        <div className="box-body">{create_bi_content}</div>
      </Modal>
    );

    const content_project = (
      <>
        <PROJECT_CONTROL_PANEL
          toggle_create_project={this.toggle_create_project}
          toggle_create_folder={this.toggle_create_folder}
          toggle_create_bi={this.toggle_create_bi}
        />
        {mode_view === "box" ? (
          <PROJECT_MODE_BOX title="Pinned" />
        ) : (
          <PROJECT_MODE_LIST title="Pinned" />
        )}

        <FOLDER_NAVIGATION />
        {mode_view === "box" ? (
          <FOLDER_PROJECT_MODE_BOX />
        ) : (
          <FOLDER_PROJECT_MODE_LIST />
        )}

        {mode_view === "box" ? <PROJECT_MODE_BOX /> : <PROJECT_MODE_LIST />}
      </>
    );

    const dashboard_menu_array = [
      {
        name: "PROJECT",
        value: "project",
      },
      {
        name: "BUSINESS INTELLIGENCE",
        value: "bi",
      },
      {
        name: "FORM",
        value: "form",
      },
      {
        name: "MAP SERVICES",
        value: "map_service",
      },
    ]?.filter(item =>
      (users_who_can_access_map_service.includes(badge)) ||
      (!users_who_can_access_map_service.includes(badge) && item?.value !== "map_service"));

    return (
      <div className="main_container">
        {modal_create_project_content}
        {modal_create_folder_content}
        {modal_create_bi_content}
        <section
          style={{
            backgroundColor: "#e4ecf1",
            position: "sticky",
            top: "60px",
            zIndex: "1",
            paddingTop: "10px",
          }}
        >
          {dashboard_menu_array.map((item, idx) => {
            return (
              <Link
                key={idx}
                className={`button_small margin_right margin_bottom ${menu === item.value ? "background_black" : "button_white"
                  }`}
                to={`/dashboard?menu=${item.value}`}
              >
                {item.name}
              </Link>
            );
          })}
        </section>
        <br />
        <br />

        {menu === "project" ? (
          content_project
        ) : menu === "bi" ? (
          <>
            <BI_CONTROL_PANEL
              toggle_create_project={this.toggle_create_project}
              toggle_create_folder={this.toggle_create_folder}
              toggle_create_bi={this.toggle_create_bi}
            />
            <BI_MENU_PAGE />
          </>
        ) : menu === "form" ? (
          <FORM_MENU_PAGE />
        ) : menu === "map_service" ? (
          <MAP_SERVICE_MENU_PAGE />
        ) : (
          content_project
        )}
        <Helmet>
          <title>{dict?.["Dashboard"]?.[language]}</title>
          <meta name="description" content={`${dict["Dashboard"][language]}`} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  payment: state.payment,
  auth: state.auth,
  project: state.project,
  license_reducer: state.license_reducer,
  properties: state.properties,
  map_service_reducer: state.map_service_reducer,
});

export default connect(mapStateToProps, {
  getUserPaymentList,
  getGroups,
  openModal,
  get_folder_project_list_by_user_id,
  get_pinned_project_list,
  get_bi_list,
  verify_license_user,
  verify_license_group,
  set_value_properties,
  get_users_who_can_access_map_service
})(ProjectListDashboard);
